import { map } from 'lodash';
import { PostOfficeList } from 'Pages/ChoosePostOffice/components';
import { ChoosePostOfficeTextEnum } from 'Pages/ChoosePostOffice/enums';
import PageTitle from 'Pages/common/PageTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import C2cOverPaymentServiceV1 from 'Services/v1/c2c-order-payment';
import { SelectPointModel } from 'Stores/c2c-order-payment';
import { orderBillSelectors } from 'Stores/order-bill';
import './styles.scss';

const ChoosePostOfficeContainer = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const navigate = useNavigate();
    const { senderAddress, receiverAddress } = useSelector(
      orderBillSelectors.get,
    );

    const [postOffices, setPostOffices] = useState<SelectPointModel[]>([]);

  useEffect(() => {
    if (!receiverAddress || !senderAddress) {
      navigate('/c2c-order-payment');
      return;
    }
    if ((state as any).type == "receiver")
      // Find Post Office
      // Tìm địa chỉ bưu cựu của người gửi
      C2cOverPaymentServiceV1.getPostOffice(receiverAddress!.lat, receiverAddress!.long).then((res) => {
        const dropPoints = res.data! || [];
        setPostOffices(
          map(dropPoints, (point) => ({
            id: point?.id,
            address: point?.address,
            latitude: point?.geo?.coordinates[1],
            longitude: point?.geo?.coordinates[0],
            name: point?.name,
            code: point?.code,
            point
          }))
        );
      });

    if ((state as any).type == "sender")
      // Tìm địa chỉ bưu cục gần người nhận
      C2cOverPaymentServiceV1.getPostOffice(senderAddress!.lat, senderAddress!.long).then((res) => {
        const dropPoints = res.data || [];
        setPostOffices(
          map(dropPoints, (point) => ({
            id: point?.id,
            address: point?.address,
            latitude: point?.geo?.coordinates[1],
            longitude: point?.geo?.coordinates[0],
            name: point?.name,
            code: point?.code,
            point
          }))
        );
      });
  }, [receiverAddress, senderAddress]);

  return (
    <>
      <PageTitle title={t(ChoosePostOfficeTextEnum.ChoosePostOffice_Title)} />
      <div className="choose-post-office-container">
        <PostOfficeList list={postOffices} type={(state as any).type}></PostOfficeList>
      </div>
    </>
  );
};

export default ChoosePostOfficeContainer;
