import { FormHookItemPropsModel } from 'Components/ReactFormHook/FormHookItem/formHookItem';
import { DTOOrderVATInfo } from 'Services/v1/cargo-direct/dto';
import invoiceIconUrl from 'Assets/icon/invoice-icon.svg';
import { PopupConfigModel } from 'Hooks';

export const E_INVOICE_FORM_CONSTANT: Array<
	FormHookItemPropsModel<DTOOrderVATInfo>
> = [
	{
		placeholder: 'placeholder_vat_email',
		label: 'Email',
		field: 'email',
		required: true,
		pattern: {
			value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			message: 'validation_email_pattern',
		},
		className: 'einvoice-form-field-container',
	},
	{
		placeholder: 'placeholder_vat_company_name',
		label: 'label_vat_company_name',
		field: 'companyName',
		required: true,
		className: 'einvoice-form-field-container',
	},
	{
		placeholder: 'placeholder_vat_company_address',
		label: 'label_vat_company_address',
		field: 'companyAddress',
		required: true,
		className: 'einvoice-form-field-container',
	},
	{
		placeholder: 'placeholder_vat_tax_number',
		label: 'label_vat_tax_number',
		field: 'taxNumber',
		required: true,
		className: 'einvoice-form-field-container',
	},
];

export const E_INVOICE_POPUP_CONFIG: PopupConfigModel = {
	icon: invoiceIconUrl,
	title: 'title_vat_form',
	hideFooter: true,
};
