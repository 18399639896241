import { Checkbox as AntdCheckBox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

export interface CheckBoxItem {
  label: string;
  value: string;
  disabled?: boolean;
}
interface Props {
  options: CheckBoxItem[];
  className?: string;
  defaultValue?: string[];
}

interface CheckBoxProps {
  checked?: boolean;
  onChange?: (e: any) => void;
  className?: string;
  value?: any;
  label?: string;
}

export const CheckBox = (props: CheckBoxProps) => {
  const { checked, onChange, className, value, label, ...rest } = props;

  const containerClasses = classnames(
    {
      [`${classPrefix}-checkbox-container`]: true,
      'd-flex align-items-center': true
    },
    className
  );

  return (
    <div className={containerClasses}>
      <AntdCheckBox
        {...rest}
        value={value}
        checked={checked}
        onChange={onChange}
      >
        {label && <span className={'ml-1'}>{label}</span>}
      </AntdCheckBox>
    </div>
  );
};

export const CheckBoxGroup = (props: Props) => {
  const { options, className, defaultValue } = props;

  const onChange = (checkedValues: CheckboxValueType[]) => {
    if (onChange) onChange(checkedValues);
  };

  const containerClasses = classnames(
    {
      [`${classPrefix}-checkbox-group-container`]: true
    },
    className
  );
  return (
    <>
      <div className={containerClasses}>
        <AntdCheckBox.Group
          options={options}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </div>
    </>
  );
};
