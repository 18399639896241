import FieldLabel from 'Components/FieldLabel';
import InputText from 'Components/InputText';
import { Controller, ValidationRule } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormHookErrorMessage } from '..';
import './styles.scss';
export interface FormHookItemPropsModel<T> {
	className?: string;
	field: keyof T;
	label?: string;
	control?: any;
	placeholder?: string;
	required?: boolean;
	messageError?: string;
	isShowMessageError?: boolean;
	pattern?: ValidationRule<RegExp>;
}

const FormHookItem = <T,>(props: FormHookItemPropsModel<T>) => {
	const { t } = useTranslation();
	const {
        className,
		field,
		label = '',
		control,
		placeholder = '',
		required = false,
		isShowMessageError = false,
		messageError = '',
		pattern,
	} = props;

	return (
		<div className={className}>
			<FieldLabel isRequired={true} value={t(label)} />
			<Controller
				control={control}
				rules={{
					required: { value: required, message: messageError },
					pattern: pattern,
				}}
				render={({ field }) => {
					return (
						<InputText {...field} placeholder={t(placeholder)} />
					);
				}}
				name={field as string}
			/>
			<FormHookErrorMessage
				isShow={isShowMessageError}
				fieldName={t(label)}
				message={messageError}
			/>
		</div>
	);
};

export default FormHookItem;
