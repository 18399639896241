import { MessageAlertModel } from 'Components/Models';
import { isEmpty } from 'lodash';
import { MESSAGE_ALERT_ERROR } from 'Pages/C2cOrderPayment/constants';
import { ValidationMessageEnum } from 'Pages/C2cOrderPayment/enums';
import { OrderBillState } from 'Stores/order-bill/models';
import { CargoValidationFieldModel } from '../models';

const CargoCheckValidationInputLogic = (orderBillState: OrderBillState) => {
  const {
    senderAddress,
    receiverAddress,
    paymentMethod,
    flightInfo,
    isTermAndConditionAgreed
  } = orderBillState;

  const checkSenderAddress = () => {
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError: !senderAddress || isEmpty(senderAddress),
      message: ValidationMessageEnum.OrderBillSenderAddressValid
    } as MessageAlertModel;
  };

  const checkReceiverAddress = () => {
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError: !receiverAddress || isEmpty(receiverAddress),
      message: ValidationMessageEnum.OrderBillReceiverAddressValid
    } as MessageAlertModel;
  };

  const checkPaymentMethod = () => {
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError: !paymentMethod || isEmpty(paymentMethod),
      message: ValidationMessageEnum.C2cNoChoosePaymentMethod
    } as MessageAlertModel;
  };

  const checkFlight = () => {
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError: !flightInfo || isEmpty(flightInfo),
      message: ValidationMessageEnum.OrderBillFlightValid
    } as MessageAlertModel;
  };

  const checkTermAndConditionAgreed = () => {
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError: !isTermAndConditionAgreed,
      message: ValidationMessageEnum.TermAndConditionAgreedValid
    } as MessageAlertModel;
  };

  const checkCargoInput = () => {
    return {
      senderAddress: checkSenderAddress(),
      receiverAddress: checkReceiverAddress(),
      paymentMethod: checkPaymentMethod(),
      flight: checkFlight(),
      termAndConditionAgreed: checkTermAndConditionAgreed()
    } as CargoValidationFieldModel;
  };

  const checkInValid = () => {
    const SenderAddressMs = checkSenderAddress();
    const ReceiverAddressMs = checkReceiverAddress();
    const paymentMethodMs = checkPaymentMethod();
    const flightMs = checkFlight();
    const termAndConditionAgreedMs = checkTermAndConditionAgreed();
    return (
      SenderAddressMs.hasError ||
      ReceiverAddressMs.hasError ||
      paymentMethodMs.hasError ||
      flightMs.hasError ||
      termAndConditionAgreedMs.hasError
    );
  };

  return {
    checkCargoInput,
    checkInValid,
    checkSenderAddress,
    checkReceiverAddress
  };
};

export default CargoCheckValidationInputLogic;
