import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { DTOGetAvailableFlightRequest } from 'Services/v1/flights/dto';
import flightSlice from 'Stores/flights';
import {
  clearFeeAndTax,
  clearFlightInfo,
  updateFlightFilter
} from 'Stores/order-bill';
import { DIMENSIONS_DEFAULT } from 'Utilities/constants';
import { FlightFilterInfo } from '.';

const dataFindFlight = {
	query: {},
	data: {} as FlightFilterInfo,
};

const useFindFlight = () => {
	const dispatch = useDispatch();

	const findFlight = (query: DTOGetAvailableFlightRequest, data: FlightFilterInfo) => {
		dataFindFlight.query = query;
		dataFindFlight.data = data;
		dispatchFindFlight();
	};

	const reload = () => {
		dispatchFindFlight();
	};

	const dispatchFindFlight = () => {
    dispatch(flightSlice.actions.availableFlightsActions.reset())
		if (isEmpty(dataFindFlight?.data) || isEmpty(dataFindFlight?.query)) {
			return;
		}
		dispatch(
			flightSlice.actions.availableFlightsActions.getting(
				dataFindFlight?.query,
			),
		);
		dispatch(clearFlightInfo());
		dispatch(clearFeeAndTax());
		dispatch(updateFlightFilter(dataFindFlight?.data));
	};

	const resetFlightFilter = () => {
		dispatch(updateFlightFilter({ weight: 1, dimension: DIMENSIONS_DEFAULT }));
	};

	return {
		findFlight,
		reload,
		resetFlightFilter,
	};
};
export default useFindFlight;
