import productClassificationIcon from 'Assets/icon/product-classification-icon.svg';
import Section from 'Components/Section';
import { usePopup } from 'Hooks';
import { CONFIRM_SELECT_PRODUCT_POPUP } from 'Pages/C2cOrderPayment/constants';

import {
	C2cCategoryPackageTypeEnum,
	C2cOverPaymentTextEnum,
} from 'Pages/C2cOrderPayment/enums';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	c2cOrderPaymentSelectors,
	C2cOverPaymentState,
	choosePackageTypeAction,
	setDeclarationPolicyAgreement,
} from 'Stores/c2c-order-payment';
import { grapSelectors } from 'Stores/grap';
import { GrapBlogsSlugEnums } from 'Stores/grap/enums';
import { layoutUtilitiesSliceSelectors } from 'Stores/layout-utilities';
import { LanguageTypeEnum } from 'Stores/layout-utilities/enums';
import { ProductTypeSwitch } from '..';
import { ButtonAddProduct } from '../c2cPickedUpProduct';
import GoodsInformation from '../GoodsInformation/GoodsInformation';
import './index.scss'

const ProductClearanceDeclaration = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const popup = usePopup();
	const { currentLanguage } = useSelector(layoutUtilitiesSliceSelectors.get);
	const { isGlobal } = useSelector(c2cOrderPaymentSelectors.get);

	const {
		packageTypeSelected,
		typeOrder,
		declarationPolicyAgreement,
	} = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;
	const { blogs } = useSelector(grapSelectors.get);


	const getDeclarationPolicyContent = () => {
		let fieldDes = '';
		switch (currentLanguage) {
			case LanguageTypeEnum.VietNam:
				fieldDes = 'descriptionVi';
				break;
			case LanguageTypeEnum.Korea:
				fieldDes = 'descriptionKo';
				break;
			case LanguageTypeEnum.English:
				fieldDes = 'descriptionEn';
				break;
		}
		const description = blogs
			?.find((blog: any) => blog?.slug === GrapBlogsSlugEnums.Rules)
			?.[fieldDes]?.toString();
		return description?.split('.');
	};


	const handleChangePackage = (actionType: C2cCategoryPackageTypeEnum) => {
		if (
			actionType === C2cCategoryPackageTypeEnum.Document &&
			!declarationPolicyAgreement?.acceptedDocumentRules &&
			isGlobal
		) {
			openDeclarationPolicyAgreementPopup(true);
			return;
		}
	};

	const handleAddProductDeclaration = () => {
		if (declarationPolicyAgreement?.acceptedParcelRules) {
			openPopupAddGoods();
			return;
		}
		openDeclarationPolicyAgreementPopup(false);
	};

	const openPopupAddGoods = () => {
		popup.onOpen(
			{
				title: 'c2c_goods_info_title',
				hideFooter: true,
				maxHeight: '90vh',
				height: 794,
				maxWidth: 1000
			},
			<GoodsInformation></GoodsInformation>
		);
	};

	const openDeclarationPolicyAgreementPopup = (isDocument: boolean) => {
		popup.onOpen(
			{
				...CONFIRM_SELECT_PRODUCT_POPUP,
				title: 'global_confirm_declared_info',
				hideBtnCancel: true,
				btnFullWidth: true,
				maxWidth: 450,
				okBtnLabel: 'global_accept_action',
				onSubmit: (isAccept: boolean) => {
					if (isAccept) {
						onAcceptRules(isDocument);
					}
				}
			},
			getDeclarationPolicyContent()
				?.filter((des: string) => des?.trim() !== '')
				?.map((des: string) => <p key={des}>{des}.</p>)
		);
	};

	const onAcceptRules = (isDocument: boolean) => {
		if (isDocument) {
			dispatch(
				setDeclarationPolicyAgreement({
					...declarationPolicyAgreement,
					acceptedDocumentRules: true
				})
			);
			dispatch(choosePackageTypeAction(C2cCategoryPackageTypeEnum.Document));
			return;
		}
		dispatch(
			setDeclarationPolicyAgreement({
				...declarationPolicyAgreement,
				acceptedParcelRules: true
			})
		);
		// navigate('/goods-info');
		openPopupAddGoods();
	};

	return (
		<Section
			title={t(C2cOverPaymentTextEnum.ProductClassification_Title)}
			icon={<img src={productClassificationIcon} alt={''} />}
		>
			<div className="product-clearance-declaration">

				<ProductTypeSwitch
					typeOrder={typeOrder}
					packageTypeSelected={packageTypeSelected}
					handleChangePackage={handleChangePackage}
				></ProductTypeSwitch>

				{isGlobal &&
				packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel && (
					<ButtonAddProduct
						onClick={handleAddProductDeclaration}
					></ButtonAddProduct>
				)}
			</div>


		</Section>
	);
};

export default ProductClearanceDeclaration;
