import RootServiceV1 from 'Services/v1/root';
import { DTOServiceResponse } from 'Services/interfaces';
import {
	DTOChangePasswordRequest,
	DTOChangePasswordRequestRaw,
	DTOGetUserProfileRaw,
	DTOUserProfile,
	DTOUserProfileRaw, DTOUserProfileUpdateRequest,
	DTOUserProfileUpdateRequestRaw,
} from 'Services/v1/user/dto';
import httpClient from 'Services/http-client';
import { AxiosResponse } from 'axios';
import { CamelSnakeMapper } from 'Services/data-mappers';
import { DTOCreateOrderVATInfoRequest, DTOCreateOrderVATInfoRequestRaw } from 'Services/v1/cargo-direct/dto';

class UserServiceV1 extends RootServiceV1 {
	constructor() {
		super();
	}

	public async getProfile(): Promise<DTOServiceResponse<DTOUserProfile>> {
		try {
			const response = (await httpClient().get('/v1/user/profile')) as AxiosResponse<DTOGetUserProfileRaw>;

			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase<DTOUserProfileRaw, DTOUserProfile>)(data)
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}
	}

	public async updateProfile(payload: DTOUserProfileUpdateRequest): Promise<DTOServiceResponse<DTOUserProfile>> {
		try {

			const rawPayload = CamelSnakeMapper.toSnakeCase<DTOUserProfileUpdateRequest, DTOUserProfileUpdateRequestRaw>(payload);

			const response = (await httpClient().put('/v1/user/profile', rawPayload)) as AxiosResponse<DTOGetUserProfileRaw>;

			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase<DTOUserProfileRaw, DTOUserProfile>)(data)
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}
	}

	public async changePassword(payload: DTOChangePasswordRequest):Promise<DTOServiceResponse<any>> {
		try {
			const rawPayload = CamelSnakeMapper.toSnakeCase<DTOChangePasswordRequest, DTOChangePasswordRequestRaw>(payload);
			const urlFormPayload = new URLSearchParams(rawPayload as any);

			const response = (await httpClient().put('/v1/user/password', urlFormPayload)) as AxiosResponse<DTOGetUserProfileRaw>;

			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase<DTOUserProfileRaw, DTOUserProfile>)(data)
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}
	}
}

export default new UserServiceV1();