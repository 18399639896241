import images from 'Assets/images';
import i18next from 'i18next';
import { DTOPaymentCardGroup } from 'Services/v1/payment/dto';
import { TypePayment } from 'Utilities/constants';
import { numberWithCommas, toUpperLetter } from 'Utilities/util';

const PaymentVnMethod = (
  pointTopup: number,
  isEnoughTopupPoint: boolean,
  paymentMethods: DTOPaymentCardGroup[]
) => {

  const customDescription = () => {
    return (
      <>
        <div>
          {
            i18next.t('topup_value_point_description', {
              point: numberWithCommas(pointTopup)
            }) as string
          }
        </div>
        {!isEnoughTopupPoint && (
          <em className="text-danger">
            {i18next.t('global_not_enough_point') as string}
          </em>
        )}
      </>
    );
  };

  const setPaymentTopup = (paymentTopup: DTOPaymentCardGroup) => {
    return {
      ...paymentTopup,
      customDescription,
      disabled: !isEnoughTopupPoint,
      urlImage: images.iconTopUp,
      width: 20,
      height: 20
    };
  };

  const renderPaymentC2C = (avaiPaymentMethods: string[] = []) => {
    let payments: DTOPaymentCardGroup[] = [];
    avaiPaymentMethods?.forEach(avaiPaymentMethod => {
      switch (toUpperLetter(avaiPaymentMethod)) {
        case TypePayment.TOPUP:
          const paymentTopup = paymentMethods.find(
            paymentMethod =>
              toUpperLetter(paymentMethod.code) === TypePayment.TOPUP
          );
          if (paymentTopup) {
            payments.push(setPaymentTopup(paymentTopup));
          }
          break;
        case toUpperLetter(TypePayment.CASH):
          payments.push({
            code: TypePayment.CASH,
            nameEng: 'payment_method_cash',
            nameVn: 'payment_method_cash',
            description: 'payment_method_cash_description',
            urlImage: images.cash,
            width: 27,
            height: 18
          });
          break;
        case TypePayment.VNBANK:
          const paymentVnBank = paymentMethods.find(
            paymentMethod =>
              toUpperLetter(paymentMethod.bankType) === TypePayment.VNBANK
          );
          if (paymentVnBank) payments.push(paymentVnBank);
          break;
        case TypePayment.INTCARD:
          const paymentsIntCard = paymentMethods.filter(
            paymentMethod =>
              toUpperLetter(paymentMethod.bankType) === TypePayment.INTCARD
          );
          if (paymentsIntCard?.length > 0) {
            payments = [...payments, ...paymentsIntCard];
          }
          break;
        default:
          break;
      }
    });

    return payments;
  };

  const renderPaymentCargo = () => {
    return paymentMethods.map(paymentMethod => {
      if (toUpperLetter(paymentMethod.code) === TypePayment.TOPUP) {
        return setPaymentTopup(paymentMethod);
      }
      return paymentMethod;
    });
  };

  return { renderPaymentC2C, renderPaymentCargo };
};

export default PaymentVnMethod;
