import { Radio } from '@swift-247/s247.library.ui-core';
import { ReactNode } from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

export interface RadioSelectCardProps {
	className?: string
	checked: boolean,
	disabled?: boolean,
	onChange?: (val: boolean) => void;
	children?: ReactNode,
	cardAction?: ReactNode,
	onClick?: () => void
}

const RadioSelectCard = (props: RadioSelectCardProps) => {
	const {
		checked,
		className,
		disabled,
		onChange,
		children,
		cardAction,
		onClick
	} = props

	const containerClasses = classnames(
		{
			[`${classPrefix}-radio-select-card`]: true,
			[`${classPrefix}-radio-select-card--checked`]: checked,
			[`${classPrefix}-radio-select-card--disabled`]: disabled,
		},
		className
	)

	return <div onClick={onClick} className={containerClasses}>
		<div className={'d-flex justify-content-start align-items-start'}>
			<div className={`${classPrefix}-radio-select-card-radio`}>
				<Radio
					onChange={(e) => onChange && onChange(e.target.checked)}
					checked={checked}
					disabled={disabled}
				/>
			</div>
			<div className={`${classPrefix}-radio-select-card-body`}>
				{children}
			</div>
		</div>

		<div className={`${classPrefix}-radio-select-card-action`}>
			{cardAction}
		</div>
	</div>
}


export default RadioSelectCard;
