import { useDispatch, useSelector } from 'react-redux';
import { DTOAvailableFlight } from 'Services/v1/flights/dto';
import { orderBillSelectors, updateFlightInfo } from 'Stores/order-bill';
import { FlightItemDisplayOnMobile } from '..';
import './styles.scss';

interface SelectFlightTableProps {
  data: Array<DTOAvailableFlight>;
  isLoading?: boolean;
}

const FlightListDisplayOnMobile = (props: SelectFlightTableProps) => {
  const { data } = props;
  const dispatch = useDispatch();
  const selectedFlight = useSelector(orderBillSelectors.get).flightInfo;
  const onSelectedFlight = (flight: DTOAvailableFlight) => {
    dispatch(updateFlightInfo(flight));
  };
  return (
    <>
      {data?.map((item) => (
        <FlightItemDisplayOnMobile
          flight={item}
          key={item.flightId}
          isActive={selectedFlight?.flightId === item?.flightId}
          onClick={(flight) => onSelectedFlight(flight)}
        ></FlightItemDisplayOnMobile>
      ))}
    </>
  );
};

export default FlightListDisplayOnMobile;
