import images from 'Assets/images';
import { OrderItem } from 'Pages/MyOrders/components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { myOrdersSelectors, myOrdersSliceActions } from 'Stores/my-orders';
import { OrderEmptyItemDto } from '../Models';
import { OrderEmptyItem } from '../OrderEmptyItem';

type ContextType = { orderType: string };
const OrderHistoryList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myOrders = useSelector(myOrdersSelectors.get).orderReducer.list;
  const [searchParams] = useSearchParams();
  const orderType =
    searchParams.get('type') && searchParams.get('type') === 'ALL'
      ? ''
      : searchParams.get('type');

  useEffect(() => {
    // page size default as 25
    dispatch(
      myOrdersSliceActions.ordersAction.getting({
        type: orderType,
        perPage: 25,
        page: 1
      })
    );
  }, []);
  const orderEmpty: OrderEmptyItemDto = {
    iconUrl: images.orderEmptyIconUrl,
    message: t('global_no_order'),
    labelButton: t('global_order_now')
  };

  return (
    <div className={'d-flex flex-column gap-4'}>
      {!myOrders?.length && <OrderEmptyItem data={orderEmpty}></OrderEmptyItem>}
      {myOrders.map(order => {
        return <OrderItem key={order.orderId} data={order} />;
      })}
    </div>
  );
};

export default OrderHistoryList;
