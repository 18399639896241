import {
  convertCamelToSnakeCase,
  convertSnakeCaseToCamel,
} from '../common/utilities';
import { get as _get, isArray, isPlainObject } from 'lodash';
import { DTOLocation, DTOLocationOption } from './v1/locations/dto';

export const CamelSnakeMapper = {
  toSnakeCase: <D, R>(data: D): R => {
    if (!data) return undefined as any;

    const newData: R = {} as any;
    Object.keys(data).forEach((key) => {
      const newKey = convertCamelToSnakeCase(key);
      newData[newKey as keyof typeof newData] = data[
        key as keyof typeof data
      ] as any;
    });
    return newData;
  },
  withRecursion: (mapperFunction: any): (<D, R>(data: D) => R) => {
    const recursiveFunc = <D, R>(data: D): R => {
      const newData = mapperFunction(data);

      Object.keys(newData).forEach((key) => {
        if (isPlainObject(newData[key])) {
          newData[key] = recursiveFunc(newData[key]);
        } else if(isArray(newData[key])) {
          newData[key] = newData[key].map((item: any) => {
            if(isPlainObject(item)) return recursiveFunc(item);
            return item;
          })
        }
      });

      return newData;
    };
    return recursiveFunc;
  },
  toCamelCase: <D, R>(data: D): R => {
    if (!data) return undefined as any;

    const newData: R = {} as any;
    Object.keys(data).forEach((key) => {
      const newKey = convertSnakeCaseToCamel(key);
      newData[newKey as keyof typeof newData] = data[
        key as keyof typeof data
      ] as any;
    });
    return newData;
  },
};

export interface DataMapperDictionary {
  [key: string]: string;
}

export const DictionaryMapper = {
  toOnlyDictValues: <D, R>(data: D, dict: DataMapperDictionary): R => {
    if (!data) return undefined as any;

    const newData: R = {} as any;
    Object.keys(dict).forEach((dictKey) => {
      newData[dictKey as keyof typeof newData] = _get(data, dict[dictKey]);
    });

    return newData;
  },

  toAllDictValues: <D, R>(data: D, dict: DataMapperDictionary): R => {
    if (!data) return undefined as any;

    const newData: R = {} as any;
    const listDataMapper = Object.keys(dict).map(item=>dict[item]);

    Object.keys(data).forEach((item) => {
      if (!(listDataMapper.indexOf(item) >= 0)) {
        dict[item] = item;
      }
    });

    Object.keys(dict).forEach((dictKey) => {
      newData[dictKey as keyof typeof newData] = _get(data, dict[dictKey]);
    });

    return newData;
  },
};

export const convertLocationDataToSelectOption = (
  locations: DTOLocation[],
): DTOLocationOption[] => {
  const locationData = Object.values(locations);
  return locationData.map((item: DTOLocation) => ({
    label: item.name,
    value: item.id,
  }));
};
