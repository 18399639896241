import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import iconTakeOffPlaneUrl from 'Assets/icon/take-off-plane.svg';
import iconChevronDownUrl from 'Assets/icon/chevron-down.svg';

import './index.scss';
import { DTOAirportInfo } from 'Services/v1/flights/dto';
import InputErrorMessage from 'Components/InputErrorMessage';
import { useTranslation } from 'react-i18next';
import { tAirport } from '../../localization';
const { Option } = Select;


export interface AirportDropdownSelectProps {
	options: DTOAirportInfo[];
	placeholder?: string;
	value?: string;
	disabled?: boolean;
	onChange?: (value: any, e: DTOAirportInfo) => void;
	className?: string;
	status?: 'error' | 'warning';
	errorMessage?: string;
	loading?: boolean
}
const AirportDropdownSelect = (props: AirportDropdownSelectProps) => {
	const { options, placeholder, value, onChange, className, disabled, status, errorMessage, loading } = props;
	const {t} = useTranslation();
	const containerClasses = classnames({
		[`${classPrefix}-airport-dropdown-select`]: true,
		[`${classPrefix}-airport-dropdown-select--disabled`]: disabled,
		[`${classPrefix}-airport-dropdown-select--error`]: !loading && !!errorMessage,
	}, className);
	return (
		<>
			<div className={containerClasses}>
				<img className={`${classPrefix}-airport-dropdown-select-icon`} src={iconTakeOffPlaneUrl} alt={''}/>
				<Select
					disabled={disabled || loading}
					placeholder={loading ? t('placeholder_airport_loading') : placeholder}
					onChange={(value: any, option:any) => {
						onChange && onChange(value, option.data)
					}}
					dropdownClassName={`${classPrefix}-airport-dropdown-select-list`}
					value={value}
					suffixIcon={<img src={iconChevronDownUrl} alt={''}/>}
					getPopupContainer={() => document.getElementById('root') as HTMLDivElement} // fix weird scroll dropdown
					status={status}
				>
					{options && options.map((e) => (
						<Option data={e} value={e.key} key={e.key}>
							{e.key} - {tAirport(e.name)}
						</Option>
					))}
				</Select>
				{!loading && errorMessage && <InputErrorMessage className={'mt-3'} message={errorMessage} />}
			</div>
		</>
	);
}

export default AirportDropdownSelect;