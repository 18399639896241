import { FeeRecord } from 'Components/DeliveryMethod';
import EInvoiceFormPopup from 'Components/EInvoiceFormPopup/eInvoiceFormPopup';
import { TaxAndFeeLayout } from 'Components/index';
import { usePopup } from 'Hooks';
import {
  E_INVOICE_POPUP_CONFIG,
  MESSAGE_ALERT_ERROR
} from 'Pages/C2cOrderPayment/constants';
import { ValidationMessageEnum } from 'Pages/C2cOrderPayment/enums';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingBarRef } from 'react-top-loading-bar';
import CargoDirectServiceV1 from 'Services/v1/cargo-direct/';
import { DTOOrderVATInfo, DTOPricing } from 'Services/v1/cargo-direct/dto';
import { updateLoadingOverlay } from 'Stores/layout-utilities';
import {
  checkValidationInputAction,
  createOrder,
  orderBillSelectors,
  setCargoValidationInputByField,
  setToggleOrder,
  updateFeeAndTax,
  updateIsTermAndConditionAgreedAction,
  updateVATInfo,
  updateVATInfoStatus
} from 'Stores/order-bill';
import classnames from 'Utilities/classnames';
import CargoCheckValidationInputLogic from '../logics/cargo-check-validation-input.logic';
import useFindFlight from '../SelectFlightFilter/useFindFlight';
import './index.scss';

interface TaxAndFeeProps {
  className?: string;
}

const TaxAndFee = (props: TaxAndFeeProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const findFlight = useFindFlight();
  const popup = usePopup();
  const orderBill = useSelector(orderBillSelectors.get);
  const {
    feeAndTax,
    flightFilter,
    flightInfo,
    senderAddress,
    receiverAddress,
    newOrder,
    vatInfo,
    isTermAndConditionAgreed,
    cargoValidateFields,
    toggleOrder,
    paymentMethod
  } = orderBill;

  const loadingRef = useRef<LoadingBarRef>(null);
  const [isFirst, setIsFirst] = useState(true);
  const setIsExportVATInvoice = (val: boolean) =>
    dispatch(updateVATInfoStatus(val));

  const handleOnSubmitOrder = () => {
    dispatch(setToggleOrder());
    const checkInputCargo = CargoCheckValidationInputLogic(orderBill);
    const checkDataInput = checkInputCargo.checkCargoInput();
    dispatch(checkValidationInputAction(checkDataInput));

    setIsFirst(false);
    if (checkInputCargo.checkInValid()) return;
    dispatch(updateLoadingOverlay(true));
    dispatch(createOrder());
  };

  useEffect(() => {
    if (!isFirst) {
      const checkInputCargo = CargoCheckValidationInputLogic(orderBill);
      dispatch(checkValidationInputAction(checkInputCargo.checkCargoInput()));
    }
  }, [paymentMethod, flightInfo]);

  useEffect(() => {
    if (!newOrder) return;
    if (newOrder.status === 'processing') {
      loadingRef?.current?.continuousStart(20, 2);
    } else if (
      newOrder.status === 'completed' ||
      newOrder.status === 'failed' ||
      newOrder.status === 'load_not_available'
    ) {
      dispatch(updateLoadingOverlay(false));
      loadingRef?.current?.complete();
    }
    if (newOrder.status === 'failed' || newOrder.status === 'invalid_input') {
      toast.error(newOrder.errorMsg || t('global_error_general'), {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    if (newOrder.status === 'load_not_available') {
      // toast.error(t('order_bill_input_validation_flight_out_of_load'));
      popup.onOpen(
        {
          okBtnLabel: 'order_bill_select_flight_filter',
          cancelBtnLabel: 'buttons_exit',
          btnFullWidth: false,
          btnReverse: false,
          onSubmit: () => findFlight.reload()
        },
        t('order_bill_input_validation_flight_out_of_load')
      );
    }
  }, [newOrder]);

  useEffect(() => {
    if (!senderAddress?.lat || !senderAddress?.long) return;
    if (!receiverAddress?.lat || !receiverAddress?.long) return;
    if (!flightInfo?.flightId) return;
    if (!flightFilter) return;

    dispatch(updateLoadingOverlay(true));
    CargoDirectServiceV1.getPricing({
      weight: flightFilter.weight || 0,
      originDropoffPoint: flightFilter?.departureAirport?.key || '',
      destinationDropoffPoint: flightFilter?.arrivalAirport?.key || '',
      productCode: flightFilter?.commodity?.code || '',
      dimension: flightFilter?.dimension || [],
      fromLat: senderAddress.lat,
      fromLng: senderAddress.long,
      toLat: receiverAddress.lat,
      toLng: receiverAddress.long,
      flightId: flightInfo.flightId,
      grossWeight: flightFilter?.weight || 0,
      originId: [
        String(senderAddress?.country),
        String(senderAddress?.province),
        String(senderAddress?.district),
        String(senderAddress?.village)
      ],
      destinationId: [
        String(receiverAddress?.country),
        String(receiverAddress?.province),
        String(receiverAddress?.district),
        String(receiverAddress?.village)
      ]
    }).then(res => {
      dispatch(updateLoadingOverlay(false));
      dispatch(updateFeeAndTax(res?.data as DTOPricing));
    });
  }, [
    flightFilter,
    flightInfo?.flightId,
    senderAddress?.lat,
    senderAddress?.long,
    receiverAddress?.lat,
    receiverAddress?.long
  ]);

  const { className } = props;

  const containerClasses = classnames('tax-and-fee', className);

  const openPopupEInvoiceForm = () => {
    popup.onOpen(
      {
        ...E_INVOICE_POPUP_CONFIG,
        onSubmit: (data: DTOOrderVATInfo) => {
          dispatch(updateVATInfoStatus(!!data));
          if (data) {
            dispatch(updateVATInfo(data));
          }
        }
      },
      <EInvoiceFormPopup></EInvoiceFormPopup>
    );
  };

  return (
    <TaxAndFeeLayout
      validate={cargoValidateFields?.termAndConditionAgreed}
      className={containerClasses}
      isExportVATInvoice={vatInfo.isEnabled}
      handleOnSubmit={handleOnSubmitOrder}
      setIsExportVATInvoice={setIsExportVATInvoice}
      openPopupEInvoiceForm={openPopupEInvoiceForm}
      isTermAndConditionAgreed={isTermAndConditionAgreed}
      toggleTermAndCondition={(checked: boolean) => {
        dispatch(updateIsTermAndConditionAgreedAction(checked));
        dispatch(
          setCargoValidationInputByField({
            key: 'termAndConditionAgreed',
            value: {
              ...MESSAGE_ALERT_ERROR,
              message: ValidationMessageEnum.TermAndConditionAgreedValid,
              ...cargoValidateFields?.termAndConditionAgreed,
              hasError: !checked
            }
          })
        );
      }}
      toggleOrder={toggleOrder}
    >
      <div className={'tax-and-fee-records'}>
        <FeeRecord
          className={'mb-2'}
          label={t('global_delivery_fee')}
          value={Math.max(feeAndTax?.price || 0, feeAndTax?.minFee || 0)}
        />
      </div>
      <FeeRecord
        label="global_total"
        className="mb-4"
        isPrimary={true}
        value={Math.max(feeAndTax?.price || 0, feeAndTax?.minFee || 0)}
      ></FeeRecord>
    </TaxAndFeeLayout>
  );
};

export default TaxAndFee;
