import images from 'Assets/images';
import ButtonClick from 'Components/ButtonClick';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classnames from 'Utilities/classnames';
import { LANGUAGE_KEY, PHONE_SERVICE } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { SidebarTypeEnum } from '../Enums';
import { SideBarItemModel } from '../Models';
import UserAvatar from '../UserAvatar';
import './index.scss';

interface MenuItemListProps {
	items: Array<SideBarItemModel>;
	className?: string;
}
interface MenuSidebarProps {
	items: SideBarItemModel[];
}

const MenuItemList = (props: MenuItemListProps) => {
	const { className, items } = props;
	const { t, i18n } = useTranslation();
	const containerClasses = classnames(
		`${classPrefix}-menu-sidebar ps-0`,
		className,
	);
	const language = (i18n.language || 'en') as LANGUAGE_KEY;

	return (
		<ul className={containerClasses}>
			{items.map((item) => (
				<div key={item.id}>
					<li className="d-flex align-items-center mb-4">
						{SidebarTypeEnum.CallCenter !== item.id && (
							<NavLink
								className={({ isActive }) =>
									isActive ? 'active-link' : undefined
								}
								to={item.link}
							>
                <span className="d-flex align-items-center">
                  {item.icon && <span className="mr-2">{item.icon}</span>}
                  {t(item.text)}
                </span>
							</NavLink>
						)}

						{SidebarTypeEnum.CallCenter === item.id && (
							<a
								href={`tel:+${PHONE_SERVICE[language]}`}
								className="font-weight-bold"
							>
								{t(item.text)}
							</a>
						)}
					</li>
					{item.subItems && (
						<ul>
							<MenuItemList
								className={`${classPrefix}-menu-sidebar-sub`}
								items={item.subItems}
							/>
						</ul>
					)}
				</div>
			))}
		</ul>
	);
};

const Sidebar = (props: { items: Array<SideBarItemModel> }) => {
	const { items } = props;
	const [isShowSidebar, setIsShowSidebar] = useState(false);

	const toggleSideBar = () => {
		setIsShowSidebar(!isShowSidebar);
	};

	return (
		<div className={`${classPrefix}-sidebar-wrap mt-8`}>
			<div className={`${classPrefix}-sidebar-user-avatar`}>
				<UserAvatar></UserAvatar>
			</div>
			<img
				src={images.hamburgerMenuIconUrl}
				alt="icon"
				width="14"
				className={`${classPrefix}-sidebar-menu-icon`}
				onClick={toggleSideBar}
			/>
			<div
				className={`${classPrefix}-sidebar ${
					isShowSidebar ? 'show-sidebar' : 'hide-sidebar'
				} mt-8 mb-6`}
			>
				{items && <MenuItemList items={items} />}
			</div>
			{isShowSidebar && (
				<ButtonClick
					variant="ghost"
					htmlType="submit"
					containerClassName="text-right sidebar-exit-button"
					className=""
					onClick={toggleSideBar}
				>
					Thoát
				</ButtonClick>
			)}
		</div>
	);
};

export default Sidebar;
