import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from '@swift-247/s247.library.ui-core';
import { Tooltip } from 'antd';
import iconTakeOffPlanePrimaryUrl from 'Assets/icon/take-off-plane-primary.svg';
import AirportDropdownSelect from 'Components/AirportDropdownSelect';
import InputNumber from 'Components/InputNumber';
import InputText from 'Components/InputText';
import MessageAlert from 'Components/MessageAlert/messageAlert';
import RangeDate from 'Components/RangeDate';
import Section from 'Components/Section';
import SelectBox from 'Components/SelectBox';
import { usePopup } from 'Hooks';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DTOAirportInfo,
  DTOCommodity,
  DTOGetAvailableFlightRequest
} from 'Services/v1/flights/dto';
import {
  default as flightSlice,
  default as flightsSlice
} from 'Stores/flights';
import { FlightsState } from 'Stores/flights/models';
import { RootState } from 'Stores/index';
import {
  clearFeeAndTax,
  orderBillSelectors,
  updateFlightInfo
} from 'Stores/order-bill';
import {
  COMMODITY_DESCRIPTION,
  DATE_FORMATS,
  DEFAULT_DAY_LEAD_TIME_ORDER,
  DIMENSIONS_DEFAULT,
  TIME_ZONE
} from 'Utilities/constants';
import CommodityPopup from '../CommodityPopup';
import { DimensionModel } from '../models';
import './index.scss';
import useFindFlight from './useFindFlight';

import minusIcon from 'Assets/icon/minus-icon.svg';
import plus from 'Assets/icon/plus.svg';
import { MESSAGE_ALERT_ERROR } from 'Pages/C2cOrderPayment/constants';
import classnames from 'Utilities/classnames';
import { toUpperLetter } from 'Utilities/util';

export interface FlightFilterInfo {
  departureAirport?: DTOAirportInfo;
  arrivalAirport?: DTOAirportInfo;
  commodity?: DTOCommodity | null;
  weight?: number;
  dimension?: DimensionModel[];
  rangeDate?: string[];
  currentRangeDateFilter?: string[];
}

const SelectFlightFilter = () => {
  // TODO: Complex logic (too many effect, chain effects) below need refactoring
  const { t } = useTranslation();
  const popup = usePopup();
  const dispatch = useDispatch();
  const findFlight = useFindFlight();

  const {
    list: departureAirports = [],
    fetchingStatus: departureAirportsFetchingStatus
  } = useSelector<RootState, FlightsState>(
    flightsSlice.selectors.get
  ).departureAirports;
  const {
    list: arrivalAirports = [],
    fetchingStatus: arrivalAirportsFetchingStatus,
    errCode
  } = useSelector<RootState, FlightsState>(
    flightsSlice.selectors.get
  ).arrivalAirports;
  const availableFlightState = useSelector<RootState, FlightsState>(
    flightSlice.selectors.get
  ).availableFlights;

  const summaryDimensionWeightState = useSelector<RootState, FlightsState>(
    flightSlice.selectors.get
  ).summaryDimensionWeight;

  const formDateDefault = moment().format(DATE_FORMATS.DDMMYYYY);
  const toDateDefault = moment()
    .add(DEFAULT_DAY_LEAD_TIME_ORDER, 'days')
    .format(DATE_FORMATS.DDMMYYYY);

  const [open, setOpen] = useState<boolean | undefined>(undefined);
  const [classNameCommodity, setClassNameCommodity] = useState<string>('');

  // stored form state
  const {
    senderAddress,
    receiverAddress,
    flightFilter,
    cargoValidateFields,
    toggleOrder
  } = useSelector(orderBillSelectors.get);

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState,
    resetField
  } = useForm<FlightFilterInfo>({
    mode: 'onChange',
    defaultValues: {
      arrivalAirport: flightFilter.arrivalAirport,
      departureAirport: flightFilter.departureAirport,
      commodity: flightFilter.commodity,
      weight: flightFilter.weight,
      dimension: DIMENSIONS_DEFAULT,
      rangeDate: [formDateDefault, toDateDefault]
    }
  });

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'dimension'
  });

  const watchSummaryDimensionWeight = useWatch({
    control,
    name: ['commodity', 'weight', 'dimension']
  });

  const isSelectDepartureAirportDisabled =
    !senderAddress || !departureAirports || departureAirports.length === 0;

  const isSelectArrivalAirportDisabled =
    !receiverAddress ||
    !watch('departureAirport') ||
    !arrivalAirports ||
    arrivalAirports.length === 0;

  const watchDepartureAirport = watch('departureAirport');
  const watchArrivalAirport = watch('arrivalAirport');

	const generateOption = (item: any) => {
		if (COMMODITY_DESCRIPTION.includes(item.code)) {
			if (!isMobile) {
				return (
					<Tooltip
						overlayStyle={{ maxWidth: '320px' }}
						overlayInnerStyle={{ borderRadius: '5px' }}
						title={
							<Trans
								i18nKey={`commodity_desc_${item.code}`}
								components={{ newLine: <br /> }}
							/>
						}
					>
						<InfoCircleOutlined />
					</Tooltip>
				);
			}
			return (
				<InfoCircleOutlined
					onClick={event => {
						event.preventDefault();
						event.stopPropagation();
						setClassNameCommodity('commodity-no-z-index');
            popup.onOpen(
              {
									title: 'global_confirm_product_warning',
									hideBtnCancel: true,
									hideBtnOk: true,
              maxWidth: 450,
              className: 'confirm-select-product-popup',
              onClose: () => {
								setOpen(true);
								setTimeout(() => {
									setClassNameCommodity('');
								}, 100);
							}
            },
            <CommodityPopup code={item.code} />
						);
					}}
				/>
			);
		}
		return <></>;
	};

  const onSubmit = (data: FlightFilterInfo) => {
    const query = {
      arrivalAirport: getValues('arrivalAirport')?.key!,
      departureAirport: getValues('departureAirport')?.key!,
      weight: getValues('weight')!,
      productCode: getValues('commodity')?.code!,
      timezone: TIME_ZONE,
      dimension: getValues('dimension')
    } as DTOGetAvailableFlightRequest;

    const fromDate = moment(getValues('rangeDate')![0], DATE_FORMATS.DDMMYYYY);
    let toDate = moment(getValues('rangeDate')![1], DATE_FORMATS.DDMMYYYY);
    const dayBetween = toDate.diff(fromDate, 'days');

    if (dayBetween > DEFAULT_DAY_LEAD_TIME_ORDER) {
      toDate = toDate.subtract(
        dayBetween - DEFAULT_DAY_LEAD_TIME_ORDER,
        'days'
      );
    }

    findFlight.findFlight(
      {
        ...query,
        fromDate: fromDate.format(DATE_FORMATS.YYYYMMDD),
        toDate: toDate.format(DATE_FORMATS.YYYYMMDD)
      },
      {
        ...data,
        currentRangeDateFilter: [
          fromDate.format(DATE_FORMATS.DDMMYYYY),
          toDate.format(DATE_FORMATS.DDMMYYYY)
        ]
      }
    );
  };

  useEffect(() => {
    if (
      Object.keys(getValues('commodity') || {})?.length > 0 &&
      Number(getValues('weight')) > 0 &&
      (getValues('dimension') || [])?.length > 0 &&
      getValues('dimension')?.every(
        dimension =>
          Number(dimension.long) > 0 &&
          Number(dimension.width) > 0 &&
          Number(dimension.height) > 0 &&
          Number(dimension.quantity) > 0
      )
    ) {
      dispatch(
        flightsSlice.actions.summaryDimensionWeightActions.getting({
          dimension: getValues('dimension'),
          weight: getValues('weight')
        })
      );
    }
  }, [watchSummaryDimensionWeight]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      dispatch(updateFlightInfo(undefined));
      dispatch(clearFeeAndTax());
      dispatch(flightSlice.actions.availableFlightsActions.reset());
      dispatch(flightSlice.actions.summaryDimensionWeightActions.reset());
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!availableFlightState.errMsg) return;
    if (availableFlightState?.fetchingStatus === 'error') {
      // if (fields.length === 1) {
      //   update(0, { long: null, width: null, height: null, quantity: 1 });
      // }
      dispatch(flightSlice.actions.availableFlightsActions.reset());
    }
  }, [availableFlightState.errMsg]);

  const resetDefaultValue = () => {
    setValue('weight', 1);
    setValue('dimension', DIMENSIONS_DEFAULT);
    setValue('commodity', null);
  };

  useEffect(() => {
    resetDefaultValue();
    findFlight.resetFlightFilter();
  }, [senderAddress, receiverAddress]);

  useEffect(() => {
    if (!senderAddress?.province) return;
    resetField('commodity');
    resetField('arrivalAirport');
    dispatch(
      flightsSlice.actions.departureAirportsActions.getting({
        provinceCode: senderAddress.province,
        districtCode: senderAddress.district
      })
    );
  }, [senderAddress?.province, senderAddress?.district]);

  useEffect(() => {
    if (!watchDepartureAirport?.key) return;
    if (!receiverAddress?.province) return;
    resetField('commodity');

    dispatch(
      flightsSlice.actions.arrivalAirportsActions.getting({
        weight: 1,
        departureAirport: watchDepartureAirport?.key,
        provinceCode: receiverAddress.province,
        districtCode: receiverAddress.district
      })
    );
  }, [
    watch('departureAirport')?.key,
    receiverAddress?.province,
    receiverAddress?.district
  ]);

  useEffect(() => {
    setValue('departureAirport', departureAirports[0]);
  }, [departureAirports]);

  useEffect(() => {
    setValue('arrivalAirport', arrivalAirports[0]);
  }, [arrivalAirports]);

  const departureAirportValidation = useMemo(() => {
    if (
      departureAirports.length === 0 &&
      departureAirportsFetchingStatus !== 'pending' &&
      departureAirportsFetchingStatus !== 'init'
    ) {
      return 'validation_airport_not_available';
    }
    return;
  }, [departureAirports, departureAirportsFetchingStatus]);

  const arrivalAirportValidation = useMemo(() => {
    if (!watchDepartureAirport) return;
    if (watchDepartureAirport?.key === watchArrivalAirport?.key) {
      return 'validation_airport_same';
    }
    if (watchArrivalAirport?.isSupport === false) {
      return 'validation_airport_not_support';
    }
    if (
      arrivalAirports.length === 0 &&
      arrivalAirportsFetchingStatus !== 'pending' &&
      arrivalAirportsFetchingStatus !== 'init'
    ) {
      return 'validation_airport_not_support';
    }
    return;
  }, [
    watchDepartureAirport,
    watchArrivalAirport,
    arrivalAirports,
    arrivalAirportsFetchingStatus
  ]);

  return (
    <Section
      className="select-address"
      title={t('order_bill_select_flight_filter')}
      icon={<img src={iconTakeOffPlanePrimaryUrl} alt={''} />}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="select-flight-container d-flex position-relative align-items-center mb-3 gap-6">
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return (
                <AirportDropdownSelect
                  placeholder={'Chọn sân bay đi'}
                  className={'select-fight-dropdown w-50'}
                  onChange={(airportCode, airport) => onChange(airport)}
                  loading={
                    departureAirportsFetchingStatus === 'init' ||
                    departureAirportsFetchingStatus === 'pending'
                  }
                  disabled={isSelectDepartureAirportDisabled}
                  value={value?.key}
                  options={departureAirports}
                  errorMessage={t(departureAirportValidation || '')}
                />
              );
            }}
            name={'departureAirport'}
          />
          <Controller
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => {
              return (
                <AirportDropdownSelect
                  placeholder={'Chọn sân bay đến'}
                  className={'select-fight-dropdown w-50'}
                  onChange={(airportCode, airport) => onChange(airport)}
                  loading={
                    arrivalAirportsFetchingStatus === 'init' ||
                    arrivalAirportsFetchingStatus === 'pending'
                  }
                  errorMessage={t(arrivalAirportValidation || '')}
                  disabled={isSelectArrivalAirportDisabled}
                  value={value?.key}
                  options={arrivalAirports}
                />
              );
            }}
            name="arrivalAirport"
          />
        </div>
        <div className="goods-info-weight-package row">
          <div className="col-md-6 col-sm-12 col-xs-12 mb-3">
            <label className="mb-2 fs-p-md lh-p-md fw-500">
              {t('select_flight_filter_field_category')}
            </label>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectBox
                    isOriginal
                    dropdownClassName={classNameCommodity}
                    open={open}
                    onDropdownVisibleChange={visible => setOpen(visible)}
                    selectedValue={value?.code}
                    options={getValues('arrivalAirport')?.commodity || []}
                    customIconRightOption={item => generateOption(item)}
                    onChange={(value, record) => onChange(record?.data)}
										getLabel={val => t(`commodity_${val.code}`)}
                    labelField="name"
                    valueField="code"
										placeholder={t('placeholder_category')}
									/>
								);
							}}
							name="commodity"
						/>
					</div>
          <div className="col-md-6 col-sm-12 col-xs-12 mb-3">
            <label className="mb-2 fs-p-md lh-p-md fw-500">
              {t('select_flight_filter_field_weight')}
            </label>
            <Controller
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => {
                return (
                  <InputNumber
                    isOriginal
                    isHasDebounce
										onChange={val => onChange(val)}
										min={1}
										max={9999}
										value={value}
										step={0.1}
										defaultValue={1}
									/>
								);
							}}
              name="weight"
						/>
					</div>
          {/* <div className="col-md-4 col-sm-12 col-xs-12 mb-3">
            <span className="mb-1 fs-p-md lh-p-md fw-500">
							{t('select_flight_filter_field_no_of_packages')}
						</div>
						<Controller
							control={control}
							rules={{ required: true }}
							render={({ field: { value, onChange } }) => {
								return (
									<InputNumber
										onChange={val => onChange(val)}
										min={1}
										max={999}
										value={value}
										step={0}
										defaultValue={1}
										formatter={value => Math.round(value || 0)?.toString()}
									/>
								);
							}}
							name="numberOfPackages"
						/>
          </div> */}
        </div>
        {!isMobile && (
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 mb-3">
              <span className="mb-1 fs-p-md lh-p-md fw-500">
                {t('select_flight_filter_field_dimension')}
              </span>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12 mb-3">
              <span className="mb-1 fs-p-md lh-p-md fw-500">
                {t('select_flight_filter_field_no_of_packages')}
              </span>
            </div>
          </div>
        )}
        {fields?.map((dimension, index) => {
          return (
            <div className="row dimension-info mb-2" key={dimension.id}>
              {isMobile && (
                <div className="mb-2 col-sm-12 col-xs-12">
                  <span className="fs-p-md lh-p-md fw-500">
                    {t('select_flight_filter_field_dimension')}
                  </span>
                </div>
              )}
              <div className="col-md-6 col-sm-12 col-xs-12">
                <div className="row">
                  <div className="col-md-4 col-sm-4 col-4 mb-3">
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        validate: value => {
                          return value! > 0;
                        }
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <InputText
                            type="text"
                            isOnlyNumber
                            isHasDebounce
                            defaultValue={value}
                            onChange={e => onChange(e.target.value)}
                            suffix="cm"
                            placeholder="0"
                            labelInfix={t(
                              'select_flight_filter_field_dimension_long'
                            )}
                          />
                        );
                      }}
                      name={`dimension.${index}.long`}
                    />
                  </div>
                  <div className="col-md-4 col-sm-4 col-4 mb-3">
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        validate: value => {
                          return value! > 0;
                        }
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <InputText
                            type="text"
                            isOnlyNumber
                            isHasDebounce
                            defaultValue={value}
                            onChange={e => onChange(e.target.value)}
                            suffix="cm"
                            placeholder="0"
                            labelInfix={t(
                              'select_flight_filter_field_dimension_width'
                            )}
                          />
                        );
                      }}
                      name={`dimension.${index}.width`}
                    />
                  </div>
                  <div className="col-md-4 col-sm-4 col-4 mb-3">
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        validate: value => {
                          return value! > 0;
                        }
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <InputText
                            type="text"
                            isOnlyNumber
                            isHasDebounce
                            defaultValue={value}
                            onChange={e => onChange(e.target.value)}
                            suffix="cm"
                            placeholder="0"
                            labelInfix={t(
                              'select_flight_filter_field_dimension_height'
                            )}
                          />
                        );
                      }}
                      name={`dimension.${index}.height`}
                    />
                  </div>
                </div>
              </div>
              {isMobile && (
                <div className="mb-2 col-sm-12 col-xs-12">
                  <span className="fs-p-md lh-p-md fw-500">
                    {t('select_flight_filter_field_no_of_packages')}
                  </span>
                </div>
              )}
              <div className="col-md-6 col-sm-12 col-xs-12 mb-3">
                <div className="dimension-info--quantity">
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      validate: value => {
                        return value! > 0;
                      }
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <InputNumber
                          isOriginal
                          isHasDebounce
                          onChange={val => onChange(val)}
                          min={1}
                          max={999}
                          step={1}
                          defaultValue={value}
                          formatter={value =>
                            Math.round(value || 0)?.toString()
                          }
                        />
                      );
                    }}
                    name={`dimension.${index}.quantity`}
                  />
                  {fields.length > 1 && index !== 0 && (
                    <div className="icon-minus">
                      <img
                        onClick={() => remove(index)}
                        src={minusIcon}
                        alt="minus dimension"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div className="row">
          {(availableFlightState?.fetchingStatus === 'error' ||
            summaryDimensionWeightState.fetchingStatus === 'error') && (
            <div className="col-md-12 mb-3">
              <MessageAlert
                {...MESSAGE_ALERT_ERROR}
                message={t(
                  toUpperLetter(
                    availableFlightState.errMsg ||
                      summaryDimensionWeightState.errMsg,
                    false
                  )
                )}
                toggle={toggleOrder}
                className="w-100"
              />
            </div>
          )}
          <div className="col-md-12 mb-3 dimension-info--btn-plus">
            <Button
              className={classnames({
                disabled: fields?.length === 10,
                'w-100': isMobile
              })}
              variant="secondary"
              size="sm"
              disabled={fields?.length === 10}
              onClick={() => {
                if (fields?.length === 10) return;
                append(DIMENSIONS_DEFAULT);
                dispatch(
                  flightSlice.actions.summaryDimensionWeightActions.reset()
                );
              }}
              type="button"
            >
              <img className="mr-1" src={plus} alt="plus dimension" />
              {t('select_flight_filter_field_dimension_btn')}
            </Button>
          </div>
          {summaryDimensionWeightState.fetchingStatus === 'success' && (
            <>
              <div className="col-md-6 col-sm-12 col-xs-12 mb-3">
                <div className="mb-2 fs-p-md lh-p-md fw-500">
                  {t('Cân nặng quy đổi từ kích thước')}
                </div>
                <div className="summary-dimension-weight">
                  <span>
                    {summaryDimensionWeightState.data?.summaryDimensionWeight ||
                      0}{' '}
                    kg
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-xs-12 mb-3">
                <div className="mb-2 fs-p-md lh-p-md fw-500">
                  {t('Cân nặng tính phí')}
                </div>
                <div className="summary-dimension-weight">
                  <span>
                    {summaryDimensionWeightState.data?.chargeWeight || 0} kg
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="col-md-12 mb-3">
            <div className="mb-2 fs-p-md lh-p-md fw-500">
              {t('global_order_lead_time')}
            </div>
            <Controller
              control={control}
              rules={{
                required: true,
                validate: value => {
                  if (value?.length === 0) return false;
                  return value?.every(x => x);
                }
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <RangeDate
                    value={value}
                    rangeDayAllow={30}
                    onChangeDate={date => onChange(date)}
                  />
                );
              }}
              name="rangeDate"
            />
          </div>
        </div>

        <div className="flight-btn-action row">
          <div className="col-md-6 col-sm-12 col-xs-12">
            {!!cargoValidateFields?.flight?.hasError && (
              <MessageAlert
                {...cargoValidateFields?.flight}
                toggle={toggleOrder}
                className={classnames('w-100', isMobile && 'mb-3')}
              />
            )}
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
            <Button
              className={isMobile ? 'w-100' : ''}
              disabled={
                !formState.isValid ||
                availableFlightState.fetchingStatus === 'pending'
              }
              type="submit"
            >
              {t('select_flight_filter_find_available_fight')}
            </Button>
          </div>
        </div>
      </form>
    </Section>
  );
};

export default SelectFlightFilter;
