import { PaginationProps, Tabs } from 'antd';
import Paginate from 'Components/Paginate';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { myOrdersSelectors, myOrdersSliceActions } from 'Stores/my-orders';
import { classPrefix } from 'Utilities/global';
import './index.scss';

const ORDER_STATUS = ['ALL', 'SENDING', 'RECEIVING', 'FINISHED', 'CANCELLED'];

const MyOrders = () => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const orderType = searchParams.get('type') || 'ALL';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderMeta = useSelector(myOrdersSelectors.get).orderReducer.meta;
  const { id } = useParams();
  const [isShowPagination, setShowPagination] = useState(true);

  const renderPagination = () => {
    if (orderMeta && orderMeta.pagination && orderMeta.pagination.total) {
      return (
        <Paginate
          total={orderMeta.pagination.total}
          size={orderMeta.pagination.perPage}
          current={orderMeta.pagination.currentPage}
          onPageChange={onPageChange}
        />
      );
    }
  };

  const onPageChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    dispatch(
      myOrdersSliceActions.ordersAction.getting({
        type: orderType,
        perPage: pageSize,
        page: pageNumber
      })
    );
  };

  useEffect(() => {
    setShowPagination(!id);
  }, [id]);

  return (
    <>
      {/* <div className="fw-bold mb-2">{t('title_page_my_order')}</div> */}
      <Tabs
        defaultActiveKey="1"
        className={`${classPrefix}-tabs-container`}
        centered
        destroyInactiveTabPane={true}
        activeKey={orderType}
        onChange={data => {
          navigate(`/my-orders?type=${data}`);
        }}
      >
        {ORDER_STATUS.map(v => (
          <TabPane
            tab={
              <span className={`${classPrefix}-tabs-tab-pane`}>
                {t(`order_status_${v}`)}
              </span>
            }
            key={v}
          >
            <Outlet />
          </TabPane>
        ))}
      </Tabs>
      {isShowPagination && renderPagination()}
    </>
  );
};

export default MyOrders;
