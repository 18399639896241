
import images from 'Assets/images';
import moment from 'moment';
import { C2cOverPaymentTextEnum } from 'Pages/C2cOrderPayment/enums';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { c2cOrderPaymentSelectors } from 'Stores/c2c-order-payment';
import { TypeOrder } from 'Stores/c2c-order-payment/enums';
import { displayCurrency } from 'Utilities/util';
import './styles.scss';

interface PropsModel {
  className?: string;
}

const DeliveryMethodClassic = (props: PropsModel) => {
  const { className = '' } = props;
  const { t } = useTranslation();
  const { deliveryMethodSelected, typeOrder } = useSelector(c2cOrderPaymentSelectors.get);
  const isBasic = typeOrder === TypeOrder.BASIC;
  const isPremium = typeOrder === TypeOrder.PREMIUM;
  const isSuper = typeOrder === TypeOrder.SUPER;
  const backgroundOrIconImage = (bg = false) => {
    let backgroundImage = images.bgStandard;
    let iconImage = images.icStandard;
    if (isPremium) {
      backgroundImage = images.bgPremium;
      iconImage = images.icPremium;
    } else if (isSuper) {
      backgroundImage = images.bgSuper;
      iconImage = images.icRocket;
    }

    return bg ? backgroundImage : iconImage;
  };

  return (
    <div
      style={{
        backgroundImage: `url("${backgroundOrIconImage(true)}")`,
      }}
      className={`${className} delivery-method-classic`}
    >
      <img
        className="delivery-method-classic-img"
        src={backgroundOrIconImage()}
        alt="delivery method classic img"
      ></img>
      <div className="delivery-method-classic-content">
        <div style={{color: isPremium || isSuper ? "#ffffff" : ""}} className="delivery-method-classic-title">
          <div>{t(isBasic ? 'standard_delivery' : isPremium ? 'premium_delivery' : 'super_delivery')}</div>
          <div>
            {displayCurrency(
              (deliveryMethodSelected?.toPrice || 0)?.toString(),
              deliveryMethodSelected?.currency || "đ"
            )}
          </div>
        </div>
        <div style={{color: isPremium ? '#C7C7C7' : isSuper ? '#ffffff' : ''}} className="est-date-time">
          {`${t(
            C2cOverPaymentTextEnum.C2CDeliveryMethodClassic_Estimation,
          )} ${moment(deliveryMethodSelected?.estDateTime).format(
            'HH:mm DD/MM',
          )}`}
        </div>
      </div>
    </div>
  );
};

export default DeliveryMethodClassic;
