export enum DeliveryMethodEnum {
  DeliveryAddress_DeliveryAt = 'delivery_address_delivery_at',
  DeliveryAddress_ReceiveAt = 'delivery_address_receive_at',
  TransactionDetail_Title = 'transaction_detail_title',
  TransactionDetail_NoteBtn = 'transaction_detail_note_btn',
  TransactionDetail_Weight = 'transaction_detail_weight',
  TransactionDetail_Size = 'transaction_detail_size',
  CommentPopup_Title = 'comment_popup_title',
  CommentPopup_ButtonSubmit = 'comment_popup_button_submit',
  PromoVoucher_VoucherName="promo_voucher_voucher_name",
  PromoVoucher_RefundName="promo_voucher_refund_name",
  PromoVoucher_ToFromDate="promo_voucher_to_from_date",
}
