import { Card, Checkbox } from 'antd';
import PageCallCenterService from 'Components/PageCallCenterService/pageCallCenterService';
import { useTranslation } from 'react-i18next';
import { DTOOrder } from 'Services/v1/cargo-direct/dto';
import { classPrefix } from 'Utilities/global';
import './index.scss';
import React, { useMemo } from 'react';

import { BOOKING_TYPE, TypeCurrency, VIETNAM_COUNTRY_CODE } from 'Utilities/constants';
import {
	ProductTableColumns,
	cargoColumns,
	c2cCrossBorderColumns,
	parseCargoData,
	parseC2CData,
	c2cDomesticColumns
} from './TableProductData'

interface ProductTableProps {
	columns: ProductTableColumns[]
	data: any[]
}

const ProductTable = (props: ProductTableProps) => {
	const {columns, data} = props;

	const {t} = useTranslation();

	const defaultComponent = (props) => (<div>{props.value}</div>)

	return <table className={'table-product'}>
		<thead>
			<tr className={'table-product-header'}>
				{
					columns.map(col => {
						return <th key={col.title}>{t(col.title)}</th>
					})
				}
			</tr>
		</thead>
		<tbody>
			{
				data.map((item) => {
					return <tr className={data.length === 1 ? 'table-product-standalone-row' : ''} key={item.id}>
						{columns.map((col) => {
							const RenderedComp = col.component || defaultComponent;
							return <td key={col.title}>{item[col.field] ? <RenderedComp value={item[col.field]} item={item} /> : <></>}</td>
						})}
					</tr>
				})
			}
		</tbody>
	</table>
}

const ProductOrder = (props: DTOOrder) => {
	const { statusName, vatInfo, platform, properties } = props;
	const isGlobal = platform === BOOKING_TYPE.C2C && (properties.originId && properties.originId[0] !== VIETNAM_COUNTRY_CODE ||  properties.destinationId && properties.destinationId[0] !== VIETNAM_COUNTRY_CODE);

	const { t } = useTranslation();


	const cargoData = useMemo(() => {
		if(!props.id || platform === BOOKING_TYPE.C2C) return [];
		return parseCargoData(props)
	}, [props])

	const c2cCargo = useMemo(() => {
		if(!props.id || platform === BOOKING_TYPE.CargoDirect) return [];
		return parseC2CData(props)
	}, [props])

	return (
		<Card
			className={`${classPrefix}-product-container mb-4`}
			bordered={false}
		>
			<div className="product-body bg-white">
				{ platform === BOOKING_TYPE.CargoDirect ? <ProductTable columns={cargoColumns} data={cargoData}/> : <ProductTable columns={isGlobal ? c2cCrossBorderColumns : c2cDomesticColumns} data={c2cCargo}/>}
			</div>

			<div className="product-footer p-6">
				<div className="d-flex justify-content-between">
					{statusName === 'CANCELLED' && (
						<div>
							<Checkbox
								className={`${classPrefix}-checkbox-container`}
								checked={!!vatInfo}
								disabled
							>
								{t('order_detail_vat_checkbox_label')}
							</Checkbox>
						</div>
					)}
					<PageCallCenterService></PageCallCenterService>
				</div>
			</div>
		</Card>
  );
};

export default ProductOrder;
