import Spinner from 'Components/Spinner';
import { ReactNode, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import {
  layoutUtilitiesSliceSelectors,
  updateLoadingBar,
  updateLoadingOverlay
} from 'Stores/layout-utilities';
import { LayoutUtilitiesToastType } from 'Stores/layout-utilities/models';

export interface LayoutUtilitiesProps {
	children?: ReactNode
}

const LayoutUtilities = (props: LayoutUtilitiesProps) => {
	const {
		children
	} = props
	const layoutUtilitiesState = useSelector(layoutUtilitiesSliceSelectors.get);
	const loadingBarState = layoutUtilitiesState.loadingBar;
	const loadingOverlayState = layoutUtilitiesState.loadingOverlay;
	const {isOpenPopup} = layoutUtilitiesState?.popupCommon

	const loadingRef = useRef<LoadingBarRef>(null);

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    if (loadingOverlayState || isOpenPopup) {
      body.style.margin ='0';
      body.style.height ='100%';
      body.style.overflow ='hidden';
      if (!isMobile) {
        body.style.width = 'calc(100% - 17px)';
      }
    } else {
      body.style.removeProperty('margin');
      body.style.removeProperty('height');
      body.style.removeProperty('overflow');
      if (!isMobile) {
        body.style.removeProperty('width');
      }
    }

  }, [loadingOverlayState, isOpenPopup])

	useEffect(() => {
		switch (loadingBarState) {
		case 'loading':
			loadingRef?.current?.continuousStart(20, 2);
			break;
		case 'done':
			loadingRef?.current?.complete();
			break;
		}
	}, [loadingBarState, loadingRef?.current])


	return <>
		<LoadingBar color='#b3446d' ref={loadingRef} />
		<ToastContainer />
		<Spinner style={{position: 'fixed', top: '50%', left: '50%', width: 'auto', maxHeight: '100%', height: 'auto'}} spinning={loadingOverlayState}>
			{children}
		</Spinner>
	</>
}

export const useLoadingBar = () => {
	const dispatch = useDispatch();
	const loadingBarState = useSelector(layoutUtilitiesSliceSelectors.get).loadingBar;

	const startLoadingBar = () => {
		dispatch(updateLoadingBar('loading'))
	}

	const stopLoadingBar= () => {
		dispatch(updateLoadingBar('done'))
	}

	return {
		startLoadingBar,
		stopLoadingBar,
		loadingBarState
	}
}


export const useToast = () => {
	return (type: LayoutUtilitiesToastType, message: string) => {
		const toaster = toast[type] || toast.info;
		toaster(message);
	}
}

export const useLoadingOverlay = () => {
	const dispatch = useDispatch();
	const loadingOverlayState = useSelector(layoutUtilitiesSliceSelectors.get).loadingBar;

	const startLoadingOverlay = () => {
		dispatch(updateLoadingOverlay(true))
	}

	const stopLoadingOverlay= () => {
		dispatch(updateLoadingOverlay(false))
	}

	return {
		startLoadingOverlay,
		stopLoadingOverlay,
		loadingOverlayState
	}
}


export default LayoutUtilities;