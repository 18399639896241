import iconWalletUrl from 'Assets/icon/wallet-icon.svg';
import Section from 'Components/Section';
import { useTranslation } from 'react-i18next';
import './index.scss';

import { useSelector } from 'react-redux';
import { c2cOrderPaymentSelectors } from 'Stores/c2c-order-payment';
import SelectPaymentKorea from './SelectPaymentKorea';
import SelectPaymentVN from './SelectPaymentVN';

const SelectPaymentMethod = () => {
  const { t } = useTranslation();
  const { isGlobal, globalDirection } = useSelector(
    c2cOrderPaymentSelectors.get
  );

  return (
    <Section
      className="select-address"
      title={t('order_bill_select_payment_method')}
      icon={<img src={iconWalletUrl} alt={''} />}
    >
      {isGlobal && globalDirection === 'inbound' ? (
        <SelectPaymentKorea />
      ) : (
        <SelectPaymentVN />
      )}
    </Section>
  );
};

export default SelectPaymentMethod;
