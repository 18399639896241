import React, { useEffect, useMemo, useState } from 'react';
import { C2cCategoryPackageTypeEnum, C2cOverPaymentTextEnum, ProductStatusEnum } from 'Pages/C2cOrderPayment/enums';
import productClassificationIcon from 'Assets/icon/product-classification-icon.svg';
import Section from 'Components/Section';
import { useTranslation } from 'react-i18next';
import { ProductTypeSwitch } from 'Pages/C2cOrderPayment/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  c2cOrderPaymentSelectors,
  C2cOverPaymentState,
  chooseC2CProductCategory,
  choosePackageTypeAction,
  ProductDeclarationByCategory,
  removeAllProductDeclarationByCategory,
  removeProductDeclarationByCategory,
  TypeOrder,
  upsertProductDeclarationByCategory,
} from 'Stores/c2c-order-payment';
import SelectBox from 'Components/SelectBox';
import InputText from 'Components/InputText';
import { Controller, useForm } from 'react-hook-form';
import { Col, Form, Row } from 'antd';
import InputNumber from 'Components/InputNumber';
import images from 'Assets/images';

import './index.scss';
import ButtonClick from 'Components/ButtonClick';
import PlusIcon from 'Assets/icon/plus.svg';
import { CONFIRM_SELECT_PRODUCT_POPUP, MESSAGE_ALERT_ERROR } from 'Pages/C2cOrderPayment/constants';
import { APRICOT_PEACH_BLOSSOM } from 'Utilities/constants';
import C2cProductConfirm from '../c2cProductConfirm/c2cProductConfirm';
import { MessageAlertModel } from 'Components/Models';
import { usePopup } from 'Hooks';
import MessageAlert from 'Components/MessageAlert/messageAlert';

export interface ProductSummaryProps {
  data: ProductDeclarationByCategory
  onEditClick?: (data: ProductDeclarationByCategory) => void
  onRemoveClick?: (data: ProductDeclarationByCategory) => void
}

const ProductSummary = (props: ProductSummaryProps) => {
  const {onEditClick, onRemoveClick, data} = props;

  return <div className={'declaration-product-summary-container'}>
      <div className={'declaration-product-summary-body'}>
        <img className={'declaration-product-summary-body-img'} src={data?.productInfo?.categoryImgUrl} alt={''}/>
        <div className={'mr-2 text-gray-primary'}>{data.productQuantity}x</div>
        <div>
          <div className={'fw-bold'}>{data.productName}</div>
          <div className={'declaration-product-summary-body-sub'}>{data?.productInfo?.product}</div>
        </div>
      </div>
      <div className={'declaration-product-summary-actions'}>
        <img onClick={() => onEditClick && onEditClick(data)} className={'mr-5 cursor-pointer'} src={images.editIcon} alt={''}/>
        <img onClick={() => onRemoveClick && onRemoveClick(data)} className={'cursor-pointer'} src={images.deleteIcon} alt={''}/>
      </div>
  </div>
}

export interface DeclarationForm {
  initialData?: ProductDeclarationByCategory
  onCancel?: () => void
  onSubmit?: (data: ProductDeclarationByCategory) => void
  onDeleteClick?: (data: ProductDeclarationByCategory) => void
}

const DeclarationForm = (props: DeclarationForm) => {
  const {initialData, onCancel, onSubmit, onDeleteClick} = props;
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [message, setMessage] = useState({} as MessageAlertModel);
  const popup = usePopup();
  const {
    productSelected,
  } = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;

  const {
    products,
    productCategoryTypeSelected,
    packageTypeSelected,
    typeOrder
  } = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;

  useEffect(() => {
    setValue('productCode', undefined)
    setValue('productInfo', undefined)
    setValue('productName', undefined)
    setValue('productQuantity', 1)
  }, [productCategoryTypeSelected, packageTypeSelected])

  useEffect(() => {
    if(!initialData) return;
    Object.keys(initialData).forEach((field) => {
      setValue(field, initialData[field as keyof typeof initialData])
    })
  }, [])

  const isEditMode = !!initialData;

  const productAvailable = products?.filter(
    p => {
      if(productSelected.code && productSelected.code !== APRICOT_PEACH_BLOSSOM.code) return p.status === ProductStatusEnum.Supported && p.code !== APRICOT_PEACH_BLOSSOM.code;
      if(typeOrder === TypeOrder.PREMIUM) return p.status === ProductStatusEnum.Supported && p.isPremium;
      return p.status === ProductStatusEnum.Supported;
    }
  );

  const onFormSubmit = async (value: ProductDeclarationByCategory) => {
    const product = value.productInfo;

    if (product?.status === ProductStatusEnum.NotSupported) {
      setMessage({
        ...MESSAGE_ALERT_ERROR,
        hasBackground: false,
        iconClose: false
      });
      return;
    }
    if(!onSubmit) return;

    if (!product?.isWarning) return onSubmit(value);

    if(productSelected.code === product.code) return onSubmit(value); // Don't popup to ask many times on edit

    popup.onOpen(
      {
        ...CONFIRM_SELECT_PRODUCT_POPUP,
        maxWidth: 450,
        onSubmit: (isAccept: boolean) => {
          if(!isAccept) return;
          onSubmit(value)
        }
      },
      <C2cProductConfirm product={product}/>
    );
  };


  return <div style={{paddingTop: isEditMode ? 24 : 0, paddingBottom: isEditMode ? 24 : 0}} className={`product-declaration`}>
    <div className={'mb-5 w-100 d-flex justify-content-between'}>
      <div className={'fw-bold fs-p-lg'}>{isEditMode ? t('label_edit_declared_product') : t('label_add_new_declared_product')}</div>
      {isEditMode && <img onClick={() => onDeleteClick && onDeleteClick(initialData)} className={'cursor-pointer'} src={images.deleteIcon} alt={''}/>}
    </div>
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Row gutter={24}>
          <Col span={24}>
            <Controller
              render={({ field: { value, onChange,...rest } }) => {
                return (
                  <Form.Item
                    labelCol={{ span: 24 }}
                    label={t('label_product_category')}
                    validateStatus={errors.productCode ? 'error' : ''}
                    help={errors.productCode ? t('message_field_required', {fieldName: t('label_product_category')}) : ''}
                  >
                    <SelectBox
                      {...rest}
                      selectedValue={value}
                      onChange={(value, option) => {
                        onChange(value)
                        setValue('productInfo', option.data);
                      }}
                      labelField={'product'}
                      valueField={'code'}
                      options={productAvailable}
                      placeholder={t('label_product_category')}
                    />
                  </Form.Item>
                );
              }}
              control={control}
              name="productCode"
              rules={{ required: true }}
            />
          </Col>
          <Col span={24}>
            <Controller
              render={({ field }) => (
                <Form.Item
                  label={t('label_product_name')}
                  validateStatus={errors.productName ? 'error' : ''}
                  labelCol={{span: 24}}
                  help={
                    errors.productName ? t('message_field_required', {fieldName: t('label_product_name')}) : ''
                  }
                >
                  <InputText
                    {...field}
                    placeholder={t('label_product_name')}
                  />
                </Form.Item>
              )}
              control={control}
              name="productName"
              rules={{ required: true }}
            />
          </Col>
          <Col span={24}>
            <Controller
              render={({ field }) => (
                <Form.Item
                  labelCol={{span: 24}}
                  label={t('global_quantity')}
                  validateStatus={errors.productQuantity ? 'error' : ''}
                  help={
                    errors.productQuantity ? t('message_field_required', {fieldName: t('global_quantity')}) : ''
                  }
                >
                  <InputNumber
                    {...field}
                    min={1}
                    placeholder={t('global_quantity')}
                  />
                </Form.Item>
              )}
              control={control}
              name="productQuantity"
              rules={{ required: true }}
            />
          </Col>
          <Col offset={24 - 10} xs={10} md={10} lg={10} xl={10}>
            <div className={'d-flex'}>
              <ButtonClick
                containerClassName="mr-2"
                className="w-100"
                variant="ghost"
                onClick={onCancel}
              >
                {t('product_declaration_close_form')}
              </ButtonClick>
              <ButtonClick
                className="w-100"
                variant="primary"
                htmlType="submit"
                // loading={loading}
              >
                {isEditMode ? t('product_declaration_update_product') : t('product_declaration_add_product')}
              </ButtonClick>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  </div>
}

export interface ProductDeclarationItemProps {
  data: ProductDeclarationByCategory
}

const ProductDeclarationItem = (props: ProductDeclarationItemProps) => {
  const {data} = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const popup = usePopup();

  const closeDeclareForm = () => {
    setIsEditMode(false);
  }

  const onEdit = (data: ProductDeclarationByCategory) => {
    dispatch(upsertProductDeclarationByCategory(data))
  }
  const onRemove = (data: ProductDeclarationByCategory) => {
    popup.onOpen({
      title: t('popup_remove_product_item_confirmation'),
      onSubmit: () => {
        dispatch(removeProductDeclarationByCategory(data.id))
      },
    }, <div>{t('popup_remove_product_item_confirmation_content')}</div>)
  }
  if(isEditMode) return <DeclarationForm
    onDeleteClick={onRemove}
    onCancel={closeDeclareForm}
    onSubmit={onEdit}
    initialData={data}
  />

  return <ProductSummary
    onEditClick={() => setIsEditMode(true)}
    onRemoveClick={onRemove}
    key={`${data.productName}-${data.productCode}`}
    data={data}
  />
}

const AddNewProductSection = () => {
  const {t} = useTranslation();
  const [isShowAddForm, setIsShowAddForm] = useState(true);

  const dispatch = useDispatch();
  const {
    productSelected,
  } = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;
  const allProductDeclaration = useSelector(c2cOrderPaymentSelectors.productDeclarationByCategorySelector.selectAll);

  useEffect(() => {
    if(!allProductDeclaration || allProductDeclaration.length === 0) {
      setIsShowAddForm(true)
    }
  }, [allProductDeclaration])

  if(productSelected.code === APRICOT_PEACH_BLOSSOM.code) return <></>

  const closeDeclareForm = () => {
    setIsShowAddForm(false)
  }

  const onAdd = (data: ProductDeclarationByCategory) => {
    dispatch(upsertProductDeclarationByCategory({
      ...data,
      id: Math.random().toString()
    }))
    closeDeclareForm()
  }
  if(isShowAddForm) return <DeclarationForm onSubmit={onAdd} onCancel={() => setIsShowAddForm(false)}/>

  return <div className={'add-new-product-section'}>
    <div onClick={() => setIsShowAddForm(true)} className={'d-flex align-items-center cursor-pointer text-primary'}>
      <img src={PlusIcon} width={14} className="mr-1" />
      <span>{t('c2c_delivery_method_classic_btn_add_product')}</span>
    </div>
    {/*<ButtonClick*/}
    {/*  variant="text"*/}
    {/*  className="d-flex justify-content-center align-items-center"*/}
    {/*  onClick={() => setIsShowAddForm(true)}*/}
    {/*>*/}
    {/*  <img src={PlusIcon} width={14} className="mr-1" />*/}
    {/*  <span>{t('c2c_delivery_method_classic_btn_add_product')}</span>*/}
    {/*</ButtonClick>*/}
  </div>
}

const ProductCategory = () => {
  const { typeOrder } = useSelector(c2cOrderPaymentSelectors.get);
  const c2cState = useSelector(c2cOrderPaymentSelectors.get);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const popup = usePopup();
  const allProductDeclaration = useSelector(c2cOrderPaymentSelectors.productDeclarationByCategorySelector.selectAll);

  const {
    productCategoryTypes,
    productCategoryTypeSelected
  } = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;
  const {
    packageTypeSelected,
  } = c2cState;

  const productCategoryList = useMemo(() => {
    return (packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel ? productCategoryTypes?.parcel : productCategoryTypes?.document)?.map((cat: string) => ({
      value: cat,
      label: cat
    })) || []
  }, [packageTypeSelected, productCategoryTypes])

  useEffect(() => {
    if(!productCategoryList || productCategoryList.length === 0) return;
    dispatch(chooseC2CProductCategory(productCategoryList[0].value));
  }, [productCategoryList])

  useEffect(() => {
    if(!(productCategoryList && productCategoryList.length > 0)) return;
    dispatch(chooseC2CProductCategory(productCategoryList[0].value));
  }, [typeOrder, productCategoryList])

  const handleChangePackage = (actionType: C2cCategoryPackageTypeEnum) => {
    if(typeOrder === TypeOrder.SUPER && actionType === C2cCategoryPackageTypeEnum.Parcel) return;

    if(allProductDeclaration && allProductDeclaration.length > 0) {
      return popup.onOpen(
        {
          title: 'global_confirm',
          okBtnLabel: 'global_accept_action',
          cancelBtnLabel: 'global_cancel',
          width: '100%',
          maxWidth: 500,
          onSubmit: () => {
            dispatch(choosePackageTypeAction(actionType));
            dispatch(chooseC2CProductCategory(productCategoryList[0].value));
          }
        },
        <div>{t('popup_redeclare_products_change_package_type_confirmation')}</div>
      );
    }
    dispatch(choosePackageTypeAction(actionType));
    dispatch(chooseC2CProductCategory(productCategoryList[0].value));
  };

  const changeProductCategory = async (value:string) => {
    const isHaveDifferentCategoryTypeItem = (productCategoryTypeSelected && productCategoryTypeSelected !== value) && allProductDeclaration && allProductDeclaration.length > 0;

    if(isHaveDifferentCategoryTypeItem) {
      await new Promise((res) => popup.onOpen(
        {
          title: 'global_confirm',
          okBtnLabel: 'global_accept_action',
          cancelBtnLabel: 'global_cancel',
          width: '100%',
          maxWidth: 500,
          onSubmit: () => {
            res(dispatch(chooseC2CProductCategory(value)));
            res(dispatch(removeAllProductDeclarationByCategory()));
          },
          onClose: () => {

          }
        },
        <div>{t('popup_redeclare_products_change_category_type_confirmation')}</div>,
      ));
    }

    dispatch(chooseC2CProductCategory(value));
  }

  return <div className={'product-category'}>
    <ProductTypeSwitch
      typeOrder={typeOrder}
      packageTypeSelected={packageTypeSelected}
      handleChangePackage={handleChangePackage}
    />
    <Row gutter={24}>
      <Col span={24}>
        <SelectBox
          defaultValue={productCategoryList[0]?.value}
          selectedValue={productCategoryTypeSelected}
          onChange={changeProductCategory}
          options={productCategoryList}
          placeholder={t('label_product_category')}
        />
      </Col>
      <Col span={24}>
        <div className={'mt-4 message-info'}>{t('message_same_category_rule')}</div>
      </Col>
    </Row>

  </div>
}

const ProductDeclaration = () => {
  const { t } = useTranslation();
  const allProductDeclaration = useSelector(c2cOrderPaymentSelectors.productDeclarationByCategorySelector.selectAll);
  const {
    c2cValidateFields,
    toggleOrder
  } = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;

  return <>
    <Section
      title={t(C2cOverPaymentTextEnum.ProductClassification_Title)}
      icon={<img src={productClassificationIcon} alt={''} />}
      // noBodyGutters={true}
      className={'mb-2'}
    >
      <ProductCategory/>
    </Section>
    {allProductDeclaration && allProductDeclaration.length > 0 && <Section className={'mb-2'}>
      <div>
        {allProductDeclaration.map((pd) => {
          return <ProductDeclarationItem
            key={`${pd.productName}-${pd.productCode}`}
            data={pd}
          />;
        })}
      </div>
    </Section>}
    <Section className={'mb-2'}>
      <AddNewProductSection/>
      {!!c2cValidateFields?.productCategories?.hasError && <div className={'pb-4 pt-4'}>
        <MessageAlert
          {...c2cValidateFields?.productCategories}
          toggle={toggleOrder}
          className={`message-alert-product`}
        />
      </div>}
    </Section>
  </>
}

export default ProductDeclaration;
