import images from 'Assets/images';
import ItemBase from 'Components/ItemBase/itemBase';
import Section from 'Components/Section';
import {
  C2cOverPaymentTextEnum,
  DeliveryTypeEnum
} from 'Pages/C2cOrderPayment/enums';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  C2cDeliveryMethodModel,
  c2cOrderPaymentSelectors,
  C2cOverPaymentState,
  chooseDeliveryMethodAction,
  selectReceiverPointAction,
  selectSenderPointAction
} from 'Stores/c2c-order-payment';
import { updatePaymentMethod } from 'Stores/order-bill';
import { APRICOT_PEACH_BLOSSOM } from 'Utilities/constants';
import { C2cDeliveryMethodDetail } from '..';
import './styles.scss';

interface C2cDeliveryMethodModelProps {}

const C2cDeliveryMethod = (props: C2cDeliveryMethodModelProps) => {
  const { t } = useTranslation();
  const { deliveriesMethod, deliveryMethodSelected, productSelected } =
    useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;

  const dispatch = useDispatch();

  useEffect(() => {
    let deliverySelected = deliveriesMethod?.find(
      x => x.deliveryType === deliveryMethodSelected?.deliveryType
    );

    let deliverySelectedNew = deliveriesMethod[0];
    if (productSelected.code === APRICOT_PEACH_BLOSSOM.code) {
      deliverySelectedNew =
        deliveriesMethod.find(x => x.deliveryType === DeliveryTypeEnum.P2P) ||
        {};
    } else if (deliverySelected) {
      deliverySelectedNew = deliverySelected;
    }
    dispatch(chooseDeliveryMethodAction(deliverySelectedNew));
  }, [deliveriesMethod]);

  const generateItem = (item: C2cDeliveryMethodModel) => {
    switch (item.deliveryType) {
      case 'D2D':
        return { img: images.D2D, name: `${t('place_order_d2d')}` };

      case 'D2P':
        return { img: images.D2P, name: `${t('place_order_d2p')}` };

      case 'P2D':
        return { img: images.P2D, name: `${t('place_order_p2d')}` };

      case 'P2P':
        return { img: images.P2P, name: `${t('place_order_p2p')}` };

      default:
        return;
    }
  };
  const renderListMethod = (items: Array<C2cDeliveryMethodModel>) => {
    return items?.map((item, index) => {
      return (
        <ItemBase
          key={index}
          className="c2c-delivery-method-item"
          active={item?.deliveryType === deliveryMethodSelected?.deliveryType}
          disable={
            item.deliveryType !== DeliveryTypeEnum.P2P &&
            productSelected.code === APRICOT_PEACH_BLOSSOM.code
          }
          item={generateItem(item)!}
          onClick={() => {
            if (item.deliveryType === deliveryMethodSelected?.deliveryType)
              return;
            dispatch(chooseDeliveryMethodAction(item));
            dispatch(selectSenderPointAction({}));
            dispatch(selectReceiverPointAction({}));
            dispatch(updatePaymentMethod());
          }}
        ></ItemBase>
      );
    });
  };

  return (
    <Section
      className="c2c-delivery-method"
      title={t(C2cOverPaymentTextEnum.C2cDeliveryMethod_Title)}
    >
      <div className="c2c-delivery-method-list">
        {renderListMethod(deliveriesMethod)}
      </div>
      <C2cDeliveryMethodDetail></C2cDeliveryMethodDetail>
    </Section>
  );
};

export default C2cDeliveryMethod;
