import { FC, useState, useEffect } from 'react';

interface MarkerOptionLocal extends google.maps.MarkerOptions {
  dragendChange: (lat: number, lng: number) => void;
}

const Marker: FC<MarkerOptionLocal> = (options) => {
  const {position, map} = options;
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [circle, setCircle] = useState<google.maps.Circle>()

  useEffect(() => {
    if(!position) return;
    const circle = new google.maps.Circle({
      strokeColor: '#b3446d',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#b3446d',
      fillOpacity: 0.2,
      map,
      center: {
        lat: position.lat,
        lng: position.lng
      },
      radius: 200,
    })
    setCircle(circle);
    return () => circle.setMap(null)
  }, [position?.lat, position?.lng])

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if(!marker) return;
    google.maps.event.addListener(marker, 'dragend', (e: any) => {
      const { lat, lng } = e.latLng;
      if (lat() && lng()) {
        options.dragendChange(parseFloat(lat().toPrecision(9)), parseFloat(lng().toPrecision(9)));
      }
    });

    return () => google.maps.event.clearListeners(marker, 'dragend')
  }, [marker])

  useEffect(() => {
    if(!marker) return;
    google.maps.event.addListener(marker, 'dragstart', () => {
      circle?.setMap(null)
    })

    return () => google.maps.event.clearListeners(marker, 'dragstart')
  }, [marker, circle])

  useEffect(() => {
    if(!options.position?.lat || !options.position?.lng) return;
    marker?.setPosition({
      lat:  options.position.lat,
      lng: options.position.lng
    })
  }, [options.position?.lng, options.position?.lat])

  useEffect(() => {
    if (marker) {
      const newOptions = {...options};
      delete newOptions['position'];
      marker.setOptions(newOptions);
    }
  }, [marker, options]);

  return null;
};

export default Marker;
