import { Slider } from 'antd';
import { UnitType } from 'Components/Enums';
import FieldUnit from 'Components/FieldUnit/fieldUnit';
import MessageAlert from 'Components/MessageAlert/messageAlert';
import { FieldUnitModel, MessageAlertModel } from 'Components/Models';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { c2cOrderPaymentSelectors } from 'Stores/c2c-order-payment';
import './styles.scss';

interface SelectWeightProps {
  className?: string;
  marks?: { [key: number]: string };
  min?: number;
  max?: number;
  value?: number;
  tipFormatter?: any;
  decimal?: number;
  validationField?: MessageAlertModel;
  fieldUnit?: FieldUnitModel;
  onChange?: (value: number) => void;
  onAfterChange?: (value: number) => void;
}

const SelectWeight = (props: SelectWeightProps) => {
  const {
    min = 0,
    max = 100,
    marks,
    className,
    value,
    tipFormatter,
    decimal = 0,
    validationField = { hasError: false },
    fieldUnit = {
      type: UnitType.Input,
      unit: 'Kg'
    },
    onAfterChange,
    onChange = () => {}
  } = props;
  const { t } = useTranslation();
  const { c2cValidateFields } = useSelector(c2cOrderPaymentSelectors.get);
  const dispatch = useDispatch();

  return (
    <div className={`${className} select-weight`}>
      <FieldUnit
        item={fieldUnit}
        value={value}
        className="select-weight-unit"
        min={min}
        max={max}
        onChange={onChange}
        onBlurChange={value => {
          onAfterChange && onAfterChange(value);
        }}
      ></FieldUnit>
      <div className="select-weight-drag">
        <Slider
          marks={marks}
          max={max * Math.pow(10, decimal)}
          min={min}
          tooltipVisible={false}
          value={(value || 0) * Math.pow(10, decimal)}
          tipFormatter={tipFormatter}
          onChange={value => onChange(value / Math.pow(10, decimal))}
          onAfterChange={value =>
            onAfterChange && onAfterChange(value / Math.pow(10, decimal))
          }
        />
        <span className="select-weight-unit-volume">
          {t(fieldUnit?.unit || 'Kg')}
        </span>
      </div>
      {!!validationField?.hasError && (
        <MessageAlert {...validationField} className="message-alert-weight" />
      )}
    </div>
  );
};

export default SelectWeight;
