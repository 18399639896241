import productClassificationIcon from 'Assets/icon/product-classification-icon.svg';
import MessageAlert from 'Components/MessageAlert/messageAlert';
import { MessageAlertModel } from 'Components/Models';
import Section from 'Components/Section';
import { usePopup } from 'Hooks';
import { CONFIRM_SELECT_PRODUCT_POPUP } from 'Pages/C2cOrderPayment/constants';
import {
  MESSAGE_ALERT_ERROR,
  MESSAGE_ALERT_WARNING
} from 'Pages/C2cOrderPayment/constants/messageAlertConstants';
import {
  C2cCategoryPackageTypeEnum,
  C2cOverPaymentTextEnum,
  ProductStatusEnum
} from 'Pages/C2cOrderPayment/enums';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  c2cOrderPaymentSelectors,
  C2cOverPaymentState,
  chooseC2cProductAction,
  chooseDeliveryMethodAction,
  choosePackageTypeAction,
  selectReceiverPointAction,
  selectSenderPointAction,
  setDeclarationPolicyAgreement,
  setWeightLongWidthHeight
} from 'Stores/c2c-order-payment';
import { TypeOrder } from 'Stores/c2c-order-payment/enums';
import { C2cProductModel } from 'Stores/c2c-order-payment/models';
import { grapSelectors } from 'Stores/grap';
import { GrapBlogsSlugEnums } from 'Stores/grap/enums';
import { layoutUtilitiesSliceSelectors } from 'Stores/layout-utilities';
import { LanguageTypeEnum } from 'Stores/layout-utilities/enums';
import { APRICOT_PEACH_BLOSSOM } from 'Utilities/constants';
import { removeVietnameseTones } from 'Utilities/util';
import { ProductCarousel, ProductSearchInput, ProductTypeSwitch } from '..';
import { ButtonAddProduct } from '../c2cPickedUpProduct';
import C2cProductConfirm from '../c2cProductConfirm/c2cProductConfirm';
import GoodsInformation from '../GoodsInformation/GoodsInformation';
import './index.scss';

interface ProductClassificationProps {}

const ProductClassification = (props: ProductClassificationProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState('');
  const [pageCarousel, setPageCarousel] = useState(1);
  const popup = usePopup();
  const { currentLanguage } = useSelector(layoutUtilitiesSliceSelectors.get);
  const { isGlobal } = useSelector(c2cOrderPaymentSelectors.get);

  const BREAK_POINT_MD = 768;

  const [productsSearch, setProductsSearch] = useState(
    [] as Array<C2cProductModel>
  );
  const [openDropdown, setOpenDropdown] = useState(false);
  const [message, setMessage] = useState({} as MessageAlertModel);
  const {
    productSelected,
    products,
    packageTypeSelected,
    typeOrder,
    c2cValidateFields,
    declarationPolicyAgreement,
    toggleOrder
  } = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;
  const { blogs } = useSelector(grapSelectors.get);
  const productAvailable = products?.filter(
    p => p?.status === ProductStatusEnum.Supported
  );

  useEffect(() => {
    setPageCarousel(1);
  }, [packageTypeSelected]);

  const getDeclarationPolicyContent = () => {
    let fieldDes = '';
    switch (currentLanguage) {
      case LanguageTypeEnum.VietNam:
        fieldDes = 'descriptionVi';
        break;
      case LanguageTypeEnum.Korea:
        fieldDes = 'descriptionKo';
        break;
      case LanguageTypeEnum.English:
        fieldDes = 'descriptionEn';
        break;
    }
    const description = blogs
      ?.find((blog: any) => blog?.slug === GrapBlogsSlugEnums.Rules)
      ?.[fieldDes]?.toString();
    return description?.split('.');
  };

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e?.target?.value);
    const productsSearch = products?.filter((product: C2cProductModel) => {
      const keySearch = removeVietnameseTones(e?.target?.value)?.toLowerCase();
      const productName = removeVietnameseTones(
        product?.product || ''
      )?.toLowerCase();
      if (typeOrder === TypeOrder.PREMIUM) {
        return productName?.includes(keySearch) && product?.isPremium;
      }
      return productName?.includes(keySearch);
    });
    if (productsSearch?.length) {
      setProductsSearch(productsSearch);
      setOpenDropdown(true);
      setMessage({});
      return;
    }
    setMessage(MESSAGE_ALERT_WARNING);
    setOpenDropdown(false);
  };

  const handleChooseC2cProduct = (product: C2cProductModel) => {
    setOpenDropdown(false);
    setMessage({});
    if (product?.status === ProductStatusEnum.NotSupported) {
      setMessage({
        ...MESSAGE_ALERT_ERROR,
        hasBackground: false,
        iconClose: false
      });
      return;
    }
    dispatch(chooseC2cProductAction(product));
    const findProduct = products?.findIndex(
      (p: C2cProductModel, index: number) => {
        return p.id === product.id;
      }
    );

    if (findProduct) {
      setPageCarousel(Math.floor(findProduct / 16) + 1);
    }
  };

  const onChooseProduct = (product: C2cProductModel) => {
    if (product?.status === ProductStatusEnum.NotSupported) {
      setMessage({
        ...MESSAGE_ALERT_ERROR,
        hasBackground: false,
        iconClose: false
      });
      setOpenDropdown(false);
      return;
    }
    if (!product?.isWarning) {
      if (productSelected.code === APRICOT_PEACH_BLOSSOM.code) {
        dispatch(
          setWeightLongWidthHeight({ weight: 0, long: 0, width: 0, height: 0 })
        );
      }
      handleChooseC2cProduct(product);
      return;
    }
    popup.onOpen(
      {
        ...CONFIRM_SELECT_PRODUCT_POPUP,
        maxWidth: 450,
        onSubmit: (isAccept: boolean) => {
          if (isAccept) {
            if (
              product.code === APRICOT_PEACH_BLOSSOM.code ||
              productSelected.code === APRICOT_PEACH_BLOSSOM.code
            ) {
              let weightAndSize = {
                weight: 0,
                long: 0,
                width: 0,
                height: 0
              };
              if (product.code === APRICOT_PEACH_BLOSSOM.code) {
                weightAndSize = {
                  weight: APRICOT_PEACH_BLOSSOM.weight,
                  long: APRICOT_PEACH_BLOSSOM.long,
                  width: APRICOT_PEACH_BLOSSOM.width,
                  height: APRICOT_PEACH_BLOSSOM.height
                };
              }
              dispatch(chooseDeliveryMethodAction({}));
              dispatch(selectSenderPointAction({}));
              dispatch(selectReceiverPointAction({}));
              dispatch(setWeightLongWidthHeight(weightAndSize));
            }
            handleChooseC2cProduct(product);
          }
        }
      },
      <C2cProductConfirm product={product}></C2cProductConfirm>
    );
  };

  const handleShowListProduct = (number: number) => {
    setPageCarousel(pageCarousel + number);
  };

  const handleChangePackage = (actionType: C2cCategoryPackageTypeEnum) => {
    setProductsSearch([]);
    if (typeOrder === TypeOrder.SUPER) {
      return;
    }
    if (
      actionType === C2cCategoryPackageTypeEnum.Document &&
      !declarationPolicyAgreement?.acceptedDocumentRules &&
      isGlobal
    ) {
      openDeclarationPolicyAgreementPopup(true);
      return;
    }
    dispatch(choosePackageTypeAction(actionType));
    dispatch(chooseC2cProductAction({}));
    setPageCarousel(1);
  };

  const handleAddProductDeclaration = () => {
    if (declarationPolicyAgreement?.acceptedParcelRules) {
      openPopupAddGoods();
      return;
    }
    openDeclarationPolicyAgreementPopup(false);
  };

  const openPopupAddGoods = () => {
    popup.onOpen(
      {
        title: 'c2c_goods_info_title',
        hideFooter: true,
        maxHeight: '90vh',
        height: 794,
        maxWidth: 1000
      },
      <GoodsInformation></GoodsInformation>
    );
  };

  const openDeclarationPolicyAgreementPopup = (isDocument: boolean) => {
    popup.onOpen(
      {
        ...CONFIRM_SELECT_PRODUCT_POPUP,
        title: 'global_confirm_declared_info',
        hideBtnCancel: true,
        btnFullWidth: true,
        maxWidth: 450,
        okBtnLabel: 'global_accept_action',
        onSubmit: (isAccept: boolean) => {
          if (isAccept) {
            onAcceptRules(isDocument);
          }
        }
      },
      getDeclarationPolicyContent()
        ?.filter((des: string) => des?.trim() !== '')
        ?.map((des: string) => <p key={des}>{des}.</p>)
    );
  };

  const onAcceptRules = (isDocument: boolean) => {
    if (isDocument) {
      dispatch(
        setDeclarationPolicyAgreement({
          ...declarationPolicyAgreement,
          acceptedDocumentRules: true
        })
      );
      dispatch(choosePackageTypeAction(C2cCategoryPackageTypeEnum.Document));
      setPageCarousel(1);
      return;
    }
    dispatch(
      setDeclarationPolicyAgreement({
        ...declarationPolicyAgreement,
        acceptedParcelRules: true
      })
    );
    // navigate('/goods-info');
    openPopupAddGoods();
  };

  return (
    <Section
      title={t(C2cOverPaymentTextEnum.ProductClassification_Title)}
      icon={<img src={productClassificationIcon} alt={''} />}
    >
      <div className="product-classification">
        {!isGlobal && (
          <ProductSearchInput
            products={productsSearch}
            productSelected={productSelected}
            keyword={keyword}
            handleOnChangeInput={handleOnChangeInput}
            onChooseProduct={onChooseProduct}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
          ></ProductSearchInput>
        )}
        {message?.message ? (
          <MessageAlert
            className="product-classification-search-message"
            {...message}
            toggle={toggleOrder}
          />
        ) : (
          <></>
        )}
        <ProductTypeSwitch
          typeOrder={typeOrder}
          packageTypeSelected={packageTypeSelected}
          handleChangePackage={handleChangePackage}
        ></ProductTypeSwitch>

        {isGlobal &&
          packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel && (
            <ButtonAddProduct
              onClick={handleAddProductDeclaration}
            ></ButtonAddProduct>
          )}
      </div>
      {!!c2cValidateFields?.productCategories?.hasError && (
        <MessageAlert
          {...c2cValidateFields?.productCategories}
          toggle={toggleOrder}
          className={`message-alert-product ${isGlobal ? 'mt-3' : 'mb-3'}`}
        />
      )}
      {!isGlobal && (
        <ProductCarousel
          products={productAvailable}
          productSelected={productSelected}
          typeOrder={typeOrder}
          handleShowListProduct={handleShowListProduct}
          page={pageCarousel}
          onChooseProduct={onChooseProduct}
          packageTypeSelected={packageTypeSelected}
          size={window?.innerWidth < BREAK_POINT_MD ? 9 : 16}
          line={window?.innerWidth < BREAK_POINT_MD ? 3 : 2}
        ></ProductCarousel>
      )}
    </Section>
  );
};

export default ProductClassification;
