import { call, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { BrowserStorageUtil } from '@swift-247/s247.library.ui-core';
import { select } from 'redux-saga/effects';
import { DTOServiceCustomResponse, DTOServiceResponse } from 'Services/interfaces';
import { DTOAddress } from 'Services/v1/address-book/dto';
import C2cOverPaymentServiceV1 from 'Services/v1/c2c-order-payment';
import { DTOUserProfile } from 'Services/v1/user/dto';
import { grapSelectors, GrapState } from 'Stores/grap';
import { updateLoadingOverlay, updateProcessingNavbarAction } from 'Stores/layout-utilities';
import { GeneralDataState } from 'Stores/models';
import { orderBillSelectors, updatePaymentMethod, updateReceiverAddress, updateSenderAddress } from 'Stores/order-bill';
import { OrderBillState } from 'Stores/order-bill/models';
import { profileSliceSelectors } from 'Stores/profile';
import { APRICOT_PEACH_BLOSSOM, TypePayment, VIETNAM_COUNTRY_CODE } from 'Utilities/constants';
import {
  C2cDeliveryMethodModel,
  C2cDeliveryQueryModel,
  c2cOrderPaymentSelectors,
  C2cOverPaymentState,
  c2cPaymentHandler,
  chooseC2cProductAction,
  chooseDeliveryMethodAction,
  getC2cDeliveriesMethodAction,
  getC2cDeliveriesSuccess,
  getC2CDeliveryMethodFailed,
  getC2cProductTypesAction,
  getC2cProductTypesSuccess,
  getC2cVouchersAction,
  getC2cVouchersSuccess,
  getGetExtraService,
  getGetExtraServiceSuccess, getSuggestedDimensionList,
  postC2cOrderAction,
  postC2cOrderSuccessAction,
  ProductDeclarationByCategory,
  QueryBuilder,
  selectReceiverPointAction,
  selectSenderPointAction,
  setGlobalDirection,
  setIsGlobal, setSuggestedDimensionList,
  setWeightLongWidthHeight,
  updateIsLoading,
  updateNewOrder,
  VirtualC2CProductModel,
} from '.';
import { RootState } from '..';
import {
  C2cOrderPayment,
  C2cVouchersQueryModel,
  ExtraServiceQueryModel,
  GoodProduceType,
  IPaymentKorea,
  PaymentCustom,
  PaymentKoreaParam,
} from './models';
import { ProductTypeEnum } from 'Stores/c2c-order-payment/enums/productTypeEnum';
import _ from 'lodash';

export function* handleGetExtraService(
  action: PayloadAction<ExtraServiceQueryModel>
) {
  const { data, error, statusCode } = yield call(
    C2cOverPaymentServiceV1.getExtraService.bind(
      C2cOverPaymentServiceV1,
      action.payload
    )
  );
  action.payload;
  if (error) {
    // yield put(
    // 	getC2cCategoriesFailed({
    // 		errCode: error.code,
    // 		errMsg: error.msg,
    // 	}),
    // );
    return;
  }
  yield put(getGetExtraServiceSuccess(data));
}

export function* handelGetVouchersService(
  action: PayloadAction<C2cVouchersQueryModel>
): any {
  yield put(updateIsLoading(true));
  const { data, error, statusCode } = yield call(
    C2cOverPaymentServiceV1.getVouchersService.bind(
      C2cOverPaymentServiceV1,
      action.payload
    )
  );
  action.payload;
  if (error) {
    // yield put(
    // 	getC2cCategoriesFailed({
    // 		errCode: error.code,
    // 		errMsg: error.msg,
    // 	}),
    // );
    return;
  }
  yield put(getC2cVouchersSuccess(data));
  yield put(updateIsLoading(false));
}

export function* handleGetC2cDelivery(
  action: PayloadAction<C2cDeliveryQueryModel>
): any {
  const res = (yield call(
    C2cOverPaymentServiceV1.getC2cDeliveriesMethod.bind(
      C2cOverPaymentServiceV1,
      action.payload
    )
  ));
  if (res.error) {
    yield put(
      getC2CDeliveryMethodFailed({
        errCode: res.error.code,
        errMsg: res.error.msg,
        data: res.error.data
      })
    );
    yield put(updatePaymentMethod());
    return;
  }
  yield put(getC2cDeliveriesSuccess(res.data));
}

export function* handleGetC2cProductTypes(
  action: PayloadAction<GoodProduceType>
): any {
  yield put(updateIsLoading(true));
  const { data, error, statusCode } = yield call(
    C2cOverPaymentServiceV1.getC2cProductTypes.bind(
      C2cOverPaymentServiceV1,
      action.payload
    )
  );
  action.payload;
  if (error) {
    // yield put(
    // 	getC2cCategoriesFailed({
    // 		errCode: error.code,
    // 		errMsg: error.msg,
    // 	}),
    // );
    return;
  }
  yield put(getC2cProductTypesSuccess(data));
  yield put(updateIsLoading(false));
}

export function* handlePostC2cOrder(
  action: PayloadAction<(isGlobal: boolean, data?: IPaymentKorea) => void>
): any {
  yield put(
    updateNewOrder({
      status: 'processing'
    })
  );
  yield put(updateIsLoading(true));
  yield put(updateProcessingNavbarAction(true));
  const c2cState = (yield select<(state: RootState) => C2cOverPaymentState>(
    c2cOrderPaymentSelectors.get
  )) as C2cOverPaymentState;

  const productDeclarationByCategory = (yield select(c2cOrderPaymentSelectors.productDeclarationByCategorySelector.selectAll))

  const orderBillState = (yield select<(state: RootState) => OrderBillState>(
    orderBillSelectors.get
  )) as OrderBillState;
  const profile = (yield select<
    (state: RootState) => GeneralDataState<DTOUserProfile>
  >(profileSliceSelectors.get)) as DTOUserProfile;
  const grapState = (yield select<(state: RootState) => GrapState>(
    grapSelectors.get
  )) as GrapState;

  const { isGlobal } = c2cState;

  const productDeclaration = yield select(
    c2cOrderPaymentSelectors.getAllProductDeclaration
  );

  const buildQueryService = QueryBuilder(c2cState, productDeclaration, productDeclarationByCategory);
  const params = buildQueryService.getOrderQuery(orderBillState, grapState);

  const { data, error } = (yield call(
    C2cOverPaymentServiceV1.postC2cOrder.bind(C2cOverPaymentServiceV1, params)
  )) as {
    data: DTOServiceCustomResponse<C2cOrderPayment, PaymentCustom>;
    error: any;
  };
  if (error) {
    // TODO: Get order failed ?
    yield put(
      updateNewOrder({
        status: 'failed',
        errorMsg: error.msg
      })
    );
    yield put(updateIsLoading(false));
    yield put(updateLoadingOverlay(false));
    yield put(updateProcessingNavbarAction(false));
    return;
  }

  yield put(postC2cOrderSuccessAction(data));

  if (orderBillState?.vatInfo?.isEnabled) {
    const queryVat = buildQueryService.getQueryVat(
      orderBillState,
      data?.data!.orderId
    );
    yield call(
      C2cOverPaymentServiceV1.postC2cVatService.bind(
        C2cOverPaymentServiceV1,
        queryVat
      )
    );
  }

  if (data) {
    const paramsPaymentMethod =
      isGlobal && data.data?.paymentMethod === TypePayment.KCP
        ? buildQueryService.getQueryPaymentMethod(data, profile)
        : ({} as PaymentKoreaParam);

    c2cPaymentHandler(data, paramsPaymentMethod, action.payload);
  }

  const persistLayer = new BrowserStorageUtil(sessionStorage);

  persistLayer.serializedSave('newOrder', {
    ...data.data,
    vnpBank: c2cState.chosenPaymentMethod?.code
  });

  yield put(updateProcessingNavbarAction(false));
  yield put(updateIsLoading(false));
  yield put(
    updateNewOrder({
      status: 'completed'
    })
  );
}

export function* c2cHandleUpdateSenderAddress(
  action: PayloadAction<DTOAddress | undefined>
) {
  const orderBillState = (yield select<(state: RootState) => OrderBillState>(
    orderBillSelectors.get
  )) as OrderBillState;

  const senderAddress = action.payload;
  const receiverAddress = orderBillState.receiverAddress;

  if (!senderAddress) return;

  const isNewAddressGlobal =
    senderAddress && senderAddress.country?.toString() !== VIETNAM_COUNTRY_CODE;

  if (
    isNewAddressGlobal ||
    (receiverAddress &&
      receiverAddress.country?.toString() !== VIETNAM_COUNTRY_CODE)
  ) {
    yield put(setIsGlobal(true));
    if (isNewAddressGlobal) yield put(setGlobalDirection('inbound'));
  } else {
    yield put(setIsGlobal(false));
  }
}

export function* c2cHandleUpdateReceiverAddress(
  action: PayloadAction<DTOAddress | undefined>
) {
  const orderBillState = (yield select<(state: RootState) => OrderBillState>(
    orderBillSelectors.get
  )) as OrderBillState;

  const senderAddress = orderBillState.senderAddress;

  const receiverAddress = action.payload;
  if (!receiverAddress) return;

  const isNewAddressGlobal =
    receiverAddress &&
    receiverAddress.country?.toString() !== VIETNAM_COUNTRY_CODE;

  if (
    (senderAddress &&
      senderAddress.country?.toString() !== VIETNAM_COUNTRY_CODE) ||
    isNewAddressGlobal
  ) {
    yield put(setIsGlobal(true));
    if (isNewAddressGlobal) yield put(setGlobalDirection('outbound'));
  } else {
    yield put(setIsGlobal(false));
  }
}

export function* c2cHandleAddressSelect(
  action: PayloadAction<DTOAddress | undefined>
) {}

export function* getSuggestedDimensionListHandler() {
  const { data, error, statusCode } = yield call(
    C2cOverPaymentServiceV1.getDimensionList.bind(C2cOverPaymentServiceV1)
  );
  if (error) {
    return;
  }
  yield put(setSuggestedDimensionList(data));
}

export function* c2cRecalculateProductDeclareSummary() {
  const newDeclarationData = (yield select(c2cOrderPaymentSelectors.productDeclarationByCategorySelector.selectAll)) as ProductDeclarationByCategory[];
  const {productSelected} = (yield select(c2cOrderPaymentSelectors.get)) as C2cOverPaymentState;
  const apricotpeachblossomItem = newDeclarationData.find(item => item.productCode === APRICOT_PEACH_BLOSSOM.code);

  const defaultWeightAndSize = {
    weight: 0,
    long: 0,
    width: 0,
    height: 0
  }

  const  apricotpeachblossomWeightAndSize= {
    weight: APRICOT_PEACH_BLOSSOM.weight,
    long: APRICOT_PEACH_BLOSSOM.long,
    width: APRICOT_PEACH_BLOSSOM.width,
    height: APRICOT_PEACH_BLOSSOM.height
  };

  let newWeightAndSize = defaultWeightAndSize;


  if(apricotpeachblossomItem) {

    const virtualItem: VirtualC2CProductModel = {
      product: apricotpeachblossomItem.productInfo.product || '',
      price: apricotpeachblossomItem.productInfo.price || '0',
      ids: [apricotpeachblossomItem.productInfo.id || 0],
      type: apricotpeachblossomItem.productInfo.type,
      code: apricotpeachblossomItem.productInfo.code || ''
    }
    yield put(chooseC2cProductAction(virtualItem))
    newWeightAndSize = apricotpeachblossomWeightAndSize
  }

  if(apricotpeachblossomItem || productSelected.code === APRICOT_PEACH_BLOSSOM.code) {
    yield put(chooseDeliveryMethodAction({}));
    yield put(selectSenderPointAction({}));
    yield put(selectReceiverPointAction({}));
    yield put(setWeightLongWidthHeight(newWeightAndSize));
  }

  // Virtual item is an item accumulated from product list
  // (this is for backward adaption with previous logic which relied on single selected item to determine displaying logic)
  const makeVirtualItem = (listItems: typeof newDeclarationData) => {
    const result = listItems.length > 0 && listItems[0] ? listItems.reduce((result, item) => {
      result.price = parseInt(result.price || '0') + parseInt(item.productInfo.price || '0') + '';
      result.product = [result.product, item.productName].filter(Boolean).join(', ');
      result.code = [result.code, item.productCode].filter(Boolean).join(', ');
      result.ids?.push(item.productInfo.id || 0);

      return result;
    }, { price: '0', product: '', code: '',  ids: []} as VirtualC2CProductModel) : undefined;

    if(!result) return result;

    if(parseInt(result.price) > 0) {
      result.type = ProductTypeEnum.VUN
    }

    return result;
  }

 const virtualItem = makeVirtualItem(newDeclarationData);

  yield put(chooseC2cProductAction(virtualItem || {}))
}

export function* c2cOrderPaymentSaga() {
  yield takeLatest(getGetExtraService.toString(), handleGetExtraService);
  yield takeLatest(getC2cVouchersAction.toString(), handelGetVouchersService);
  yield takeLatest(
    getC2cDeliveriesMethodAction.toString(),
    handleGetC2cDelivery
  );
  yield takeLatest(
    getC2cProductTypesAction.toString(),
    handleGetC2cProductTypes
  );
  yield takeLatest(postC2cOrderAction.toString(), handlePostC2cOrder);
  yield takeLatest(
    updateSenderAddress.toString(),
    c2cHandleUpdateSenderAddress
  );
  yield takeLatest(
    updateReceiverAddress.toString(),
    c2cHandleUpdateReceiverAddress
  );
  yield takeEvery(
    [updateSenderAddress.toString(), updateReceiverAddress.toString()],
    c2cHandleAddressSelect
  );
  yield takeLatest((action: any) => {
    return action.type.includes('ProductDeclarationByCategory');
  }, c2cRecalculateProductDeclareSummary);
  yield takeLatest(getSuggestedDimensionList.toString(), getSuggestedDimensionListHandler)
}
