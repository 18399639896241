import React, { useEffect } from 'react';
import Section from 'Components/Section';

import './index.scss'
import FieldLabel from 'Components/FieldLabel';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, ValidationRule } from 'react-hook-form';
import ButtonClick from 'Components/ButtonClick';
import UserServiceV1 from 'Services/v1/user';
import InputPassword from 'Components/InputPassword';
import './index.scss'
import { DTOChangePasswordRequest } from 'Services/v1/user/dto';
import InputErrorMessage from 'Components/InputErrorMessage';
import { validations } from '@swift-247/s247.library.ui-core';
import { useLoadingBar, useToast } from 'Components/LayoutUtilities';
import { useNavigate } from 'react-router-dom';

interface DTOChangePasswordForm extends DTOChangePasswordRequest {
	reEnterPassword: string
}

const ChangeProfilePassword = () => {
	const {t}= useTranslation();

	const {control, handleSubmit, formState: {errors}, getValues} = useForm<DTOChangePasswordForm>();
	const {startLoadingBar, stopLoadingBar, loadingBarState} = useLoadingBar()
	const navigate = useNavigate();
	const toast = useToast();

	const onSubmit = async (values: DTOChangePasswordForm) => {
		const {
			newPassword,
			oldPassword
		} = values;
		startLoadingBar()
		const data = await UserServiceV1.changePassword({
			newPassword,
			oldPassword
		})

		if(data.error) {
			toast('error', t('message_error_change_password'));
			stopLoadingBar()
		} else {
			navigate('/')
		}
	}

	// TODO: Validations translate messages blow usages are not enough general -> Need to workout more (core UI lib)

	return <Section title={t('menu_item_change_password')}>
		<form className={'change-password-form'} onSubmit={handleSubmit(onSubmit)}>
			<div className={'mb-1 change-password-form-input-container'}>
				<FieldLabel className={'mb-2'} value={t('label_old_password')}/>
				<Controller
					control={control}
					rules={{
						validate: {
							required: validations.required
						}
					}}
					render={({field}) => {
						return <InputPassword
							{...field}
							placeholder={t('label_old_password')}
						/>
					}}
					name={'oldPassword'}
				/>
				{errors.oldPassword && <InputErrorMessage className={'mt-2'} message={t(`${errors.oldPassword.message}`, {fieldName: t('label_old_password')})}/>}
			</div>
			<div className={'mb-1 change-password-form-input-container'}>
				<FieldLabel className={'mb-2'} value={t('label_new_password')}/>
				<Controller
					control={control}
					rules={{
						validate: validations.passwordValidationGroup
					}}
					render={({field}) => {
						return <InputPassword
							{...field}
							placeholder={t('label_new_password')}
						/>
					}}
					name={'newPassword'}
				/>
				{errors.newPassword && <InputErrorMessage className={'mt-2'} message={t(`${errors.newPassword.message}_password`, {min: validations.MIN_PASSWORD_LENGTH, max: validations.MAX_PASSWORD_LENGTH})}/>}
			</div>
			<div className={'mb-1 change-password-form-input-container'}>
				<FieldLabel className={'mb-2'} value={t('label_re_enter_password')}/>
				<Controller
					control={control}
					rules={{
						validate: {
							passwordMatch: (val) => {
								return val === getValues('newPassword') || 'validation_re_enter_password'
							}
						}
					}}
					render={({field}) => {
						return <InputPassword
							{...field}
							placeholder={t('label_re_enter_password')}
						/>
					}}
					name={'reEnterPassword'}
				/>
				{errors.reEnterPassword && <InputErrorMessage className={'mt-2'} message={t(`${errors.reEnterPassword.message}`, {min: validations.MIN_PASSWORD_LENGTH, max: validations.MAX_PASSWORD_LENGTH})}/>}
			</div>
			<ButtonClick
				variant="primary"
				htmlType="submit"
				containerClassName={'text-right'}
				disabled={loadingBarState === 'loading'}
				className={'business-profile-form-save-button'}
			>
				{t('global_confirm')}
			</ButtonClick>
		</form>
	</Section>
}

export default ChangeProfilePassword;