import { message, Upload } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import uploadIcon from 'Assets/icon/photo-upload-icon.svg';
import React from 'react';
import { classPrefix } from 'Utilities/global';
import './index.scss';
import MediaAssetsServiceV1 from 'Services/v1/media-assets';

interface Props {
	value?: any;
	name?: string;
	onChange?: (info: UploadChangeParam<UploadFile>) => void;
	beforeUpload?: (file: RcFile) => void;
	className?: string;
	error?: boolean;
	helperText?: string;
	errorMsg?: string;
	onClick?: () => void;
	disabled?: boolean;
	label?: string;
	placeholder?: string;
	readonly?: boolean;
	status?: 'error' | 'warning' | '';
	uploadText?: string;
	action?: string,
	customRequest?: any,
	fileList?: any,
	maxCount?: number
}
const UploadForm = (props: Props) => {
	const {
		value,
		onChange,
		beforeUpload,
		name,
		className,
		error,
		helperText,
		disabled = false,
		errorMsg,
		label,
		placeholder,
		readonly,
		onClick,
		status,
		uploadText,
		action,
		customRequest,
		fileList,
		maxCount = 100,
		...rest
	} = props;

	const containerClasses = `${classPrefix}-upload-container d-inline-block`;
	// console.log('fileList: ', fileList);
	return (
		<>
			<div className={`${containerClasses}`}>
				<Upload
					listType="picture-card"
					className="avatar-uploader"
					beforeUpload={beforeUpload}
					onChange={onChange}
					customRequest={customRequest}
					fileList={fileList}
					maxCount={3}
					isImageUrl={() =>true}
				>
					{(fileList && fileList.length < maxCount) && <div className='d-flex flex-column align-items-center'>
						<img src={uploadIcon} width='30' />
						<span className='text-gray-primary mt-1'>{uploadText}</span>
					</div>}
				</Upload>
			</div>
		</>
	);
};

export default UploadForm;
