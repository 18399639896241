import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import TopupServiceV1 from 'Services/v1/topup';
import {
  getTopupInfo,
  getTopupInfoSuccess,
  getTopupTransaction,
  getTopupTransactionSuccess
} from '.';
import { TopupTransaction } from './models';

export function* handleGetTopupInfo() {
	const { data, error, statusCode } = yield call(
		TopupServiceV1.getTopupInfo.bind(TopupServiceV1),
	);
	if (data) {
		yield put(getTopupInfoSuccess(data));
	}
}

export function* handleGetTopupTransaction(
	action: PayloadAction<TopupTransaction>,
) {
	const { data, error, statusCode } = yield call(
		TopupServiceV1.getTopupTransactions.bind(
			TopupServiceV1.getTopupTransactions,
			action.payload,
		),
	);
	if (data) {
		yield put(getTopupTransactionSuccess(data));
	}
}

export default function* topupSaga() {
	yield takeLatest(getTopupInfo.toString(), handleGetTopupInfo);
	yield takeLatest(getTopupTransaction.toString(), handleGetTopupTransaction);
}
