import { C2cSelectWeightTypeModel } from '../models';

export const C2C_MARKS_WEIGHT = {
  0: '0',
  100: '10',
  200: '20',
  300: '30',
  400: '40'
};

export const C2C_WEIGHT_MAX: C2cSelectWeightTypeModel = {
  ParcelVN: {
    max: 40,
    min: 0,
    decimal: 1,
    marks: {
      0: '0',
      100: '10',
      200: '20',
      300: '30',
      400: '40'
    }
  },
  DocumentVN: {
    max: 7,
    min: 0,
    decimal: 1,
    marks: {
      0: '0',
      10: '1',
      20: '2',
      30: '3',
      40: '4',
      50: '5',
      60: '6',
      70: '7'
    }
  },
  ParcelKR: {
    max: 20,
    min: 0,
    decimal: 1,
    marks: {
      0: '0',
      50: '5',
      100: '10',
      150: '15',
      200: '20'
    }
  },
  DocumentKR: {
    max: 2,
    min: 0,
    decimal: 1,
    marks: {
      0: '0',
      5: '0.5',
      10: '1',
      15: '1.5',
      20: '2'
    }
  }
};
