import { C2cCategoryPackageTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { useTranslation } from 'react-i18next';
import { TypeOrder } from 'Stores/c2c-order-payment';
import documentIcon from 'Assets/images/ic-document.png';
import parcelIcon from 'Assets/images/ic-parcel.png';
import './styles.scss';

interface PropsModel {
    typeOrder: TypeOrder;
    packageTypeSelected: C2cCategoryPackageTypeEnum;
    handleChangePackage: (p: C2cCategoryPackageTypeEnum)=>void
}

const ProductTypeSwitch = (props: PropsModel) => {
    const { t } = useTranslation();
    const {packageTypeSelected, typeOrder, handleChangePackage}= props;
		return (
			<div className="product-classification-switch-icon">
				<div
					className={
						typeOrder === TypeOrder.SUPER
							? 'pc-button-wrap pc-button-super'
							: 'pc-button-wrap'
					}
				>
					<div
						className={`pc-button-item ${
							packageTypeSelected ===
								C2cCategoryPackageTypeEnum.Parcel &&
							'pc-button-item--active'
						}`}
						onClick={() => {
							handleChangePackage(C2cCategoryPackageTypeEnum.Parcel);
						}}
					>
						<img src={parcelIcon} width="18" />
						<span>{t('product_classification_post_office')}</span>
					</div>
					<div
						className={`pc-button-item ${
							packageTypeSelected ===
								C2cCategoryPackageTypeEnum.Document &&
							'pc-button-item--active'
						}`}
						onClick={() => {
							handleChangePackage(
								C2cCategoryPackageTypeEnum.Document,
							);
						}}
					>
						<img src={documentIcon} width="18" />
						<span> {t('product_classification_document')}</span>
					</div>
				</div>
			</div>
		);
};

export default ProductTypeSwitch;
