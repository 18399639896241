import { MessageAlertModel } from 'Components/Models';
import { isEmpty } from 'lodash';
import { C2cOverPaymentState, GoodsInfoModel } from 'Stores/c2c-order-payment';
import { C2cValidationFieldModel } from 'Stores/c2c-order-payment/models';
import { OrderBillState } from 'Stores/order-bill/models';
import { MESSAGE_ALERT_ERROR } from '../constants';
import { C2cCategoryPackageTypeEnum, ValidationMessageEnum } from '../enums';

const C2cCheckValidationInputLogic = (
  c2cState: C2cOverPaymentState,
  orderBillState: OrderBillState,
  productDeclaration?: Array<GoodsInfoModel>
) => {
  const {
    weightAndSize,
    productSelected,
    chosenPaymentMethod,
    packageTypeSelected,
    isTermAndConditionAgreed,
    isGlobal,
    globalDirection
  } = c2cState;
  const { longs, width, height } = weightAndSize;
  const { paymentMethod, senderAddress, receiverAddress } = orderBillState;

  const checkSelectAddress = () => {
    if (isGlobal && globalDirection === 'outbound' && !receiverAddress?.pccc) {
      return {
        ...MESSAGE_ALERT_ERROR,
        message: ValidationMessageEnum.C2cGlobalAddressPCCC
      };
    }
    if (
      !isGlobal &&
      (receiverAddress?.isForceUpdateAddress ||
        senderAddress?.isForceUpdateAddress)
    ) {
      return {
        ...MESSAGE_ALERT_ERROR,
        message: ValidationMessageEnum.C2cForceUpdateAddress
      };
    }
    return {
      hasError: false
    } as MessageAlertModel;
  };

  const checkWeight = () => {
    if (!weightAndSize.weight) {
      return {
        ...MESSAGE_ALERT_ERROR,
        message: ValidationMessageEnum.C2cNoValueWeight
      } as MessageAlertModel;
    }
    return {
      hasError: false
    } as MessageAlertModel;
  };

  const checkSize = () => {
    let message = '';
    let hasError = false;
    if (!longs || !width || !height) {
      message = ValidationMessageEnum.C2cNoValueSize;
      hasError = true;
    }

    return {
      ...MESSAGE_ALERT_ERROR,
      hasError,
      message
    } as MessageAlertModel;
  };

  const checkProductSelected = () => {
    if (
      isGlobal &&
      packageTypeSelected === C2cCategoryPackageTypeEnum.Document
    ) {
      return {
        hasError: false
      } as MessageAlertModel;
    }
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError:
        (isEmpty(productSelected) && !isGlobal) ||
        (isEmpty(productDeclaration) && isGlobal),
      message: ValidationMessageEnum.C2cNoChooseProduct
    } as MessageAlertModel;
  };

  const checkProductDeclaration = () => {
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError:
        !productDeclaration?.length &&
        packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel,
      message: ValidationMessageEnum.C2cAddProductInvalid
    } as MessageAlertModel;
  };

  const checkPaymentMethod = () => {
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError:
        (isEmpty(chosenPaymentMethod) && isGlobal) ||
        (isEmpty(paymentMethod) && !isGlobal),
      message: ValidationMessageEnum.C2cNoChoosePaymentMethod
    } as MessageAlertModel;
  };

  const checkTermAndConditionAgreed = () => {
    return {
      ...MESSAGE_ALERT_ERROR,
      hasError: !isTermAndConditionAgreed,
      message: ValidationMessageEnum.TermAndConditionAgreedValid
    } as MessageAlertModel;
  };

  const checkC2cInput = () => {
    return {
      productCategories: isGlobal
        ? checkProductDeclaration()
        : checkProductSelected(),
      weight: checkWeight(),
      size: checkSize(),
      paymentMethod: checkPaymentMethod(),
      address: checkSelectAddress(),
      termAndConditionAgreed: checkTermAndConditionAgreed()
    } as C2cValidationFieldModel;
  };

  const checkInValid = () => {
    const productMs = checkProductSelected();
    const weightMs = checkWeight();
    const sizeMs = checkSize();
    const paymentMethodMs = checkPaymentMethod();
    const termAndConditionAgreedMs = checkTermAndConditionAgreed();
    const selectAddress = checkSelectAddress();
    return (
      productMs.hasError ||
      weightMs.hasError ||
      sizeMs.hasError ||
      paymentMethodMs.hasError ||
      termAndConditionAgreedMs.hasError ||
      selectAddress.hasError
    );
  };

  return {
    checkC2cInput,
    checkInValid,
    checkProductDeclaration,
    checkSelectAddress
  };
};

export default C2cCheckValidationInputLogic;
