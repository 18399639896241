import {
  DTOOrderExtraPriceRaw,
  DTOOrderPropertyExtractPriceRaw,
  DTOProductOrderRaw
} from 'Services/v1/cargo-direct/dto';
import { C2cAddressInfoModel, ExtraServiceModel } from '.';

export interface C2cOrderQueryModel {
	amount?: number;
	beforeTime?: string;
	benAgreed?: boolean;
	chosenExtraPrice?: Array<any>;
	dateTimeSlot?: string; //-"2022-08-14"
	deliveryType?: string;
	destinationDop?: number;
	destinationDropoffPoint?: string;
	destinationId?: Array<string> | undefined;
	device?: string;
	deviceToken?: string;
	deviceType?: string;
	dimension?: string;
	discountAmount?: number;
	extraPrices?: Array<ExtraServiceModel>;
	fromLat?: number;
	fromLng?: number;
	fromName?: string;
	fromPhone?: string;
	goodsInfo?: Array<any>;
	hasInsurance?: boolean;
	location?: string;
	minEndTime?: string;
	note?: string;
	orderDetails?: Array<C2cOrderDetail>;
	originDop?: number;
	originDropoffPoint?: string;
	originId?: Array<string> | undefined;
	packageType?: string;
	paymentMethod?: string;
	premium?: boolean;
	receiverAdditionalInfo?: string;
	receiverAddress?: string;
	receiverAddressId?: number;
	receiverPointInfo?: Array<C2cAddressInfoModel>;
	senderAdditionalInfo?: string;
	senderAddress?: string;
	senderAddressId?: number;
	senderPointInfo?: Array<C2cAddressInfoModel>;
	shippingTime?: number;
	super?: boolean;
	superswiftFee?: number;
	timeSlotFrom?: string; //-"09?:45"
	timeSlotTo?: string; //-"09?:45"
	toApollo?: boolean;
	toLat?: number;
	toLng?: number;
	toName?: string;
	toPhone?: string;
	transportRules?: Array<{ rule?: string }>;
	ver?: string;
	voucher?: string;
	weight?: number;
	vnpBank?: string;
}

export interface C2cOrderQueryRawModel {}

export interface C2cOrderDetail {
	name?: string;
	key?: string;
	productId?: number;
}

export interface C2cOrderPayment {
	allowCancel: boolean;
	allowToApollo: boolean;
	amount: number;
	appVersion: string;
	awb: string | null;
	base64QrCode: string;
	beforeTime: string;
	bookingShipperTime: string;
	cargoAllowCancel: boolean;
	createdAt: string;
	currency: string;
	dateTimeSlot: string;
	declareValue: null;
	deliveryType: string;
	destinationDop: number;
	destinationProvinceCode: string;
	dimension: string;
	discountAmount: number;
	extraPrices: DTOOrderExtraPriceRaw[];
	feedback: null | string;
	flightId: string;
	fromLat: number;
	fromLng: number;
	fromName: string;
	fromPhone: string;
	id: string;
	image: null | string;
	minEndTime: string;
	note: null | string;
	object: string;
	omsTrackingId: null | string;
	orderDetails: DTOProductOrderRaw[];
	orderId: string;
	originDop: number;
	originProvinceCode: string;
	packageType: string;
	paymentMethod: string;
	paymentStatus: string;
	paymentTime: null | string;
	platform: string;
	properties: DTOOrderPropertyExtractPriceRaw;
	quantity: null | string;
	receiverDdditionalInfo: null | string;
	receiverAddress: string;
	receiverAddressId: number;
	receiverPointInfo: [];
	remark: string;
	reviews: [];
	senderAdditionalInfo: null;
	senderAddress: string;
	senderAddressId: number;
	senderPointInfo: [];
	shippingTime: string;
	status: string;
	statusName: string;
	superswiftFee: number;
	timeSlotFrom: string;
	timeSlotTo: string;
	toLat: number;
	toLng: number;
	toName: string;
	toPhone: string;
	trackingInfo: [];
	trackingInfoVenus: [];
	transportRules: [];
	updatedAt: string;
	uuid: string;
	vatInfo: null | string;
	voucher: string;
	weight: string;
}

export interface C2cOrderPaymentRaw {
	allow_cancel: boolean;
	allow_to_apollo: boolean;
	amount: number;
	app_version: string;
	awb: string | null;
	base64_qr_code: string;
	before_time: string;
	booking_shipper_time: string;
	cargo_allow_cancel: boolean;
	created_at: string;
	currency: string;
	date_time_slot: string;
	declare_value: null;
	delivery_type: string;
	destination_dop: number;
	destination_province_code: string;
	dimension: string;
	discount_amount: number;
	extra_prices: DTOOrderExtraPriceRaw[];
	feedback: null | string;
	flight_id: string;
	from_lat: number;
	from_lng: number;
	from_name: string;
	from_phone: string;
	id: string;
	image: null | string;
	min_end_time: string;
	note: null | string;
	object: string;
	oms_tracking_id: null | string;
	order_details: DTOProductOrderRaw[];
	order_id: string;
	origin_dop: number;
	origin_province_code: string;
	package_type: string;
	payment_method: string;
	payment_status: string;
	payment_time: null | string;
	platform: string;
	properties: DTOOrderPropertyExtractPriceRaw;
	quantity: null | string;
	receiver_additional_info: null | string;
	receiver_address: string;
	receiver_address_id: number;
	receiver_point_info: [];
	remark: string;
	reviews: [];
	sender_additional_info: null;
	sender_address: string;
	sender_address_id: number;
	sender_point_info: [];
	shipping_time: string;
	status: string;
	status_name: string;
	superswift_fee: number;
	time_slot_from: string;
	time_slot_to: string;
	to_lat: number;
	to_lng: number;
	to_name: string;
	to_phone: string;
	tracking_info: [];
	tracking_info_venus: [];
	transport_rules: [];
	updated_at: string;
	uuid: string;
	vat_info: null | string;
	voucher: string;
	weight: string;
}

export interface PaymentData {
	amount: string;
	buyerTel: string;
	merchantId: string;
	merchantUid: string;
	name: string;
	payMethod: string;
	pg: string;
  appScheme: string;
  paymentMethod: string;
  buyerName: string;
  buyerEmail: string;
  mRedirectUrl: string;
  niceMobileV2: boolean;
  escrow: boolean;
  digital: boolean;
  card: {
		detail: { cardCode: string; enabled: boolean }[];
	};
}

export interface PaymentDataRaw {
	amount: string;
	buyer_tel: string;
	merchant_id: string;
	merchant_uid: string;
	name: string;
	pay_method: string;
	pg: string;
  app_scheme: string;
  payment_method: string;
  buyer_name: string;
  buyer_email: string;
  m_redirect_url: string;
  niceMobileV2: boolean;
  escrow: boolean;
  digital: boolean;
  card: {
		detail: { card_code: string; enabled: boolean }[];
	};
}

export interface PaymentCustom {
	paymentData: PaymentData;
	paymentUrl: string;
}

export interface PaymentCustomRaw {
	payment_ata: PaymentDataRaw;
	payment_url: string;
}

export interface PaymentKoreaParam {
	pg: string;
	pay_method: string;
	merchant_uid: string;
	popup: boolean;
	amount: string;
	app_scheme: string;
	buyer_tel: string;
	name: string;
	buyer_name: string;
	buyer_email: string;
	m_redirect_url: string;
	niceMobileV2: boolean;
	escrow: boolean;
	language: string;
	digital: boolean;
	card: {
		detail: { card_code: string; enabled: boolean }[];
	};
}

export interface IPaymentKorea {
	apply_num: string;
	bank_name: null | string;
	buyer_addr: string;
	buyer_email: string;
	buyer_name: string;
	buyer_postcode: string;
	buyer_tel: string;
	card_name: string;
	card_number: string;
	card_quota: number;
	currency: string;
	custom_data: null | string;
	imp_uid: string;
	merchant_uid: string;
	name: string;
	paid_amount: number;
	paid_at: number;
	pay_method: string;
	pg_provider: string;
	pg_tid: string;
	pg_type: string;
	receipt_url: string;
	status: string;
	success: boolean;
}

export class PaymentKorea {
	applyNum: string = '';
	bankName: null | string = '';
	buyerAddr: string = '';
	buyerEmail: string = '';
	buyerName: string = '';
	buyerPostcode: string = '';
	buyerTel: string = '';
	cardName: string = '';
	cardNumber: string = '';
	cardQuota: number = 0;
	currency: string = '';
	customData: null | string = '';
	impUid: string = '';
	merchantUid: string = '';
	name: string = '';
	paidAmount: number = 0;
	paidAt: number = 0;
	payMethod: string = '';
	pgProvider: string = '';
	pgTid: string = '';
	pgType: string = '';
	receiptUrl: string = '';
	status: string = '';
	success: boolean = false;

	constructor(data: IPaymentKorea) {
		if (data == null) return;

		this.applyNum = data.apply_num;
		this.bankName = data.bank_name;
		this.buyerAddr = data.buyer_addr;
		this.buyerEmail = data.buyer_email;
		this.buyerName = data.buyer_name;
		this.buyerPostcode = data.buyer_postcode;
		this.buyerTel = data.buyer_tel;
		this.cardName = data.card_name;
		this.cardNumber = data.card_number;
		this.cardQuota = data.card_quota;
		this.currency = data.currency;
		this.customData = data.custom_data;
		this.impUid = data.imp_uid;
		this.merchantUid = data.merchant_uid;
		this.name = data.name;
		this.paidAmount = data.paid_amount;
		this.paidAt = data.paid_at;
		this.payMethod = data.pay_method;
		this.pgProvider = data.pg_provider;
		this.pgTid = data.pg_tid;
		this.pgType = data.pg_type;
		this.receiptUrl = data.receipt_url;
		this.status = data.status;
		this.success = data.success || false;
	}
}
