import moment from 'moment';
import { CamelSnakeMapper } from 'Services/data-mappers';
import httpClient from 'Services/http-client';
import { DTOServiceResponse } from 'Services/interfaces';
import {
  DTOAirportBasicInfoRaw,
  DTOAirportInfo,
  DTOAirportProvinceMapping,
  DTOAirportProvinceMappingRaw,
  DTOArrivalAirportRaw,
  DTOAvailableFlight,
  DTOAvailableFlightRaw,
  DTOCheckFlightAvailableLoadRequest,
  DTOCheckFlightAvailableLoadRequestRaw,
  DTOCheckFlightAvailableLoadResponse,
  DTOCheckFlightAvailableLoadResponseRaw,
  DTOCommodity,
  DTOGetArrivalAirportsRequest,
  DTOGetArrivalAirportsRequestRaw,
  DTOGetAvailableFlightRequest,
  DTOGetAvailableFlightRequestRaw,
  DTOGetAvailableFlightResponse,
  DTOGetAvailableFlightResponseRaw,
  DTOGetDepartureAirportsRequest,
  DTOGetDepartureAirportsRequestRaw,
  DTOSummaryDimensionWeightMapping,
  DTOSummaryDimensionWeightRaw,
  DTOSummaryDimensionWeightRequest
} from 'Services/v1/flights/dto';
import RootServiceV1 from 'Services/v1/root';
import { DATE_FORMATS } from 'Utilities/constants';

class FlightsServiceV1 extends RootServiceV1 {
  constructor() {
    super();
  }

  public async getDepartureAirports(
    payload: DTOGetDepartureAirportsRequest
  ): Promise<DTOServiceResponse<DTOAirportInfo[]>> {
    try {
      const rawPayload = CamelSnakeMapper.toSnakeCase<
        DTOGetDepartureAirportsRequest,
        DTOGetDepartureAirportsRequestRaw
      >(payload);

      const response = await httpClient().get('/v2/cargo/departure-available', {
        params: rawPayload
      });
      const data = response.data.data;
      return {
        statusCode: 200,
        data: data.map(
          CamelSnakeMapper.toCamelCase<DTOAirportBasicInfoRaw, DTOAirportInfo>
        )
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError
      };
    }
  }

  public async getArrivalAirports(
    payload: DTOGetArrivalAirportsRequest
  ): Promise<DTOServiceResponse<DTOAirportInfo[]>> {
    try {
      const rawPayload = CamelSnakeMapper.toSnakeCase<
        DTOGetArrivalAirportsRequest,
        DTOGetArrivalAirportsRequestRaw
      >(payload);

      const response = await httpClient().get('/v2/cargo/arrival-available', {
        params: rawPayload
      });
      const data = response.data.data;

      return {
        statusCode: 200,
        data: data.map((item: DTOArrivalAirportRaw) => {
          const commodity: DTOCommodity[] =
            item.commodity?.map(c => ({
              code: c.product_code,
              name: c.product_type,
              shortName: c.product_type
            })) || [];
          const returnedValue = CamelSnakeMapper.toCamelCase<
            DTOArrivalAirportRaw,
            DTOAirportInfo
          >(item);

          returnedValue.commodity = commodity;
          return returnedValue;
        })
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError
      };
    }
  }

  public async getAvailableFlights(
    payload: DTOGetAvailableFlightRequest
  ): Promise<DTOServiceResponse<DTOGetAvailableFlightResponse>> {
    try {
      const rawPayload = CamelSnakeMapper.toSnakeCase<
        DTOGetAvailableFlightRequest,
        DTOGetAvailableFlightRequestRaw
      >(payload);

      const response = await httpClient().post(
        '/v2/cargo/available-flights',
        rawPayload
      );

      if (response.data?.status !== 200) {
        const parsedError = this._errorParser({ ...response.data });
        return {
          statusCode: response.data?.status,
          error: parsedError
        };
      }
      const data = response.data.data as DTOGetAvailableFlightResponseRaw;
      const returnedValue = {} as DTOGetAvailableFlightResponse;

      Object.keys(data).forEach(dateGroup => {
        const dataGroupFormat = moment(
          dateGroup,
          DATE_FORMATS.YYYYMMDD
        )?.format(DATE_FORMATS.DDMMYYYY);
        returnedValue[
          dataGroupFormat as unknown as keyof DTOGetAvailableFlightResponse
        ] = data[dateGroup].map(
          CamelSnakeMapper.toCamelCase<
            DTOAvailableFlightRaw,
            DTOAvailableFlight
          >
        );
      });

      return {
        statusCode: 200,
        data: returnedValue
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError
      };
    }
  }

  public async checkFlightAvailableLoad(
    payload: DTOCheckFlightAvailableLoadRequest
  ): Promise<DTOServiceResponse<DTOCheckFlightAvailableLoadResponse>> {
    try {
      const rawPayload = CamelSnakeMapper.toSnakeCase<
        DTOCheckFlightAvailableLoadRequest,
        DTOCheckFlightAvailableLoadRequestRaw
      >(payload);
      const response = await httpClient().post(
        '/v2/cargo/book-flight',
        rawPayload
      );

      const data = response.data.data as DTOCheckFlightAvailableLoadResponseRaw;
      const returnedValue = {} as DTOCheckFlightAvailableLoadResponse;

      returnedValue.isAvailable = !(
        data.message &&
        ((Array.isArray(data.data) && data.data.length === 0) || !data.data)
      );

      returnedValue.message = data.message;
      returnedValue.data = data.data;

      return {
        statusCode: 200,
        data: returnedValue
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError
      };
    }
  }

  public async getAirportProvinceMapping(
    provinceCode: string
  ): Promise<DTOServiceResponse<DTOAirportProvinceMappingRaw[]>> {
    try {
      const response = await httpClient().get(
        '/v2/cargo/airport-with-province',
        {
          params: {
            province_code: provinceCode
          }
        }
      );

      const data = response.data.data as DTOAirportProvinceMappingRaw[];

      return {
        statusCode: 200,
        data: data.map(
          CamelSnakeMapper.toCamelCase<
            DTOAirportProvinceMappingRaw,
            DTOAirportProvinceMapping
          >
        )
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError
      };
    }
  }

  public async getSummaryDimensionWeight(
    summaryDimensionWeight: DTOSummaryDimensionWeightRequest
  ): Promise<DTOServiceResponse<DTOSummaryDimensionWeightMapping>> {
    try {
      const response = await httpClient().post(
        '/v1/orders/summary-dimension-weight',
        summaryDimensionWeight
      );

      if (response.data?.status !== 200) {
        const parsedError = this._errorParser({ ...response.data });
        return {
          statusCode: response.data?.status,
          error: parsedError
        };
      }

      const data = response.data.data as DTOSummaryDimensionWeightRaw;

      return {
        statusCode: 200,
        data: CamelSnakeMapper.toCamelCase(data)
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError
      };
    }
  }
}

export default new FlightsServiceV1();
