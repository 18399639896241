import { CheckBox } from 'Components/CheckBox';
import { C2cOverPaymentTextEnum } from 'Pages/C2cOrderPayment/enums';
import { useTranslation } from 'react-i18next';
import { ExtraServiceModel } from 'Stores/c2c-order-payment';
import { displayCurrency } from 'Utilities/util';

interface PropsModel {
	extraService: ExtraServiceModel;
	isSelected: boolean;
	currency?: string;
	className?: string;
	toggleExtraService: (checked: boolean) => void;
}

const ExtraServiceItem = (props: PropsModel) => {
	const { t } = useTranslation();
	const {
		extraService,
		isSelected,
		currency,
		toggleExtraService,
		className,
	} = props;

	const classNameContainer = isSelected
		? `extra-service-item selected ${className}`
		: `extra-service-item ${className}`;

	return (
		<div className={classNameContainer}>
			<div className="extra-service-item-left">
				<CheckBox
					className={'mr-2'}
					checked={isSelected}
					onChange={(e) => toggleExtraService(e?.target.checked)}
				/>
				<label>{t(extraService?.name || '')}</label>
			</div>
			<div className="extra-service-item-right">
				<span className="extra-service-fee-service-title">
					{t(C2cOverPaymentTextEnum.PSAW_ExtraService_FeeService)}:
				</span>
				<span className="extra-service-fee-service-value">
					{` +${displayCurrency(
						(extraService?.price || 0)?.toString(),
						currency || 'đ',
					)}`}
				</span>
			</div>
		</div>
	);
};

export default ExtraServiceItem;
