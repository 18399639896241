import { useTranslation } from 'react-i18next';
import classnames from 'Utilities/classnames';
import { displayCurrency } from 'Utilities/util';
import './styles.scss';

interface PropsModel {
	className?: string;
	value?: number|string;
	currency?: string;
	currencyDefault?: string;
	label?: string;
	isPrimary?: boolean
}

const FeeRecord = (props: PropsModel) => {
	const { className, currency, value, currencyDefault, label = '' , isPrimary} = props;
	const containerClasses = classnames(
		'd-flex justify-content-between align-items-center pl-2',
		className,
	);
	const { t } = useTranslation();
	return (
		<div
			className={containerClasses}
		>
			<div className={isPrimary?'fs-h-sm lh-h-sm fw-bold':'fs-p-md lh-p-md'}>{t(label)}:</div>
			<div className={isPrimary?'text-primary fs-h-sm lh-h-sm fw-bold':'fs-p-md lh-p-md fw-bold'}>
				{displayCurrency(
					(value || 0)?.toString(),
					currency || currencyDefault,
				)}
			</div>
		</div>
	);
};

export default FeeRecord;
