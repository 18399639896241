import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { profileSliceSelectors } from 'Stores/profile';
import './index.scss';

const UserAvatar = () => {
  const profile = useSelector(profileSliceSelectors.get).data;
  const { t } = useTranslation();
  const firstCharName = profile?.avatar
    ? ''
    : profile?.name?.[0]?.toUpperCase();
  const settingInfo = t('user_setting_information');

  return (
    <div className="user-avatar d-flex">
      <div
        style={{ backgroundImage: `url("${profile?.avatar}")` }}
        className="user-avatar-img"
      >
        {firstCharName}
      </div>
      <div className="ml-2">
        <div className='my-profile-name'>{profile.name}</div>
        <NavLink to="my-profile">{settingInfo}</NavLink>
      </div>
    </div>
  );
};

export default UserAvatar;
