import ButtonClick from 'Components/ButtonClick';
import { PageCallCenterService } from 'Components/index';
import { useNavigate } from 'react-router-dom';
import { OrderEmptyItemDto } from '../Models/order-empty-item.dto';
import './index.scss';

export const OrderEmptyItem = (props: { data: OrderEmptyItemDto }) => {
  const { data } = props;
  const navigate = useNavigate();
  const onClickButton = () => {
    navigate('/order-bill');
  };

  return (
    <div className="order-empty-item d-flex flex-column align-items-center bg-white">
      <img className="order-empty-icon" src={data?.iconUrl} alt={''} />
      <p className="order-empty-message">{data?.message}</p>
      {data?.labelButton && (
        <ButtonClick
          variant="primary"
          htmlType="submit"
          containerClassName="d-flex align-items-center justify-content-center"
          className="order-empty-button"
          onClick={onClickButton}
        >
          {data?.labelButton}
        </ButtonClick>
      )}
      {data?.callCenter && (
        <PageCallCenterService className="order-empty-item-call-center"></PageCallCenterService>
      )}
    </div>
  );
};
