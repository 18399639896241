import { LogoutOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import Address from 'Assets/icon/address.svg';
import History from 'Assets/icon/history.svg';
import logo from 'Assets/icon/Logo.svg';
import Rocket from 'Assets/icon/rocket.svg';
import UserProfile from 'Assets/icon/user-profile.svg';
import Wallet from 'Assets/icon/wallet.svg';
import images from 'Assets/images';
import Dropdown from 'Components/Dropdown';
import LanguagePicker from 'Components/LanguagePicker';
import MenuItemList from 'Components/MenuItemList';
import { ItemBaseModel } from 'Components/Models';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { MainPageEnum } from 'Routes';
import { layoutUtilitiesSliceSelectors } from 'Stores/layout-utilities';
import { profileSliceActions, profileSliceSelectors } from 'Stores/profile';
import { PROFILE_TYPES } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { isVenusLogin, logUserOut } from 'Utilities/util';
import { TABS_HEADER } from '../Constants';
import './index.scss';
interface PropsModel {
  tabs: Array<ItemBaseModel>;
  onChangeTab?: (key: string) => void;
  defaultActiveKey?: string;
  isShowTab?: boolean;
}

const TopNav = (props: PropsModel) => {
  const { tabs, defaultActiveKey, onChangeTab = () => {}, isShowTab } = props;
  const dispatch = useDispatch();
  const profile = useSelector(profileSliceSelectors.get);
  const [scroll, setScroll] = useState(false);
  const loadingRef = useRef<LoadingBarRef>(null);
  const { processingNavbar, currentPath } = useSelector(
    layoutUtilitiesSliceSelectors.get
  );

  const { t } = useTranslation();
  const data = [
    {
      text: t('profile_section_title'),
      icon: <img src={UserProfile} />,
      link: '/my-profile'
    },
    {
      text: t('menu_item_place_order'),
      icon: <img src={Rocket} />,
      link: '/'
    },
    {
      text: t('menu_item_order_history'),
      icon: <img src={History} />,
      link: '/my-orders'
    },
    {
      text: t('menu_item_address_book'),
      icon: <img src={Address} />,
      link: '/address'
    },
    {
      text: t('topup_prepayment'),
      icon: <img src={Wallet} />,
      link: '/top-up'
    },
    {
      text: t('menu_item_logout'),
      icon: <LogoutOutlined />,
      action: () => {
        logUserOut();
      }
    }
  ];

  useEffect(() => {
    if (
      (currentPath === MainPageEnum.CargoDirect ||
        currentPath === MainPageEnum.Swift247 ||
        Object.keys(profile.data || {})?.length == 0) &&
      profile.fetchingStatus !== 'pending'
    ) {
      dispatch(profileSliceActions.getting({}));
    }
  }, [currentPath]);

  useEffect(() => {
    if (processingNavbar) {
      loadingRef?.current?.continuousStart(20, 2);
    } else {
      loadingRef?.current?.complete();
    }
  }, [processingNavbar]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { TabPane } = Tabs;

  const renderTab = () => {
    if (!tabs?.length || !isShowTab) {
      return <></>;
    }

    const onTabClick = (tab: string) => {
      const isPageTab = TABS_HEADER.some(tab => tab.field === currentPath);
      if (!isPageTab) {
        onChangeTab(tab);
      }
    };

    const listTabs = tabs.map(tab => {
      return !tab.isHide && (
        <TabPane
          tab={
            <>
              <img src={tab.img} alt="tab" />
              <span>{t(tab?.name || '')}</span>
            </>
          }
          key={tab?.field}
          className="tab-pane-header"
          disabled={tab?.disabled}
        ></TabPane>
      );
    });
    return (
      <Tabs
        activeKey={defaultActiveKey}
        type="card"
        onChange={onChangeTab}
        onTabClick={onTabClick}
      >
        {listTabs}
      </Tabs>
    );
  };

  const handleScroll = () => {
    if (window.pageYOffset > 75) {
      setScroll(true);
      return;
    }
    setScroll(false);
  };

  return (
    <>
      <div
        className={`${classPrefix}-header-container ${
          tabs?.length ? 'header-with-tab' : ''
        }`}
      >
        <Header className="header">
          <div className="header-top-wrap">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="" height="44" />
              </Link>
            </div>
            <div className={'d-flex align-items-center'}>
              <LanguagePicker />
              {profile && (
                <Dropdown
                  className={'cursor-pointer'}
                  arrow
                  placement="bottomRight"
                  overlay={
                    <MenuItemList
                      className={`${classPrefix}-header-menu-list`}
                      items={data}
                    />
                  }
                >
                  <div className={'d-flex align-items-center'}>
                    <img src={images.profilerIconUrl} alt={''} />
                    <span className={'text-white fw-600 ml-2 fs-p-md'}>
                      {profile?.data.type === PROFILE_TYPES.personal
                        ? profile?.data.name
                        : profile?.data.companyName}
                    </span>
                  </div>
                </Dropdown>
              )}
            </div>
          </div>

          <div className="tabs-header-container">{renderTab()}</div>

          {/* <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={items1} /> */}
        </Header>

        <LoadingBar
          color="#b3446d"
          containerClassName={!scroll ? 'processing-header' : ''}
          ref={loadingRef}
        />
      </div>
    </>
  );
};

export default TopNav;
