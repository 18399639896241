import ButtonClick from 'Components/ButtonClick';
import { useTranslation } from 'react-i18next';
import PlusIcon from 'Assets/icon/plus.svg';
import './styles.scss';

const ButtonAddProduct = (props: { onClick?: () => void }) => {
	const { t } = useTranslation();
	return <div className="c2c-pc-button-add-product">
		<ButtonClick
			variant="default"
			className="w-100 d-flex justify-content-center align-items-center"
			onClick={props?.onClick}
		>
			<img src={PlusIcon} width={14} className="mr-1" />
			<span>{t('c2c_delivery_method_classic_btn_add_product')}</span>
		</ButtonClick>
	</div>;
};

export default ButtonAddProduct;
