import httpClient from '../../http-client';
import { DTOServiceResponse } from '../../interfaces';
import RootServiceV1 from '../root';
import { CamelSnakeMapper } from '../../data-mappers';
import {
	DTOAddress,
	DTOAddressRaw,
	DTOAddressPayload,
	DTOSearchGoogleAddressRequest,
	DTOSearchGoogleAddress,
	DTOAutoCompleteGoogleAddressRequest,
	DTOAutoCompleteGoogleAddress,
	DTOGetGoogleAddressDetailRequest,
	DTOGoogleReverseGeocoding,
	DTOConvertGoogleAddressToInternalAddressRequest,
	DTOConvertGoogleAddressToInternalAddressResponse,
	DTOConvertGoogleAddressToInternalAddressResponseRaw,
} from 'Services/v1/address-book/dto';

type PlaceResult = google.maps.places.PlaceResult;
type AutocompletePrediction = google.maps.places.AutocompletePrediction;
type GeocoderResult = google.maps.GeocoderResult;

class AddressBookServiceV1 extends RootServiceV1 {
	constructor() {
		super();
	}
	async listAddress(
		payload: DTOAddressPayload,
	): Promise<DTOServiceResponse<DTOAddress[]>> {
		try {
			const response = await httpClient().post(
				`/v2/address?page=${payload?.page || 1}&limit=${payload?.limit || 20}`,
				{
					search_text: payload?.search_text,
					sort: [
						{
							field: 'default_sender',
							dir: 'desc',
						},
						{
							field: 'default_receiver',
							dir: 'desc',
						},
						{
							field: 'created_at',
							dir: 'desc',
						},
						// {
						// 	field: 'country',
						// 	dir: 'asc',
						// },
						// {
						// 	field: 'province',
						// 	dir: 'asc',
						// },
						// {
						// 	field: 'place_name',
						// 	dir: 'desc',
						// },
					],
				},
			);
			const data = response.data.data;
			const pagination = response.data?.meta?.pagination;

			return {
				statusCode: 200,
				data: data.map((item: DTOAddressRaw) =>
					CamelSnakeMapper.toCamelCase(item),
				),
				meta: {
					pagination: CamelSnakeMapper.toCamelCase(pagination),
				},
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async addAddress(
		payload: DTOAddress,
	): Promise<DTOServiceResponse<DTOAddress>> {
		try {
			const requestData = CamelSnakeMapper.toSnakeCase<
				DTOAddress,
				DTOAddressRaw
				>(payload);

			const response = await httpClient().post('/v1/address', requestData);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async removeAddress(
		id: string | number,
	): Promise<DTOServiceResponse<DTOAddress>> {
		try {
			const response = await httpClient().delete(`/v1/address/${id}`);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async updateAddress(
		id: string | number,
		payload: DTOAddress,
	): Promise<DTOServiceResponse<DTOAddress>> {
		try {
			const requestData = CamelSnakeMapper.toSnakeCase<
				DTOAddress,
				DTOAddressRaw
				>(payload);

			const response = await httpClient().post(`/v1/address/${id}`, requestData);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
	async getAddress(
		id: string | number,
	): Promise<DTOServiceResponse<DTOAddress>> {
		try {
			const response = await httpClient().get(`/v1/address/${id}`);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase<DTOAddressRaw, DTOAddress>(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
	async getAccessibleLocation() {
		try {
			const response = await httpClient().get(`/v1/address/configpicklatlon`);
			const data = response.data;
			return {
				statusCode: 200,
				data,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
	async getAutoCompleteGoogleAddress(payload: DTOAutoCompleteGoogleAddressRequest): Promise<DTOServiceResponse<DTOAutoCompleteGoogleAddress[]>> {
		const {query, sessionToken} = payload;
		try {
			const service = new google.maps.places.AutocompleteService();
			const request:google.maps.places.AutocompletionRequest  = {
				input: query,
				componentRestrictions: {
					country: ['VN']
				},
				sessionToken,
				language: 'vi'
			};

			const data = await (new Promise ((res, rej) => service.getPlacePredictions(request, function(results, status) {
				const successStatus = [
					google.maps.places.PlacesServiceStatus.OK,
					google.maps.places.PlacesServiceStatus.NOT_FOUND,
					google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
				]
				if (successStatus.includes(status)) {
					return res(results || [])
				}
				rej(results);
			}).catch(err => rej(err)))) as AutocompletePrediction[];
			return {
				statusCode: 200,
				data: data.map(CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<PlaceResult, DTOAutoCompleteGoogleAddress>)
			}

		} catch (err) {
			return {
				statusCode: 500,
				error: {
					msg: 'Error while fetching google api',
					code: 500,
					statusCode: '500',
					data: []
				}
			};
		}
	}
	async searchGoogleAddress(payload: DTOSearchGoogleAddressRequest): Promise<DTOServiceResponse<DTOSearchGoogleAddress[]>> {
		const {attrContainer, query} = payload;
		try {
			const service = new google.maps.places.PlacesService(attrContainer);
			const request = {
				query: query,
				fields: ['name', 'formatted_address', 'geometry', 'place_id'],
			};

			const data = await (new Promise ((res, rej) => service.findPlaceFromQuery(request, function(results, status) {
				const successStatus = [
					google.maps.places.PlacesServiceStatus.OK,
					google.maps.places.PlacesServiceStatus.NOT_FOUND,
					google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
				]
				if (successStatus.includes(status)) {
					return res(results || [])
				}
				rej(results);
			}))) as PlaceResult[];

			return {
				statusCode: 200,
				data: data.map(CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<PlaceResult, DTOSearchGoogleAddress>)
			}

		} catch (err) {
			return {
				statusCode: 500,
				error: {
					msg: 'Error while fetching google api',
					code: 500,
					statusCode: '500',
					data: []
				}
			};
		}
	}
	async getGoogleAddressDetail(payload: DTOGetGoogleAddressDetailRequest): Promise<DTOServiceResponse<DTOSearchGoogleAddress>> {
		const {attrContainer, placeId, sessionToken} = payload;
		try {
			const service = new google.maps.places.PlacesService(attrContainer);
			const request = {
				placeId,
				fields: ['name', 'formatted_address', 'geometry', 'place_id', 'types', 'address_components'],
				sessionToken,
				language: 'vi'
			};

			const data = await (new Promise ((res, rej) => service.getDetails(request, function(results, status) {
				const successStatus = [
					google.maps.places.PlacesServiceStatus.OK,
					google.maps.places.PlacesServiceStatus.NOT_FOUND,
					google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
				]
				if (successStatus.includes(status)) {
					return res(results || null)
				}
				rej(results);
			}))) as (PlaceResult | null);

			return {
				statusCode: 200,
				data: CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<PlaceResult | null, DTOSearchGoogleAddress>(data)
			}

		} catch (err) {
			return {
				statusCode: 500,
				error: {
					msg: 'Error while fetching google api',
					code: 500,
					statusCode: '500',
					data: []
				}
			};
		}
	}
	async getGoogleReverseGeocoding(payload: DTOGoogleReverseGeocoding) {
		const {location} = payload;
		try {
			const service = new google.maps.Geocoder();


			const data = await (new Promise ((res, rej) => service.geocode({location}, function(results, status) {
				const successStatus = [
					google.maps.GeocoderStatus.OK,
					google.maps.GeocoderStatus.ZERO_RESULTS,
				]
				if (successStatus.includes(status) && results) {
					const firstMatch = results[0];
					return res(firstMatch || null)
				}

				rej(results);
			}).catch(err => rej(err)))) as GeocoderResult | null;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<GeocoderResult | null, DTOSearchGoogleAddress>(data)
			}

		} catch (err) {
			return {
				statusCode: 500,
				error: {
					msg: 'Error while fetching google api',
					code: 500,
					statusCode: '500',
					data: []
				}
			};
		}
	}
	async convertGoogleAddressToInternalAddress(payload: DTOConvertGoogleAddressToInternalAddressRequest) {
		try {
			const requestData = CamelSnakeMapper.toSnakeCase<
				DTOConvertGoogleAddressToInternalAddressRequest,
				any
				>(payload);

			const response = await httpClient().get(`/v1/get_location_by_place_id`, {
				params: requestData
			});

			const data = response.data.data;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<DTOConvertGoogleAddressToInternalAddressResponseRaw, DTOConvertGoogleAddressToInternalAddressResponse>(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

}

export default new AddressBookServiceV1() as AddressBookServiceV1;
