import { Card } from 'antd';
import directMarker from 'Assets/icon/direct-marker.svg';
import marker from 'Assets/icon/marker.svg';
import OrderCancelled from 'Assets/icon/order-cancelled.svg';
import OrderFinish from 'Assets/icon/order-finish.svg';
import OrderReceiving from 'Assets/icon/order-receiving.svg';
import { useEffect, useState } from 'react';
import { classPrefix } from 'Utilities/global';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DTOOrder } from 'Services/v1/cargo-direct/dto';
import './index.scss';

const AddressInfo = (props: DTOOrder) => {
	const { t } = useTranslation();
	const [estimationTime, setEstimationTime] = useState('');
	const {
		senderAddress,
		receiverAddress,
		fromName,
		fromPhone,
		toName,
		toPhone,
		statusName,
		minEndTime,
		timeSlotTo,
		timeSlotFrom
	} = props;

	useEffect(()=>{
		const timeSlotFromTime = new Date(`${minEndTime?.slice(0,10)} ${timeSlotFrom}`);
		const timeSlotToTime = new Date(`${minEndTime?.slice(0,10)} ${timeSlotTo}`);
		const formattedETA = `${moment(timeSlotFromTime).format('HH:mm')} - ${moment(timeSlotToTime).format('HH:mm DD/MM/YYYY')}`;
		setEstimationTime(formattedETA);
	},[minEndTime, timeSlotFrom ,timeSlotTo])

	return (
		<>
			<Card
				className={`${classPrefix}-address-info-container mb-4`}
				bordered={false}
			>
				<div className="order-status p-5 mb-2">
					<div className="d-flex justify-content-between">
						<div>
							<div className="order-status-text fw-bold">
								{statusName && t(`order_statusName_${statusName}`)}
							</div>
							{statusName === 'SENDING' ? (
								<div className="order-status-time">
									{t('order_estimated_delivery')}{' '}
									<span className="fw-500">{estimationTime}</span>
								</div>
							) : (
								<div className="order-status-time">
									{statusName && t(`order_msg_status_${statusName}`)}
								</div>
							)}
						</div>
						{statusName === 'RECEIVING' && (
							<img src={OrderReceiving} width="80" />
						)}
						{statusName === 'FINISHED' && <img src={OrderFinish} width="80" />}
						{statusName === 'CANCELLED' && (
							<img src={OrderCancelled} width="80" />
						)}
					</div>
				</div>
				<div className="row">
					<div className="pickup-info col-md-6 col-sm-12 col-xs-12 mb-2">
						<div className="pickup-info-label mb-1">
							<img src={directMarker} width="12" className="mr-1" />
							<span>{t('order_sender_address')}</span>
						</div>
						<div className="mb-1 fw-500">
							{fromName} - {fromPhone}
						</div>
						<div className="pickup-info-address">{senderAddress}</div>
					</div>
					<div className="pickup-info col-md-6 col-sm-12 col-xs-12">
						<div className="pickup-info-label mb-1">
							<img src={marker} width="12" className="mr-1" />
							<span>{t('order_receiver_address')}</span>
						</div>
						<div className="mb-1 fw-500">
							{toName} - {toPhone}
						</div>
						<div className="pickup-info-address">{receiverAddress}</div>
					</div>
				</div>
			</Card>
		</>
	);
};

export default AddressInfo;
