import {
  CamelSnakeMapper,
  DataMapperDictionary,
  DictionaryMapper
} from 'Services/data-mappers';
import {
  C2cDeliveryMethodModel,
  C2cDeliveryQueryModel,
  C2cDropPointModel,
  C2cDropPointRawModel,
  C2cGoodsCategoriesModel,
  C2cOrderPayment, C2cOrderQueryModel,
  C2cOrderQueryRawModel,
  C2cProductModel,
  C2cProductTypesModel,
  C2cVatQueryMode,
  C2cVoucherCheckingAvailableQueryModel,
  C2cVoucherModel,
  C2cVouchersQueryModel,
  ExtraServiceQueryModel,
  ExtraServiceResponse,
  GoodProduceType,
  PaymentCustom
} from 'Stores/c2c-order-payment/models';
import { DTOVoucherStatus, VOUCHER_STATUS_MAPPING } from 'Utilities/constants';
import httpClient from '../../http-client';
import { DTOServiceCustomResponse, DTOServiceResponse } from '../../interfaces';
import RootServiceV1 from '../root';
import { DTOSuggestedDimension } from 'Services/v1/c2c-order-payment/dto';

class C2cOrderPaymentServiceV1 extends RootServiceV1 {
	constructor() {
		super();
	}

	async getExtraService(
		params: ExtraServiceQueryModel,
	): Promise<DTOServiceResponse<ExtraServiceResponse>> {
		const dict: DataMapperDictionary = {
			shipping_fee: 'shippingFee',
			location_from: 'locationFrom',
			location_to: 'locationTo'
		};

		const paramsMapper = DictionaryMapper.toAllDictValues<
			ExtraServiceQueryModel,
			any
		>(params, dict);
		try {
			const response = await httpClient().get(`/v1/order/extra_prices`, {
				params: paramsMapper,
			});
			const data = response.data;
			return {
				statusCode: 200,
				data: data,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async getCategoriesService(
		language: string,
	): Promise<DTOServiceResponse<ExtraServiceResponse>> {
		try {
			const response = await httpClient().get(
				`/v1/categories/lang/${language}`,
			);

			const data = response.data.data;
			const returnedData = data?.map((item: any) =>
				CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<
					any,
					C2cGoodsCategoriesModel
				>(item),
			);

			return {
				statusCode: 200,
				data: returnedData,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async getVouchersService(
		params: C2cVouchersQueryModel,
	): Promise<DTOServiceResponse<Array<C2cVoucherModel>>> {
		try {
			const response = await httpClient().get(
				`/v1/vouchers?productId=${params.productId}&origin_id=[${params?.originId}]&destination_id=[${params?.destinationId}]`,
			);

			const data = response.data;
			return {
				statusCode: 200,
				data: data?.map((voucher: any) =>
					CamelSnakeMapper.withRecursion(
						CamelSnakeMapper.toCamelCase,
					)<any, any>(voucher),
				),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
	async postC2cVatService(
		params: C2cVatQueryMode,
	): Promise<DTOServiceResponse<any>> {
		const rawPayload = CamelSnakeMapper.withRecursion(
			CamelSnakeMapper.toSnakeCase,
		)<C2cVatQueryMode, any>(params);
		try {
			const response = await httpClient().post(
				`/v1/order/create/vat`,
				rawPayload,
			);

			const data = response.data;
			return {
				statusCode: 200,
				data: data,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async checkVoucherAvailability(
		params: C2cVoucherCheckingAvailableQueryModel,
	): Promise<DTOServiceResponse<DTOVoucherStatus | undefined>> {
		try {
			const response = await httpClient().get(`/v1/voucher/check`, {
				params: params,
			});

			const rawStatus = response.data.data.res;

			return {
				statusCode: 200,
				data: VOUCHER_STATUS_MAPPING[
					rawStatus as unknown as keyof typeof VOUCHER_STATUS_MAPPING
				],
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async postC2cOrder(
		params: C2cOrderQueryModel,
	): Promise<DTOServiceCustomResponse<C2cOrderPayment, PaymentCustom>> {
		const rawPayload = CamelSnakeMapper.withRecursion(
			CamelSnakeMapper.toSnakeCase,
		)<C2cOrderQueryModel, C2cOrderQueryRawModel>(params);

		const dict: DataMapperDictionary = {
			toApollo: 'to_apollo',
		};
		const paramsMapper = DictionaryMapper.toAllDictValues<
			C2cDeliveryQueryModel,
			any
		>(rawPayload, dict);

		try {
			const response = await httpClient().post(`/v1/order`, paramsMapper);

			const data = response.data;
			if (data.status === false) throw data;
			return {
				statusCode: 200,
				data: CamelSnakeMapper.withRecursion(
					CamelSnakeMapper.toCamelCase,
				)<any, any>(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async getC2cDeliveriesMethod(
		params: C2cDeliveryQueryModel,
	): Promise<DTOServiceResponse<Array<C2cDeliveryMethodModel>>> {
		const dict: DataMapperDictionary = {
			origin_id: 'originId',
			service_type: 'serviceType',
			destination_id: 'destinationId',
			goods_info: 'goodsInfo',
			product_id: 'productId',
		};

		const paramsMapper = DictionaryMapper.toAllDictValues<
			C2cDeliveryQueryModel,
			any
		>(params, dict);

		// const queryString = (isEmpty(paramsMapper) ? "" : "?" + qs.stringify(paramsMapper));

		try {
			const response = await httpClient().post(
				'/v1/preorder_nationwide',
				paramsMapper,
			);

			const data = response.data;
			if (data.status === false || data.message) throw data;
			return {
				statusCode: 200,
				data: CamelSnakeMapper.withRecursion(
					CamelSnakeMapper.toCamelCase,
				)<any, any>(data),
			};
		} catch (err) {
			const parsedError = this._errorParser(err, 'global_error_general');

			return {
				statusCode: parsedError.statusCode,
				error: parsedError
			};
		}
	}

	async getC2cProductTypes(
		goodProduceType: GoodProduceType,
	): Promise<DTOServiceResponse<C2cProductTypesModel>> {
		try {
      let url = `/v1/producttypes/language/${goodProduceType.lang}`;
      if (goodProduceType.location) {
        url += `?location=${goodProduceType.location}`;
      }
			const response = await httpClient().get(url, {
				params: {
					location_from: goodProduceType.locationFrom,
					location_to: goodProduceType.locationTo,
				}
			})

			const data = response.data;
			const dict: DataMapperDictionary = {
				parcel: 'PARCEL',
				document: 'DOCUMENT',
			};

			const dataMapper = DictionaryMapper.toAllDictValues<
				C2cDeliveryQueryModel,
				any
			>(data, dict);

			return {
				statusCode: 200,
				data: dataMapper,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async getPostOffice(
		lat: string | number | undefined,
		long: string | number | undefined,
	): Promise<DTOServiceResponse<C2cDropPointModel[]>> {
		try {
			const response = await httpClient().get(
				`/v1/drop_point/${lat}/${long}`,
			);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: data.dropPoints?.map((x: C2cDropPointRawModel) =>
					CamelSnakeMapper.toCamelCase<
						C2cDropPointRawModel,
						C2cDropPointModel
					>(x),
				),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async getDimensionList(): Promise<DTOServiceResponse<DTOSuggestedDimension[]>> {
		try {
			const response = await httpClient().get(
				`/v1/config-dimensions-list`,
			);
			const data = response.data.data;

			return {
				statusCode: 200,
				data: data.map((x: any) =>
					CamelSnakeMapper.toCamelCase<
						any,
						DTOSuggestedDimension
						>(x),
				),
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
}

export default new C2cOrderPaymentServiceV1() as C2cOrderPaymentServiceV1;
