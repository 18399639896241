import { call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import GrapServices from 'Services/v1/grap/grapService';
import { getSettingGrapAction, getSettingGrapSuccess } from '.';

export function* handleGetSettingGrap(action: PayloadAction<any>): any {
	const { data } = yield call(
		GrapServices.getSettingGrapData.bind(GrapServices),
	);
	yield put(getSettingGrapSuccess(data));
}

export function* grapSaga() {
	yield takeLatest(getSettingGrapAction.toString(), handleGetSettingGrap);
}
