import { CheckBox } from 'Components/CheckBox';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface PropsModel {
	checked: boolean;
	href?: string;
	className?: string;
	toggleTermAndCondition: (checked: boolean) => void;
}

const TermAndConditionAgreed = (props: PropsModel) => {
	const { t } = useTranslation();
	const { checked, href, toggleTermAndCondition, className } = props;

	return (
		<div className={`d-flex ${className}`}>
			<CheckBox
				className={'mr-3'}
				checked={checked}
				onChange={(e) => toggleTermAndCondition(e.target.checked)}
			/>
			<span className={'fs-p-md-2 lh-p-md-2 text-secondary'}>
				{t('order_bill_term_and_service_1')}{' '}
				<a target="_blank" href={href} className={'text-primary'}>
					{t('order_bill_term_and_service_2')}
				</a>{' '}
				{t('order_bill_term_and_service_3')}
			</span>
		</div>
	);
};

export default TermAndConditionAgreed;
