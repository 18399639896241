import RootServiceV1 from 'Services/v1/root';
import {
	DTOCancelOrderRequest,
	DTOCancelOrderResponse,
	DTOCreateOrderRequest,
	DTOCreateOrderRequestRaw,
	DTOCreateOrderVATInfoRequest,
	DTOCreateOrderVATInfoRequestRaw,
	DTOGetPricingRequest,
	DTOGetPricingRequestRaw,
	DTOOrder, DTOOrderDetail, DTOOrderDetailRaw,
	DTOOrderId,
	DTOOrderRaw,
	DTOOrderType,
	DTOOrderVATInfo,
	DTOOrderVATInfoRaw,
	DTOPricing,
	DTOPricingRaw, DTOPricingRawV2,
} from 'Services/v1/cargo-direct/dto';
import { DTOServiceResponse } from 'Services/interfaces';
import { CamelSnakeMapper } from 'Services/data-mappers';

import httpClient from 'Services/http-client';
class CargoDirectServiceV1 extends RootServiceV1 {
	constructor() {
		super();
	}

	public async createOrder(payload: DTOCreateOrderRequest): Promise<DTOServiceResponse<DTOOrder>> {
		try {
			const rawPayload = CamelSnakeMapper.withRecursion(CamelSnakeMapper.toSnakeCase)<DTOCreateOrderRequest, DTOCreateOrderRequestRaw>(payload);

			const response = await httpClient().post('v2/cargo/create-order', rawPayload);

			if (response.data.status === false) { // status maybe undefined
				throw {
					code: response.status,
					message: response.data.message
				}
			}

			const metaData = response.data.meta;

			const returnedData = CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<DTOOrderRaw, DTOOrder>(response.data.data)

			returnedData.paymentURL = metaData?.custom?.payment_url;
			returnedData.paymentData = metaData?.custom?.payment_data;

			return {
				statusCode: 200,
				data: returnedData
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}

	}

	public async getPricing(payload: DTOGetPricingRequest): Promise<DTOServiceResponse<DTOPricing>> {
		try {
			const rawPayload = CamelSnakeMapper.toSnakeCase<DTOGetPricingRequest, DTOGetPricingRequestRaw>(payload);

			const response = await httpClient().post('/v2/cargo/recalculate-fee', rawPayload)
			const data = response.data.data as DTOPricingRawV2;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase<DTOPricingRawV2, DTOPricing>(data)
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}
	}

	public async createOrderVATInfo(payload: DTOCreateOrderVATInfoRequest) {
		try {
			const rawPayload = CamelSnakeMapper.toSnakeCase<DTOCreateOrderVATInfoRequest, DTOCreateOrderVATInfoRequestRaw>(payload);

			const response = await httpClient().post('/v1/order/create/vat', rawPayload)

			const data = response.data.data as DTOOrderVATInfoRaw;

			return {
				statusCode: 200,
				data: CamelSnakeMapper.toCamelCase<DTOOrderVATInfoRaw, DTOOrderVATInfo>(data)
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}
	}

	public async getMyOrders(payload: DTOOrderType): Promise<DTOServiceResponse<DTOOrder[]>> {
		try {
			let params:any = {}

			if (payload.type !== 'ALL') {
				params.type = payload.type;

				if (payload.type === 'FINISHED') {
					params.ver = 'FINISHED'
				}
			}
			if (payload.perPage ) {
				params.perPage = payload.perPage
			} else {
				// default per page as 25
				params.perPage = 25;
			}

			if (payload.page) {
				params.page = payload.page;
			} else {
				params.page = 1;
			}


			const response = await httpClient().get('/v1/orders', {
				params
			})

			const data = response.data.data as DTOOrderRaw[];
			const pagination = response.data.meta?.pagination;

			return {
				statusCode: 200,
				data: data.map(CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<DTOOrderRaw, DTOOrder>),
				meta: {
					pagination: CamelSnakeMapper.toCamelCase(pagination)
				}
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}
	}

	public async getOrderDetail(payload: DTOOrderId): Promise<DTOServiceResponse<DTOOrderDetail>> {
		try {
			const response = await httpClient().get(`/v1/order/detail/${payload.id}`)

			const data = response.data.data as DTOOrderDetailRaw;
			const returnedData = CamelSnakeMapper.withRecursion(CamelSnakeMapper.toCamelCase)<DTOOrderDetailRaw, DTOOrderDetail>(data)

			return {
				statusCode: 200,
				data: returnedData
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}
	}

	public async cancelOrder(payload: DTOCancelOrderRequest): Promise<DTOServiceResponse<DTOCancelOrderResponse>> {
		try {
			await httpClient().post(`/v1/order/${payload.id}/cancel`, {
				reason: payload.reason
			});

			return {
				statusCode: 200,
				data: {
					isSuccess: true
				}
			}
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			}
		}
	}

}

export default new CargoDirectServiceV1();