import images from 'Assets/images';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { DTOAvailableFlight } from 'Services/v1/flights/dto';
import { DATE_FORMATS, FLIGHT_LOAD_STATUS } from 'Utilities/constants';
import { formatToCurrency, newDateWithFormat } from 'Utilities/util';
import FlightTimeFormat from '../FlightTimeFormat/flightTimeFormat';
import './styles.scss';

interface SelectFlightItemProps {
  flight: DTOAvailableFlight;
  onClick: (item: DTOAvailableFlight) => any;
  isActive?: boolean;
}

const FlightItemDisplayOnMobile = (props: SelectFlightItemProps) => {
  const { flight, onClick, isActive } = props;
  const {t} = useTranslation();

  const startTime = newDateWithFormat(
    flight?.startTime,
    DATE_FORMATS.ISO_CUSTOM,
  );
  const endTime = newDateWithFormat(
    flight?.minEndTime,
    DATE_FORMATS.ISO_CUSTOM,
  );

  const calculateLoadStatus = (remain: number, capacity: number | string) => {
    if (remain > 100) return FLIGHT_LOAD_STATUS.LOAD_ENOUGH;
    if (remain >= 0 && remain < 100) {
      if (Number(capacity) !== -1) {
        return FLIGHT_LOAD_STATUS.LOAD_LOW;
      }
      return FLIGHT_LOAD_STATUS.LOAD_FULL;
    }
    if (remain < 0) return FLIGHT_LOAD_STATUS.LOAD_EXCEEDED;
  };

  return (
    <div
      className={
        isActive
          ? 'flight-item-display-on-mobile flight-item-active'
          : 'flight-item-display-on-mobile'
      }
      onClick={() => onClick(flight)}
    >
      <div className="flight-item-info">
        <div className="flight-item-number">
          <span className="flight-item-number-number">
            {flight?.flightNumber}
          </span>
          <div className="flight-item-extend">
            <span>Cất cánh</span>
            <FlightTimeFormat value={flight?.startTime}></FlightTimeFormat>
          </div>
        </div>
        <div className="flight-item-time">
          <div className="flight-item-time-time">
            {format(startTime, 'HH:mm')}-{format(endTime, 'HH:mm')}
          </div>
          <div className="flight-item-extend">
            <span>Hạ cánh</span>
            <FlightTimeFormat value={flight?.minEndTime}></FlightTimeFormat>
          </div>
        </div>
        <div className="flight-item-price">
          <div className="flight-item-price-price">
            {formatToCurrency(Number(flight?.price), true)} đ
          </div>
          <div>{t(calculateLoadStatus(flight?.remainwt, flight?.capacity) || '')}</div>
        </div>
      </div>
      {isActive && (
        <img
          className="flight-item-img-arrow"
          src={images.fromToArrowIconUrl}
          alt={'arrow flight'}
        />
      )}
    </div>
  );
};

export default FlightItemDisplayOnMobile;
