import React from 'react';
import { Card, Timeline } from 'antd';
import { classPrefix } from 'Utilities/global';
import check from 'Assets/icon/check.svg'
import './index.scss'
import { DTOOrder, DTOOrderTrackingVenusItem } from 'Services/v1/cargo-direct/dto';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const OrderTimeline = (props: DTOOrder) => {
	const {trackingInfoVenus} = props;
	const {t} = useTranslation();

	const TimelineItem = (timeline: DTOOrderTrackingVenusItem[]) => {
		return <>
			<Timeline mode="left" className={`${classPrefix}-timeline-container mb-4`}>
				{
					timeline && timeline.length && timeline.map(
						(item: DTOOrderTrackingVenusItem, key) =>{
							// BE already filtered and returned valid status.
							// if (validKey.includes(item.title)) {
							return (
								<Timeline.Item color={key > 0 ? 'gray': ''} label={moment(item.date).format('DD/MM HH:mm')} dot={key > 0 ? null : (<img src={check} width="14" />)} key={key}>
									<span className='timeline-title d-block mb-1'>{t(`order_delivery_status_${item.title}`)}</span>
									<span className='timeline-description'>{t(`order_delivery_status_${item.content}`) + ` ${item.hubName} ${item.flight}`}</span>
								</Timeline.Item>
							)
							// }
						}
					)
				}
			</Timeline>
		</>
	}
	if (!trackingInfoVenus || !trackingInfoVenus.length) {
		return <></>
	}
	return (
		<Card
			title={t('order_tracking_info')}
			className={`${classPrefix}-order-timeline-container mb-4`}
			bordered={false}
		>
			{TimelineItem(trackingInfoVenus)}
		</Card>
	);
};

export default OrderTimeline;
