import { Wrapper, Status } from '@googlemaps/react-wrapper';
import config from '../../../config/config';
import Map from './Map';
import Marker from './Marker';
import { useState } from 'react';

interface MapProps {
	center: google.maps.LatLngLiteral;
	zoom?: number;
	isShowMarker?: boolean;
	dragendChange: (lat: number, lng: number) => void;
	markerLocation?: google.maps.LatLngLiteral | null;
	height?: string;
	draggable?: boolean
}

const GMaps = (props: MapProps) => {
	const { center, zoom = 16, isShowMarker, dragendChange, markerLocation, height, draggable = true } = props;
	const [myImg, setMyImg] = useState(require('Assets/icon/marker.svg'));

	return (
		<div className={'gmap-container'} style={{ display: 'flex', height }}>
			<Wrapper apiKey={config.googleKey}>
				<Map
					center={center}
					zoom={zoom}
					style={{ flexGrow: '1', height: '100%' }}
				>
					{isShowMarker && (
						<Marker
							position={markerLocation}
							draggable={draggable}
							dragendChange={dragendChange}
						/>
					)}
				</Map>
			</Wrapper>
		</div>
	);
};

export default GMaps;
