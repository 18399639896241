import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { updateCurrentLanguage } from 'Stores/layout-utilities';
import { LANGUAGE_PERSIST_KEY } from 'Utilities/constants';
import { removeVietnameseTones } from 'Utilities/util';
import en from './locales/en.json';
import kr from './locales/kr.json';
import vi from './locales/vi.json';

const resources = {
	en: {
		translation: en,
	},
	vi: {
		translation: vi,
	},
	kr: {
		translation: kr
	}
} as any;

export const languagePersistLayer = window.localStorage;

export const changeLanguage = (lng:string) => {
	i18n.changeLanguage(lng).then(() => {
		languagePersistLayer.setItem(LANGUAGE_PERSIST_KEY, lng)
	})
}

export const tCategory = (value?: string):string => {
	if(!value) return '';

}
export const tAirport = (value?: string):string => {
	if(!value) return '';
	const airportName = value.replace('Sân bay ', '');
	const unTonedAirportName = removeVietnameseTones(airportName);
	return i18n.t('global_airport_name', {name: i18n.language === 'vi' ? airportName : unTonedAirportName});
}

export const customTranslate = {
	tAirport,

}

export default {
	init: () => {
		i18n.use(initReactI18next).init({
			resources,
			fallbackLng: 'en',
			lng: languagePersistLayer.getItem(LANGUAGE_PERSIST_KEY) || 'en',
			keySeparator: '.',
			interpolation: {
				escapeValue: false,
			},
		});
		const dispatch = useDispatch();
		dispatch(updateCurrentLanguage(i18n.language))
	},
};
