import higherOrderReducers from 'Stores/hors';
import { GeneralDataState, ListItemsState } from 'Stores/models';
import { defaultGeneralInitialState, defaultListInitialState } from 'Stores/hors/fetchedSlice';
import { DTOUserProfile } from 'Services/v1/user/dto';
import { RootState } from 'Stores/index';

const profileSlice = higherOrderReducers.createFetchedSlice<GeneralDataState<DTOUserProfile>, DTOUserProfile, Record<string, never>>('profile', defaultGeneralInitialState);

export const profileSliceActions = profileSlice.actions;
export const profileSliceSelectors = {
	get: (rootState: RootState) => rootState.profile,
}

export default profileSlice.reducer;