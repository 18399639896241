import phoneBlack from 'Assets/icon/phone-black.svg';
import { useTranslation } from 'react-i18next';
import { c2cOrderPaymentSelectors } from 'Stores/c2c-order-payment';
import { LANGUAGE_KEY, PHONE_SERVICE } from 'Utilities/constants';
import { useSelector } from 'react-redux';

interface PropsModel {
	className?: string;
	content?: string;
}

const PageCallCenterService = ({
	className,
	content = 'global_call_customer_support',
}: PropsModel) => {
	const {t, i18n} = useTranslation();
	const language = (i18n.language || 'en') as LANGUAGE_KEY

	return (
		<a className={`${className} text-dark`} href={`tel:+${PHONE_SERVICE[language]}`}>
			<img src={phoneBlack} alt="icon" width="14" className="me-2" />
			<span>{t(content)}</span>
		</a>
	);
};

export default PageCallCenterService;
