import { Button } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import React, { ReactNode } from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

interface Props {
  variant?:
    | 'primary'
    | 'text'
    | 'link'
    | 'ghost'
    | 'default'
    | 'dashed'
    | undefined;
  size?: SizeType;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  icon?: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  bold?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  containerClassName?: string;
}

const ButtonClick = (props: Props) => {
  const {
    variant = 'primary',
    size = 'small',
    disabled = false,
    children,
    className,
    icon,
    onClick = () => {},
    bold,
    htmlType,
    loading,
    containerClassName
  } = props;

  const btnClassNames = classnames(
    {
      [`${classPrefix}-btn`]: true,
      [`${classPrefix}-btn-${variant}`]: true,
      [`${classPrefix}-btn-${size}`]: true,
      [`${classPrefix}-btn-${variant}-disabled`]: disabled,
      [`${classPrefix}-btn-bold`]: bold
    },
    className
  );

  const containerClassNames = classnames(
    `w-100 ${classPrefix}-btn-container`,
    containerClassName
  );

  return (
    <div className={containerClassNames}>
      <Button
        type={variant}
        onClick={onClick}
        className={btnClassNames}
        size={size}
        icon={icon}
        htmlType={htmlType}
        loading={loading}
        disabled={disabled}
      >
        {children}
      </Button>
    </div>
  );
};

export default ButtonClick;
