import { call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import AddressServiceV1 from 'Services/v1/address-book';
import { DTOAddress, DTOAddressPayload } from 'Services/v1/address-book/dto';
import { updateReceiverAddress, updateSenderAddress } from 'Stores/order-bill';

import {
  createNewAddress,
  createNewAddressFailed,
  createNewAddressSuccess,
  deleteAddress,
  deleteAddressFailed,
  deleteAddressSuccess,
  getAccessibleLocation,
  getAccessibleLocationSuccess,
  getAddressBook,
  getAddressBookFailed,
  getAddressBookSuccess,
  updateAddress,
  updateAddressFailed,
  updateAddressSuccess
} from './index';

export function* handleGetAddressBook(
  action: PayloadAction<DTOAddressPayload>
): any {
  const { data, error, meta } = yield call(
    AddressServiceV1.listAddress.bind(AddressServiceV1),
    action.payload
  );
  if (error) {
    yield put(
      getAddressBookFailed({
        errCode: error.code,
        errMsg: error.msg
      })
    );
    return;
  }
  yield put(getAddressBookSuccess({ data, meta }));
}

export function* handleCreateNewAddressBook(
  action: PayloadAction<DTOAddress>
): any {
  const { data, error } = yield call(
    AddressServiceV1.addAddress.bind(AddressServiceV1),
    action.payload
  );
  if (error) {
    yield put(
      createNewAddressFailed({
        errCode: error.code,
        errMsg: error.msg
      })
    );
    return;
  }

  yield put(createNewAddressSuccess(data));
}

export function* handleUpdateAddressBook(
  action: PayloadAction<{ data: DTOAddress, metaData: {addressType: 'sender' | 'receiver', isDirect: boolean} }>
): any {
  const {data, metaData} = action.payload;

  const { res, error } = yield call(
    AddressServiceV1.updateAddress.bind(AddressServiceV1),
    data.id || '',
    data
  );

  if (error) {
    yield put(
      updateAddressFailed({
        errCode: error.code,
        errMsg: error.msg
      })
    );
    return;
  }

  if (metaData?.addressType === 'sender' && metaData.isDirect) {
    yield put(updateSenderAddress(data));
  } else if (metaData.addressType === 'receiver' && metaData.isDirect) {
    yield put(updateReceiverAddress(data));
  }

  yield put(updateAddressSuccess());

}

export function* handleDeleteAddressBook(action: PayloadAction<string>): any {
  const { data, error } = yield call(
    AddressServiceV1.removeAddress.bind(AddressServiceV1),
    action.payload || ''
  );
  if (error) {
    yield put(
      deleteAddressFailed({
        errCode: error.code,
        errMsg: error.msg
      })
    );
    return;
  }

  yield put(deleteAddressSuccess());
}

export function* handleGetAccessibleLocation(): any {
  const { data, error } = yield call(
    AddressServiceV1.getAccessibleLocation.bind(AddressServiceV1)
  );
  if (error) {
    return;
  }

  yield put(getAccessibleLocationSuccess(data));
}

export default function* addressBookSaga() {
  yield takeLatest(getAddressBook.toString(), handleGetAddressBook);
  yield takeLatest(createNewAddress.toString(), handleCreateNewAddressBook);
  yield takeLatest(updateAddress.toString(), handleUpdateAddressBook);
  yield takeLatest(deleteAddress.toString(), handleDeleteAddressBook);
  yield takeLatest(
    getAccessibleLocation.toString(),
    handleGetAccessibleLocation
  );
}
