import { Divider } from 'antd';
import parcelIcon from 'Assets/images/ic-parcel.png';
import { Trans, useTranslation } from 'react-i18next';
import './index.scss';

const CommodityPopup = (props: { code: string }) => {
  const { t } = useTranslation();
  return (
    <div className="commodity">
      <div className="commodity-header">
        <div className="commodity-icon">
          <img width={25} height={25} src={parcelIcon} alt="" />
        </div>

        <div>
          <div className="title-medium-15">{t(`commodity_${props.code}`)}</div>
        </div>
      </div>
      <Divider></Divider>
      <p>
        {
          <Trans
            i18nKey={`commodity_desc_${props.code}`}
            components={{ newLine: <br /> }}
          />
        }
      </p>
    </div>
  );
};

export default CommodityPopup;
