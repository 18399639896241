import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import koKR from 'antd/es/locale/ko_KR';
import viVN from 'antd/es/locale/vi_VN';
import CommonPopup from 'Components/CommonPopup/commonPopup';
import LayoutUtilities from 'Components/LayoutUtilities';
import moment from 'moment';
import 'moment/locale/ko';
import 'moment/locale/vi';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { resetC2cOrderPayment } from 'Stores/c2c-order-payment';
import {
  layoutUtilitiesSliceSelectors,
  updateRoutePathAction
} from 'Stores/layout-utilities';
import { LanguageTypeEnum } from 'Stores/layout-utilities/enums';
import { resetOrderBill } from 'Stores/order-bill';
import { classPrefix } from 'Utilities/global';
import { SIDEBAR_LIST, TABS_HEADER } from '../Constants';
import TopNav from '../Header';
import Sidebar from '../Sidebar';
import './index.scss';
import { profileSliceSelectors } from 'Stores/profile';
import { PROFILE_TYPES } from 'Utilities/constants';
import { MainPageEnum } from 'Routes';
import { CONFIRM_SELECT_PRODUCT_POPUP } from 'Pages/C2cOrderPayment/constants';
import { PopupConfigModelCustomHeaderProps, usePopup } from 'Hooks';
import images from 'Assets/images';
import { useTranslation } from 'react-i18next';

const PopupCustomHeader = (props: PopupConfigModelCustomHeaderProps) => {
	const {onClose} = props;
	const {t} = useTranslation();
	return <div className={'cd-personal-access-prevent-popup-header'}>
		<img src={images.S247PopupIcon}/>
		<div className={'cd-personal-access-prevent-popup-header-title'}>{t('title_cargo_direct_service')}</div>
		<img onClick={onClose} className={'cd-personal-access-prevent-popup-header-close-icon'} src={images.PopupWhiteCloseIcon}/>
	</div>
}

const Layout = (props: { isShowSidebar: boolean }) => {
	const { isShowSidebar } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const profile = useSelector(profileSliceSelectors.get);
	const dispatch = useDispatch();
	const currentPath = location?.pathname?.replace('/', '');
  const { currentLanguage } = useSelector(layoutUtilitiesSliceSelectors.get);
  const [locale, setLocal] = useState(enUS);
	const popup = usePopup()
	const {t} = useTranslation();

	const openPopup = () => {
		popup.onOpen(
			{
				...CONFIRM_SELECT_PRODUCT_POPUP,
				title: 'global_confirm_declared_info',
				hideBtnCancel: true,
				hideBtnOk: true,
				btnFullWidth: true,
				maxWidth: 450,
				CustomHeader: PopupCustomHeader,
				onSubmit: (isAccept: boolean) => {
					/*  if (isAccept) {
							onAcceptRules(isDocument);
					}*/
				}
			},
			<div className={'cd-personal-access-prevent-popup-body'}>
				<div className={'mb-2'}>{t('cd_access_prevent_popup_content_1')}</div>
				<div className={'mb-3'}>{t('cd_access_prevent_popup_content_2')}</div>
				<div className={'mb-2 text-primary fw-bold'}>{t('cd_access_prevent_popup_content_3')}</div>
				<div className={'cd-personal-access-prevent-popup-body-list-item mb-2'}><img height={10} alt={''} className={'mr-1'} src={images.CDAccessPreventPopupListIcon}/><span>{t('cd_access_prevent_popup_content_4')}</span></div>
				<div className={'cd-personal-access-prevent-popup-body-list-item'}><img height={10} alt={''} className={'mr-1'} src={images.CDAccessPreventPopupListIcon}/><span>{t('cd_access_prevent_popup_content_5')}</span></div>
			</div>
		);
	};

	const handleOnChangeTab = (path: string) => {
		if(profile.data.type === PROFILE_TYPES.personal && path === MainPageEnum.OrderBill) {
			return openPopup()
		}
		dispatch(resetOrderBill());
		dispatch(resetC2cOrderPayment(false));
		navigate(path);
	};

	useEffect(() => {
		dispatch(updateRoutePathAction(currentPath));
		// dispatch(updatePaymentMethod());
	}, [location.pathname]);

  useEffect(() => {
    let locale = enUS;
    let momentLocale = LanguageTypeEnum.English;
    if (currentLanguage === LanguageTypeEnum.VietNam) {
      locale = viVN;
      momentLocale = LanguageTypeEnum.VietNam;
    } else if (currentLanguage === LanguageTypeEnum.Korea) {
      locale = koKR;
      momentLocale = LanguageTypeEnum.Korea;
    }
    setLocal(locale);
    moment.locale(momentLocale);
  },
  [currentLanguage])

	return (
    <ConfigProvider locale={locale}>
		<LayoutUtilities>
			<TopNav
				tabs={TABS_HEADER}
				isShowTab={true}
				onChangeTab={handleOnChangeTab}
				defaultActiveKey={currentPath}
			/>
			<div className={`${classPrefix}-page-container d-flex`}>
				{isShowSidebar && <Sidebar items={SIDEBAR_LIST} />}
				<div
					className={`${classPrefix}-page-content ${
						isShowSidebar ? 'mt-8 mb-6' : ''
					}`}
				>
					<Outlet />
				</div>
			</div>
			<CommonPopup></CommonPopup>
		</LayoutUtilities>
    </ConfigProvider>
	);
};

export default Layout;
