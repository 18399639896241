import closeIcon from 'Assets/icon/close-icon.svg';
import { MessageAlertModel } from 'Components/Models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface MessageAlertProps extends MessageAlertModel {
	className?: string;
}

const MessageAlert = (props: MessageAlertProps) => {
	const { t } = useTranslation();
	const {
		className,
		message = '',
		iconSrc,
		type = 'warning',
		size = 'medium',
		iconClose,
		hasBackground,
		toggle,
		onClose,
	} = props;

	const classContainer = `${className ?? ''} ${type} ${size} ${
		hasBackground ? 'has-background' : ''
	}`;

	const [closeMessage, setCloseMessage] = useState(false);

	useEffect(() => {
		setCloseMessage(false);
	}, [toggle]);

	return closeMessage ? (
		<></>
	) : (
		<div className={`${classContainer} message-alert`}>
			<div className="message-alert-wrap">
				<div className="message-alert-content">
					<img
						className="message-alert-img"
						src={iconSrc}
						alt={''}
					/>
					{t(message)}
				</div>

				{iconClose && (
					<img
						className="message-alert-img"
						src={closeIcon}
						alt={''}
						onClick={() => setCloseMessage(true)}
					/>
				)}
			</div>
		</div>
	);
};

export default MessageAlert;
