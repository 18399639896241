import React from 'react';
import classnames from 'Utilities/classnames';
import images from 'Assets/images';

export interface InputMessageProps {
	message: string,
	className?: string,
	type: 'success' | 'info' | 'error'
}

const InputMessage = (props: InputMessageProps) => {
	const {
		message,
		className,
		type = 'success'
	} = props;

	const containerClasses = classnames('d-flex align-items-center fs-p-md lh-p-md', className)

	return <div className={containerClasses}>
		<img className={'mr-2'} src={images[`${type}MessageIconUrl`]} alt={''}/>
		<span className={`text-${type}`}>{message}</span>
	</div>
}

export default InputMessage;