import SelectAddress from 'Components/SelectAddress';
import C2cCheckValidationInputLogic from 'Pages/C2cOrderPayment/logics/c2c-check-validation-input.logic';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';
import AddressBookServiceV1 from 'Services/v1/address-book';
import {
  addressBookSelectors,
  getAccessibleLocation,
  getAddressBook
} from 'Stores/address-book';
import {
  c2cOrderPaymentSelectors,
  checkValidationInputAction
} from 'Stores/c2c-order-payment';
import {
  orderBillSelectors,
  updateReceiverAddress,
  updateSenderAddress
} from 'Stores/order-bill';
import { VIETNAM_COUNTRY_CODE } from 'Utilities/constants';
import { AddressPathEnum } from '../../../routes/enums';
import './index.scss';

const C2CSelectAddress = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addressBook = useSelector(addressBookSelectors.get).list;
  const accessibleLocation = useSelector(
    addressBookSelectors.get
  ).accessibleLocation;
  const receiverAddress = useSelector(orderBillSelectors.get).receiverAddress;
  const senderAddress = useSelector(orderBillSelectors.get).senderAddress;
  const deliveryFetchingState = useSelector(
    c2cOrderPaymentSelectors.get
  ).deliveryMethodFetchingState;
  const weightAndSize = useSelector(c2cOrderPaymentSelectors.get).weightAndSize;
  const c2cValidateFields = useSelector(
    c2cOrderPaymentSelectors.get
  ).c2cValidateFields;

  const c2cState = useSelector(c2cOrderPaymentSelectors.get);
  const orderBillState = useSelector(orderBillSelectors.get);
  const productDeclaration = useSelector(
    c2cOrderPaymentSelectors.getAllProductDeclaration
  );

  const { isGlobal, globalDirection } = c2cState;

  const checkInputC2c = C2cCheckValidationInputLogic(
    c2cState,
    orderBillState,
    productDeclaration
  );

  useEffect(() => {
    dispatch(
      checkValidationInputAction({
        ...c2cValidateFields,
        address: checkInputC2c.checkSelectAddress()
      })
    );
  }, [isGlobal, globalDirection, receiverAddress]);

  useEffect(() => {
    dispatch(
      getAddressBook({
        limit: 20,
        page: 0
      })
    );
    dispatch(getAccessibleLocation());
  }, []);

  useEffect(() => {
    let accessibleLocationIds: string[] = [];
    if (accessibleLocation) {
      accessibleLocationIds = Object.keys(accessibleLocation);
    }

    const defaultSenderAddress = addressBook.find(
      item => item.isDefault && item.type === 'SENDER'
    );

    if (
      accessibleLocationIds.length &&
      !senderAddress &&
      defaultSenderAddress &&
      defaultSenderAddress.province &&
      (accessibleLocationIds.includes(defaultSenderAddress.province) ||
        (defaultSenderAddress.country &&
          defaultSenderAddress.country?.toString() !== VIETNAM_COUNTRY_CODE))
    ) {
      dispatch(updateSenderAddress(defaultSenderAddress));
    }

    const defaultReceiverAddress = addressBook.find(
      item => item.isDefault && item.type === 'RECEIVER'
    );
    if (!receiverAddress && defaultReceiverAddress) {
      dispatch(updateReceiverAddress(defaultReceiverAddress));
    }
  }, [addressBook, senderAddress, receiverAddress, accessibleLocation]);

  useEffect(() => {
    if (senderAddress && senderAddress.id) {
      AddressBookServiceV1.getAddress(senderAddress.id).then(data => {
        dispatch(updateSenderAddress(data.data));
      });
    }
    if (receiverAddress && receiverAddress.id) {
      AddressBookServiceV1.getAddress(receiverAddress.id).then(data => {
        dispatch(updateReceiverAddress(data.data));
      });
    }
  }, []);

  const onSenderClick = () => {
    navigate(
      generatePath(AddressPathEnum.AddressListingSelect, {
        orderType: 'c2c',
        addressType: 'sender'
      })
    );
  };

  const onReceiverClick = () => {
    navigate(
      generatePath(AddressPathEnum.AddressListingSelect, {
        orderType: 'c2c',
        addressType: 'receiver'
      })
    );
  };

  return (
    <SelectAddress
      senderAddress={senderAddress}
      receiverAddress={receiverAddress}
      errorMessage={
        c2cValidateFields?.address?.message || deliveryFetchingState.errMsg
      }
      onSenderClick={onSenderClick}
      onReceiverClick={onReceiverClick}
      isShowMessage={
        c2cValidateFields?.address?.hasError ||
        (deliveryFetchingState.fetchingStatus !== 'pending' &&
          !!weightAndSize?.weight)
      }
    />
  );
};

export default C2CSelectAddress;
