import { Tabs } from 'antd';
import { ItemBaseModel } from 'Components/Models';
import { useTranslation } from 'react-i18next';
import { classPrefix } from 'Utilities/global';
import './index.scss';

interface PropsModel {
	title?: string;
	tabs?: Array<ItemBaseModel>;
	onChange?: (key: string) => void;
	defaultActiveKey?: string;
}

const PageTitle = (props: PropsModel) => {
	const { title, tabs, defaultActiveKey, onChange } = props;
	const { t } = useTranslation();
	const { TabPane } = Tabs;

	const renderTab = () => {
		if (!tabs?.length) {
			return <></>;
		}
		const listTabs = tabs.map((tab) => {
			return (
				<TabPane tab={t(tab?.name || '')} key={tab?.field} disabled={tab?.disabled}></TabPane>
			);
		});
		return (
			<Tabs defaultActiveKey={defaultActiveKey} onChange={onChange}>
				{listTabs}
			</Tabs>
		);
	};
	return (
		<div
			className={`${classPrefix}-page-title d-flex mb-4 ${
				!!tabs?.length ? 'page-title-with-tabs' : ''
			}`}
		>
			<span className="ps-6">{title}</span>
			{renderTab()}
		</div>
	);
};

export default PageTitle;
