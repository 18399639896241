import { Tooltip } from 'antd';
import iconMarkerUrl from 'Assets/icon/marker.svg';
import straightFlightUrl from 'Assets/icon/straigt-flight.svg';
import images from 'Assets/images';
import InputErrorMessage from 'Components/InputErrorMessage';
import MessageAlert from 'Components/MessageAlert/messageAlert';
import Section from 'Components/Section';
import { MESSAGE_ALERT_ERROR } from 'Pages/C2cOrderPayment/constants';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AddressPathEnum } from 'Root/routes/enums';
import { MainPageEnum } from 'Routes';
import { DTOAddress } from 'Services/v1/address-book/dto';
import { c2cOrderPaymentSelectors } from 'Stores/c2c-order-payment';
import { layoutUtilitiesSliceSelectors } from 'Stores/layout-utilities';
import {
  orderBillSelectors,
  setCargoValidationInputByField
} from 'Stores/order-bill';
import classnames from 'Utilities/classnames';
import './index.scss';
export interface AddressInfoProps {
  className?: string;
  data?: DTOAddress;
  isSenderAddress?: boolean;
  title: string;
  defaultText: string;
  onClick: () => void;
  errorMessage?: string;
  urlNavigationReturn?: string;
}

const AddressInfo = (props: AddressInfoProps) => {
  const {
    className,
    data,
    isSenderAddress,
    title,
    defaultText,
    onClick,
    errorMessage,
    urlNavigationReturn
  } = props;
  const navigate = useNavigate();

  const { cargoValidateFields, toggleOrder, senderAddress, receiverAddress } =
    useSelector(orderBillSelectors.get);
  const { isGlobal } = useSelector(c2cOrderPaymentSelectors.get);
  const { currentPath } = useSelector(layoutUtilitiesSliceSelectors.get);

  const { personName, phone } = data || {};

  const address = data?.address?.replace('--', '');

  const containerClasses = classnames(
    'select-address-info d-flex flex-column',
    className
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (senderAddress) {
      dispatch(
        setCargoValidationInputByField({
          key: 'senderAddress',
          value: { hasError: false }
        })
      );
    }

    if (receiverAddress) {
      dispatch(
        setCargoValidationInputByField({
          key: 'receiverAddress',
          value: { hasError: false }
        })
      );
    }
  }, [senderAddress, receiverAddress]);

  const renderMessageError = () => {
    if (isSenderAddress) {
      if (!cargoValidateFields?.senderAddress?.hasError) return <></>;
      return (
        <MessageAlert
          {...MESSAGE_ALERT_ERROR}
          {...cargoValidateFields.senderAddress}
          toggle={toggleOrder}
          className="mt-2"
        />
      );
    }

    if (cargoValidateFields?.receiverAddress?.hasError) {
      return (
        <MessageAlert
          {...MESSAGE_ALERT_ERROR}
          {...cargoValidateFields.receiverAddress}
          toggle={toggleOrder}
          className="mt-2"
        />
      );
    }
    return <></>;
  };

  const isCheckForceUpdate = (isForceUpdateAddress: boolean) => {
    return (
      !isGlobal && currentPath === MainPageEnum.Swift247 && isForceUpdateAddress
    );
  };

  const goToDetailAddress = () => {
    navigate({
      pathname: AddressPathEnum.AddressFormUpdate.replace(
        ':id',
        data?.id || ''
      ),
      search: createSearchParams({
        orderType: 'c2c',
        addressType: isSenderAddress ? 'sender' : 'receiver',
        isDirect: 'true'
      })?.toString()
    });
  };

  return (
    <div className={containerClasses}>
      <div>
        {data ? (
          <div className={'d-flex align-items-center mb-2'}>
            <div className={'text-secondary-700 fs-p-md lh-p-md fw-600'}>
              {personName}
            </div>
            <div
              className={
                'text-secondary-700 fs-p-md lh-p-md fw-normal ml-2 mr-2'
              }
            >
              -
            </div>
            <div className={'text-secondary-700 fs-p-md lh-p-md fw-normal'}>
              {phone}
            </div>
          </div>
        ) : (
          <div className={'fs-h-sm fs-h-sm text-primary fw-bold'}>
            {defaultText}
          </div>
        )}
        <Tooltip
          placement="topLeft"
          overlayClassName="tooltip-address"
          overlayStyle={{ maxWidth: '500px', width: '75%' }}
          overlayInnerStyle={{ borderRadius: '5px' }}
          title={address}
        >
          <div
            onClick={onClick}
            className="fs-h-sm lh-h-sm fw-bold mb-3 select-address-info--dot cursor-pointer"
          >
            {address}
          </div>
        </Tooltip>
        <div className="fs-p-md lh-p-md d-flex justify-content-between">
          {isCheckForceUpdate(
            isSenderAddress
              ? senderAddress?.isForceUpdateAddress || false
              : receiverAddress?.isForceUpdateAddress || false
          ) && (
            <em className="d-flex align-items-center">
              <img
                className="mr-2"
                src={images.warningAddressIcon}
                alt="warning address"
              />
              <span
                className="text-danger cursor-pointer"
                onClick={goToDetailAddress}
              >
                {t('global_confirm_address')}
              </span>
            </em>
          )}
          <span className="text-primary cursor-pointer" onClick={onClick}>
            {t('global_change')}
          </span>
        </div>
      </div>
      {renderMessageError()}
      {errorMessage && (
        <InputErrorMessage className={'mt-5'} message={errorMessage} />
      )}
    </div>
  );
};

export interface FlightFromToIconProps {
  className?: string;
}

const FlightFromToIcon = (props: FlightFromToIconProps) => {
  const { className } = props;

  const containerClasses = classnames('flight-from-to-icon', className);

  return (
    <div className={containerClasses}>
      <div className={'flight-from-to-icon-dot'} />
      <div className={'flight-from-to-icon-line'} />
      <img
        className={'flight-from-to-icon-plane'}
        src={straightFlightUrl}
        alt={''}
      />
      <div className={'flight-from-to-icon-dot'} />
    </div>
  );
};

interface SelectAddressProps {
  senderAddress?: DTOAddress;
  receiverAddress?: DTOAddress;
  toggleOrder?: boolean;
  onSenderClick?: any;
  onReceiverClick?: any;
  errorMessage?: string;
  isShowMessage?: boolean;
}

const SelectAddress = (props: SelectAddressProps) => {
  const { t } = useTranslation();
  const {
    receiverAddress,
    senderAddress,
    toggleOrder,
    onSenderClick,
    onReceiverClick,
    errorMessage,
    isShowMessage
  } = props;
  useEffect(() => {
  }, [receiverAddress, senderAddress])
  return (
    <Section
      className={'select-address'}
      title={t('order_bill_select_address')}
      icon={<img src={iconMarkerUrl} alt={''} />}
    >
      <div
        className={'d-flex justify-content-between select-address-container'}
      >
        <AddressInfo
          defaultText={t('select_address_title_departure')}
          title={t('order_bill_select_address_sender_address')}
          data={senderAddress}
          isSenderAddress={true}
          onClick={onSenderClick}
        />
        <FlightFromToIcon className={'align-self-center'} />
        <AddressInfo
          defaultText={t('select_address_title_arrival')}
          title={t('order_bill_select_address_receiver_address')}
          data={receiverAddress}
          onClick={onReceiverClick}
        />
      </div>
      {errorMessage && isShowMessage && (
        <MessageAlert
          {...MESSAGE_ALERT_ERROR}
          message={t(errorMessage)}
          iconClose={false}
          className={'mt-5'}
          size={'small'}
        />
      )}
    </Section>
  );
};

export default SelectAddress;
