import { Divider } from 'antd';
import { includes, map, size, split, trim } from 'lodash';
import { useTranslation } from 'react-i18next';
import { C2cProductModel } from 'Stores/c2c-order-payment';
import parcelIcon from 'Assets/images/ic-parcel.png';
import './styles.scss';

const C2cProductConfirm = (props: { product: C2cProductModel }) => {
	const { t } = useTranslation();
	const { product } = props;
	const { description } = props.product;
	const _split = split(description, ':');
	const REGULATION = !includes(description, ':')
		? description
		: size(_split) >= 2
		? _split[0]
		: size(_split) == 1
		? _split[0]
		: '';
	const DESCS = size(_split) >= 2 ? split(_split[1], ';') : [];
	return (
		<div className="c2c-product-confirm">
			<div className="c2c-product-confirm-header">
				<div className="c2c-product-confirm-icon">
					<img src={parcelIcon} alt="" />
				</div>

				<div>
					<div className="title-medium-15">{product?.product}</div>
					<div className="c2c-product-category">
						{product?.categoryName}
					</div>
				</div>
			</div>
			<Divider></Divider>
			<div className="title-medium-15">
				{t('global_confirm_product_warning')}:
			</div>
			<p>{REGULATION}:</p>
			<ol>
				{map(DESCS, (desc, index) => {
					return <li key={index}>{trim(desc)}.</li>;
				})}
			</ol>
		</div>
	);
};

export default C2cProductConfirm;
