import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  layoutUtilitiesSliceSelectors,
  openPopupAction,
  togglePopupAction
} from 'Stores/layout-utilities';
import { PopupCommonModel } from 'Stores/layout-utilities/models';
import { PopupConfigModel, UsePopupModel } from '../models';

export const usePopup = () => {
  const dispatch = useDispatch();
  const { popupCommon } = useSelector(layoutUtilitiesSliceSelectors.get);

  const onOpen = (popupConfig: PopupConfigModel, component: ReactNode) => {
    dispatch(
      openPopupAction({
        component,
        isOpenPopup: true,
        popupConfig
      } as PopupCommonModel)
    );
  };

  const onSubmit = (data: any) => {
    dispatch(togglePopupAction(false));
    if (popupCommon.popupConfig?.onSubmit) {
      popupCommon.popupConfig?.onSubmit(data);
    }
  };

  const onClose = () => {
    dispatch(togglePopupAction(false));
    if (popupCommon.popupConfig?.onClose) {
      popupCommon.popupConfig?.onClose(false);
    }
  };

  const onAccept = () => {
    dispatch(togglePopupAction(false));
    if (popupCommon.popupConfig?.onSubmit) {
      popupCommon.popupConfig?.onSubmit(true);
    }
  };

  return {
    onOpen,
    onClose,
    onSubmit,
    onAccept,
    isOpen: popupCommon?.isOpenPopup,
    component: popupCommon?.component
  } as UsePopupModel;
};
