import { Button } from '@swift-247/s247.library.ui-core';
import { CheckBox } from 'Components/CheckBox';
import { TermAndConditionAgreed } from 'Components/DeliveryMethod';
import MessageAlert from 'Components/MessageAlert/messageAlert';
import { MessageAlertModel } from 'Components/Models';
import Section from 'Components/Section';
import Spinner from 'Components/Spinner';
import { ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { LANGUAGE_KEY } from 'Utilities/constants';
import config from '../../../config/config';
import './styles.scss';

interface PropsModel {
	className?: string;
	isExportVATInvoice?: boolean;
	children: ReactNode;
	isDisableSubmit?: boolean;
	openPopupEInvoiceForm: () => void;
	setIsExportVATInvoice: (checked: boolean) => void;
	toggleTermAndCondition: (checked: boolean) => void;
	handleOnSubmit: () => void;
  isTermAndConditionAgreed?: boolean;
  validate?: MessageAlertModel;
  toggleOrder?: boolean;
}

const TaxAndFeeLayout = (props: PropsModel) => {
	const { t, i18n } = useTranslation();
	const {
		className,
		isExportVATInvoice,
		children,
		openPopupEInvoiceForm,
		setIsExportVATInvoice,
		handleOnSubmit,
		toggleTermAndCondition,
    isTermAndConditionAgreed,
    validate,
    toggleOrder
	} = props;

	const loadingRef = useRef<LoadingBarRef>(null);

  const renderMessageError = () => {
    if (!validate?.hasError) return <></>;
    return (
      <MessageAlert
        {...validate}
        hasBackground={true}
        toggle={toggleOrder}
        className="message-alert-condition-agree"
      />
    )
  }
	return (
		<Section className={className}>
			<LoadingBar color="#b3446d" ref={loadingRef} />
			<div className={'d-flex align-items-center'}>
				<CheckBox
					className={'mr-2'}
					checked={isExportVATInvoice}
					onChange={(e) => {
						if (e.target.checked) {
							openPopupEInvoiceForm();
						}
						setIsExportVATInvoice(e.target.checked);
					}}
				/>
				<span className={'fs-p-lg lh-p-lg'}>
					{t('global_vat_info')}
				</span>
			</div>

			{children}

			<div className={'d-flex flex-column mb-6'}>
				<TermAndConditionAgreed
					checked={!!isTermAndConditionAgreed}
					toggleTermAndCondition={(checked) =>
						toggleTermAndCondition(checked)
					}
					href={
						config?.termOfUseUrl[
							(i18n.language || 'en') as LANGUAGE_KEY
						]
					}
				></TermAndConditionAgreed>

				{renderMessageError()}
			</div>
			<Spinner spinning={false}>
				<Button
					className={'w-100'}
					onClick={handleOnSubmit}
				>
					{t('global_place_order')}
				</Button>
			</Spinner>
		</Section>
	);
};
export default TaxAndFeeLayout;
