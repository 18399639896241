import styled, { keyframes } from 'styled-components';

const heightPoint = 913;

const moveTopFirst = keyframes`
    from {
        top: 0px;
    }

    to {
        top: 16px;
    }
`;

const moveTop = keyframes`
    from {
        top: ${screen.availHeight - heightPoint}px;
    }

    to {
        top: 16px;
    }
`;

const moveBottom = keyframes`
    from {
        bottom: ${Math.abs(screen.availHeight - heightPoint)}px;
    }

    to {
        bottom: 4px
    }
`;

const OrderBillContainerLeftStyle = styled.div`
	.pm-stick-top {
		animation: ${moveTop} 0.5s ease-in-out;
		top: 16px;
	}

	.pm-stick-top.pc-stick-top-first {
		animation: ${moveTopFirst} 0.5s ease-in-out;
		top: 16px;
	}

	.pm-tick-bottom {   
		animation: ${moveBottom} 0.5s ease-in-out;
        bottom: 4px;
	}
`;

export default OrderBillContainerLeftStyle;