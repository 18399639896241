import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import { ReactNode } from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

interface RadioOptionProps {
  label: string;
  value: string | boolean;
}

export interface RadioButtonSelectProps {
  className?: string;
  disabled?: boolean;
  onChange?: (event: RadioChangeEvent) => void;
  children?: ReactNode;
  value?: string;
  options: RadioOptionProps[];
  onClick?: () => void;
  radioGroupClassName?: string;
}

const RadioButton = (props: RadioButtonSelectProps) => {
  const {
    className,
    disabled,
    onChange,
    children,
    value,
    options,
    onClick,
    radioGroupClassName
  } = props;

  const containerClasses = classnames(
    {
      [`${classPrefix}-radio-button-select`]: true,
      [`${classPrefix}-radio-button-select--disabled`]: disabled
    },
    className
  );

  return (
    <div onClick={onClick} className={containerClasses}>
      <Radio.Group
        className={radioGroupClassName}
        onChange={onChange}
        value={value}>
        <Row gutter={24}>
          {options?.map((option, index) => (
            <Col key={index}>
              <Radio value={option.value}>{option.label}</Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
      {children && <span className="ms-3">{children}</span>}
    </div>
  );
};

export default RadioButton;
