import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import {
  TopupInfo,
  TopupState,
  TopupTransaction,
  TopupTransactionData
} from './models';

const initialState: TopupState = {
	topupInfo: {},
	topupTransaction: {
		page: 1,
	},
};

export const topupSlice = createSlice({
	name: 'topup',
	initialState,
	reducers: {
		getTopupInfo: (state) => {},
		getTopupInfoSuccess: (state, action: PayloadAction<TopupInfo>) => {
			state.topupInfo = action.payload;
		},
		getTopupTransaction: (state, action: PayloadAction<TopupTransaction>) => {
			state.topupTransaction = action.payload;
		},
		getTopupTransactionSuccess: (
			state,
			action: PayloadAction<TopupTransactionData>,
		) => {
			state.topupTransaction.data = action.payload;
		},
    resetTopupTransition: () => initialState
	},
});

export const {
	getTopupInfo,
	getTopupInfoSuccess,
	getTopupTransaction,
	getTopupTransactionSuccess,
  resetTopupTransition
} = topupSlice.actions;

export const topupSelectors = {
	get: (rootState: RootState) => rootState.topup,
};

export default topupSlice.reducer;
