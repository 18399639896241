import React from 'react';
import classNames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

export interface ChipProps {
	isActive?: boolean
	label: string
	className?: string
}

const Chip = (props: ChipProps) => {
	const {isActive, label, className} = props;
	const containerClasses = classNames(
		`${classPrefix}-chip`,
		className,
		{
			[`${classPrefix}-chip--active`]: isActive
		}
	);
	return <div className={containerClasses}>{label}</div>
}

export default Chip;
