import { ItemBaseModel } from "Components/Models";
import { TypeOrder } from "Stores/c2c-order-payment/enums";

export const C2cOrderTabs : Array<ItemBaseModel> =[
    {
        name: 'c2c_order_type_standard',
        field: TypeOrder.BASIC,
        disabled: false,
    },
    {
        name: 'c2c_order_type_premium',
        field: TypeOrder.PREMIUM,
        disabled: false,
    },
    {
        name: 'c2c_order_type_super',
        field: TypeOrder.SUPER,
        disabled: false,
    },

]

export const C2cOrderTabsGlobal : Array<ItemBaseModel> =[
    {
        name: 'c2c_order_type_standard',
        field: TypeOrder.BASIC,
        disabled: false,
    },
    {
        name: 'c2c_order_type_premium',
        field: TypeOrder.PREMIUM,
        disabled: true,
    },
    {
        name: 'c2c_order_type_super',
        field: TypeOrder.SUPER,
        disabled: true,
    },

]
