import { InputNumber as AntdInputNumber } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import classnames from 'Utilities/classnames';
import { TIMER_SEARCH } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import './index.scss';

export interface InputNumberProps {
  value?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number;
  ref?: React.Ref<HTMLInputElement>;
  className?: string;
  formatter?: (value: number | undefined) => string;
  isOriginal?: boolean;
  onFocus?: (ev: any) => void;
  isSelectAll?: boolean;
  isHasDebounce?: boolean;
  placeholder?: string
}

const InputNumber = (props: InputNumberProps) => {
  const {
    className,
    step,
    value,
    max,
    min,
    defaultValue,
    onChange,
    formatter,
    isOriginal = false,
    onFocus,
    isSelectAll,
    isHasDebounce,
    ref,
    placeholder
  } = props;

  const handleFocus = (e: any) => {
    if (isSelectAll) {
      e.target.select();
    }
    if (onFocus) onFocus(e);
  };

  const handleOnChange = (value: number) => {
    if (onChange) {
      isHasDebounce ? changeTextDebounce(value) : onChange(value);
    }
  };

  const changeTextDebounce = useCallback(
    debounce((value: number) => {
      if (onChange) onChange(value);
    }, TIMER_SEARCH),
    []
  );

  const containerClasses = classnames(
    {
      [`${classPrefix}-input-number-container`]: true,
      [`${classPrefix}-input-number-container--original`]: !isOriginal
    },
    className
  );
  return (
    <div className={containerClasses}>
      <AntdInputNumber
        value={value}
        ref={ref}
        step={step}
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={value => handleOnChange(value)}
        formatter={formatter}
        onFocus={handleFocus}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputNumber;
