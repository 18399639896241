import EInvoiceFormPopup from 'Components/EInvoiceFormPopup/eInvoiceFormPopup';
import TaxAndFeeLayout from 'Components/taxAndFeeLayout/taxAndFeeLayout';
import { usePopup } from 'Hooks';
import {
  E_INVOICE_POPUP_CONFIG,
  MESSAGE_ALERT_ERROR
} from 'Pages/C2cOrderPayment/constants';
import { ValidationMessageEnum } from 'Pages/C2cOrderPayment/enums';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingBarRef } from 'react-top-loading-bar';
import { DTOOrderVATInfo } from 'Services/v1/cargo-direct/dto';
import {
  c2cOrderPaymentSelectors,
  removeNewOrder,
  setC2cValidationInputByField,
  updateIsTermAndConditionAgreedAction
} from 'Stores/c2c-order-payment';
import { ProductTypeEnum } from 'Stores/c2c-order-payment/enums/productTypeEnum';
import {
  orderBillSelectors,
  updateVATInfo,
  updateVATInfoStatus
} from 'Stores/order-bill';
import classnames from 'Utilities/classnames';
import { TypeCurrency, TypePayment } from 'Utilities/constants';
import { toUpperLetter } from 'Utilities/util';
import { FeeRecordList } from '..';
import './index.scss';

interface TaxAndFeeProps {
  className?: string;
  onSubmit?: () => void;
  disabled?: boolean;
}

const TaxAndFeeComponent = (props: TaxAndFeeProps) => {
  const { onSubmit = () => {} } = props;
  const popup = usePopup();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [isFirst, setIsFirst] = useState(true);
  const {
    priceDelivery,
    totalPrice,
    extraServiceSelected,
    deliveryMethodSelected,
    supperFee,
    productSelected,
    packageTypeSelected,
    newOrder,
    toggleOrder,
    chosenPaymentMethod,
    isGlobal,
    globalDirection,
    isTermAndConditionAgreed,
    c2cValidateFields
  } = useSelector(c2cOrderPaymentSelectors.get);
  const loadingRef = useRef<LoadingBarRef>(null);
  const isExportVATInvoice = useSelector(orderBillSelectors.get)?.vatInfo
    ?.isEnabled;
  const setIsExportVATInvoice = (val: boolean) =>
    dispatch(updateVATInfoStatus(val));

  const getTotalToPrice = () => {
    if (!deliveryMethodSelected?.rate) return;
    return totalPrice * deliveryMethodSelected.rate;
  };
  const isShowTotalVn = () => {
    return (
      isGlobal &&
      toUpperLetter(chosenPaymentMethod.codeParent) ===
        toUpperLetter(TypePayment.VNPAY)
    );
  };
  const currencyDefault =
    isGlobal && globalDirection === 'inbound'
      ? TypeCurrency.WON
      : TypeCurrency.VND;

  const { className } = props;

  const containerClasses = classnames('tax-and-fee', className);

  const handleOnSubmit = () => {
    // setIsFirst(false);
    onSubmit();
  };

  // useEffect(() => {
  // 	setIsFirst(true);
  // }, [packageTypeSelected]);

  useEffect(() => {
    if (!newOrder) return;
    if (newOrder.status === 'processing') {
      loadingRef?.current?.continuousStart(20, 2);
    } else if (
      newOrder.status === 'completed' ||
      newOrder.status === 'failed' ||
      newOrder.status === 'load_not_available'
    ) {
      loadingRef?.current?.complete();
    }
    if (newOrder.status === 'failed' || newOrder.status === 'invalid_input') {
      const messageErr =
        t(toUpperLetter(newOrder.errorMsg, false)) || t('global_error_general');
      toast.error(messageErr, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    dispatch(removeNewOrder());
  }, [newOrder]);

  const openPopupEInvoiceForm = () => {
    popup.onOpen(
      {
        ...E_INVOICE_POPUP_CONFIG,
        onSubmit: (data: DTOOrderVATInfo) => {
          dispatch(updateVATInfoStatus(!!data));
          if (data) {
            dispatch(updateVATInfo(data));
          }
        }
      },
      <EInvoiceFormPopup></EInvoiceFormPopup>
    );
  };

  return (
    <TaxAndFeeLayout
      validate={c2cValidateFields.termAndConditionAgreed}
      className={containerClasses}
      isExportVATInvoice={isExportVATInvoice}
      handleOnSubmit={handleOnSubmit}
      isTermAndConditionAgreed={isTermAndConditionAgreed}
      toggleOrder={toggleOrder}
      toggleTermAndCondition={(checked: boolean) => {
        dispatch(updateIsTermAndConditionAgreedAction(checked));
        dispatch(
          setC2cValidationInputByField({
            key: 'termAndConditionAgreed',
            value: {
              ...MESSAGE_ALERT_ERROR,
              message: ValidationMessageEnum.TermAndConditionAgreedValid,
              ...c2cValidateFields.termAndConditionAgreed,
              hasError: !checked
            }
          })
        );
      }}
      setIsExportVATInvoice={setIsExportVATInvoice}
      openPopupEInvoiceForm={openPopupEInvoiceForm}
    >
      <FeeRecordList
        currency={deliveryMethodSelected?.currency || currencyDefault}
        extraServiceSelected={extraServiceSelected}
        priceDelivery={priceDelivery}
        supperFee={supperFee}
        dutyFee={deliveryMethodSelected?.dutyFee}
        discount={deliveryMethodSelected?.discount}
        vunFee={
          productSelected?.type === ProductTypeEnum.VUN
            ? productSelected?.price
            : 0
        }
        totalPrice={totalPrice}
        priceVn={{
          toCurrency: deliveryMethodSelected?.toCurrency!,
          toPrice: getTotalToPrice(),
          isShow: isShowTotalVn()
        }}
      ></FeeRecordList>
    </TaxAndFeeLayout>
  );
};

export default TaxAndFeeComponent;
