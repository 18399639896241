import httpClient from 'Services/http-client';
import { DTOServiceResponse } from 'Services/interfaces';
import { TopupInfo, TopupTransaction } from 'Stores/topup/models';
import { PAGESIZE } from 'Utilities/constants';
import RootServiceV1 from '../root';
import { TopupTransactionResponse } from './dto';

class TopupServiceV1 extends RootServiceV1 {
	constructor() {
		super();
	}

	async getTopupInfo(): Promise<DTOServiceResponse<TopupInfo>> {
		try {
			const response = await httpClient().get('v1/topup/info');
			return {
				statusCode: 200,
				data: response.data?.data,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}

	async getTopupTransactions(params: TopupTransaction): Promise<
		DTOServiceResponse<TopupTransactionResponse>
	> {
		try {
			const response = await httpClient().get('v1/topup/transaction', {
        params: {
          fromDate: params.fromDate,
          toDate: params.toDate,
          page: params.page,
          limit: PAGESIZE
        }
      });

			return {
				statusCode: 200,
				data: response.data?.data,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
}

export default new TopupServiceV1();
