import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { CargoFieldValueModel, CargoValidationFieldModel } from 'Pages/OrderBill/models';
import { FlightFilterInfo } from 'Pages/OrderBill/SelectFlightFilter';
import { DTOAddress } from 'Services/v1/address-book/dto';
import { DTOOrderVATInfo, DTOPricing } from 'Services/v1/cargo-direct/dto';
import { DTOAvailableFlight } from 'Services/v1/flights/dto';
import { DTOPaymentCard } from 'Services/v1/payment/dto';
import { RootState } from 'Stores/index';
import {
  NewOrderState,
  OrderBillState
} from './models';

const defaultFlightFilter = {
	weight: 1,
	numberOfPackages: 1
}

const defaultCargoValidate = {
  senderAddress: {
		message: 'order_bill_input_validation_select_sender_address',
		hasError: false,
	},
	receiverAddress: {
		message: 'order_bill_input_validation_select_receiver_address',
		hasError: false,
	},
  flight: {
		message: 'order_bill_input_validation_select_flight',
		hasError: false,
	},
	paymentMethod: {
		message: 'order_bill_input_validation_select_payment_method',
		hasError: false,
	}
}

const initialState: OrderBillState = {
	vatInfo: {
		isEnabled: false,
	},
	flightFilter: defaultFlightFilter,
  cargoValidateFields: defaultCargoValidate,
  toggleOrder: false,
  isTermAndConditionAgreed: false
};

export const orderBillSlice = createSlice({
	name: 'orderBill',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		updateFlightFilter: (state, action: PayloadAction<FlightFilterInfo>) => {
			state.flightFilter = action.payload;
		},
		clearFlightFilter: (state) => {
			state.flightFilter = defaultFlightFilter;
		},
		updateFlightInfo: (state, action: PayloadAction<DTOAvailableFlight | undefined>) => {
			state.flightInfo = action.payload;
		},
		clearFlightInfo: (state) => {
			state.flightInfo = undefined;
		},
		updatePaymentMethod: (state, action: PayloadAction<DTOPaymentCard | undefined>) => {
			state.paymentMethod = action.payload;
		},
		updateSenderAddress: (state, action: PayloadAction<DTOAddress | undefined>) => {
			state.senderAddress = action.payload;
		},
		updateReceiverAddress: (state, action: PayloadAction<DTOAddress | undefined>) => {
			state.receiverAddress = action.payload;
		},
		updateFeeAndTax: (state, action: PayloadAction<DTOPricing | undefined>) => {
			state.feeAndTax = action.payload;
		},
		clearFeeAndTax: (state) => {
			state.feeAndTax = undefined;
		},
		updateNewOrder: (state, action: PayloadAction<NewOrderState>) => {
			if(!state.newOrder) {
				state.newOrder = action.payload;
				return;
			}
			if(action.payload.status) {
				state.newOrder.status = action.payload.status
			}

			if(action.payload.order) {
				state.newOrder.order = action.payload.order;
			}

			if(action.payload.errorMsg) {
				state.newOrder.errorMsg = action.payload.errorMsg;
			}
		},
		updateVATInfo: (state, action: PayloadAction<DTOOrderVATInfo|null>) => {
			if(isEmpty(action?.payload)){
				state.vatInfo = {isEnabled: false};
			}
			if(action.payload){
				state.vatInfo.data = action.payload;
			}
		},

		updateVATInfoStatus: (state, action: PayloadAction<boolean>) => {
			state.vatInfo.isEnabled = action.payload;
		},
    resetOrderBill: () => initialState,
    checkValidationInputAction: (
			state,
			action: PayloadAction<CargoValidationFieldModel>,
		) => {
			state.cargoValidateFields = action.payload;
		},
    setCargoValidationInputByField: (state,
			action: PayloadAction<CargoFieldValueModel>) => {
        if (state.cargoValidateFields && action.payload) {
          state.cargoValidateFields[action.payload.key] = action.payload.value;
        }
    },
    setToggleOrder: (
			state,
		) => {
			state.toggleOrder = !state.toggleOrder
		},
    updateIsTermAndConditionAgreedAction: (state,
			action: PayloadAction<boolean>) => {
        state.isTermAndConditionAgreed = action.payload;
    },
	},
});

export const createOrder = createAction('orderBill/createOrder');

export const {
	updateFlightFilter,
	updateFlightInfo,
	updatePaymentMethod,
	updateSenderAddress,
	updateReceiverAddress,
	updateNewOrder,
	updateVATInfo,
	updateVATInfoStatus,
	updateFeeAndTax,
	clearFeeAndTax,
	clearFlightFilter,
	clearFlightInfo,
  resetOrderBill,
  checkValidationInputAction,
  setCargoValidationInputByField,
  setToggleOrder,
  updateIsTermAndConditionAgreedAction
} = orderBillSlice.actions;


export const orderBillSelectors = {
	get: (rootState: RootState) => rootState.orderBill
}


export default orderBillSlice.reducer;
