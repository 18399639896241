import { CloseOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import ButtonClick from 'Components/ButtonClick';
import { usePopup } from 'Hooks';
import { CONFIRM_SELECT_PRODUCT_POPUP } from 'Pages/C2cOrderPayment/constants';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupConfigModel } from 'src/hooks/models/popupModels/popupConfigModel';
import { classPrefix } from 'Utilities/global';
import './index.scss';

interface PropsModel {
	popupConfig?: PopupConfigModel;
	className?: string;
	children?: ReactNode;
}

const CommonPopupLayout = <T,>(props: PropsModel) => {
	const {
		popupConfig = CONFIRM_SELECT_PRODUCT_POPUP,
		children,
		className = '',
	} = props;
	const {CustomHeader} = popupConfig;
	const { t } = useTranslation();
	const { onClose, onSubmit } = usePopup();
	return (
		<div
			onClick={(e) => e.stopPropagation()}
			className={`${className} common-popup-layout`}
			style={{
				width: popupConfig?.width,
				height: popupConfig?.height,
				minWidth: popupConfig?.minWidth,
				minHeight: popupConfig?.minHeight,
				maxHeight: popupConfig?.maxHeight,
				maxWidth: popupConfig?.maxWidth,
			}}
		>
			{CustomHeader ? <CustomHeader onClose={onClose}/> :<div className={`${classPrefix}-common-popup-header`}>
				<div className={`${classPrefix}-common-popup-header-info`}>
					{!!popupConfig?.icon && (
						<img src={popupConfig?.icon} alt={''} />
					)}
					<div
						className={`${classPrefix}-common-popup-header-info-title`}
					>
						{t(popupConfig?.title || '')}
					</div>
				</div>
				<CloseOutlined className={'cursor-pointer'} onClick={onClose} />
			</div>}
			<div className={`${classPrefix}-common-popup-body`}>
				<div className="confirm-popup-content">{children}</div>
				{popupConfig?.hasDivider && <Divider />}
				{!popupConfig?.hideFooter && (
					<div
						className={`common-popup-layout-footer ${
							popupConfig?.btnFullWidth && 'btn-full-width'
						} ${popupConfig?.btnReverse && 'btn-reverse'}`}
					>
						{!popupConfig?.hideBtnCancel && (
							<ButtonClick
								containerClassName="confirm-popup-btn"
								onClick={onClose}
								variant="ghost"
							>
								{t(
									popupConfig?.cancelBtnLabel ||
										'buttons_cancel',
								)}
							</ButtonClick>
						)}
						{!popupConfig?.hideBtnOk && (
							<ButtonClick
								containerClassName="confirm-popup-btn"
								onClick={onSubmit}
							>
								{t(popupConfig?.okBtnLabel || 'buttons_ok')}
							</ButtonClick>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default CommonPopupLayout;
