export enum SidebarTypeEnum {
    AccountInfo,
    Order,
    OrderHistory,
    AddressBook,
    Prepayment,
    CallCenter,
    Profile,
    ChangePassword,
    TopupInfo
  }
