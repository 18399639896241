import { Form } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import Dash from 'Assets/icon/dash.svg';
import DatePicker from 'Components/DatePicker';
import moment from 'moment';
import { useEffect } from 'react';
import classnames from 'Utilities/classnames';
import { DATE_FORMATS } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import './index.scss';
export interface RageDatePicker {
  isBackground?: boolean;
  className?: string;
  rangeDate?: string[];
  showTime?: boolean;
  rangeDayAllow?: number;
  isOnlyAllowFromCurrentDate?: boolean;
  isOnlyAllowRangeFromCurrentDate?: boolean;
  onChangeDate?: (dateFrom: string, dateTo: string) => void;
}

const RangeDatePicker = (props: RageDatePicker) => {
  const {
    isBackground,
    className,
    rangeDate,
    showTime = false,
    rangeDayAllow = 30,
    isOnlyAllowFromCurrentDate = false,
    isOnlyAllowRangeFromCurrentDate = false,
    onChangeDate
  } = props;
  const dateFormat = showTime
    ? DATE_FORMATS.DDMMYYYYHHmm
    : DATE_FORMATS.DDMMYYYY;
  const fromDate = rangeDate ? rangeDate![0] : null;
  const toDate = rangeDate ? rangeDate![1] : null;
  const [form] = Form.useForm();
  const containerClasses = classnames(
    `${classPrefix}-range-date-picker d-flex align-items-center`,
    className
  );
  const getFormatDate = (date: string | null) =>
    moment(date, DATE_FORMATS.DDMMYYYY);

  useEffect(() => {
    form.setFieldsValue({ fromDate, toDate });
  }, []);

  const handleChangeFromDate = (value: any, dateFormat: string) => {
    form.setFieldValue('fromDate', dateFormat);
    if (
      getFormatDate(toDate) < getFormatDate(dateFormat) ||
      getFormatDate(toDate) >
        getFormatDate(dateFormat).add(rangeDayAllow + 1, 'days')
    ) {
      form.setFieldValue('toDate', '');
    }
    if (onChangeDate) onChangeDate(dateFormat, form.getFieldValue('toDate'));
  };

  const handleChangeToDate = (value: any, dateFormat: string) => {
    form.setFieldValue('toDate', dateFormat);
    if (
      getFormatDate(fromDate) > getFormatDate(dateFormat) ||
      getFormatDate(fromDate) <
        getFormatDate(dateFormat).subtract(rangeDayAllow + 1, 'days')
    ) {
      form.setFieldValue('fromDate', '');
    }
    if (onChangeDate) onChangeDate(form.getFieldValue('fromDate'), dateFormat);
  };

  const disabledFromDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return (
      current &&
      ((!isOnlyAllowRangeFromCurrentDate &&
        current <=
          moment(toDate, DATE_FORMATS.DDMMYYYY).subtract(
            rangeDayAllow + 1,
            'days'
          )) ||
        (!isOnlyAllowRangeFromCurrentDate &&
          current > moment(toDate, DATE_FORMATS.DDMMYYYY)) ||
        (isOnlyAllowFromCurrentDate && current < moment().startOf('day')) ||
        (isOnlyAllowRangeFromCurrentDate &&
          current >=
            moment()
              .add(rangeDayAllow, 'days')
              .startOf('day')))
    );
  };

  const disabledToDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return (
      current &&
      ((!isOnlyAllowRangeFromCurrentDate &&
        current < moment(fromDate, DATE_FORMATS.DDMMYYYY)) ||
        (!isOnlyAllowRangeFromCurrentDate &&
          current >=
            moment(fromDate, DATE_FORMATS.DDMMYYYY).add(
              rangeDayAllow,
              'days'
            )) ||
        (isOnlyAllowFromCurrentDate && current < moment().startOf('day')) ||
        (isOnlyAllowRangeFromCurrentDate &&
          current >= moment().add(rangeDayAllow, 'days').startOf('day')))
    );
  };

  return (
    <div className={containerClasses}>
      <DatePicker
        isBackground={isBackground}
        value={fromDate ? moment(fromDate, dateFormat) : null}
        showTime={showTime}
        onChange={handleChangeFromDate}
        format={dateFormat}
        disabledDate={disabledFromDate}
      />
      <img src={Dash} width="24" className="p-2" />
      <DatePicker
        isBackground={isBackground}
        value={toDate ? moment(toDate, dateFormat) : null}
        showTime={showTime}
        onChange={handleChangeToDate}
        format={dateFormat}
        disabledDate={disabledToDate}
      />
    </div>
  );
};

export default RangeDatePicker;
