import { isEmpty, isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  C2cCheckDimWeightValidationModel,
  c2cOrderPaymentSelectors,
  C2cOverPaymentState,
  getC2cDeliveriesMethodAction,
  setCheckDimWeight,
  TypeOrder
} from 'Stores/c2c-order-payment';
import { C2cDeliveryQueryModel } from 'Stores/c2c-order-payment/models/c2cDeliveryModel';

import { C2cCategoryPackageTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { grapSelectors } from 'Stores/grap';
import { GrapSettingsEnums } from 'Stores/grap/enums';
import { orderBillSelectors } from 'Stores/order-bill';
import { OrderBillState } from 'Stores/order-bill/models';
import { Location } from 'Utilities/constants';

const useGetPrice = () => {
  const { typeOrder } = useSelector(c2cOrderPaymentSelectors.get);
  const grap = useSelector(grapSelectors.get);
  const c2cState = useSelector(
    c2cOrderPaymentSelectors.get
  ) as C2cOverPaymentState;
  const allProductDeclaration = useSelector(c2cOrderPaymentSelectors.productDeclarationByCategorySelector.selectAll);

  const orderBillState = useSelector(orderBillSelectors.get) as OrderBillState;

  const { isGlobal } = c2cState;

  const productDeclaration = useSelector(
    c2cOrderPaymentSelectors.getAllProductDeclaration
  );
  const dispatch = useDispatch();

  const {
    weightAndSize,
    packageTypeSelected,
    productSelected,
    voucherSelected,
    deliveryMethodSelected
  } = c2cState;

  const location = isGlobal ? Location.KR : Location.VI;

  const goodsDeclaration = useMemo(
    () =>
      productDeclaration?.map(product => {
        if (packageTypeSelected === C2cCategoryPackageTypeEnum.Document) {
          return [];
        }
        return {
          ...product,
          key: product?.categories?.code,
          productId: product?.categories?.id,
          name: product?.productName
        };
      }),
    [productDeclaration, packageTypeSelected]
  );

  const defaultGoodDeclaration = useMemo(() => [], []);

  const goodsInfo = isGlobal ? goodsDeclaration : defaultGoodDeclaration;

  const interval =
    grap?.settings?.find(
      setting => setting?.settingKey == GrapSettingsEnums.Interval
    )?.value || '';
  const nDay =
    grap?.settings?.find(
      setting => setting?.settingKey == GrapSettingsEnums.NDay
    )?.value || '';

  const { senderAddress, receiverAddress } = orderBillState;

  const serviceType = deliveryMethodSelected?.deliveryType || 'D2D';

  const payload = {
    category: null,
    dimension: `${weightAndSize.longs || 0}x${weightAndSize.width || 0}x${
      weightAndSize.height || 0
    }`,

    fromAddr: senderAddress?.address,
    fromLat: Number(senderAddress?.lat),
    fromLng: Number(senderAddress?.long),
    toAddr: receiverAddress?.address,
    toLat: Number(receiverAddress?.lat),
    toLng: Number(receiverAddress?.long),
    interval,
    nDay,
    packageType: packageTypeSelected,
    premium: typeOrder === TypeOrder.PREMIUM,
    super: typeOrder === TypeOrder.SUPER,
    productId: allProductDeclaration?.map(i => i.productInfo?.id) || [],
    serviceType,
    voucher: voucherSelected?.code,
    weight: weightAndSize?.weight || 0,
    location,
    originId: [
      String(senderAddress?.country),
      String(senderAddress?.province),
      String(senderAddress?.district),
      String(senderAddress?.village)
    ],
    destinationId: [
      String(receiverAddress?.country),
      String(receiverAddress?.province),
      String(receiverAddress?.district),
      String(receiverAddress?.village)
    ],
    goodsInfo,
    grossWeight: weightAndSize?.weight || 0,
  } as C2cDeliveryQueryModel;

  useEffect(() => {
    if (isEmpty(grap?.settings)) {
      return;
    }

    if (!weightAndSize.longs || !weightAndSize.width || !weightAndSize.height) {
      dispatch(setCheckDimWeight({} as C2cCheckDimWeightValidationModel));
    }

    if (
      isNil(c2cState?.weightAndSize?.weight) ||
      isEmpty(senderAddress) ||
      isEmpty(receiverAddress)
    ) {
      return;
    }

    dispatch(getC2cDeliveriesMethodAction(payload));
  }, [
    // dependencies need to be as specific as possible to prevent redundant runs
    weightAndSize.weight,
    weightAndSize.longs,
    weightAndSize.width,
    weightAndSize.height,

    senderAddress?.address,
    senderAddress?.lat,
    senderAddress?.long,
    senderAddress?.country,
    senderAddress?.province,
    senderAddress?.district,
    senderAddress?.village,

    receiverAddress?.address,
    receiverAddress?.lat,
    receiverAddress?.long,
    receiverAddress?.country,
    receiverAddress?.province,
    receiverAddress?.district,
    receiverAddress?.village,

    packageTypeSelected,

    productSelected?.ids,
    voucherSelected?.code,
    typeOrder,
    location,

    isEmpty(grap?.settings),
    isEmpty(senderAddress),
    isEmpty(receiverAddress),

    // isFirstTimeInputC2C,

    serviceType,
    interval,
    nDay,
    goodsInfo
  ]);
};

export default useGetPrice;
