export enum C2cOverPaymentTextEnum {
	C2cOverPayment_Title = 'c2c_over_payment_title',
	ProductClassification_Title = 'product_classification_title',
	ProductClassification_PlaceHolderInput = 'product_classification_place_holder_input',
	ProductSizeAndWeight_Title = 'product_size_and_weight_title',
	PSAW_SelectSize_Longs = 'product_size_and_weight_select_size_longs',
	PSAW_SelectSize_Width = 'product_size_and_weight_select_size_width',
	PSAW_SelectSize_Height = 'product_size_and_weight_select_size_height',
	PSAW_ExtraService_Title = 'product_size_and_weight_extra_service_title',
	PSAW_ExtraService_FeeService = 'product_size_and_weight_extra_service_fee_service',
	C2cPromoCode_Title = 'c2c_promo_code_title',
	C2cPromoCode_PlaceHolderInput = 'c2c_promo_code_place_holder_input',
	C2cPromo_PlaceHolderInput = 'c2c_promotion_place_holder_input',
	C2cPromoCode_BtnApply = 'c2c_promo_code_btn_apply',
	C2cDeliveryMethod_Title = 'c2c_delivery_method_title',
	C2CDeliveryMethodClassic_Title = 'c2c_delivery_method_classic_title',
	C2CDeliveryMethodClassic_Estimation = 'c2c_delivery_method_classic_estimation',
	C2CDeliveryMethodClassic_BtnAddProduct = 'c2c_delivery_method_classic_btn_add_product',
	C2CGoodsInformationTitle = 'c2c_goods_info_title',
	C2cPromoCode_BtnDeleteVoucher = 'buttons_delete',
	C2cViewAllProduct = 'c2c_view_all_products',
}
