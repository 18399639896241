import { Col, Form, Row, Select } from 'antd';
import marker from 'Assets/icon/marker.svg';
import ButtonClick from 'Components/ButtonClick';
import { CheckBox, CheckBoxItem } from 'Components/CheckBox';
import InputText from 'Components/InputText';
import ModalDialog from 'Components/ModalDialog';
import RadioButton from 'Components/RadioButton';
import Section from 'Components/Section';
import SelectBox from 'Components/SelectBox';
import { debounce, replace, startsWith } from 'lodash';
import PageTitle from 'Pages/common/PageTitle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { toast } from 'react-toastify';
import AddressBookServiceV1 from 'Services/v1/address-book';
import {
  DTOAddress,
  DTOSearchGoogleAddress
} from 'Services/v1/address-book/dto';
import LocationServiceV1 from 'Services/v1/locations';
import { DTOAddressKorea, DTOLocationOption } from 'Services/v1/locations/dto';
import {
  addressBookSelectors,
  createNewAddress,
  deleteAddress,
  resetStatus,
  updateAddress
} from 'Stores/address-book';
import {
  getLocations,
  getSelectedLocation,
  locationSelectors,
  resetLocation
} from 'Stores/locations';
import {
  PHONES_CODE,
  PHONE_CODE_NUMBER,
  REGREX_UTIL,
  VIETNAM_COUNTRY_CODE
} from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { AddressPathEnum, MainPageEnum } from '../../../routes/enums';

import { usePopup } from 'Hooks';
import { updateReceiverAddress, updateSenderAddress } from 'Stores/order-bill';
import SearchAddress from 'Pages/AddressBook/SearchAddress';
import './index.scss';

const initialData: DTOAddress = {
  id: '',
  personName: '',
  phone: '',
  address: '',
  province: '',
  district: '',
  village: '',
  isDefault: false,
  lat: 0,
  long: 0,
  type: 'NORMAL',
  country: '0',
  cardId: '',
  post_code: '',
  cardId: ''
};

const provinceAccess: number[] = [22, 61, 62, 28, 2];

const initAddress: DTOAddress = {
  id: '',
  lat: '',
  long: '',
  province: ''
};

interface FormState {
  personName: string;
  phone: string;
  country: string;
  province: string;
  district: string;
  village: string;
  address: string;
  type: string;

  cardId?: string | null;
  addressDetail?: string;
  postCode?: string;
  pccc?: string;
  prefixPhone?: string;
}

enum OrderTypeEnum {
  cd = 'cd',
  c2c = 'c2c'
}

const AddressForm = () => {
  const { Option } = Select;
  const [form] = Form.useForm<FormState>();
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const compClass = `${classPrefix}-address-detail`;

  const [loading, setLoading] = useState(false);

  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  const [visibleModal, setVisibleModal] = useState(false);

  const popup = usePopup();

  const deleteStatus = useSelector(addressBookSelectors.get).fetching
    .deleteStatus;
  const updateStatus = useSelector(addressBookSelectors.get).fetching
    .updateStatus;
  const createStatus = useSelector(addressBookSelectors.get).fetching
    .createStatus;
  const newAddress = useSelector(addressBookSelectors.get).newAddress;

  const countryData = useSelector(locationSelectors.get).country.data || [];
  const provinceData = useSelector(locationSelectors.get).city.data || [];
  const districtData: DTOLocationOption[] =
    useSelector(locationSelectors.get).district.data || [];
  const villageData: DTOLocationOption[] =
    useSelector(locationSelectors.get).ward.data || [];
  const allPickupLocation = useSelector(locationSelectors.get);
  const wardList = useSelector(locationSelectors.get).ward.list;

  const [searchParams] = useSearchParams();
  const addressType = searchParams.get('addressType') || undefined;
  const isDirect = searchParams.get('isDirect') === 'true' ? true : false;
  const orderType:OrderTypeEnum | undefined = searchParams.get('orderType') as OrderTypeEnum || undefined;

  const page = searchParams.get('page') || '1';
  const isAddressBookSavingOptional = addressType && !id;

  const [isSaveToAddressBook, setIsSaveToAddressBook] = useState(!isAddressBookSavingOptional);

  const isGlobalCountry = () =>
    form.getFieldValue('country') !== VIETNAM_COUNTRY_CODE;

  const pageIndex = useRef(1);
  const totalPage = useRef(0);
  const isLoading = useRef(false);
  const txtSearch = useRef('');
  const [addressBuildingNumberList, setAddressBuildingNumberList] = useState<
    DTOAddressKorea[]
  >([]);
  const [addressDetail, setAddressDetail] = useState(initAddress);
  const [searchGoogleAddressDetail, setSearchGoogleAddressDetail] = useState<
    DTOSearchGoogleAddress | null | undefined
  >();

  const ORDER_TYPE_ROUTING_MAPPING = {
    'c2c': `/${MainPageEnum.C2cOrderPayment}`,
    'cd': `/${MainPageEnum.OrderBill}`
  }

  const placeId = searchGoogleAddressDetail?.placeId;
  const getFullAddress = (address?: string) => {
    if (
      !address ||
      !allPickupLocation.ward.selectedValue ||
      !allPickupLocation.district.selectedValue ||
      !allPickupLocation.city.selectedValue
    )
      return;
    return `${address}, ${allPickupLocation.ward.selectedValue}, ${
      allPickupLocation.district.selectedValue
    }, ${allPickupLocation.city.selectedValue}, ${
      allPickupLocation.country.selectedValue || 'Việt Nam'
    }.`;
  };

  // Get Address Detail With ID
  useEffect(() => {
    if (id) {
      AddressBookServiceV1.getAddress(id).then(data => {
        if (data?.data) {
          setAddressDetail(data?.data);
        }
      });
    }
  }, [id]);

  // Init value for google map value if Address Detail exists
  useEffect(() => {
    if (
      !addressDetail ||
      !addressDetail.address ||
      searchGoogleAddressDetail ||
      !ref.current
    )
      return;
    const fullAddressString = getFullAddress(addressDetail.address);
    if (!fullAddressString) return;

    setSearchGoogleAddressDetail({
      formattedAddress: fullAddressString,
      geometry: {
        location: {
          lat: () => parseFloat(addressDetail.lat),
          lng: () => parseFloat(addressDetail.long)
        }
      }
    });
    setCenter({
      lat: parseFloat(addressDetail.lat),
      lng: parseFloat(addressDetail.long)
    });
  }, [
    addressDetail,
    searchGoogleAddressDetail,
    getFullAddress(addressDetail.address),
    allPickupLocation.ward.selectedValue,
    allPickupLocation.district.selectedValue,
    allPickupLocation.city.selectedValue
  ]);

  // Backing logic
  const backToPreviousScreen = () => {
    if(isDirect) {
      navigate(ORDER_TYPE_ROUTING_MAPPING[orderType]);
    } else if(addressType && id) {
      const previousAddressListPath = generatePath(
        AddressPathEnum.AddressListingSelect,
        {
          addressType,
          orderType
        }
      );
      navigate(`${previousAddressListPath}?page=${page}`);
    } else if (orderType) {
      navigate(ORDER_TYPE_ROUTING_MAPPING[orderType]);
    } else {
      navigate('/' + MainPageEnum.Address + `?page=${page}`);
    }
  };

  const checkBoxOptions: CheckBoxItem[] = [
    { label: t('checkbox_normal_address'), value: 'NORMAL' },
    { label: t('checkbox_default_receiver_address'), value: 'RECEIVER' },
    { label: t('checkbox_default_sender_address'), value: 'SENDER' }
  ];

  const handleGetAddressBuildingNumber = (listData: DTOAddressKorea[] = []) => {
    const { village } = form.getFieldsValue();
    LocationServiceV1.getAddressKorea(
      village,
      pageIndex.current,
      txtSearch.current
    ).then(res => {
      totalPage.current = Number(res.meta?.totalPages || 0);
      const newData = [...listData, ...(res.data! || [])];
      setAddressBuildingNumberList(newData);
      isLoading.current = false;
    });
  };

  const onSearchBuildingNumber = (text: string) => {
    txtSearch.current = text;
    pageIndex.current = 1;
    handleGetAddressBuildingNumber();
  };

  const onScrollBottom = (e: any) => {
    if (!isLoading.current && totalPage.current > pageIndex.current) {
      isLoading.current = true;
      pageIndex.current += 1;
      handleGetAddressBuildingNumber(addressBuildingNumberList);
    }
  };

  const onAddressComponentSelectChange = (
    value: string,
    options: any,
    field: string
  ) => {
    let fieldReset: string[] = [];
    let filedResetState: any[] = [];
    if (field !== 'address') {
      pageIndex.current = 1;
      txtSearch.current = '';
      setAddressBuildingNumberList([]);
    }
    switch (field) {
      case 'country':
        fieldReset = [
          'province',
          'district',
          'village',
          'address',
          'addressDetail'
        ];
        filedResetState = ['city', 'district', 'ward', 'address'];
        dispatch(getLocations({ level: 'city', id: value }));
        dispatch(getSelectedLocation({ level: 'country', id: value }));
        break;
      case 'province':
        fieldReset = ['district', 'village', 'address'];
        filedResetState = ['district', 'ward'];
        dispatch(getLocations({ level: 'district', id: value }));

        dispatch(getSelectedLocation({ level: 'city', id: value }));
        break;
      case 'district':
        fieldReset = ['village', 'address'];
        filedResetState = ['ward'];
        dispatch(getLocations({ level: 'ward', id: value }));
        dispatch(
          getSelectedLocation({
            level: 'district',
            id: value
          })
        );
        break;
      case 'village':
        fieldReset = ['address'];
        if (isGlobalCountry()) handleGetAddressBuildingNumber();
        dispatch(getSelectedLocation({ level: 'ward', id: value }));
        const { address } = form.getFieldsValue();
        if (!address && Object.values(wardList).length) {
          // get location of ward
          let wardLatLng = { lat: 0, lon: 0 };
          for (const k in wardList) {
            if (Object.prototype.hasOwnProperty.call(wardList, k)) {
              const e = wardList[k];
              if (e.id === value) {
                wardLatLng = {
                  lat: Number(e.geo.lat),
                  lon: Number(e.geo.lon)
                };
              }
            }
          }
          if (wardLatLng.lat && wardLatLng.lon) {
            setCenter({
              lat: wardLatLng.lat,
              lng: wardLatLng.lon
            });
          }
        }
        break;
      default:
        break;
    }
    form.resetFields(fieldReset);
    filedResetState.forEach(field => {
      dispatch(resetLocation({ level: field }));
    });
  };

  const onStreetAddressChange = () => {
    const { address } = form.getFieldsValue();
    searchGoogleAddressesFn(getFullAddress(address), ref.current);
  };

  const onMapChange = useCallback(
    debounce((address: DTOSearchGoogleAddress) => {
      setSearchGoogleAddressDetail(address);
      AddressBookServiceV1.convertGoogleAddressToInternalAddress({
        placeId: address.placeId
      }).then(response => {
        const data = response.data;

        if (!data) return;
        // Parse to address detail
        const [country, province, district, ward] =
          data.origin.locationId.split('-');
        const parsedAddressDetail: DTOAddress = {
          country,
          province,
          district,
          village: ward,
          address: data.origin.address
        };

        setAddressDetail(parsedAddressDetail);
      });
    }, 200),
    []
  );

  // Complex of form values assigning and address data fetching
  useEffect(() => {
    dispatch(getLocations({ level: 'country', id: '' }));
    dispatch(getLocations({ level: 'city', id: '0' }));
    if (id || placeId) {
      // get location
      if (addressDetail.country) {
        dispatch(
          getLocations({
            level: 'city',
            id: addressDetail.country
          })
        );
      }

      let prefixPhone = PHONE_CODE_NUMBER.VIETNAM;
      if (addressDetail.phone) {
        if (startsWith(addressDetail.phone, PHONE_CODE_NUMBER.KOREA)) {
          prefixPhone = PHONE_CODE_NUMBER.KOREA;
        }
        addressDetail.phone = replace(addressDetail.phone, prefixPhone, '0');
      }

      if (addressDetail.province) {
        dispatch(
          getLocations({
            level: 'district',
            id: addressDetail.province
          })
        );
      }

      if (addressDetail.district) {
        dispatch(getLocations({ level: 'ward', id: addressDetail.district }));
      }

      if (addressDetail?.address) {
        const addressToList = addressDetail.address.split(',');
        if (addressToList.length > 4) {
          for (let index = 0; index < 4; index++) {
            addressToList.pop();
          }
        }

        addressDetail.address = addressToList.toString();
      }

      form && form.setFieldsValue({ ...addressDetail, prefixPhone });

      if (addressDetail.address) {
        handleGetAddressBuildingNumber();
      }

      form.setFieldsValue({
        type: addressDetail.isDefault ? addressDetail.type : 'NORMAL'
      });
    } else {
      form.setFieldsValue({
        country: '0',
        type: 'NORMAL',
        prefixPhone: PHONE_CODE_NUMBER.VIETNAM
      });
    }
  }, [addressDetail]);

  // Navigation logics
  useEffect(() => {
    const status = createStatus || updateStatus || deleteStatus;
    const updateType = (createStatus && 'create') || (updateStatus && 'update') || (deleteStatus && 'delete')
    if(!status || status === 'pending') return;

    const isCreateAddressAndSave = newAddress.id && createStatus === 'success' && updateType === 'create' && orderType && isSaveToAddressBook;

    const isEditExpiredAddress = true;

    if(isCreateAddressAndSave && isEditExpiredAddress) {
      selectAddressForBooking(newAddress, addressType);
    } else if (status === 'success') {
      toast.success(t(`message_${updateType}_success`), {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (status === 'error') {
      toast.error(t(`message_${updateType}_error`), {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    setTimeout(() => {
      backToPreviousScreen();
    }, 300);

    dispatch(resetStatus());
  }, [
    deleteStatus,
    createStatus,
    updateStatus,
    newAddress,
    orderType,
    isSaveToAddressBook,
    addressType
  ]);

  // Get selected address fields (province, district, village...)
  useEffect(() => {
    const { province, district, village } = form.getFieldsValue();
    province &&
      provinceData.length &&
      dispatch(
        getSelectedLocation({
          level: 'city',
          id: province
        })
      );
    district &&
      districtData.length &&
      dispatch(
        getSelectedLocation({
          level: 'district',
          id: district
        })
      );
    village &&
      villageData.length &&
      dispatch(
        getSelectedLocation({
          level: 'ward',
          id: village
        })
      );
  }, [provinceData, districtData, villageData]);

  const searchGoogleAddressesFn = useCallback(
    debounce((query, attrContainer) => {
      if (!attrContainer) return;
      AddressBookServiceV1.searchGoogleAddress({
        query,
        attrContainer
      }).then(data => {
        if (!data.data) return;
        const searchGoogleAddress = data.data[0];
        setSearchGoogleAddressDetail(searchGoogleAddress);
        setCenter({
          lat: searchGoogleAddress.geometry.location.lat(),
          lng: searchGoogleAddress.geometry.location.lng()
        });
      });
    }, 500),
    []
  );

  const onOpenAddressRecheckPopup = (
    data: DTOAddress & { prefixPhone: string }
  ) => {
    popup.onOpen(
      {
        title: 'global_confirm',
        width: 450,
        okBtnLabel: isSaveToAddressBook ? 'address_submit_recheck_popup_ok_btn' : 'address_submit_recheck_popup_ok_btn_not_save',
        cancelBtnLabel: 'address_submit_recheck_popup_cancel_btn',
        btnFullWidth: false,
        btnReverse: false,
        onSubmit: () => onSubmit(data)
      },
      <div className={`${compClass}-recheck-popup`}>
        <div className={'mb-2 fs-p-lg lh-p-lg'}>
          <span className={'fw-bold'}>{data.personName}</span> -{' '}
          <span className={'text-secondary'}>{data.phone}</span>
        </div>
        <div className={'mb-4 text-secondary-700 fs-p-lg lh-p-lg'}>
          {getFullAddress(data.address)}
        </div>
        <div className={`${compClass}-recheck-popup-confirm-text`}>
          <div className={'fw-bold'}>
            {t('address_submit_recheck_popup_confirm_text_title')}
          </div>
          <div>{t('address_submit_recheck_popup_content')}</div>
        </div>
      </div>
    );
  };

  const selectAddressForBooking = (address: DTOAddress, addressType?: string) => {
    if(!addressType) return;
    if(addressType === 'sender') return dispatch(updateSenderAddress(address));
    if(addressType === 'receiver') return dispatch(updateReceiverAddress(address));
    return;
  }
  const onSubmit = (data: DTOAddress & { prefixPhone: string }) => {
    setLoading(true);
    const formData: DTOAddress & { prefixPhone: string } = {
      ...initialData,
      ...data,
      address: getFullAddress(String(data?.address))
    };
    if (formData.type === 'SENDER' || formData.type === 'RECEIVER') {
      formData.is_default = true;
    } else {
      // set default value as sender
      formData.type = 'SENDER';
    }
    formData.lat = searchGoogleAddressDetail?.geometry.location.lat();
    formData.long = searchGoogleAddressDetail?.geometry.location.lng();
    if (isGlobalCountry()) {
      formData.address = data.address;
    }
    if (formData.phone) {
      let phone = formData.phone;
      if (startsWith(formData.phone, '0')) {
        phone = replace(formData.phone, '0', '');
      }
      formData.phone = formData.prefixPhone + phone;
    }

    formData.post_code = data.postCode;

    if (id) {
      formData.id = id;
      dispatch(updateAddress({
        data: formData,
        metaData: {
          addressType,
          isDirect
        }
      }));

    } else if(!isSaveToAddressBook && orderType) {
      selectAddressForBooking(formData, addressType);
      const navigationPath = orderType === 'c2c' ? `/${MainPageEnum.C2cOrderPayment}` : `/${MainPageEnum.OrderBill}`;
      return navigate(navigationPath);
    } else {
      delete formData.id;
      dispatch(createNewAddress(formData));
    }
  };

  const onDelete = () => {
    setVisibleModal(true);
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onHandleCancelModal = () => {
    setVisibleModal(false);
  };

  const onHandleOkModal = () => {
    if (id) {
      dispatch(deleteAddress(id));
    }
    setVisibleModal(false);
  };

  const validateMessages = {
    required: t('validation_required', { fieldName: '${label}' })
  };

  const prefixPhone = (
    <Form.Item name="prefixPhone" noStyle>
      <Select
        style={{
          width: 90
        }}
      >
        {PHONES_CODE.map((phoneCode, index) => (
          <Option key={index} value={phoneCode.value}>
            <div className="d-flex align-items-center">
              <img
                className="me-1"
                width={30}
                height={15}
                src={phoneCode.icon}
                alt={phoneCode.label}
              />
              {phoneCode.label}
            </div>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <>
      <PageTitle title={t('title_page_address')} />
      <Section
        className={`${compClass}-container pb-4`}
        title={id ? t('title_update_address') : t('title_create_address')}
        icon={<img src={marker} width="14" alt={''} />}
        actionIcon={
          id ? (
            <a className="delete-btn" onClick={onDelete}>
              {t('buttons_delete')}
            </a>
          ) : undefined
        }
      >
        <ModalDialog
          visible={visibleModal}
          title={t('modal_title_confirm')}
          children={t('modal_content_delete_confirm')}
          cancelText={t('buttons_cancel')}
          okText={t('buttons_delete')}
          onHandleCancel={onHandleCancelModal}
          onHandleOk={onHandleOkModal}
        />
        <div className={`${compClass}-form mt-6`}>
          <Row gutter={24}>
            <Col xs={16}>
              <SearchAddress
                draggable={!isGlobalCountry()}
                center={center}
                selectedAddressDetail={searchGoogleAddressDetail}
                onChange={onMapChange}
              />
              <div ref={ref} />
            </Col>
            <Col xs={8}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onOpenAddressRecheckPopup}
                initialValues={{ country: '0' }}
                validateMessages={validateMessages}
              >
                <Col xs={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="personName"
                    rules={[
                      { required: true },
                      {
                        pattern: REGREX_UTIL.spacesStartOrEnd,
                        message: t('validation_spaces_full_name')
                      }
                    ]}
                    label={t('label_full_name')}
                  >
                    <InputText
                      autoFocus
                      maxLength={45}
                      placeholder={t('label_full_name')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    className="phone-form"
                    name="phone"
                    rules={[
                      {
                        required: true
                      },
                      {
                        pattern: /^[0-9]{9,11}$/,
                        message: t('validation_range_length_phone', {
                          min: 0,
                          max: 9
                        })
                      }
                    ]}
                    label={t('label_phone')}
                  >
                    <InputText
                      addonBefore={prefixPhone}
                      type="number"
                      className="hide-arrow"
                      placeholder={t('label_phone')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="cardId"
                    rules={[
                      {
                        pattern: /[0-9]{9,}/,
                        message: t('invalid_CMND_CCCD')
                      }
                    ]}
                    label={t('label_identity')}
                  >
                    <InputText
                      maxLength={24}
                      minLength={9}
                      placeholder={t('label_identity')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="country"
                    rules={[{ required: true }]}
                    label={t('label_country')}
                  >
                    <SelectBox
                      selectedValue={form.getFieldValue('country')}
                      onChange={(value: string, options: any) =>
                        onAddressComponentSelectChange(
                          value,
                          options,
                          'country'
                        )
                      }
                      options={countryData}
                      placeholder={t('label_country')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="province"
                    rules={[{ required: true }]}
                    label={t('label_province')}
                  >
                    <SelectBox
                      selectedValue={form.getFieldValue('province')}
                      onChange={(value: string, options: any) =>
                        onAddressComponentSelectChange(
                          value,
                          options,
                          'province'
                        )
                      }
                      options={provinceData}
                      placeholder={t('label_province')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="district"
                    rules={[{ required: true }]}
                    label={t('label_district')}
                  >
                    <SelectBox
                      selectedValue={form.getFieldValue('district')}
                      onChange={(value: string, options: any) =>
                        onAddressComponentSelectChange(
                          value,
                          options,
                          'district'
                        )
                      }
                      options={districtData}
                      placeholder={t('label_district')}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="village"
                    rules={[{ required: true }]}
                    label={t(
                      isGlobalCountry() ? 'label_ward_korean' : 'label_ward'
                    )}
                  >
                    <SelectBox
                      selectedValue={form.getFieldValue('village')}
                      onChange={(value: string, options: any) =>
                        onAddressComponentSelectChange(
                          value,
                          options,
                          'village'
                        )
                      }
                      options={villageData}
                      placeholder={t(
                        isGlobalCountry() ? 'label_ward_korean' : 'label_ward'
                      )}
                    />
                  </Form.Item>
                </Col>
                {isGlobalCountry() ? (
                  <Col span={24}>
                    <Form.Item
                      name="address"
                      rules={[{ required: true }]}
                      label={t('building_number')}
                    >
                      <SelectBox
                        selectedValue={form.getFieldValue('address')}
                        onChange={(_, item) => {
                          form.setFieldValue('postCode', item.data.postcode);
                          onStreetAddressChange();
                        }}
                        onScrollBottom={onScrollBottom}
                        isSearchServer
                        onSearch={onSearchBuildingNumber}
                        valueField={'address'}
                        labelField={'address'}
                        options={addressBuildingNumberList}
                        isShowCheck={false}
                        placeholder={t('building_number')}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Form.Item
                      name="address"
                      rules={[{ required: true }]}
                      label={t('label_house_street_number')}
                    >
                      <InputText
                        placeholder={t('label_house_street_number')}
                        onChange={onStreetAddressChange}
                        maxLength={250}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item name="addressDetail" label={t('detail_address')}>
                    <InputText
                      placeholder={t('detail_address')}
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="postCode"
                    rules={[
                      {
                        pattern: REGREX_UTIL.onlyCharsAndNums,
                        message: t('validation_special_characters')
                      }
                    ]}
                    label={t('label_postcode')}
                  >
                    <InputText
                      disabled={isGlobalCountry()}
                      autoFocus
                      maxLength={45}
                      placeholder={t('label_postcode')}
                    />
                  </Form.Item>
                </Col>

                {isGlobalCountry() && (
                  <Col span={24}>
                    <Form.Item
                      name="pccc"
                      rules={[
                        {
                          pattern: REGREX_UTIL.onlyCharsAndNums,
                          message: t('validation_special_characters')
                        }
                      ]}
                      label={t('label_pccc')}
                    >
                      <InputText
                        autoFocus
                        maxLength={45}
                        placeholder={t('label_pccc')}
                      />
                    </Form.Item>
                  </Col>
                )}

                <Col span={24}>
                  <Form.Item name="type" label={t('label_type_address')}>
                    <RadioButton
                      options={checkBoxOptions}
                      radioGroupClassName="w-100 d-flex justify-content-start"
                    />
                  </Form.Item>
                </Col>

                  {isAddressBookSavingOptional && <Col span={24}>
                    <Form.Item>
                      <CheckBox
                        checked={isSaveToAddressBook}
                        onChange={value => setIsSaveToAddressBook(value.target.checked)}
                        label={t('label_save_to_address_book')}
                      />
                    </Form.Item>
                  </Col>}

                <Col xs={24} md={24} lg={24} xl={24}>
                  <div className={'d-flex'}>
                    <ButtonClick
                      containerClassName="mr-2"
                      className="w-100"
                      variant="ghost"
                      onClick={onCancel}
                    >
                      {t('buttons_cancel')}
                    </ButtonClick>
                    <ButtonClick
                      className="w-100"
                      variant="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      {t('buttons_complete')}
                    </ButtonClick>
                  </div>
                </Col>
              </Form>
            </Col>
          </Row>
        </div>
      </Section>
    </>
  );
};

export default AddressForm;
