import React from 'react';
import { Dropdown as AntDropdown, DropdownProps } from 'antd';
import { classPrefix } from 'Utilities/global';
import './index.scss'
const Dropdown = (props: DropdownProps) => {
	return <AntDropdown overlayClassName={`${classPrefix}-dropdown-overlay`} {...props}>
		{props.children}
	</AntDropdown>
}

export default Dropdown;