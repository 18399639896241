import { Tooltip } from 'antd';
import images from 'Assets/images';
import { useTranslation } from 'react-i18next';
import { classPrefix } from 'Utilities/global';
import { copyToClipboard } from 'Utilities/util';
import './components.scss';

interface Props {
  code: string | undefined;
}

const CustomerCode = ({ code }: Props) => {
  const { t } = useTranslation();
  const componentClass = `${classPrefix}-customer-code col-md-12 col-12 d-flex py-4 ps-3 mb-6`;
  return (
    <div className="row">
      <div className={componentClass}>
        <img src={images.QR} width={18} className="me-3" />
        <span className="me-2">{t('topup_customer_code')}:</span>
        <span className="fw-500 me-2">{code}</span>
        <Tooltip
          title={t('global_copy')}
          overlayInnerStyle={{ borderRadius: '5px' }}
        >
          <img
            className="cursor-pointer"
            onClick={() => copyToClipboard(code)}
            src={images.icCopy}
            width={18}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default CustomerCode;
