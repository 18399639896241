import MessageAlert from 'Components/MessageAlert/messageAlert';
import { usePopup } from 'Hooks';

import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MainPageEnum } from 'Routes';
import { DTOPaymentCard, DTOPaymentCardGroup } from 'Services/v1/payment/dto';
import {
  c2cOrderPaymentSelectors,
  selectPaymentMethodAction
} from 'Stores/c2c-order-payment';
import { layoutUtilitiesSliceSelectors } from 'Stores/layout-utilities';
import { orderBillSelectors, updatePaymentMethod } from 'Stores/order-bill';
import { getPayment, paymentMethodSelector } from 'Stores/payment-method';
import { profileSliceSelectors } from 'Stores/profile';
import { TypePayment } from 'Utilities/constants';
import { toUpperLetter } from 'Utilities/util';
import PaymentMethodItem from '../PaymentMethodItem';
import PaymentVnMethod from '../PaymentVnMethod';
import SelectBanks from '../SelectBanks';

const SelectPaymentVN = (props: {
  paymentMethodDataDiff?: DTOPaymentCardGroup[];
  isIgnoreShowRequired?: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const popup = usePopup();
  const paymentMethod = useSelector(paymentMethodSelector.get);
  const { currentPath } = useSelector(layoutUtilitiesSliceSelectors.get);

  const {
    c2cValidateFields,
    toggleOrder,
    isGlobal,
    totalPrice,
    deliveryMethodSelected
  } = useSelector(c2cOrderPaymentSelectors.get);

  const orderBill = useSelector(orderBillSelectors.get);
  const profile = useSelector(profileSliceSelectors.get);
  const isC2C = currentPath === MainPageEnum.Swift247;
  const pointTopup = Number(profile.data?.pointTopup || 0);
  const currentTotal = (isC2C ? totalPrice : orderBill.feeAndTax?.price) || 0;
  const isEnoughTopupPoint = pointTopup > currentTotal;

  const [renderedPaymentMethods, setPaymentMethodDataTransform] = useState<
    DTOPaymentCardGroup[]
  >([]);

  const renderListPaymentMethods = () => {
    if (
      props.paymentMethodDataDiff &&
      props.paymentMethodDataDiff?.length > 0
    ) {
      return props.paymentMethodDataDiff;
    }

    const paymentMethods = paymentMethod.list || [];
    const paymentVnMethod = PaymentVnMethod(
      pointTopup,
      isEnoughTopupPoint,
      paymentMethods
    );
    if (paymentMethods.length === 0) return [];
    if (isC2C) {
      return paymentVnMethod.renderPaymentC2C(
        deliveryMethodSelected?.avaiPaymentMethods
      );
    }
    return paymentVnMethod.renderPaymentCargo();
  };

  useEffect(() => {
    if (profile.fetchingStatus === 'pending') return;
    const paymentMethods = renderListPaymentMethods();
    setPaymentMethodDataTransform(paymentMethods);
    if (isGlobal) return;

    if (
      !isEnoughTopupPoint &&
      toUpperLetter(orderBill.paymentMethod?.code) === TypePayment.TOPUP
    ) {
      dispatch(updatePaymentMethod());
    } else if (
      isEnoughTopupPoint &&
      (!orderBill.paymentMethod ||
        Object.keys(orderBill.paymentMethod)?.length === 0)
    ) {
      dispatch(
        updatePaymentMethod(
          paymentMethods?.find(
            x => toUpperLetter(x.code) === TypePayment.TOPUP
          ) || {}
        )
      );
    }
  }, [
    deliveryMethodSelected?.avaiPaymentMethods,
    props.paymentMethodDataDiff,
    paymentMethod.list,
    profile,
    currentTotal
  ]);

  useEffect(() => {
    if (
      paymentMethod.list?.length > 0 ||
      paymentMethod.fetchingStatus === 'pending'
    ) {
      return;
    }
    dispatch(getPayment());
  }, [currentPath]);

  const groupDataSetParentState = useCallback(
    (val: DTOPaymentCard[], isOpenBanksList: boolean) => {
      if (isOpenBanksList) {
        popup.onOpen(
          {
            title: t('title_select_bank'),
            hideFooter: true,
            width: isMobile ? 350 : 630,
            height: '100%'
          },
          <SelectBanks
            onSelect={val => {
              if (isGlobal) {
                dispatch(selectPaymentMethodAction(val));
              }
              dispatch(updatePaymentMethod(val));
              popup.onClose();
            }}
            data={val}
            isOpen={true}
            onClose={() => popup.onClose()}
          />
        );
      }
    },
    []
  );

  const renderMessageError = () => {
    if (
      !c2cValidateFields?.paymentMethod?.hasError &&
      !orderBill.cargoValidateFields?.paymentMethod?.hasError
    ) {
      return <></>;
    }

    let toggleOrderCurrent = toggleOrder;
    let propsMessage = c2cValidateFields?.paymentMethod;
    if (orderBill.cargoValidateFields?.paymentMethod?.hasError) {
      propsMessage = orderBill.cargoValidateFields?.paymentMethod;
      toggleOrderCurrent = orderBill.toggleOrder || false;
    }

    return (
      <MessageAlert
        {...propsMessage}
        toggle={toggleOrderCurrent}
        className="message-alert-payment-method"
      />
    );
  };

  return (
    <>
      {renderedPaymentMethods.map((method, index) => (
        <PaymentMethodItem
          key={index}
          method={method}
          handleSetParentSetter={groupDataSetParentState}
        />
      ))}
      {!props.isIgnoreShowRequired && renderMessageError()}
    </>
  );
};

export default SelectPaymentVN;
