import { Card } from 'antd';
import { usePopup } from 'Hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  c2cOrderPaymentSelectors,
  GoodsInfoModel,
  removeProductDeclaration
} from 'Stores/c2c-order-payment';
import { classPrefix } from 'Utilities/global';
import { ButtonAddProduct } from '.';
import './index.scss';
import ProductItem from './ProductItem';

const OrderProductItems = (props: {
	addOrEditProduct: (idGoods?: string) => void;
}) => {
	const { addOrEditProduct } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { onClose } = usePopup();
	const [totalPrice, setTotalPrice] = useState(0);
	const productInfo = useSelector(
		c2cOrderPaymentSelectors.getAllProductDeclaration,
	);

	useEffect(() => {
		let subTotal = 0;
		productInfo.map((item: GoodsInfoModel) => {
			subTotal += Number(item.price) * Number(item.quantity);
		});
		setTotalPrice(subTotal);
	}, [productInfo]);

	const renderData = () => {
		if (!productInfo.length) {
			onClose();
		}
		return (
			<>
				<div
					className={`${compClass} c2c-order-payment-container mb-4`}
				>
					<div className="c2c-order-payment-left mb-4">
						<Card title={undefined} bordered={false}>
						<ButtonAddProduct onClick={addOrEditProduct}></ButtonAddProduct>
							{renderProductItem(productInfo)}
						</Card>
					</div>
					<div className="c2c-order-payment-right mb-4">
						<Card>
							<div className="d-flex justify-content-between">
								<span className="fw-bold fs-l-lg">
									{t('global_total')}:
								</span>
								<span className="fw-bold fs-l-lg text-primary">
									{totalPrice}$
								</span>
							</div>
						</Card>
					</div>
				</div>
			</>
		);
	};

	const renderProductItem = (productInfo: any) => {
		return (
			<>
				{productInfo.map((item: GoodsInfoModel) => {
					return (
						<ProductItem
							key={item.id}
							item={item}
							onEdit={(id) => onEditProductItem(id)}
							onDelete={(id) => onDeleteProductItem(id)}
						/>
					);
				})}
			</>
		);
	};

	const onDeleteProductItem = (productId: string) => {
		dispatch(removeProductDeclaration(productId));
	};

	const onEditProductItem = (productId: string) => {
		addOrEditProduct(productId);
	};

	const compClass = `${classPrefix}-c2c-pickup-product-wrapper`;

	return renderData();
};

export default OrderProductItems;
