import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import CargoDirectServiceV1 from 'Services/v1/cargo-direct';
import FlightServiceV1 from 'Services/v1/flights';
import { createOrder, orderBillSelectors, updateNewOrder } from 'Stores/order-bill/index';

import { BrowserStorageUtil } from '@swift-247/s247.library.ui-core';
import moment from 'moment';

import { DTOServiceResponse } from 'Services/interfaces';
import { DTOCreateOrderVATInfoRequest, DTOOrder } from 'Services/v1/cargo-direct/dto';
import { DTOCheckFlightAvailableLoadResponse } from 'Services/v1/flights/dto';
import { RootState } from 'Stores/index';
import { updateLoadingOverlay } from 'Stores/layout-utilities';
import { OrderBillState } from 'Stores/order-bill/models';
import { DATE_FORMATS, TypePayment } from 'Utilities/constants';
import { getConfirmationUrl, isWhitelistedHost } from 'Utilities/util';
import config from '../../../config/config';

export function* handleCreateOrder(): any {
	yield put(updateNewOrder({
		status: 'init'
	}))

	const orderBillState = (yield select<(state: RootState) => OrderBillState>(orderBillSelectors.get)) as OrderBillState;

	const {
		flightFilter,
		flightInfo,
		paymentMethod,
		senderAddress,
		receiverAddress,
		vatInfo,
		feeAndTax
	} = orderBillState;

	yield put(updateNewOrder({
		status: 'processing'
	}))

	const checkFlightAvailableLoadResponse = (yield call(FlightServiceV1.checkFlightAvailableLoad.bind(FlightServiceV1), {
		weight: flightFilter.weight,
		flightId: flightInfo?.flightId,
    dimension: flightFilter?.dimension
	})) as DTOServiceResponse<DTOCheckFlightAvailableLoadResponse>

	if(!checkFlightAvailableLoadResponse.data?.isAvailable) {
		return yield put(updateNewOrder({
			status: 'load_not_available',
			errorMsg: checkFlightAvailableLoadResponse.data?.message
		}))
	}

	const minEndTimeUTC = moment(flightInfo?.deliveryTime).utcOffset(0);

	const {data, error} = (yield call(CargoDirectServiceV1.createOrder.bind(CargoDirectServiceV1), {
		flightId: flightInfo?.flightId,
		senderAddress: senderAddress?.address,
		senderAdditionalInfo: null,
		receiverAddress: receiverAddress?.address,
		receiverAdditionalInfo: null,

		fromLat: senderAddress?.lat,
		fromLng: senderAddress?.long,
		toLat: receiverAddress?.lat,
		toLng: receiverAddress?.long,

		originDop: 1, // default -> TBD with truck
		destinationDop: 6, // default -> TBD with truck

		originId: [
			String(senderAddress?.country),
			String(senderAddress?.province),
			String(senderAddress?.district),
			String(senderAddress?.village),
		],
		destinationId: [
			String(receiverAddress?.country),
			String(receiverAddress?.province),
			String(receiverAddress?.district),
			String(receiverAddress?.village),
		],

		discountAmount: 0,
		voucher: '',

		packageType: 'PARCEL',
		deliveryType: 'P2P',
		serviceType: 'standard',

		dateTimeSlot: minEndTimeUTC.format('YYYY-MM-DD'),
		timeSlotFrom: minEndTimeUTC.format('HH:mm'),
		timeSlotTo: minEndTimeUTC.add(2,'hours').format('HH:mm'),

		superswiftFee: 0,

		paymentMethod: paymentMethod?.code === TypePayment.TOPUP ? TypePayment.TOPUP : TypePayment.VNPAY,
		vnpBank: paymentMethod?.code === TypePayment.TOPUP ? '' : paymentMethod?.code,
		amount: feeAndTax?.price,

		beforeTime: flightInfo?.startTime,
		minEndTime: minEndTimeUTC.format(DATE_FORMATS.ISO_TIMEZONE),

		// quantity: flightFilter?.numberOfPackages,
		weight: flightFilter?.weight,
		grossWeight: flightFilter?.weight,

		fromPhone: senderAddress?.phone,
		toPhone: receiverAddress?.phone,
		fromName: senderAddress?.personName,
		toName: receiverAddress?.personName,
		note: '',
		senderAddressId: senderAddress?.id,
		receiverAddressId: receiverAddress?.id,

		dimension: flightFilter.dimension,

		extraPrices: [],

		orderDetails: [{
			production_code: flightFilter?.commodity?.code,
			name: flightFilter?.commodity?.name
		}], // category/commodity

		ver: '1.3.6',

		senderPointInfo: [
			{
				id: senderAddress?.id,
				address: senderAddress?.address,
				latitude: senderAddress?.lat,
				longitude: senderAddress?.long,
				code: senderAddress?.province
			}
		],
		receiverPointInfo: [
			{
				id: receiverAddress?.id,
				address: receiverAddress?.address,
				latitude: receiverAddress?.lat,
				longitude: receiverAddress?.long,
				code: receiverAddress?.province
			}
		],
		shippingTime: 0,

		originDropoffPoint: flightInfo?.departureAirport,
		destinationDropoffPoint: flightInfo?.arrivalAirport,

		originHandoverPoint: flightInfo?.originHandoverPoint,
		destinationHandoverPoint: flightInfo?.destinationHandoverPoint,

		toApollo: true,
		hasInsurance: false,
		benAgreed: true,

		transportRules: [{
			'rule': 'Liên thành phố'
		}],

		goodsInfo: [],

		device: 'browser',
		deviceType: 'browser',
		deviceToken: 'cargo_direct',
		fees: feeAndTax?.fees,
		feeOptions: []
	} as any)) as DTOServiceResponse<DTOOrder>

	yield put(updateLoadingOverlay(false));
	if(error) {
		yield put(updateNewOrder({
			status: 'failed',
			errorMsg: error.msg
		}))
		return
	}

	if(vatInfo.isEnabled && vatInfo.data) {
		yield call(CargoDirectServiceV1.createOrderVATInfo.bind(CargoDirectServiceV1), {
			orderId: data?.orderId,
			email: vatInfo.data.email,
			companyAddress: vatInfo.data.companyAddress,
			companyName: vatInfo.data.companyName,
			taxNumber: vatInfo.data.taxNumber
		} as DTOCreateOrderVATInfoRequest)
	}


	yield put(updateNewOrder({
		status: 'completed',
		order: data
	}))

	const persistLayer = new BrowserStorageUtil(sessionStorage);

	persistLayer.serializedSave('newOrder', data);

	if (data?.paymentMethod === TypePayment.VNPAY && isWhitelistedHost([config.vnpayRedirectDomain], data?.paymentURL || '') && data?.paymentURL) {
		(window as Window).location = data?.paymentURL;
	} else if (data?.paymentMethod === TypePayment.TOPUP) {
		(window as Window).location = getConfirmationUrl(
			TypePayment.TOPUP,
			data.paymentStatus !== 'FAILED'
		);
	}
}

export default function* orderBillSaga() {
	yield takeLatest(createOrder.toString(), handleCreateOrder);
}
