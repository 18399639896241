import Wallet from 'Assets/icon/wallet-icon.svg';
import Section from 'Components/Section';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { profileSliceSelectors } from 'Stores/profile';
import { PROFILE_TYPES } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import CustomerCode from './components/CustomerCode';
import TopUpBonus from './components/TopUpBonus';
import Transaction from './components/Transaction';
import './index.scss';

const TopUp = () => {
	const { t } = useTranslation();
	const compClass = `${classPrefix}-top-up-container`;
  const { data } = useSelector(profileSliceSelectors.get);

	return <>
			<Section
				className={`${compClass}-container pb-4`}
				title={t('topup_prepayment')}
				icon={<img src={Wallet} width="14" alt={''} />}
		>
			<CustomerCode code={data.type === PROFILE_TYPES.personal ? data.phone : data.customerCode} />
			<TopUpBonus />
			<Transaction />
		</Section>

	</>;
};

export default TopUp;