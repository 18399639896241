import { format, isValid } from 'date-fns';
import { DATE_FORMATS } from 'Utilities/constants';
import { newDateWithFormat } from 'Utilities/util';

const FlightTimeFormat = (props: { value?: string }) => {
  const { value } = props;

  if (!value) return <></>;

  const dateObj = newDateWithFormat(value, DATE_FORMATS.ISO_CUSTOM);

  if (!isValid(dateObj)) return <div>-</div>;

  return (
    <div className={'text-center'}>
      <div>{format(dateObj, 'HH:mm')}</div>
      <div>{format(dateObj, 'dd/MM/yyyy')}</div>
    </div>
  );
};

export default FlightTimeFormat;
