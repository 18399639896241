import TopUpIn from 'Assets/icon/top-up-in.svg';
import TopUpOut from 'Assets/icon/top-up-out.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TransactionType } from 'Stores/topup/enums';
import { TopupInfo, Transaction } from 'Stores/topup/models';
import { DATE_FORMATS } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { displayCurrency, toUpperLetter } from 'Utilities/util';
import './components.scss';

const TransactionItem = (props: {
  transaction: Transaction & { agent: TopupInfo };
}) => {
  const { t } = useTranslation();
  const { amountAfter, amountBefore, tranReqType, orderNumber, createdDate } =
    props.transaction;
  const isTopupIn = amountAfter - amountBefore > 0;
  const compClass = `${classPrefix}-transaction-item d-flex justify-content-between align-item-start mb-3 p-3`;

  const renderTransaction = () => {
    let labelTransaction = (
      <span>{t(`topup_${toUpperLetter(tranReqType, false)}`)}</span>
    );
    if (
      tranReqType === TransactionType.REFUND ||
      tranReqType === TransactionType.PAYMENT ||
      tranReqType === TransactionType.PAYMENT_S247
    ) {
      labelTransaction = (
        <>
          {labelTransaction} <strong>{`#${orderNumber}`}</strong>
        </>
      );
    }

    return labelTransaction;
  };

  return (
    <div className={compClass}>
        <div className="d-flex align-items-start">
          {isTopupIn ? (
            <img src={TopUpIn} width="24" />
          ) : (
            <img src={TopUpOut} width="24" />
          )}
          <div className="d-flex flex-column ms-2">
            <div className="fw-500">{renderTransaction()}</div>
            <span style={{ fontSize: '12px', color: '#828282' }}>
              {moment(createdDate).format(DATE_FORMATS.DDMMYYYYHHmm)}
            </span>
          </div>
        </div>
        <div className={`fw-500 ${isTopupIn ? 'text-in' : 'text-out'}`}>
          {`${isTopupIn ? '+ ' : ''}${displayCurrency(
            amountAfter - amountBefore
          )}`}
        </div>
      </div>
  );
};

export default TransactionItem;
