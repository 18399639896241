import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  c2cOrderPaymentSelectors,
  chooseExtraServiceAction,
  ExtraServiceModel,
  removeExtraServiceAction
} from 'Stores/c2c-order-payment';
import ExtraServiceItem from '../extraServiceItem/extraServiceItem';
import './styles.scss';

interface ExtraServiceProps {
  title?: string;
  className?: string;
}

const ExtraService = (props: ExtraServiceProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { className } = props;
  const { extraServices, extraServiceSelected, deliveryMethodSelected } =
    useSelector(c2cOrderPaymentSelectors.get);

  const renderListExtra = (items?: Array<ExtraServiceModel>) => {
    return items?.map(item => {
      const isSelected = extraServiceSelected.some(
        (extra: ExtraServiceModel) => extra.name === item.name
      );
      return (
        <ExtraServiceItem
          key={item?.name}
          extraService={item}
          isSelected={isSelected}
          currency={deliveryMethodSelected?.currency || 'đ'}
          toggleExtraService={checked => {
            dispatch(
              checked
                ? chooseExtraServiceAction(item)
                : removeExtraServiceAction(item)
            );
          }}
        ></ExtraServiceItem>
      );
    });
  };

  return (
    <div className={`${className} extra-service`}>
      {props.title && <h2 className="extra-service-title">{props.title}</h2>}
      {renderListExtra(extraServices?.data)}
    </div>
  );
};

export default ExtraService;
