
import OrderConfirmation from "Pages/OrderConfirmation"
import {  RouteModel } from ".."
import { OrderBillPathEnum } from "../enums"

export const OrderBillRoutes:Array<RouteModel>  = [
    {
		name: 'C2C Order Payment',
		path: OrderBillPathEnum.OrderBillConfirmation,
		exact: true,
		sideBar: false,
		component: <OrderConfirmation />,
	},
]
