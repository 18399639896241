import { all } from 'redux-saga/effects';
import flightSagas from 'Stores/flights/sagas';
import myOrdersSaga from 'Stores/my-orders/saga';
import paymentMethodSaga from 'Stores/payment-method/saga';
import profileSaga from 'Stores/profile/saga';
import addressBookSaga from './address-book/saga';
import { c2cOrderPaymentSaga } from './c2c-order-payment';
import { grapSaga } from './grap';
import locationsSaga from './locations/saga';
import orderBillSaga from './order-bill/saga';
import topupSaga from './topup/saga';

export default function* rootSaga() {
  yield all([
    addressBookSaga(),
    locationsSaga(),
    orderBillSaga(),
    paymentMethodSaga(),
    ...myOrdersSaga.map((s) => s()),
    ...flightSagas.map((s) => s()),
    profileSaga(),
    c2cOrderPaymentSaga(),
    grapSaga(),
    topupSaga()
    // Add more other sagas
  ]);
}
