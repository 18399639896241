import iconChevronRightPrimary from 'Assets/icon/chevron-right-primary.svg';
import RadioSelectCard from 'Components/RadioSelectCard';
import classnames from 'Utilities/classnames';

export interface PaymentMethodSelectCard {
	iconUrl: string;
	name: string;
	isGroup?: boolean;
	checked: boolean;
	onChange: (val: any) => void;
	className?: string;
	onGroupAction?: () => void;
	value: any;
	iconWidth?: number;
	iconHeight?: number;
	disabled?: boolean;
  description?: string;
  customDescription?: (item: PaymentMethodSelectCard) => string
}

const SelectPaymentMethodCard = (props: PaymentMethodSelectCard) => {
	const {
		iconUrl,
		name,
		isGroup,
		checked,
		onChange,
		className,
		value,
		onGroupAction = () => {},
		iconHeight,
		iconWidth,
		disabled,
    description,
    customDescription
	} = props;

	const containerClasses = classnames(
		`payment-method-select-card cursor-pointer`,
		className,
	);

	return (
		<RadioSelectCard
			onClick={() => {
				onChange(value);
				isGroup && onGroupAction && onGroupAction();
			}}
			disabled={disabled}
			checked={checked}
			className={containerClasses}
			cardAction={
				isGroup ? (
					<img
						className={'cursor-pointer'}
						width={6}
						height={10}
						src={iconChevronRightPrimary}
						alt={''}
					/>
				) : null
			}
		>
			<div className={'mb-1 d-flex align-items-center'}>
				<img
					width={iconWidth}
					height={iconHeight}
					src={iconUrl}
					alt={''}
				/>
				<span className={'ml-2 fs-p-md lh-p-md fw-bold'}>{name}</span>
			</div>
      {customDescription && <div className="payment-method-select-card-description">
        {customDescription(props)}
      </div>}
		</RadioSelectCard>
	);
};

export default SelectPaymentMethodCard;
