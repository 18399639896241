import promoCodeIcon from 'Assets/icon/promo-code-icon.svg';
import Section from 'Components/Section';
import { C2cOverPaymentTextEnum } from 'Pages/C2cOrderPayment/enums';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import InputMessage from 'Components/InputMessage';
import InputText from 'Components/InputText';
import PromotionModal from 'Pages/PromotionVoucher/PromotionModal';
import { useSelector } from 'react-redux';
import { c2cOrderPaymentSelectors, chooseVoucherAction } from 'Stores/c2c-order-payment';
import ButtonClick from 'Components/ButtonClick';
import { useDispatch } from 'react-redux';

const C2cPromoCode = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [visiblePromotionModal, setVisiblePromotionModal] = useState(false);
	const selectedVoucher = useSelector(
		c2cOrderPaymentSelectors.get,
	).voucherSelected;
	const voucherValidationResult = useSelector(
		c2cOrderPaymentSelectors.get,
	).voucherValidation;

	const showVoucherList = () => {
		setVisiblePromotionModal(true);
	};

	const removeVoucher = () => {
		dispatch(chooseVoucherAction({}))
	}

	return (
		<Section
			className="c2c-promo-code"
			title={t(C2cOverPaymentTextEnum.C2cPromoCode_Title)}
			icon={<img src={promoCodeIcon} alt={''} />}
		>
			<div className="c2c-promo-code-input-wrap">
				<InputText
					readOnly={true}
					className="c2c-promo-code-input"
					value={selectedVoucher?.code}
					name="c2c-promo-code"
					placeholder={t(
						C2cOverPaymentTextEnum.C2cPromo_PlaceHolderInput,
					)}
					onClick={showVoucherList}
					suffix={
						selectedVoucher?.code ? (
							<ButtonClick className={'c2c-promo-code-input-delete-btn'} variant="link" onClick={removeVoucher}>
								{t(
									C2cOverPaymentTextEnum.C2cPromoCode_BtnDeleteVoucher,
								)}
							</ButtonClick>
						) : (
							<></>
						)
					}
				/>
			</div>
			<div className={'mt-2'}>
				{selectedVoucher?.code &&
					voucherValidationResult &&
					voucherValidationResult.status && (
						<InputMessage
							message={t(voucherValidationResult.message || '')}
							type={
								voucherValidationResult.status === 'checking'
									? 'info'
									: voucherValidationResult.status
							}
						/>
					)}
			</div>
			{ (
				<PromotionModal
					visible={visiblePromotionModal}
					onClose={() => setVisiblePromotionModal(false)}
				/>
			)}
		</Section>
	);
};

export default C2cPromoCode;
