import { Radio } from '@swift-247/s247.library.ui-core';
import postOfficeMapIcon from 'Assets/icon/post-office-map-icon.svg';
import { ChoosePostOfficeTextEnum } from 'Pages/ChoosePostOffice/enums';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { c2cOrderPaymentSelectors } from 'Stores/c2c-order-payment';
import { TypeOrder } from 'Stores/c2c-order-payment/enums';
import { SelectPointModel } from 'Stores/c2c-order-payment/models';
import { getTimeLocal } from 'Utilities/util';
import './styles.scss';
interface PostOfficeItemProps {
  className?: string;
  onSelectedPostOffice: (item: SelectPointModel) => void;
  checked: boolean;
  item: SelectPointModel;
}

const PostOfficeItem = (props: PostOfficeItemProps) => {
  const { className = '', checked, item, onSelectedPostOffice } = props;
  const { t } = useTranslation();
  const { typeOrder } = useSelector(
    c2cOrderPaymentSelectors.get,
  );


  return (
    <div className={`${className} post-office-item`}>
      <div className="post-office-item-icon">
        <Radio className="mr-2"
						checked={checked}
						onChange={() => null}
						onClick={() => onSelectedPostOffice(item)}
					/>
        <img src={postOfficeMapIcon} alt={''} />
      </div>

      <div className="post-office-item-content">
        <h2 className="post-office-item-name">{item?.name}</h2>
        <div className="post-office-item-open-time">
          {t(ChoosePostOfficeTextEnum.CPO_PostOfficeItem_OpenTime)} :
          {getTimeLocal({
            time: item?.point?.workHourStart!,
            currentFormat: 'HH:mm',
            format: 'HH:mm',
          })}
          {item?.point?.workHourEnd
            ? ' - ' +
              getTimeLocal({
                time: item?.point?.workHourEnd!,
                currentFormat: 'HH:mm',
                format: 'HH:mm',
              })
            : ''}
        </div>
        <div className="post-office-item-address">
          {item?.address}
        </div>
        <div className="post-office-item-phone-wrap">
          <a className="post-office-item-phone">
          { item.point?.phone }
          </a>
        </div>
      </div>
    </div>
  );
};

export default PostOfficeItem;
