import { call, put, select, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import FlightServiceV1 from 'Services/v1/flights';
import { FlightsState, GetSuggestAirportPayload } from 'Stores/flights/models';
import higherOrderSaga from 'Stores/hos';
import { RootState } from 'Stores/index';
import flightsSlice from './index';

function* getSuggestedAirportsHandler(
  action: PayloadAction<GetSuggestAirportPayload>
) {
  const { provinceCode } = action.payload;

  const flightState = (yield select<(state: RootState) => FlightsState>(
    flightsSlice.selectors.get
  )) as FlightsState;

  const currentSuggestedList = flightState.suggestedAirports;

  if (currentSuggestedList[provinceCode]) return;

  const { data, error } = yield call(
    FlightServiceV1.getAirportProvinceMapping.bind(FlightServiceV1),
    provinceCode
  );

  if (error) {
    return console.error(error);
  }

  yield put(
    flightsSlice.actions.suggestedAirportsActions.save({
      provinceCode,
      data
    })
  );
}

function* suggestedAirportsSaga() {
  yield takeEvery(
    flightsSlice.actions.suggestedAirportsActions.get,
    getSuggestedAirportsHandler
  );
}

export default [
  higherOrderSaga.createFetchedSaga(
    flightsSlice.actions.departureAirportsActions,
    FlightServiceV1.getDepartureAirports.bind(FlightServiceV1)
  ),
  higherOrderSaga.createFetchedSaga(
    flightsSlice.actions.arrivalAirportsActions,
    FlightServiceV1.getArrivalAirports.bind(FlightServiceV1)
  ),
  higherOrderSaga.createFetchedSaga(
    flightsSlice.actions.availableFlightsActions,
    FlightServiceV1.getAvailableFlights.bind(FlightServiceV1)
  ),
  higherOrderSaga.createFetchedSaga(
    flightsSlice.actions.summaryDimensionWeightActions,
    FlightServiceV1.getSummaryDimensionWeight.bind(FlightServiceV1)
  ),
  suggestedAirportsSaga
];
