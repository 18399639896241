import TextArea from 'antd/lib/input/TextArea';
import React, { useRef } from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

interface Props {
	value?: any;
	name?: string;
	onChange?: () => void;
	className?: string;
	error?: boolean;
	helperText?: string;
	errorMsg?: string;
	onFocus?: () => void;
	onClick?: () => void;
	disabled?: boolean;
	label?: string;
	placeholder?: string;
	readonly?: boolean;
	status?: 'error' | 'warning' | '';
	maxLength?: number;
	rows?: number;
	cols?: number;
}
const TextAreaField = (props: Props) => {
	const {
		value,
		name,
		className,
		error,
		helperText,
		onChange,
		onFocus,
		onClick,
		disabled = false,
		errorMsg,
		label,
		placeholder,
		readonly,
		status,
		rows = 1,
		cols = 1,
		maxLength,
		...rest
	} = props;
	const inputRef = useRef(null);

	const containerClasses = classnames(
		{
			[`${classPrefix}-textarea-container`]: true,
			[`${classPrefix}-textarea-container--error`]: error,
			[`${classPrefix}-textarea-container--disabled`]: disabled,
			[`${classPrefix}-textarea-container--with-label`]: label,
		},
		className,
	);

	return (
		<>
			<div className={`${containerClasses}`}>
				<TextArea
					placeholder={placeholder || label}
					value={value}
					name={name}
					onFocus={onFocus}
					onClick={onClick}
					onChange={onChange}
					status={status}
					disabled={disabled}
					ref={inputRef}
					rows={rows}
					cols={cols}
					{...rest}
				/>
			</div>
		</>
	);
};

export default TextAreaField;
