import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Stores/index';
import {
  LayoutUtilitiesLoadingBarStatus,
  LayoutUtilitiesState,
  PopupCommonModel
} from 'Stores/layout-utilities/models';

const initialState: LayoutUtilitiesState = {
	loadingBar: 'init',
	loadingOverlay: false,
	currentLanguage: '',
	processingNavbar: false,
	currentPath: '',
	previousPath: '',
	popupCommon: {
		isOpenPopup: false,
	}
};

const LayoutUtilitiesSlice = createSlice({
	name: 'layoutUtilities',
	initialState,
	reducers: {
		updateLoadingBar: (
			state,
			action: PayloadAction<LayoutUtilitiesLoadingBarStatus>,
		) => {
			state.loadingBar = action.payload;
		},
		updateLoadingOverlay: (state, action: PayloadAction<boolean>) => {
			state.loadingOverlay = action.payload;
		},
		updateCurrentLanguage: (state, action: PayloadAction<string>) => {
			state.currentLanguage =
				action.payload === 'kr' ? 'ko' : action.payload;
		},
		// TODO: do we need ?
		updateProcessingNavbarAction: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.processingNavbar = action.payload;
		},
		updateRoutePathAction: (
			state,
			action: PayloadAction<string>,
		) => {
			state.previousPath = state.currentPath;
			state.currentPath = action.payload;
		},
		togglePopupAction: (
			state,
			action: PayloadAction<boolean>,
		) => {
			state.popupCommon.isOpenPopup = action.payload;
      if (!action.payload) {
        delete state.popupCommon.component;
      }
		},
		openPopupAction:  (
			state,
			action: PayloadAction<PopupCommonModel>,
		) => {
			state.popupCommon = action.payload;
		},
	},
});

export const {
	updateLoadingBar,
	updateLoadingOverlay,
	updateCurrentLanguage,
	updateProcessingNavbarAction,
	updateRoutePathAction,
	togglePopupAction,
	openPopupAction
} = LayoutUtilitiesSlice.actions;

export const layoutUtilitiesSliceSelectors = {
	get: (state: RootState) => state.layoutUtilities,
};

export default LayoutUtilitiesSlice.reducer;
