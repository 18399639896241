import React from 'react';
import Section from 'Components/Section';

import { useSelector } from 'react-redux';
import { PROFILE_TYPES } from 'Utilities/constants';
import { profileSliceSelectors } from 'Stores/profile';

import './index.scss'
import BasicProfile from 'Pages/Profile/components/BasicProfile';
import BusinessProfile from 'Pages/Profile/components/BusinessProfile';
import { useTranslation } from 'react-i18next';

const Profile = () => {
	const profile = useSelector(profileSliceSelectors.get).data;
	const {t} = useTranslation();

	return <Section title={t('profile_section_title')}>
		{profile.id ? (profile.type === PROFILE_TYPES.personal ? <BasicProfile data={profile} /> : <BusinessProfile data={profile}/>) : <></>}
	</Section>
}

export default Profile;