import Address from 'Assets/icon/address.svg';
import Flight from 'Assets/icon/flight.svg';
import History from 'Assets/icon/history.svg';
import Phone from 'Assets/icon/phone.svg';
import UserProfile from 'Assets/icon/user-profile.svg';
import Wallet from 'Assets/icon/wallet.svg';
import { SidebarTypeEnum } from 'Pages/common/Enums';
import { SideBarItemModel } from 'Pages/common/Models';

export const SIDEBAR_LIST: Array<SideBarItemModel> = [
  {
    text: 'profile_section_title',
    icon:  <img src={UserProfile} />,
    link: '/my-profile',
    id: SidebarTypeEnum.AccountInfo,
    subItems: [
      {
        text: 'menu_item_profile',
        link: '/my-profile',
        id: SidebarTypeEnum.Profile,
      },
      {
        text: 'menu_item_change_password',
        link: '/change-password',
        id: SidebarTypeEnum.ChangePassword,
      },
    ],
  },
  {
    text: 'menu_item_place_order',
    icon: <img src={Flight} />,
    link: '/',
    id: SidebarTypeEnum.Order,
  },
  {
    text: 'title_page_order_history',
    icon: <img src={History} />,
    link: '/my-orders',
    id: SidebarTypeEnum.OrderHistory,
  },
  {
    text: 'title_address_book',
    icon: <img src={Address} />,
    link: '/address',
    id: SidebarTypeEnum.AddressBook,
  },
  {
    text: 'topup_prepayment',
    icon: <img src={Wallet} />,
    link: '/top-up',
    id: SidebarTypeEnum.TopupInfo,
  },
  {
    text: 'menu_item_call_center',
    icon: <img src={Phone} />,
    link: '/',
    id: SidebarTypeEnum.CallCenter,
  },
];
