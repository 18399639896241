import { Button } from '@swift-247/s247.library.ui-core';
import { Card, Tooltip } from 'antd';
import iconPrintUrl from 'Assets/icon/print-icon.svg';
import images from 'Assets/images';
import CustomPopup from 'Components/CustomPopup';
import { useLoadingBar, useToast } from 'Components/LayoutUtilities';
import RenderPaymentMethod from 'Components/PaymentMethod';
import PrintWaybill from 'Components/PrintWaybill';
import moment from 'moment';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import CargoDirectServiceV1 from 'Services/v1/cargo-direct/';
import { DTOOrderDetail } from 'Services/v1/cargo-direct/dto';
import { ExternalSourceFetchingStatus } from 'Stores/models';
import { myOrdersSliceActions } from 'Stores/my-orders';
import {
  BOOKING_TYPE,
  DATE_FORMATS,

  TIME_CANCEL_ORDER_CARGO_DIRECT,
  TRANSPORT_RULE_GLOBAL,
  TypePayment,
  koreaProvinceCode,
  STATUS_ORDER,
} from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import {
  copyToClipboard,
  displayCurrency,
  formatTime,
  newDateWithFormat
} from 'Utilities/util';
import './index.scss';

enum CancelType {
  c2c,
  cargoDirect,
  global
}

enum ChargeType {
  refund,
  recharge
}

interface PaymentOrderProps {
  data: DTOOrderDetail;
  fetchingStatus: ExternalSourceFetchingStatus;
}

const PaymentOrder = (props: PaymentOrderProps) => {
  const { t } = useTranslation();
  const { startLoadingBar, stopLoadingBar, loadingBarState } = useLoadingBar();
  const [isGlobalOrderCancelPopupOpen, setIsGlobalOrderCancelPopupOpen] =
    useState(false);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const { fetchingStatus, data } = props;
  const componentRef = useRef(null);

  const {
    properties,
    orderId,
    createdAt,
    amount,
    discountAmount,
    extraPrices,
    id,
    uuid,
    beforeTime,
    paymentTime,
    cargoAllowCancel,
    platform,
    originProvinceCode,
    destinationProvinceCode,
    allowCancel,
    status,
    currency,
    base64QrCode,
    toCurrency,
    awb,
    transportRules,
    toPrice,
    syncData,
    paymentMethod
  } = props.data;

  const { airwayBillNumber } = awb || {};

  // Extra fee info
  const extraFeeDiffAmount = syncData?.feeUpdate.diffShippingFee || (Number(syncData?.properties.diffShippingFee) - Number(syncData?.properties.discountAmount));
  const afterReceivedExtraFeeChargeStatus = syncData?.histories.find(
    item => item.status === 'received'
  );
  const haveExtraFeeButNotReceived =
    extraFeeDiffAmount && !afterReceivedExtraFeeChargeStatus;
  const afterReceivedTotalFee = haveExtraFeeButNotReceived
    ? Number(amount) + Number(extraFeeDiffAmount)
    : syncData?.feeUpdate.totalValue;
  const afterReceivedExtraFeeChargeType =
    afterReceivedTotalFee && afterReceivedTotalFee > amount
      ? ChargeType.recharge
      : ChargeType.refund;

  // Duty fee info
  const originalDutyFee = Number(properties?.extractPrice?.dutyFee);
  const afterReceivedDutyFee = Number(syncData?.feeUpdate.customDutyFee);
  const afterReceivedDutyDiffAmount = syncData?.feeUpdate.extraCustomDutyFee;
  const afterReceivedDutyFeeChargeType =
    afterReceivedDutyFee && afterReceivedDutyFee > originalDutyFee
      ? ChargeType.recharge
      : ChargeType.refund;

  // After security check info
  const afterSecTotalFee =
    syncData?.securityCheckInformation?.afterSecurityCheck?.price;
  const afterSecDiffAmount = syncData?.securityCheckInformation?.chargeAmount;
  const afterSecRefundStatus =
    platform === BOOKING_TYPE.CargoDirect
      ? !!syncData?.isRefundAcceptedSuccess
      : false;
  const noShowFee = syncData?.securityCheckInformation?.afterSecurityCheck?.noShowFee || syncData?.properties.noShowFee;

  const finalTotalValue =
    platform === BOOKING_TYPE.CargoDirect
      ? afterSecTotalFee || afterReceivedTotalFee || amount
      : afterReceivedTotalFee || amount;

  const departureTimeDateInstance = useMemo(() => {
    return newDateWithFormat(beforeTime, DATE_FORMATS.ISO_CUSTOM);
  }, [beforeTime]);

  const renderServiceType = () => {
    switch (properties?.deliveryOms) {
      case 'premium':
        return t('premium_delivery');
      case 'super':
        return t('super_delivery');
      case 'cargo':
        return t('cargo_delivery');
      case 'korea':
        return t('delivery_from_korea');
      default:
        return t('standard_delivery');
    }
  };

  const deliveryPrice = () => {
    let totalExtra = 0;
    // Calculate total extra price
    extraPrices &&
      extraPrices.map(
        (parcelItem: any) => (totalExtra += parseInt(parcelItem?.price))
      );

    let totalPrice = amount;
    if (properties && properties.extractPrice) {
      if (properties.extractPrice.feeVunSurcharge) {
        totalPrice = totalPrice - properties.extractPrice.feeVunSurcharge;
      }
      if (properties.extractPrice.dutyFee) {
        totalPrice = totalPrice - Number(properties.extractPrice.dutyFee);
      }
    }
    if (discountAmount) {
      totalPrice = totalPrice + Number(discountAmount);
    }
    return displayCurrency(totalPrice - totalExtra, currency);
  };

  //TODO: below code is korea specific need to be refactored later
  const cancelType = useMemo(() => {
    if (
      platform === BOOKING_TYPE.C2C &&
      transportRules?.some(x =>
        TRANSPORT_RULE_GLOBAL.some(o => x.rule?.includes(o))
      )
    ) {
      return CancelType.global;
    }
    if (platform === BOOKING_TYPE.C2C) {
      return CancelType.c2c;
    }
    if (platform === BOOKING_TYPE.CargoDirect) {
      return CancelType.cargoDirect;
    }
    return CancelType.c2c;
  }, [platform, originProvinceCode]);

  const isCancellable = useMemo(() => {
    if (cancelType === CancelType.global) return true;
    if (cancelType === CancelType.cargoDirect) return cargoAllowCancel;
    if (cancelType === CancelType.c2c)
      return status === STATUS_ORDER.IN_PROGRESS && allowCancel;
  }, [cancelType, status, allowCancel]);

  const cancelOrder = useCallback(async () => {
    switch (cancelType) {
      case CancelType.c2c:
      case CancelType.cargoDirect: {
        startLoadingBar();
        const response = await CargoDirectServiceV1.cancelOrder({
          id,
          reason: 'Package was cancelled by Customer' //TODO: default hardcoded
        });
        if (response.data?.isSuccess) {
          toast('success', t('message_cancel_success'));
          dispatch(
            myOrdersSliceActions.orderDetailAction.getting({
              id: uuid
            })
          );
        } else {
          toast('error', t('global_error_general'));
        }
        stopLoadingBar();
        break;
      }
      case CancelType.global: {
        setIsGlobalOrderCancelPopupOpen(true);
      }
    }
  }, [orderId]);

  const onCancelConfirm = () => {
    setIsCancelPopupOpen(true);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <Card
      className={`${classPrefix}-payment-card-container mb-4`}
      title={null}
      bordered={false}
    >
      <div className="ps-6 pe-6 pt-6">
        <div className="d-flex justify-content-between mb-3">
          <span className="fw-500">{t('global_order_code')}</span>
          <div>
            <span className="fw-500">
              <span className="mr-2">{`#${orderId}`}</span>
              <Tooltip
                title={t('global_copy')}
                overlayInnerStyle={{ borderRadius: '5px' }}
              >
                <img
                  width={18}
                  className="cursor-pointer"
                  onClick={() => copyToClipboard(orderId)}
                  src={images.icCopy}
                />
              </Tooltip>
            </span>
            {platform === BOOKING_TYPE.C2C &&
              transportRules?.every(x =>
                TRANSPORT_RULE_GLOBAL.every(o => !x.rule?.includes(o))
              ) && (
                <Tooltip
                  title={t('waybill_tooltip_print')}
                  overlayInnerStyle={{ borderRadius: '5px' }}
                >
                  <img
                    width={18}
                    onClick={handlePrint}
                    className="print ml-2"
                    src={iconPrintUrl}
                  />
                </Tooltip>
              )}
          </div>
        </div>
        <div className="d-flex justify-content-end mb-3">
          <img
            width="100"
            height="90"
            src={`data:image/png;base64, ${base64QrCode}`}
          />
        </div>
        <div className="d-flex justify-content-between mb-3">
          <span className="text-grey-light">
            {t('order_detail_booking_date')}
          </span>
          <span className="text-grey-light">
            {moment(createdAt).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <span className="text-grey-light">
            {t('order_detail_payment_status_paid')}
          </span>
          <span className="text-grey-light">
            {paymentTime
              ? moment(paymentTime).format('DD/MM/YYYY HH:mm')
              : null}
          </span>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <span className="text-grey-light">{t('global_payment_method')}</span>
          <div>
            <RenderPaymentMethod data={props.data} />
          </div>
        </div>
        <div className="d-flex justify-content-between mb-3">
          <span className="text-grey-light">{t('global_service_type')}</span>
          <span className="text-grey-light">{renderServiceType()}</span>
        </div>
        {platform === BOOKING_TYPE.CargoDirect && (
          <div className="d-flex justify-content-between mb-3">
            <span className="text-grey-light">{t('global_awb_number')}</span>
            <span className="fw-bold">
              {airwayBillNumber || t('global_awb_updating')}
            </span>
          </div>
        )}
      </div>

      <div className="total-payment">
        <div className={'p-6'}>
          <div className="d-flex justify-content-between mb-3">
            <span className="text-grey-light">
              {t(`order_extra_fee_shipping_fee`)}
            </span>
            <span className="text-grey-light">{deliveryPrice()}</span>
          </div>
          {!!originalDutyFee && (
            <div className="d-flex justify-content-between mb-3">
              <span className="text-grey-light">
                {t(`global_order_duty_fee`)}
              </span>
              <span className="text-grey-light">
                {displayCurrency(originalDutyFee, currency)}
              </span>
            </div>
          )}

          {properties && properties.extractPrice && (
            <div className="d-flex justify-content-between mb-3">
              <span className="text-grey-light">
                {t(`order_extra_fee_price_vun`)}
              </span>
              <span className="text-grey-light">
                {displayCurrency(
                  properties.extractPrice.feeVunSurcharge,
                  currency
                )}
              </span>
            </div>
          )}
          {extraPrices &&
            extraPrices.map((item, k) => (
              <div className="d-flex justify-content-between mb-3" key={k}>
                <span className="text-grey-light">
                  {t(`order_extra_fee_${item.name}`)}
                </span>
                <span className="text-grey-light">
                  {displayCurrency(item.price, currency)}
                </span>
              </div>
            ))}
          <div className="d-flex justify-content-between mb-3">
            <span className="text-grey-light">{t('global_discount')}</span>
            <span style={{ color: '#EE5B33' }}>
              {`${discountAmount ? '-' : ''} ${displayCurrency(
                discountAmount,
                currency
              )}`}
            </span>
          </div>
          {!!syncData && (
            <>
              {afterReceivedDutyDiffAmount && (
                <div className="d-flex justify-content-between mb-3">
                  <span className="text-grey-light">
                    {t('order_detail_extra_duty_fee')}
                  </span>
                  <span className="text-grey-light">
                    {afterReceivedDutyFeeChargeType === ChargeType.refund
                      ? '-'
                      : '+'}
                    {displayCurrency(
                      Math.abs(afterReceivedDutyDiffAmount || 0),
                      currency
                    )}
                  </span>
                </div>
              )}
              <div className="d-flex justify-content-between mb-3">
                <span className="text-grey-light">
                  {t('order_detail_received_refund_recharge')}
                </span>
                <span className="text-grey-light">
                  {afterReceivedExtraFeeChargeType === ChargeType.refund
                    ? '-'
                    : '+'}
                  {displayCurrency(
                    Math.abs(extraFeeDiffAmount || 0),
                    currency
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span className="text-grey-light">
                  {t('order_detail_received_refund_recharge_status')}
                </span>
                <span className="text-grey-light">
                  {afterReceivedExtraFeeChargeStatus
                    ? t('order_detail_received_refund_recharge_status_success')
                    : t('order_detail_received_refund_recharge_status_waiting')}
                </span>
              </div>
            </>
          )}

          {platform === BOOKING_TYPE.CargoDirect &&
            !!syncData &&
            !!afterSecDiffAmount && (
              <>
                <div className="d-flex justify-content-between mb-3">
                  <span className="text-grey-light">
                    {t('order_detail_after_sec_refund')}
                  </span>
                  <span className="text-grey-light">
                    -{displayCurrency(afterSecDiffAmount, currency)}
                  </span>
                </div>
                <div className="d-flex justify-content-between mb-3">
                  <span className="text-grey-light">
                    {t('order_detail_after_sec_refund_status')}
                  </span>
                  <span className="text-grey-light">
                    {afterSecRefundStatus
                      ? t(
                          'order_detail_received_refund_recharge_status_success'
                        )
                      : t(
                          'order_detail_received_refund_recharge_status_waiting'
                        )}
                  </span>
                </div>
              </>
            )}

          <div className="d-flex justify-content-between mb-3">
            <span className="text-grey-light">
              {t('order_detail_paid_total')}
            </span>
            <span className="text-grey-light">
              {displayCurrency(amount, currency)}
            </span>
          </div>


          {noShowFee !== undefined && noShowFee > 0 && <div className='d-flex justify-content-between mb-3'>
            <span className='text-grey-light'>{t('order_detail_no_show_fee')}</span>
            <span className='text-grey-light'>
              {displayCurrency(noShowFee, currency)}
            </span>
          </div>}

          {!!(toCurrency && toPrice) && (
            <div className="d-flex justify-content-between mb-3">
              <span className="text-grey-light">{t('global_total_vnd')}</span>
              <span>{displayCurrency(toPrice, toCurrency)}</span>
            </div>
          )}
          <div className="d-flex justify-content-between fw-bold">
            <span className="text-grey-light">{t('global_total')}</span>
            <span className="text-total">
              {displayCurrency(finalTotalValue, currency)}
            </span>
          </div>
        </div>
        {status !== STATUS_ORDER.CANCELLED && status !== STATUS_ORDER.DONE && (
          <div
            className={
              'p-6 total-payment-action d-flex flex-column align-items-end'
            }
          >
            {cancelType === CancelType.cargoDirect && (
              <div className={'fs-p-md fw-bold mb-2'}>
                {t('order_detail_cancel_policy', {
                  time: TIME_CANCEL_ORDER_CARGO_DIRECT
                })}{' '}
                ({formatTime(departureTimeDateInstance)})
              </div>
            )}
            <Button
              onClick={() => {
                if (cancelType === CancelType.global) return cancelOrder();
                onCancelConfirm();
              }}
              disabled={
                !isCancellable ||
                loadingBarState === 'loading' ||
                fetchingStatus === 'pending'
              }
              size={'md'}
            >
              {t('global_cancel')}
            </Button>
          </div>
        )}
      </div>
      <CustomPopup
        onClose={() => {
          setIsGlobalOrderCancelPopupOpen(false);
        }}
        title={t('global_popup_inform_title')}
        isOpen={isGlobalOrderCancelPopupOpen}
      >
        <div
          className={`${classPrefix}-payment-card-popup-content py-6 d-flex flex-column`}
        >
          <div className={'fs-p-lg'}>{t('korea_order_cancel_message')}</div>
          <Button
            onClick={() => setIsGlobalOrderCancelPopupOpen(false)}
            className={'mt-6 w-fit align-self-end'}
          >
            {t('global_got_it')}
          </Button>
        </div>
      </CustomPopup>
      <CustomPopup
        onClose={() => setIsCancelPopupOpen(false)}
        title={t('popup_cancel_confirm_title')}
        isOpen={isCancelPopupOpen}
      >
        <div className={'py-6 d-flex flex-column'}>
          <div className={'mb-3 fs-p-lg'}>
            {t('popup_cancel_confirm_message')}
          </div>
          <div className={'d-flex align-items-center align-self-end'}>
            <Button
              onClick={() => setIsCancelPopupOpen(false)}
              className={'mr-4'}
              variant={'text'}
            >
              {t('buttons_cancel')}
            </Button>
            <Button
              onClick={() => {
                setIsCancelPopupOpen(false);
                return cancelOrder();
              }}
              className={'btn-min-width'}
            >
              {t('buttons_ok')}
            </Button>
          </div>
        </div>
      </CustomPopup>
      <div style={{ display: 'none' }}>
        <PrintWaybill ref={componentRef} orderDetail={props.data} />
      </div>
    </Card>
  );
};

export default PaymentOrder;
