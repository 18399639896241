import { Empty, Select } from 'antd';
import images from 'Assets/images';
import { t } from 'i18next';
import { debounce } from 'lodash';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { TIMER_SEARCH } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import './index.scss';
import classnames from 'Utilities/classnames';
import { compareOnUISearchTexts } from 'Utilities/util';

const { Option } = Select;

interface Props {
  options: any[];
  customIconRightOption?: (item: any) => React.ReactNode;
  placeholder?: string;
  selectedValue: any | null;
  disabled?: boolean;
  onChange?: (value: string, options: any) => void;
  onScrollBottom?: (e: any) => void;
  className?: string;
  dropdownClassName?: string;
  labelField?: string;
  valueField?: string;
  onSelect?: (value: any, e: any) => void;
  getLabel?: (value: any) => string | ReactElement;
  isSearchServer?: boolean;
  onSearch?: (text: string) => void;
  isShowCheck?: boolean;
  open?: boolean;
  onDropdownVisibleChange?: ((open: boolean) => void) | undefined;
  isOriginal?: boolean;
  defaultValue?: string
}

const SelectBox = (props: Props) => {
  const {
    options,
    customIconRightOption,
    placeholder,
    selectedValue,
    onChange,
    onScrollBottom,
    className,
    dropdownClassName,
    labelField = 'label',
    valueField = 'value',
    onSelect,
    getLabel,
    isSearchServer,
    onSearch,
    isShowCheck = true,
    open = undefined,
    onDropdownVisibleChange,
    isOriginal = false,
    defaultValue,
    ...rest
  } = props;

  const handleScrollBottom = (e: any) => {
    const { target } = e;
    if (
      onScrollBottom &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      onScrollBottom(e);
    }
  };

  const handleSearch = (text: string) => changeTextDebounce(text);
  const fetchValue = (text = '') => {
    if (isSearchServer && onSearch) onSearch(text);
  };
  const [value, setValue] = useState('');
  useEffect(() => {
    setValue(selectedValue || null);
  }, [selectedValue]);

  const changeTextDebounce = useCallback(
    debounce(
      (text: string) => fetchValue(text),
      isSearchServer ? TIMER_SEARCH : 0
    ),
    []
  );

  const containerClasses = classnames(
    {
      [`${classPrefix}-select-box-container--original`]: !isOriginal
    },
    `${classPrefix}-select-box-container`,
    className
  );

  const dropdownClasses = classnames(
    `${classPrefix}-select-box-dropdown`,
    dropdownClassName
  );

  const renderOption = (option: any) => {
    return (
      <Option
        data={option}
        value={option[valueField]}
        label={getLabel ? getLabel(option) : option[labelField]}
        title={getLabel ? getLabel(option) : option[labelField]}
        key={option[valueField]}
      >
        <div className="d-flex align-items-center justify-content-between gap-2">
          <span className="custom-label">
            {getLabel ? getLabel(option) : option[labelField]}
          </span>
          <span>
            {isShowCheck && option[valueField] == selectedValue && (
              <span
                role="img"
                aria-label="check"
                className="anticon anticon-check"
              >
                <img src={images.icChecked} />
              </span>
            )}

            {customIconRightOption && (
              <span className="ml-2">{customIconRightOption(option)}</span>
            )}
          </span>
        </div>
      </Option>
    );
  };
  return (
    <>
      <div className={containerClasses}>
        <Select
          open={open}
          onDropdownVisibleChange={onDropdownVisibleChange}
          autoClearSearchValue={false}
          showSearch={true}
          placeholder={placeholder}
          onChange={(value: string, option: any) => {
            if (onChange) onChange(value, option);
          }}
          onSelect={(value: any, option: any) =>
            onSelect && onSelect(value, option.data)
          }
          defaultValue={defaultValue}
          value={selectedValue}
          optionLabelProp="label"
          onSearch={handleSearch}
          onPopupScroll={handleScrollBottom}
          dropdownClassName={dropdownClasses}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<>{t('global-no-data')}</>}
            />
          }
          filterOption={(inputValue, option) => {
            if (isSearchServer) return true;
            return compareOnUISearchTexts(inputValue, option?.data[labelField]);
          }}
          getPopupContainer={() =>
            document.getElementById('root') as HTMLDivElement
          } // fix weird scroll dropdown
          {...rest}
        >
          {options && options.map(option => renderOption(option))}
        </Select>
      </div>
    </>
  );
};

export default SelectBox;
