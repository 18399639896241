import { DatePicker, Space } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'Utilities/classnames';
import { DATE_FORMATS } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import './index.scss';

export interface RageDatePicker {
  className?: string;
  value?: string[];
  showTime?: boolean;
  isRangeDayAllow?: boolean;
  rangeDayAllow?: number;
  rangesDate?: number[];
  onChangeDate?: (date: string[]) => void;
}

const RangeDate = (props: RageDatePicker) => {
  const {
    className,
    value,
    showTime = false,
    isRangeDayAllow = true,
    rangeDayAllow = 30,
    rangesDate = [5, 7, 15, 30],
    onChangeDate
  } = props;

  const { t } = useTranslation();
  const [currentDates, setCurrentDates] = useState(value);
  const { RangePicker } = DatePicker;

  useEffect(() => {
    const max = Math.max.apply(Math, rangesDate);
    if (isRangeDayAllow && max > rangeDayAllow) {
      throw new Error('Range date must be greater than range day allow');
    }
  }, [rangesDate]);

  const setChooseDate = (dateFrom: string, dateTo: string): void => {
    const dayDiff = moment(dateTo, dateFormat).diff(
      moment(dateFrom, dateFormat),
      'days'
    );
    const elementsRangeDate = document.querySelectorAll(
      '.range-calendar .ant-picker-ranges .ant-picker-preset .ant-tag'
    );
    const text = t('global_range_date', { day: dayDiff + 1 });

    elementsRangeDate?.forEach(element => {
      if (element?.textContent === text) {
        element?.classList.add('choose-range');
      } else {
        element?.classList.remove('choose-range');
      }
    });
  };

  const generateRangesDate = () => {
    let rangeDate: {
      [key: string]: [Moment, Moment];
    } = {};

    rangesDate.forEach(date => {
      rangeDate[`${t('global_range_date', { day: date })}`] = [
        moment(),
        moment().add(date - 1, 'days')
      ];
    });
    return rangeDate;
  };

  const dateFormat = showTime
    ? DATE_FORMATS.DDMMYYYYHHmm
    : DATE_FORMATS.DDMMYYYY;

  const containerClasses = classnames(
    `${classPrefix}-range-date d-flex align-items-center`,
    className
  );

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    let dateFrom = '';
    let dateTo = '';
    if (dateStrings) {
      dateFrom = dateStrings[0];
      dateTo = dateStrings[1];
    }
    setChooseDate(dateFrom, dateTo);
    setCurrentDates([dateFrom, dateTo]);

    if (onChangeDate) onChangeDate([dateFrom, dateTo]);
  };

  const onOpenChange = (open: boolean) => {
    if (!open) return;
    setChooseDate(currentDates![0], currentDates![1]);
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return (
      isRangeDayAllow &&
      current &&
      (current < moment().startOf('day') ||
        current >= moment().add(rangeDayAllow, 'days').startOf('day'))
    );
  };

  return (
    <div className={containerClasses}>
      <Space direction="vertical" size={12}>
        <RangePicker
          allowClear={false}
          value={[
            moment(currentDates ? currentDates[0] : null, dateFormat),
            moment(currentDates ? currentDates[1] : null, dateFormat)
          ]}
          format={dateFormat}
          dropdownClassName="range-calendar"
          disabledDate={disabledDate}
          onChange={onChange}
          ranges={generateRangesDate()}
          onOpenChange={onOpenChange}
        />
      </Space>
    </div>
  );
};

export default RangeDate;
