export enum MainPageEnum {
	Swift247 = 'c2c-order-payment',
	International = 'order-international',
	CargoDirect = 'order-bill',
	C2cOrderPayment = 'c2c-order-payment',
	GoodsInfo = 'goods-info',
	GoodsInfoEdit = 'goods-info/:id',
	OrderBill = 'order-bill',
	Address = 'address',
	MyOrders = 'my-orders',
	OrderDetail = ':id', //--Children order detail
	MyOrdersListing = '', //--Children Order Detail
	MyProfile = 'my-profile',
	ChangePassword = 'change-password',
	ChoosePostOffice = 'choose-post-office',
	PromotionVoucher = 'promotion-voucher',
  TopUp = 'top-up'
}
