import choosePostOfficeIcon from 'Assets/icon/choose-post-office-icon.svg';
import Section from 'Components/Section';
import { ChoosePostOfficeTextEnum } from 'Pages/ChoosePostOffice/enums';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SelectPointModel } from 'Services/v1/c2c-over-payment/models';
import {
  c2cOrderPaymentSelectors,
  selectReceiverPointAction,
  selectSenderPointAction
} from 'Stores/c2c-order-payment';
import { PostOfficeItem } from '..';
import './styles.scss';

const PostOfficeList = (props: { list: SelectPointModel[]; type: string }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { selectReceiverPoint, selectSenderPoint } = useSelector(
		c2cOrderPaymentSelectors.get,
	);

	const checkChecked = (item: SelectPointModel) => {
		if (props.type === 'sender') {
			return selectSenderPoint.id === item.id;
		} else if (props.type === 'receiver') {
			return selectReceiverPoint.id === item.id;
		}
		return false;
	};
	const renderPostOffice = () => {
		return props.list?.map((item, i) => {
			return (
				<PostOfficeItem
					key={i}
					onSelectedPostOffice={(e: SelectPointModel) => {
						if (props.type === 'sender') {
							dispatch(selectSenderPointAction(e));
						} else if (props.type === 'receiver') {
							dispatch(selectReceiverPointAction(e));
						}
						navigate('/c2c-order-payment');
					}}
					checked={checkChecked(item)}
					item={item}
				></PostOfficeItem>
			);
		});
	};
	return (
		<Section
			title={t(ChoosePostOfficeTextEnum.CPO_PostOfficeList_Title)}
			icon={<img src={choosePostOfficeIcon} alt={''} />}
			className="post-office-list"
		>
			{props?.list && renderPostOffice()}
		</Section>
	);
};

export default PostOfficeList;
