import React from 'react';
import iconErrorValidationUrl from 'Assets/icon/validation-error-icon.svg';
import classnames from 'Utilities/classnames';

export interface InputErrorMessageProps {
	message: string,
	className?: string
}

const InputErrorMessage = (props: InputErrorMessageProps) => {
	const {
		message,
		className
	} = props;

	const containerClasses = classnames('d-flex align-items-center fs-p-md lh-p-md', className)

	return <div className={containerClasses}>
		<img className={'mr-2'} src={iconErrorValidationUrl} alt={''}/>
		<span className={'text-error'}>{message}</span>
	</div>
}

export const RequiredErrorInput = (props: {fieldName: string, isShow: boolean}) => {
	const {
		fieldName,
		isShow
	} = props;
	if (!isShow) return <></>
	return <InputErrorMessage className={'mt-2 fs-p-sm lh-p-sm'} message={`Vui lòng nhập ${fieldName}`} />
}

export default InputErrorMessage;