import { InputNumber } from 'antd';
import { UnitType } from 'Components/Enums';
import { FieldUnitModel } from 'Components/Models';
import './styles.scss';
import { useEffect, useState } from 'react';

interface FieldUnitProps {
	item: FieldUnitModel;
	className?: string;
	value?: number;
	placeholder?: string;
	min?: number;
	max?: number;
	onChange?: (value: number) => void;
	onKeyUp?: (value: number) => void;
	onBlurChange?: (value: any) => void;
}

const FieldUnit = (props: FieldUnitProps) => {
	const {
		item,
		className,
		value,
		placeholder = '0.0',
    min,
    max,
		onChange ,
		onBlurChange,
	} = props;

	const [_value, setValue] = useState(value); // self manage value
	useEffect(() => {
		setValue(value);
	}, [value])
	const _onChange = (value: number) => {
		setValue(value);
		onChange && onChange(value);
	}

	return (
		<div className={`${className} field-unit`}>
			{item.type === UnitType.Input && (
        <InputNumber
					onChange={_onChange}
					onBlur={() => {
						onBlurChange && onBlurChange(_value)
					}}
					min={min}
					max={max}
					value={_value}
					step={0.1}
					placeholder={placeholder}
				/>
			)}
			{item.type === UnitType.Text && (
				<span className="field-unit-value">{_value}</span>
			)}

			<span className="field-unit-unit">{item?.unit}</span>
		</div>
	);
};

export default FieldUnit;
