import higherOrderReducers from 'Stores/hors';
import { RootState } from 'Stores/index';
import { DTOOrder, DTOOrderDetail } from 'Services/v1/cargo-direct/dto';
import { defaultGeneralInitialState, defaultListInitialState } from 'Stores/hors/fetchedSlice';
import { GeneralDataState, ListItemsState } from 'Stores/models';
import { combineReducers } from 'redux';

const myOrdersSlice = higherOrderReducers.createFetchedSlice<ListItemsState<any>, DTOOrder, Record<string, never>>('order', defaultListInitialState);

const myOrderDetailSlice = higherOrderReducers.createFetchedSlice<GeneralDataState<any>, DTOOrderDetail, Record<string, never>>('orderDetail', defaultGeneralInitialState);

export const myOrdersSliceActions = {
	ordersAction: myOrdersSlice.actions,
	orderDetailAction: myOrderDetailSlice.actions,
}

export default combineReducers({
	orderReducer: myOrdersSlice.reducer,
	orderDetailReducer: myOrderDetailSlice.reducer
});

export const myOrdersSelectors = {
	get: (rootState: RootState) => rootState.myOrders,
}