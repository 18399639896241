import { useTranslation } from 'react-i18next';
import { useLoadingBar, useToast } from 'Components/LayoutUtilities';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getLocations, getSelectedLocation, locationSelectors } from 'Stores/locations';
import { CheckBoxItem } from 'Components/CheckBox';
import React, { useEffect } from 'react';
import moment from 'moment';
import { VIETNAM_COUNTRY_CODE } from 'Utilities/constants';
import UserServiceV1 from 'Services/v1/user';
import FieldLabel from 'Components/FieldLabel';
import InputText from 'Components/InputText';
import RadioButton from 'Components/RadioButton';
import DatePicker from 'Components/DatePicker';
import SelectBox from 'Components/SelectBox';
import ButtonClick from 'Components/ButtonClick';
import { DTOUserProfile } from 'Services/v1/user/dto';
import './index.scss';

interface BasicProfileProps {
	data: DTOUserProfile
}

const BasicProfile = (props: BasicProfileProps) => {
	const {t} = useTranslation();

	const {startLoadingBar, stopLoadingBar, loadingBarState} = useLoadingBar();
	const toast = useToast();

	const profile = props.data;

	const {
		control,
		watch,
		formState: { errors, dirtyFields },
		setValue,
		getValues,
		resetField,
		handleSubmit,

	} = useForm();

	const dispatch = useDispatch();

	const watchCountry = watch('country');
	const watchProvince = watch('province');
	const watchDistrict = watch('district');
	const watchVillage = watch('village');
	const watchAddress = watch('address');

	const cityData = useSelector(locationSelectors.get).city.data;
	const districtData = useSelector(locationSelectors.get).district.data;
	const wardData = useSelector(locationSelectors.get).ward.data;
	// const wardListData = useSelector(locationSelectors.get).ward.list;

	const titleOptions: CheckBoxItem[] = [
		{ label: t('global_title_male'), value: '1' },
		{ label: t('global_title_female'), value: '0' },
	];

	useEffect(() => {
		if(!profile) return;
		const birth = moment(profile.birth);

		setValue('phone', profile.phone)
		setValue('name', profile.name)
		setValue('idenNumber', profile.idenNumber)
		setValue('email', profile.email)
		setValue('country', profile.country)
		setValue('birth', birth.isValid() ? birth : undefined)
		setValue('gender', profile.gender)
		setValue('province', profile.province)
		setValue('district', profile.district)
		setValue('village', profile.village)
		setValue('address', profile.address)
	}, [profile])

	useEffect(() => {
		dispatch(getLocations({ level: 'city', id: VIETNAM_COUNTRY_CODE }));
	}, [watchCountry]);

	useEffect(() => {
		if (watchProvince) {
			dispatch(getLocations({ level: 'district', id: watchProvince }));
		}
	}, [watchProvince]);

	useEffect(() => {
		if (watchDistrict) {
			dispatch(getLocations({ level: 'ward', id: watchDistrict }));
		}
	}, [watchDistrict]);

	useEffect(() => {
		if (watchVillage) {
			dispatch(getSelectedLocation({ level: 'ward', id: watchVillage }));
		}
	}, [watchVillage]);

	useEffect(() => {
		dispatch(getLocations({ level: 'country', id: '' }));
		// mapDataToForm();
	}, []);

	const onSubmit = async (values: any) => {
		const payload = {...values};
		if(payload.birth) {
			payload.birth = payload.birth.format('YYYY/MM/DD')
		}
		startLoadingBar();
		const resp = await UserServiceV1.updateProfile(payload);
		const {error} = resp;
		if(!error) {
			toast('success', t('message_update_profile_successfully'));
		} else {
			toast('error', t('message_update_profile_failed'));
		}
		stopLoadingBar();
	}

	return <form className={'basic-profile-form d-flex m-auto flex-column'} onSubmit={handleSubmit(onSubmit)}>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_phone')}/>
			<Controller
				control={control}
				render={({field}) => {
					return <InputText
						{...field}
						disabled={true}
						placeholder={t('label_phone')}
					/>
				}}
				name={'phone'}
			/>
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_full_name')}/>
			<Controller
				control={control}
				render={({field}) => {
					return <InputText
						{...field}
						placeholder={t('label_full_name')}
					/>
				}}
				name={'name'}
			/>
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_email')}/>
			<Controller
				control={control}
				render={({field}) => {
					return <InputText
						{...field}
						placeholder={t('label_email')}
					/>
				}}
				name={'email'}
			/>
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_identity')}/>
			<Controller
				control={control}
				render={({field}) => {
					return <InputText
						{...field}
						placeholder={t('label_identity')}
					/>
				}}
				name={'idenNumber'}
			/>
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_title')}/>
			<Controller
				control={control}
				name={'gender'}
				render={({field}) => {
					return <RadioButton
						{...field}
						options={titleOptions}
						radioGroupClassName="w-100 d-flex"
					/>
				}}
			/>
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_date_of_birth')}/>
			<Controller
				control={control}
				render={({field}) => {
					const {onChange, value} = field;
					return <DatePicker
						onChange={onChange}
						format={'DD/MM/YYYY'}
						value={value}
						placeholder={t('label_date_of_birth')}
					/>
				}}
				name={'birth'}
			/>
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_province')}/>
			<Controller
				render={({ field: { value, ref, ...rest } }) => (
					<SelectBox
						{...rest}
						options={cityData || []}
						selectedValue={value}
						placeholder={t('label_province')}
					/>
				)}
				control={control}
				name="province"
			/>
			{/*<RequiredErrorInput fieldName={t('label_province')} isShow={true}/>*/}
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_district')}/>
			<Controller
				render={({ field: { value, ...rest } }) => (
					<SelectBox
						{...rest}
						options={districtData || []}
						selectedValue={value}
						placeholder={t('label_district')}
					/>
				)}
				control={control}
				name="district"
			/>
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_ward')}/>
			<Controller
				render={({ field: { value, name, ...rest } }) => (
					<SelectBox
						{...rest}
						options={wardData || []}
						selectedValue={value}
						placeholder={t('label_ward')}
					/>
				)}
				control={control}
				name="village"
			/>
		</div>
		<div className={'mb-6'}>
			<FieldLabel className={'mb-2'} value={t('label_address')}/>
			<Controller
				render={({ field }) => (
					<InputText {...field} placeholder={t('label_address')} />
				)}
				control={control}
				name="address"
			/>
		</div>
		<ButtonClick
			variant="primary"
			htmlType="submit"
			containerClassName={'text-right'}
			disabled={loadingBarState === 'loading' || Object.keys(dirtyFields).length === 0}
			className={'basic-profile-form-save-button'}
		>
			{t('global_save')}
		</ButtonClick>
	</form>
}

export default BasicProfile;
