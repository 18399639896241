import { DatePicker as AntdDatePicker, DatePickerProps } from 'antd';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';
interface IDatePicker {
  isBackground: boolean;
}

const DatePicker = (props: DatePickerProps & Partial<IDatePicker>) => {
  const { isBackground = true } = props;
  const containerClasses = classnames(
    `${classPrefix}-date-picker`,
    props.className,
    isBackground && 'has-background'
  );
  return (
    <AntdDatePicker
      {...props}
      className={containerClasses}
      dropdownClassName={`${classPrefix}-date-picker-dropdown`}
    />
  );
};

export default DatePicker;
