import { FeeRecord } from 'Components/DeliveryMethod';
import { useTranslation } from 'react-i18next';
import { ExtraServiceModel } from 'Stores/c2c-order-payment';
import './styles.scss';

interface PropsModel {
	className?: string;
	priceDelivery: string | number;
	supperFee?: string | number;
	extraServiceSelected: Array<ExtraServiceModel>;
	totalPrice: string | number;
	dutyFee?: string | number;
	discount?: string | number;
	vunFee?: string | number;
	currency?: string;
	priceVn?: { toCurrency?: string; toPrice?: number; isShow: boolean };
}

const FeeRecordList = (props: PropsModel) => {
	const { t } = useTranslation();
	const {
		className,
		priceDelivery,
		supperFee,
		extraServiceSelected,
		totalPrice,
		dutyFee,
		discount,
		vunFee,
		currency,
		priceVn,
	} = props;

	const renderListExtra = (items: Array<ExtraServiceModel>) => {
		if (items?.length < 0) {
			return <></>;
		}
		return items?.map((item, index) => {
			return (
				<FeeRecord
					key={index}
					className={'mb-2 py-1'}
					label={t(item?.name || '')}
					currency={currency}
					value={item?.price}
				/>
			);
		});
	};

	return priceDelivery !== 0 ? (
		<>
			<div className={`tax-and-fee-records ${className}`}>
				<FeeRecord
					className={'mb-2 py-1'}
					label={t('global_delivery_fee')}
					currency={currency}
					value={priceDelivery}
				/>

				{!!vunFee && (
					<FeeRecord
						className={'mb-2 py-1'}
						label={t('global_valuable_goods_guarantee_fee')}
						currency={currency}
						value={vunFee}
					/>
				)}

				{!!supperFee && (
					<FeeRecord
						className={'mb-2 py-1'}
						label={t('global_surcharge_fee')}
						currency={currency}
						value={supperFee}
					/>
				)}

				{!!dutyFee && Number(dutyFee) > 0 && (
					<FeeRecord
						className={'mb-2 py-1'}
						label={t('global_order_duty_fee')}
						value={dutyFee}
						currency={currency}
					/>
				)}

				{renderListExtra(extraServiceSelected)}

				{!!discount && (
					<FeeRecord
						className={'mb-2 py-1 discount-fee'}
						label={t('global_discount_fee')}
						currency={currency}
						value={discount}
					/>
				)}
			</div>

			<FeeRecord
				label="global_total"
				className="mb-2"
				isPrimary={true}
				value={totalPrice}
				currency={currency}
			></FeeRecord>
			{!!priceVn?.isShow && (
				<FeeRecord
					label="global_total_vnd"
					className="mb-4"
					isPrimary={false}
					value={priceVn?.toPrice}
					currency={priceVn?.toCurrency}
				></FeeRecord>
			)}
		</>
	) :(<>
		<div className={`tax-and-fee-records ${className}`}>
			<FeeRecord
				className={'mb-2 py-1'}
				label={t('global_delivery_fee')}
				currency={currency}
				value={priceDelivery}
			/>
		</div>
		<FeeRecord
			label="global_total"
			className="mb-2"
			isPrimary={true}
			value={totalPrice}
			currency={currency}
		/>
	</>);
};

export default FeeRecordList;
