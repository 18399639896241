import { MessageAlertModel } from "Components/Models";
import warningIcon from 'Assets/icon/warning-icon.svg';
import errorIcon from 'Assets/icon/error-icon.svg';

export const MESSAGE_ALERT_WARNING : MessageAlertModel = {
    message: 'c2c_notification_no_support_message',
    type: 'success' ,
    size: 'medium',
    iconSrc: warningIcon,
    iconClose: false,
    hasBackground: false,
    hasError: true,
}

export const MESSAGE_ALERT_ERROR : MessageAlertModel = {
    message: 'c2c_notification_reject_message',
    type: 'error' ,
    size: 'medium',
    iconSrc: errorIcon,
    iconClose: true,
    hasBackground: true,
    hasError: true,
}