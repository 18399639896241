import AddressListing from 'Pages/AddressBook/AddressListing';
import { C2cOrderPaymentContainer } from 'Pages/C2cOrderPayment';
import ChangeProfilePassword from 'Pages/ChangeProfilePassword';
import { ChoosePostOfficeContainer } from 'Pages/ChoosePostOffice';
import MyOrders from 'Pages/MyOrders';
import MyOrdersListing from 'Pages/MyOrders/MyOrdersListing';
import OrderDetail from 'Pages/MyOrders/OrderDetail';
import OrderBill from 'Pages/OrderBill';
import Profile from 'Pages/Profile';
import TopUp from 'Pages/TopUp';
import { MainPageEnum, MyOrderChildrenPathEnum } from '../enums';
import { RouteModel } from '../models/router-model';

export const RoutersMain: Array<RouteModel> = [
	{
		name: 'C2C Order Payment',
		path: MainPageEnum.C2cOrderPayment,
		exact: true,
		component: <C2cOrderPaymentContainer />,
		sideBar: false,
	},
	{
		name: 'C2C Order Payment',
		path: MainPageEnum.International,
		exact: true,
		component: <C2cOrderPaymentContainer />,
		sideBar: false,
	},
	{
		name: 'Order Bill',
		path: MainPageEnum.OrderBill,
		exact: true,
		component: <OrderBill />,
		sideBar: false,
	},
	{
		name: 'Address',
		path: MainPageEnum.Address,
		exact: true,
		component: <AddressListing />,
		sideBar: true,
	},
	{
		name: 'My Orders',
		path: MainPageEnum.MyOrders,
		exact: true,
		component: <MyOrders />,
		sideBar: true,
		children: [
			{
				name: 'My Order Detail',
				path: MyOrderChildrenPathEnum.OrderDetail,
				exact: true,
				component: <OrderDetail />,
				sideBar: true,
			},
			{
				name: 'My Orders Listing',
				path: MyOrderChildrenPathEnum.MyOrdersListing,
				exact: true,
				component: <MyOrdersListing />,
				sideBar: true,
			},
		],
	},
	{
		name: 'My Profile',
		path: MainPageEnum.MyProfile,
		exact: true,
		component: <Profile />,
		sideBar: true,
	},
	{
		name: 'Change Password',
		path: MainPageEnum.ChangePassword,
		exact: true,
		component: <ChangeProfilePassword />,
		sideBar: true,
	},
	{
		name: 'Choose Post Office',
		path: MainPageEnum.ChoosePostOffice,
		exact: true,
		component: <ChoosePostOfficeContainer />,
		sideBar: false,
	},
	{
		name: 'TopUp',
		path: MainPageEnum.TopUp,
		exact: true,
		component: <TopUp />,
		sideBar: true,
	},
];
