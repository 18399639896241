import SelectFlightTable from 'Components/SelectFlightTable';
import TableTabs, { ITableTabData } from 'Components/TableTabs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';

import nextSquareIcon from 'Assets/icon/next-square-icon.svg';
import previousSquareIcon from 'Assets/icon/previous-square-icon.svg';
import { format as formatTime } from 'date-fns';
import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';
import { DTOGetAvailableFlightRequest } from 'Services/v1/flights/dto';
import flightSlice from 'Stores/flights';
import { FlightsState } from 'Stores/flights/models';
import { RootState } from 'Stores/index';
import { orderBillSelectors } from 'Stores/order-bill';
import {
  DATE_FORMATS,
  DEFAULT_DAY_LEAD_TIME_ORDER,
  TIME_ZONE
} from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import useFindFlight from '../SelectFlightFilter/useFindFlight';

const SelectFlight = () => {
  const dispatch = useDispatch();
  const findFlight = useFindFlight();

  const [flightDate, setFlightDate] = useState<string>(
    new Date().toISOString()
  );
  const flightFilter = useSelector(orderBillSelectors.get).flightFilter;
  const availableFlightState = useSelector<RootState, FlightsState>(
    flightSlice.selectors.get
  ).availableFlights;
  const availableFlight = availableFlightState.data;

  const currentTime = new Date();
  const defaultFlightDateGroup = [
    {
      key: currentTime.toISOString(),
      title: formatTime(currentTime, 'dd/MM/yyyy')
    }
  ];
  const [flightDateGroups, setFlightDateGroups] = useState<ITableTabData[]>(
    defaultFlightDateGroup
  );

  const isDisabledPreviousNextDate = (index: number) => {
    if (
      (flightFilter.rangeDate || []).length > 0 &&
      (flightFilter.currentRangeDateFilter || []).length > 0
    ) {
      return moment(flightFilter.currentRangeDateFilter![index], DATE_FORMATS.DDMMYYYY).isSame(moment(flightFilter.rangeDate![index], DATE_FORMATS.DDMMYYYY))
    }
    return true;
  }

  const previousFlightDate = () => {
    if (isDisabledPreviousNextDate(0) || availableFlightState.fetchingStatus === 'pending') return;
    if (
      (flightFilter.rangeDate || []).length > 0 &&
      (flightFilter.currentRangeDateFilter || []).length > 0
    ) {
      const toDate = moment(
        flightFilter.currentRangeDateFilter![0],
        DATE_FORMATS.DDMMYYYY
      ).subtract(1, 'days');
      const fromDate = cloneDeep(toDate).subtract(DEFAULT_DAY_LEAD_TIME_ORDER, 'days');

      // Get flight
      getFlight(fromDate, toDate);
    }
  };

  const nextFlightDate = () => {
    if (isDisabledPreviousNextDate(1) || availableFlightState.fetchingStatus === 'pending') return;
    if (
      (flightFilter.rangeDate || []).length > 0 &&
      (flightFilter.currentRangeDateFilter || []).length > 0
    ) {
      const fromDate = moment(
        flightFilter.currentRangeDateFilter![1],
        DATE_FORMATS.DDMMYYYY
      ).add(1, 'days');
      let toDate = moment(flightFilter.rangeDate![1], DATE_FORMATS.DDMMYYYY);
      const fromDatePaging = cloneDeep(fromDate).add(
        DEFAULT_DAY_LEAD_TIME_ORDER,
        'days'
      );
      if (fromDatePaging.isSameOrBefore(toDate)) {
        toDate = fromDatePaging;
      }
      // Get flight
      getFlight(fromDate, toDate);
    }
  };

  const getFlight = (fromDate: Moment, toDate: Moment) => {
    const query = {
      arrivalAirport: flightFilter.arrivalAirport?.key!,
      departureAirport: flightFilter.departureAirport?.key!,
      weight: flightFilter.weight!,
      productCode: flightFilter.commodity?.code,
      timezone: TIME_ZONE,
      fromDate: fromDate.format(DATE_FORMATS.YYYYMMDD),
      toDate: toDate.format(DATE_FORMATS.YYYYMMDD),
      dimension: flightFilter.dimension
    } as DTOGetAvailableFlightRequest;

    findFlight.findFlight(query, {
      ...flightFilter,
      currentRangeDateFilter: [
        fromDate.format(DATE_FORMATS.DDMMYYYY),
        toDate.format(DATE_FORMATS.DDMMYYYY)
      ]
    });
  };

  useEffect(() => {
    if (!availableFlight) return setFlightDateGroups(defaultFlightDateGroup);

    const flightDateGroups: ITableTabData[] = [];
    if (flightFilter.currentRangeDateFilter && flightFilter.currentRangeDateFilter.length > 0) {
      const fromDate = moment(flightFilter.currentRangeDateFilter[0], DATE_FORMATS.DDMMYYYY);
      const toDate = moment(flightFilter.currentRangeDateFilter[1], DATE_FORMATS.DDMMYYYY);

      for (let m = fromDate; m.isSameOrBefore(toDate); m.add(1, 'days')) {
        const date = m.format(DATE_FORMATS.DDMMYYYY);
        flightDateGroups.push({
          key: date,
          title: date
        })
      }
    }

    setFlightDateGroups(flightDateGroups);
    if (flightDateGroups && flightDateGroups.length > 0) {
      setFlightDate(flightDateGroups[0].key);
    }

  }, [availableFlight]);

  if (availableFlightState.fetchingStatus === 'init' || !flightFilter.commodity || Object.keys(availableFlight || {}).length === 0)
    return <></>;

  return (
    <div className={`${classPrefix}-select-flight`}>
      <div className="tab-date">
        {flightDate && <div className="previous-date">
          <img
            className={isDisabledPreviousNextDate(0) || availableFlightState.fetchingStatus === 'pending' ? 'disabled' : ''}
            onClick={previousFlightDate}
            src={previousSquareIcon}
            alt="previous date"
          />
        </div>}
        <TableTabs
          activeTabKey={flightDate}
          onTabChange={activeKey => {
            setFlightDate(activeKey);
          }}
          data={flightDateGroups}
        />
        { flightDate && <div className="next-date">
          <img className={isDisabledPreviousNextDate(1) || availableFlightState.fetchingStatus === 'pending' ? 'disabled' : ''} onClick={nextFlightDate} src={nextSquareIcon} alt="next date" />
        </div>}
      </div>
      <SelectFlightTable
        isLoading={availableFlightState.fetchingStatus === 'pending'}
        data={availableFlight ? availableFlight[flightDate] : []}
      />
    </div>
  );
};

export default SelectFlight;
