import RootServiceV1 from 'Services/v1/root';
import httpClient from 'Services/http-client';
import { CamelSnakeMapper } from 'Services/data-mappers';
import { DTOLocation, DTOLocationRaw } from 'Services/v1/locations/dto';
import { DTOServiceResponse } from 'Services/interfaces';
import { DTOUploadGoodsImageResponse, DTOUploadGoodsImageResponseRaw } from 'Services/v1/media-assets/dto';

class MediaAssetsServiceV1 extends RootServiceV1 {
	constructor() {
		super();
	}

	async uploadGoodsImage(formData: FormData):Promise<DTOServiceResponse<DTOUploadGoodsImageResponse>> {
		try {
			const response = await httpClient().post<DTOUploadGoodsImageResponseRaw>('/v1/order/upload-goods-image', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			if(!response.data) throw response;

			const returnedData = {
				resUrl: response.data.data.res_url
			}

			return {
				statusCode: 200,
				data: returnedData
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
}

export default (new MediaAssetsServiceV1()) as MediaAssetsServiceV1;
