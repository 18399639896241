import { BrowserStorageUtil, Button } from '@swift-247/s247.library.ui-core';
import { Tooltip } from 'antd';
import iconCopyUrl from 'Assets/icon/copy-icon.svg';

import iconFailedMascotUrl from 'Assets/icon/order-confirmation-failed-mascot.svg';
import iconSuccessMascotUrl from 'Assets/icon/order-confirmation-success-mascot.png';
import iconPrintUrl from 'Assets/icon/print-icon.svg';
import RenderPaymentMethod from 'Components/PaymentMethod';
import PrintWaybill from 'Components/PrintWaybill';
import Section from 'Components/Section';
import { DeliveryTypeEnum } from 'Pages/C2cOrderPayment/enums';
import PageTitle from 'Pages/common/PageTitle';
import { TransportRulesType } from 'Pages/Enums';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { DTOOrder } from 'Services/v1/cargo-direct/dto';
import { resetC2cOrderPayment } from 'Stores/c2c-order-payment';
import { updateVATInfo } from 'Stores/order-bill';
import classnames from 'Utilities/classnames';
import {
  BOOKING_TYPE,
  TRANSPORT_RULE_GLOBAL,
  TypePayment
} from 'Utilities/constants';
import { copyToClipboard, displayCurrency, formatTime } from 'Utilities/util';
import { MainPageEnum } from '../../routes/enums';
import './index.scss';

enum PaymentStatus {
  failed,
  success
}

const OrderConfirmation = () => {
	const persistLayer = new BrowserStorageUtil(sessionStorage);

	const dispatch = useDispatch();
  const componentRef = useRef(null);

	const newOrder = persistLayer.deserializedGet('newOrder') as DTOOrder;

	if (!newOrder) {
		window.location = '/' as any;
		return <></>;
	}

	useEffect(() => {
		dispatch(resetC2cOrderPayment(true));
		dispatch(updateVATInfo(null));
	}, []);

	const navigate = useNavigate();

	const [searchParams, _] = useSearchParams();

	const paymentType = searchParams.get('paymentType');
	const isPaymentSuccess = searchParams.get('paymentSuccess');
	const isVNPPaymentSuccess = searchParams.get('vnp_ResponseCode') === '00';
	const urlRedirect =
		newOrder.platform === BOOKING_TYPE.C2C
			? MainPageEnum.C2cOrderPayment
			: MainPageEnum.CargoDirect;

	const paymentStatus = useMemo(() => {
		if (!paymentType)
			return isVNPPaymentSuccess ? PaymentStatus.success : PaymentStatus.failed; // callback from Vnpay without payment type
		if (paymentType === TypePayment.CASH) return PaymentStatus.success;
		return isPaymentSuccess?.toLowerCase() == 'true'
			? PaymentStatus.success
			: PaymentStatus.failed;
	}, [paymentType, isPaymentSuccess]);

	const { t } = useTranslation();

	const statusDataMapping = {
		[PaymentStatus.success]: {
			title: t('order_confirmation_title_success'),
			subTitle: t('order_confirmation_sub_title_success'),
			imageUrl: iconSuccessMascotUrl
		},
		[PaymentStatus.failed]: {
			title: t('order_confirmation_title_failed'),
			subTitle: t('order_confirmation_sub_title_failed'),
			imageUrl: iconFailedMascotUrl
		}
	};

	const renderedData = statusDataMapping[paymentStatus];

	// if(!newOrder) {
	// 	return (window as any).location = '/order-bill'
	// }

	const contentClasses = classnames('order-confirm', {
		'order-confirm--success': paymentStatus === PaymentStatus.success,
		'order-confirm--failed': paymentStatus === PaymentStatus.failed
	});

	if (
		newOrder?.deliveryType === DeliveryTypeEnum.D2D &&
		newOrder?.transportRules?.[0]?.rule === TransportRulesType.Internal
	) {
		const diffMinute = Math.floor(
			(new Date(newOrder?.bookingShipperTime).getTime() -
				new Date(newOrder?.createdAt).getTime()) /
			(1000 * 60)
		);
		renderedData.subTitle = `${t(
			'order_confirmation_sub_title_success_d2x_within'
		)} ${diffMinute} ${t('global_minute')}`;
	}

	if (
		newOrder?.deliveryType === DeliveryTypeEnum.D2P ||
		(newOrder?.deliveryType === DeliveryTypeEnum.D2D &&
			newOrder?.transportRules?.[0]?.rule !== TransportRulesType.Internal)
	) {
		if (
			new Date(newOrder?.createdAt).getDate() ===
			new Date(newOrder?.bookingShipperTime).getDate()
		) {
			const diffMinute = Math.floor(
				(new Date(newOrder?.bookingShipperTime).getTime() -
					new Date(newOrder?.createdAt).getTime()) /
				(1000 * 60)
			);

			renderedData.subTitle = `${t(
				'order_confirmation_sub_title_success_d2x_within'
			)} ${diffMinute} ${t('global_minute')}`;
		} else {
			const dateShipping = formatTime(
				new Date(newOrder?.bookingShipperTime),
				'HH:mm dd/MM/yyyy'
			);
			renderedData.subTitle = `${t(
				'order_confirmation_sub_title_success_d2x_before_header'
			)} ${dateShipping}`;
		}
	}

	const getButtonNavigates = (): any => {
		if (paymentStatus === PaymentStatus.success) {
			return [
				{ urlNavigate: urlRedirect, label: 'global_place_new_order' },
				{
					urlNavigate: `my-orders/${newOrder.uuid}`,
					label: 'global_order_detail'
				}
			];
		}
		return [
			{
				urlNavigate: urlRedirect,
				label: 'order_confirmation_button_try_failed'
			},
			{
				urlNavigate: urlRedirect,
				label: 'order_confirmation_button_new_order_failed'
			}
		];
	};

	const [buttonFirst, buttonSecond] = getButtonNavigates();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

	return (
		<>
			<PageTitle title={t('title_page_order')} />
      <Section className="order-confirm-container">
				<div className={contentClasses}>
					<img
            className="order-confirm-image mb-4"
						src={renderedData.imageUrl}
						alt={''}
					/>
          <div className="order-confirm-title">{renderedData.title}</div>
					<div
						className={
							'order-confirm-sub-title text-secondary fs-p-lg text-center mb-6'
						}
					>
						{renderedData.subTitle}
					</div>
          <div className="w-100">
            <div className="order-confirm-item-info">
              <div className="order-confirm-item-info-label">
								{t('global_order_code')}
							</div>
              <div className="d-flex align-items-center">
                <span className="mr-3">{newOrder?.orderId}</span>
								<img
									onClick={() => copyToClipboard(newOrder?.orderId)}
                  className="cursor-pointer"
									src={iconCopyUrl}
									alt={''}
								/>
                {newOrder.platform === BOOKING_TYPE.C2C &&
                  newOrder.transportRules?.every(x =>
                    TRANSPORT_RULE_GLOBAL.every(o => !x.rule?.includes(o))
                  ) && (
                    <Tooltip
                      title={t('waybill_tooltip_print')}
                      overlayInnerStyle={{ borderRadius: '5px' }}
                    >
                      <img
                        onClick={handlePrint}
                        className="print ml-3"
                        src={iconPrintUrl}
                      />
                    </Tooltip>
                  )}
							</div>
						</div>
            <div className="order-confirm-item-info align-items-center">
              <div className="order-confirm-item-info-label">
								{t('global_payment_method')}
							</div>
							<div>
								<RenderPaymentMethod data={newOrder} />
							</div>
						</div>
            <div className="order-confirm-item-info">
              <div className="order-confirm-item-info-label">
								{t('global_total')}
							</div>
              <div className="text-primary fs-h-sm">
								{displayCurrency(
									newOrder.toPrice ? newOrder.toPrice : newOrder.amount,
									newOrder.toCurrency ? newOrder.toCurrency : newOrder.currency
								)}
							</div>
						</div>
					</div>
          <div className="w-100 d-flex gap-4 mt-6 mb-10">
						<Button
							onClick={() => navigate(`/${buttonFirst.urlNavigate}`)}
              className="w-50"
              size="lg"
              variant="secondary"
						>
							{t(buttonFirst.label)}
						</Button>
						<Button
							onClick={() => navigate(`/${buttonSecond.urlNavigate}`)}
              className="w-50"
              size="lg"
						>
							{t(buttonSecond.label)}
						</Button>
					</div>
        </div>
        <div style={{ display: 'none' }}>
          <PrintWaybill ref={componentRef} orderDetail={newOrder} />
				</div>
			</Section>
		</>
	);
};

export default OrderConfirmation;
