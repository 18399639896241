import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { displayCurrency } from 'Utilities/util';
import images from 'Assets/images';
import { DTODimensionData, DTOOrder } from 'Services/v1/cargo-direct/dto';
import { parseInt } from 'lodash';

export interface ProductTableColumns {
	title: string,
	field: string,
	component?: any
}

export const cargoColumns: ProductTableColumns[] = [{
	title: '',
	field: 'title',
	component: ({value}) => {
		return <div className={'text-start'}>
			{value}
		</div>
	}
},{
	title: 'global_product',
	field: 'productName',
	component: ({value}) => <div className={'fw-bold'}>{value}</div>
}, {
	title: 'order_detail_declaration_table_header_weight',
	field: 'weight',
	component: ({value}) => <div>{value} kg</div>
}, {
	title: 'order_detail_declaration_table_header_dimension',
	field: 'dimension',
	component: ({value, item}) => {
		return value.map((d: any, index: number) => {
			return <div key={index}>{d.long || d.length} x {d.width} x {d.height} x <span className={'fw-bold'}>{d.quantity}</span></div>
		})
	}
}, {
	title: 'order_detail_declaration_table_header_charge_weight',
	field: 'chargeWeight',
	component: ({value}) => <div>{value} kg</div>
}, {
	title: 'order_detail_declaration_table_header_package_quantity',
	field: 'totalQuantity'
}, {
	title: 'order_detail_declaration_table_header_total',
	field: 'totalPrice'
}]

export const c2cCrossBorderColumns: ProductTableColumns[] = [{
	title: '',
	field: 'title',
	component: ({value}) => {
		return <div className={'text-start'}>

			{value}
		</div>
	}
},{
	title: 'global_product',
	field: 'productName',
	component: ({value}) => <div className={'fw-bold'}>{value}</div>
}, {
	title: 'order_detail_declaration_table_header_weight',
	field: 'weight',
	component: ({value}) => <div>{value} kg</div>
}, {
	title: 'order_detail_declaration_table_header_dimension',
	field: 'dimension',
}, {
	title: 'order_detail_declaration_table_header_package_quantity',
	field: 'totalQuantity'
}, {
	title: 'order_detail_declaration_table_header_total',
	field: 'totalPrice'
}]

export const c2cDomesticColumns: ProductTableColumns[] = [{
	title: '',
	field: 'title',
	component: ({value}) => {
		return <div className={'text-start'}>

			{value}
		</div>
	}
},{
	title: 'global_product',
	field: 'productName',
	component: ({value}) => <div className={'fw-bold'}>{value}</div>
}, {
	title: 'order_detail_declaration_table_header_weight',
	field: 'weight',
	component: ({value}) => <div>{value} kg</div>
}, {
	title: 'order_detail_declaration_table_header_dimension',
	field: 'dimension',
}]

interface CargoProductItem {
	id: string
	title?: ReactNode
	productName: string
	weight: string | number
	dimension: DTODimensionData[],
	chargeWeight: number,
	totalQuantity: string | number,
	totalPrice: string | number,
}

interface C2CProductItem {
	id: string
	title?: ReactNode
	productName: string
	weight: string | number
	dimension: string,
	totalQuantity: string | number,
	totalPrice: string | number,
}

interface InfoTypeProps {
	title: string
	icon: any
	showLine?: boolean
}

export const InfoType = (props: InfoTypeProps) => {
	const {showLine = true, icon, title} = props;
	const {t} = useTranslation();
	return <div className={'d-flex align-items-center'}>
		<img src={icon}/>
		{showLine && <div className={'info-type-decorated-line'} />}
		<span className={'text-primary ml-2'}>{t(title)}</span>
	</div>
}

export const parseCargoData = (data: DTOOrder): CargoProductItem[] => {
	const { syncData, orderDetails, dimension } = data;
	const productName = orderDetails[0]?.name;

	const originalDimensionData = JSON.parse(dimension || '{}');
	const declaredWeight = originalDimensionData.declared_weight / 1000;
	const declaredChargeWeight = originalDimensionData.weight / 1000;
	const declaredDimension = originalDimensionData.dimension || [];
	const declaredTotalQuantity = _.sumBy(declaredDimension, 'quantity');
	const receivedTotalQuantity = _.sumBy(syncData?.dimension?.actualDimension || [], 'quantity')
	const {currency, amount: declaredAmount} = data;

	const result = [];

	const declaredData: CargoProductItem = {
		id: 'declaredData',
		title: <InfoType showLine={!!syncData} title={'product_declared_information'} icon={images.ProductDeclaredInfo}/>,
		productName,
		weight: declaredWeight,
		dimension: declaredDimension,
		chargeWeight: declaredChargeWeight,
		totalQuantity: declaredTotalQuantity,
		totalPrice: displayCurrency(declaredAmount, currency)
	}

	result.push(declaredData);

	if(!syncData) return result;

	const receivedChargeWeight = (syncData?.dimension?.actualWeight || 0) / 1000;
	const receivedDimension = syncData?.dimension?.actualDimension || [];
	const receivedData: CargoProductItem = {
		id: 'receivedData',
		title: <InfoType showLine={!!syncData?.securityCheckInformation} title={'product_received_information'} icon={images.ProductReceivedInfo}/>,
		productName,
		weight: declaredWeight,
		dimension: receivedDimension,
		chargeWeight: receivedChargeWeight,
		totalQuantity: receivedTotalQuantity,
		totalPrice: displayCurrency(syncData?.properties?.actualShippingFee, currency)
	}

	result.push(receivedData);

	if(!syncData?.securityCheckInformation) return result;

	const afterSecChargeWeight = syncData?.securityCheckInformation?.afterSecurityCheck?.weightInKg;

	const afterSecDimension = receivedChargeWeight !== afterSecChargeWeight ? [] : receivedDimension;

	const afterSecTotalQuantity = syncData?.securityCheckInformation.afterSecurityCheck.quantity;

	const afterSecurityData: CargoProductItem = {
		id: 'afterSecurityData',
		title: <InfoType showLine={false} title={'product_security_checked_information'} icon={images.ProductSecurityCheckedInfo}/>,
		productName,
		weight: declaredWeight,
		dimension: afterSecDimension,
		chargeWeight: syncData?.securityCheckInformation?.afterSecurityCheck?.weightInKg || 0,
		totalQuantity: afterSecTotalQuantity,
		totalPrice: displayCurrency(syncData?.securityCheckInformation?.afterSecurityCheck?.price, currency)
	}

	result.push(afterSecurityData);

	return result;
}

export const parseC2CData = (props: DTOOrder): C2CProductItem[] => {
	const {orderDetails, syncData, dimension: declaredDimension, weight: declaredWeight} = props;
	const productName = orderDetails.map(item => item.name).sort().join(', ');
	const [declaredLength, declaredWidth, declaredHeight] = declaredDimension?.split('x') || [];
	const declaredTotalQuantity = _.sumBy(orderDetails, item => parseInt(item.quantity || '0'));
	const declaredTotalPrice = _.sumBy(orderDetails, 'totalPrice');

	const result = [];

	const declaredData: C2CProductItem = {
		id: 'declaredData',
		productName,
		title: <InfoType showLine={!!syncData} title={'product_declared_information'} icon={images.ProductDeclaredInfo}/>,
		weight: declaredWeight || 0,
		dimension: `${declaredLength} x ${declaredWidth} x ${declaredHeight}`,
		totalQuantity: declaredTotalQuantity,
		totalPrice: displayCurrency(declaredTotalPrice, 'USD')
	}

	result.push(declaredData);

	const isPackageReceived = syncData && syncData.histories.find(item => item.status === 'received')

	if(!isPackageReceived) return result;

	const {actualWeight, actualHeight, actualLength, actualWidth} = syncData?.dimension || {};
	const receivedGoodsInfo = syncData.properties.actualGoodsInfo || syncData?.goodsInfo;
	const receivedProductName = receivedGoodsInfo.map(item => item.productName).sort().join(', ');
	const receivedQuantity = _.sumBy(receivedGoodsInfo, (item) => parseInt(item.quantity || '0'));
	const receivedTotalPrice =  receivedGoodsInfo.reduce((result, item) => {
		return result + parseInt(item.quantity || '0') * parseInt(item.price || '0')
	}, 0)


	const receivedData:C2CProductItem = {
		id: 'receivedData',
		productName: receivedProductName,
		title: <InfoType showLine={false} title={'product_received_information'} icon={images.ProductReceivedInfo}/>,
		weight: (actualWeight || 0) / 1000,
		dimension: `${actualLength} x ${actualWidth} x ${actualHeight}`,
		totalQuantity: receivedQuantity,
		totalPrice: displayCurrency(receivedTotalPrice, 'USD')
	}

	result.push(receivedData)

	return result;
}
