import {
  DeliveryAddress,
  DeliveryMethodClassic,
  TransactionDetail
} from 'Components/DeliveryMethod';
import './styles.scss';

const C2cDeliveryMethodDetail = () => {
  return (
    <div className="c2c-delivery-method-detail">
      <div className='c2c-detail-method-classic-address'>
        <DeliveryMethodClassic></DeliveryMethodClassic>
        <DeliveryAddress></DeliveryAddress>
      </div>
      <TransactionDetail></TransactionDetail>
    </div>
  );
};

export default C2cDeliveryMethodDetail;
