import { Card } from 'antd';
import ArrowRightIcon from 'Assets/icon/arrow-right.svg';
import { usePopup } from 'Hooks';
import { C2cOverPaymentTextEnum } from 'Pages/C2cOrderPayment/enums';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  c2cOrderPaymentSelectors,
  removeProductDeclaration
} from 'Stores/c2c-order-payment';
import { TypeOrder } from 'Stores/c2c-order-payment/enums';
import { GoodsInfoModel } from 'Stores/c2c-order-payment/models';
import { classPrefix } from 'Utilities/global';
import GoodsInformation from '../GoodsInformation/GoodsInformation';
import OrderProductItems from './OrderProductItems';
import ProductItem from './ProductItem';

const c2cPickedUpProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const popup = usePopup();
  const { typeOrder, isGlobal } = useSelector(c2cOrderPaymentSelectors.get);
  const orderType = typeOrder;
  const productInfo = useSelector(
    c2cOrderPaymentSelectors.getAllProductDeclaration
  );

  const openPopup = (
    title: string,
    component: ReactNode,
    action?: () => void,
    maxWidth?: number
  ) => {
    popup.onOpen(
      {
        title,
        hideFooter: true,
        maxHeight: '90vh',
        height: 794,
        maxWidth,
        onSubmit: () => action
      },
      component
    );
  };

  const renderData = () => {
    if (
      !productInfo.length ||
      !isGlobal ||
      (orderType !== TypeOrder.BASIC &&
        orderType !== TypeOrder.SUPER &&
        orderType !== TypeOrder.PREMIUM)
    ) {
      return <></>;
    }
    // get 3 first items
    const productItems = productInfo.slice(0, 3);
    return (
      <>
        <Card className={`${compClass} mb-4`} bordered={false}>
          {renderProductItem(productItems)}
          {productInfo.length > 3 && (
            <div
              className="custom-card-footer text-center cursor-pointer"
              onClick={openPopupAllProduct}
            >
              <span className="mr-2">
                {t(C2cOverPaymentTextEnum.C2cViewAllProduct)}
              </span>
              <img src={ArrowRightIcon} width="12" />
            </div>
          )}
        </Card>
      </>
    );
  };

  const renderProductItem = (productInfo: any) => {
    return (
      <>
        {productInfo.map((item: GoodsInfoModel) => (
          <ProductItem
            key={item.id}
            item={item}
            onEdit={id => openPopupGoods(id)}
            onDelete={id => onDeleteProductItem(id)}
          />
        ))}
      </>
    );
  };

  const onDeleteProductItem = (productId: string) => {
    dispatch(removeProductDeclaration(productId));
  };
  const openPopupAllProduct = () => {
    openPopup(
      'title_page_my_order',
      <OrderProductItems
        addOrEditProduct={addOrEditProduct}
      ></OrderProductItems>
    );
  };

  const addOrEditProduct = (idGoods?: string) => {
    openPopupGoods(idGoods, () => {
      openPopupAllProduct();
    });
  };

  const openPopupGoods = (productId?: string, action?: () => void) => {
    openPopup(
      'c2c_goods_info_title',
      <GoodsInformation idGoods={productId}></GoodsInformation>,
      action,
      1000
    );
  };

  const compClass = `${classPrefix}-c2c-pickup-product-wrapper`;

  return renderData();
};

export default c2cPickedUpProduct;
