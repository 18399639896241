import InputErrorMessage from "Components/InputErrorMessage";
import { useTranslation } from "react-i18next";

interface PropsModel {
    fieldName?: string;
    isShow?: boolean;
    message?: string;
}

const FormHookErrorMessage = (props: PropsModel) =>{
    const { fieldName, isShow = false, message } = props;
	const { t } = useTranslation();

	if (!isShow) return <></>;
    
	return (
		<InputErrorMessage
			className={'mt-1 fs-p-sm lh-p-sm'}
			message={t(!!message? message : 'validation_required',{ fieldName: fieldName } )}
		/>
	);
}
export default FormHookErrorMessage;