import editIcon from 'Assets/icon/edit.svg';
import deleteIcon from 'Assets/icon/trash.svg';
import { useTranslation } from 'react-i18next';
import { GoodsInfoModel } from 'Stores/c2c-order-payment';
import { classPrefix } from 'Utilities/global';
import './index.scss';

interface Props {
	item: GoodsInfoModel;
	onDelete: (id: string) => void;
	onEdit: (id: string) => void;
}

const ProductItem = (props: Props) => {
	const { item, onDelete, onEdit } = props;
  const {t} = useTranslation();
	const compClass = `${classPrefix}-c2c-pickup-product-item`;
	return (
		<div
			className={`${compClass} d-flex align-items-center justify-content-between`}
			key={item.id}
		>

			<div className="d-flex flex-column flex-fill">
				<span className="mb-3">
					<b>{item.categories.categoryName}</b>
				</span>

				<div className="d-flex justify-content-between">
					<div className="d-flex">
						<div className="image-wrapper d-flex align-items-center justify-content-center">
							<img
								src={item.categories.categoryImgUrl}
								width="40"
							/>
						</div>
						<div className="d-flex flex-column ms-3">
							<div className="mb-1">{item.productName}</div>
							<div className="text-gray-primary mb-3">
								{item.material}
							</div>
							<div className="mb-1">
								{item.quantity} items, {t('goods_info_price')}: {item.price}$
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-between ms-2">
						<div
							className="d-flex flex-column cursor-pointer"
							onClick={() => item.id && onEdit(item.id)}
						>
							<img src={editIcon} alt="" height={20} />
							<span className="mt-2 text-gray-primary">{t('buttons_edit')}</span>
						</div>
						<div
							className="d-flex flex-column ms-lg-4 cursor-pointer"
							onClick={() => item.id && onDelete(item.id)}
						>
							<img src={deleteIcon} alt="" height={20} />
							<span className="mt-2 text-gray-primary">{t('buttons_delete')}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductItem;
