import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import '@swift-247/s247.library.ui-core/styles/dist/reboot.css';
import '@swift-247/s247.library.ui-core/styles/dist/apis.css';
import './index.scss';
import './utilities.scss';
import store, { persistor } from './stores';
import { PersistGate } from 'redux-persist/integration/react';


const container = document.getElementById('root') as any;
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			{/*<React.StrictMode>*/}
				<App />
			{/*</React.StrictMode>*/}
		</PersistGate>
	</Provider>,
);
