import images from 'Assets/images';
import { useTranslation } from 'react-i18next';
import { DTOOrder, DTOOrderDetail } from 'Services/v1/cargo-direct/dto';
import { TypeCard, TypePayment } from 'Utilities/constants';
import { toUpperLetter } from 'Utilities/util';
import './index.scss';

const RenderPaymentMethod = (props: { data?: DTOOrder | DTOOrderDetail }) => {
	const { t } = useTranslation();

	const renderHtml = () => {
		switch (toUpperLetter(props.data?.paymentMethod)) {
			case toUpperLetter(TypePayment.CASH):
				return (
					<>
						<img width="27" height="18" src={images.cash} />
						<span>{t('payment_method_cash')}</span>
					</>
				);
			case toUpperLetter(TypePayment.VNPAY):
				return <img height="25" src={images.icVNPAY} />;
			case toUpperLetter(TypePayment.KCP):
				if (props.data?.vnpBank === TypeCard.PHONE) {
					return <span>Phone</span>;
				} else if (props.data?.vnpBank === TypeCard.CARD) {
					return <span>{t('korea_local_cards')}</span>;
				} else if (props.data?.vnpBank === TypeCard.PAYCO) {
					return <img width="45" height="10" src={images.icPayco} />;
				} else if (props.data?.vnpBank === TypeCard.SAMSUNG) {
					return (
						<>
							<img src={images.icSamSungPay} />
							<span>Samsung Pay</span>
						</>
					);
				} else if (props.data?.vnpBank === TypeCard.VBANK) {
					return (
						<>
							<img
								width="30"
								height="25"
								src={images.icLogoVbank}
							/>
							<span>VirtualBank</span>
						</>
					);
				}
				return <></>;
			case TypePayment.TOPUP:
				return (
					<>
						<img width="18" height="22" src={images.iconTopUp} />
						<span>{t('payment_method_topup')}</span>
					</>
				);
			default:
				break;
		}
	};
	return <div className="payment-method">{renderHtml()}</div>;
};

export default RenderPaymentMethod;
