import { call, put, takeEvery } from '@redux-saga/core/effects';
import { DTOServiceResponse } from 'Services/interfaces';
import { PayloadAction } from '@reduxjs/toolkit';

type ApiCallerPayload<P> = P extends {
	[key: string]: any
} ? P : undefined;

function createFetchedSaga<P>(actions: any, apiCaller: (payload: ApiCallerPayload<P>) => Promise<DTOServiceResponse<any>>) {
	function* handler(action: PayloadAction<P>): any {
		const { data, error, meta } = yield call(apiCaller, action.payload as any);
		if(error) {
			return yield put(actions.getFailed({
				errCode: error.code,
				errMsg: error.msg
			}))
		}

		if (meta) {
			yield put(actions.getMetaSuccess({meta}));
		}

		yield put(actions.getSuccess(data))

	}
	return function* saga() {
		yield takeEvery(actions.getting.toString(), handler);
	}
}

export default createFetchedSaga;