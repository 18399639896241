import React, { useState } from 'react';
import './index.scss';
import Section from 'Components/Section';
import iconMarkerUrl from 'Assets/icon/marker.svg';
import { useTranslation } from 'react-i18next';
import RadioSelectCard from 'Components/RadioSelectCard';
import infoCircleUrl from 'Assets/icon/info-circle.svg';
import classnames from 'Utilities/classnames';
import iconTakeOffPlaneUrl from 'Assets/icon/take-off-plane.svg'
import { useSelector } from 'react-redux';
import { orderBillSelectors } from 'Stores/order-bill';
import {addMinutes, format} from 'date-fns'
import iconTruckUrl from 'Assets/icon/truck-icon.svg';
import { DATE_FORMATS } from 'Utilities/constants';
import { newDateWithFormat, removeVietnameseTones } from 'Utilities/util';
import { tAirport } from '../../../localization';
import i18next from 'i18next';

enum DeliveryMethod {
	bySelf,
	byTruck
}

interface CommonTextBlockProps {
	text: string,
	subText: string,
	className?: string
}

const CommonTextBlock = (props: CommonTextBlockProps) => {
	const {
		text,
		subText,
		className
	} = props;

	const containerClasses = classnames(`d-flex flex-column`, className)

	return <div className={containerClasses}>
		<div className={'fs-p-md lh-p-md mb-1'}>{text}</div>
		<div className={'fs-p-md lh-p-md text-secondary'}>{subText}</div>
	</div>
}

interface DestinationBlockProps {
	deliveryMethod: DeliveryMethod
	onMethodChange: (method: DeliveryMethod) => void,
	selfMethodText: string,
	selfMethodCutOffTime: string,
	truckMethodText: string,
	airportLocation: string,
	airportName: string,
	className?: string
}

const DestinationInfoBlock = (props: DestinationBlockProps) => {
	const {
		deliveryMethod,
		onMethodChange,
		selfMethodCutOffTime,
		selfMethodText,
		truckMethodText,
		airportLocation,
		airportName,
		className
	} = props;
	const {t} = useTranslation();
	return <div className={className}>
		<CommonTextBlock className={'mb-3'} text={airportLocation} subText={airportName}/>
		<div className={'destination-info-options d-flex gap-6'}>
			<RadioSelectCard className={'flex-grow-1 destination-info-select'} checked={deliveryMethod === DeliveryMethod.bySelf} onChange={(val) => {
				if(!val) return;
				onMethodChange(DeliveryMethod.bySelf)
			}}>
				<div className={'fs-p-md lh-p-md mb-2'}>{selfMethodText}</div>
				<div className={'fs-p-lg lh-p-lg fw-600'}>{selfMethodCutOffTime}</div>
			</RadioSelectCard>
			<RadioSelectCard className={'destination-info-select flex-grow-1'} disabled checked={deliveryMethod === DeliveryMethod.byTruck} onChange={(val) => {
				if(!val) return;
				onMethodChange(DeliveryMethod.byTruck)
			}}>
				<div className={'fs-p-md lh-p-md mb-2'}>{truckMethodText}</div>
				<div className={'fs-p-lg lh-p-lg fw-600'}>{t('global_coming_soon_feature')}</div>
			</RadioSelectCard>
		</div>
	</div>
}

const RoadLineIllustration = () => {
	return (<div className={'road-line-illus'}>
		<div className={'road-line-illus-dot road-line-illus-dot--origin'}/>
		<div className={'road-line-illus-line'}/>
		<div className={'road-line-illus-dot road-line-illus-dot--destination'}/>
	</div>)
}

const SelectDeliveryMethod = () => {
	const {t} = useTranslation();
	const [departedMethod, setDepartedMethod] = useState<DeliveryMethod>(DeliveryMethod.bySelf);
	const [arrivalMethod, setArrivalMethod] = useState<DeliveryMethod>(DeliveryMethod.bySelf);
	const flightInfo = useSelector(orderBillSelectors.get).flightInfo;
	const flightFilter = useSelector(orderBillSelectors.get).flightFilter;

	if(!flightInfo || !flightFilter) return <></>

	const timeFormat = 'dd/MM/yyyy - HH:mm'

	const departureTime = newDateWithFormat(flightInfo.startTime, DATE_FORMATS.ISO_CUSTOM);
	const arrivalTime = newDateWithFormat(flightInfo.minEndTime, DATE_FORMATS.ISO_CUSTOM);

	const originSelfCutOffTime = format(newDateWithFormat(flightInfo.collectionTime, DATE_FORMATS.ISO_CUSTOM), timeFormat);
	const destinationSelfCutOffTime = format(newDateWithFormat(flightInfo.deliveryTime, DATE_FORMATS.ISO_CUSTOM), timeFormat);

	return <Section
		className={'select-address'}
		title={t('order_bill_select_delivery_method')}
		icon={<img src={iconTruckUrl} alt={''}/>}
	>

		<div className={'d-flex gap-7'}>
			<div style={{height: 197}} className={'d-flex flex-column justify-content-between'}>
				<CommonTextBlock className={'align-items-center'} text={format(departureTime, 'HH:mm')} subText={format(departureTime, 'dd/MM/yyyy')}/>
				<div className={'d-flex flex-column align-items-center'}>
					<div><img src={iconTakeOffPlaneUrl} alt={''}/></div>
					<div className={'fs-p-md lh-p-md'}>{flightInfo.flightNumber}</div>
				</div>
				<CommonTextBlock className={'align-items-center'} text={format(arrivalTime, 'HH:mm')} subText={format(arrivalTime, 'dd/MM/yyyy')}/>
			</div>
			<RoadLineIllustration/>
			<div className={'flex-grow-1 payment-method-section'}>
				<DestinationInfoBlock
					selfMethodText={`${t('order_bill_delivery_method_departure_airport_message')}:`}
					selfMethodCutOffTime={originSelfCutOffTime}
					truckMethodText={t('order_bill_delivery_method_departure_door_message')}
					airportLocation={`${i18next.language === 'vi'? flightFilter.departureAirport?.province : removeVietnameseTones(flightFilter.departureAirport?.province)} (${flightFilter.departureAirport?.key})`}
					airportName={tAirport(flightFilter.departureAirport?.name)}
					deliveryMethod={departedMethod}
					onMethodChange={val => setDepartedMethod(val)}
					className={'mb-8'}
				/>
				<DestinationInfoBlock
					selfMethodText={`${t('order_bill_delivery_method_arrival_airport_message')}:`}
					selfMethodCutOffTime={destinationSelfCutOffTime}
					truckMethodText={t('order_bill_delivery_method_arrival_door_message')}
					airportLocation={`${i18next.language === 'vi' ? flightFilter.arrivalAirport?.province : removeVietnameseTones(flightFilter.arrivalAirport?.province)} (${flightFilter.arrivalAirport?.key})`}
					airportName={tAirport(flightFilter.arrivalAirport?.name)}
					deliveryMethod={arrivalMethod}
					onMethodChange={val => setArrivalMethod(val)}
				/>
			</div>

		</div>


	</Section>
}

export default SelectDeliveryMethod