import { BrowserStorageUtil } from '@swift-247/s247.library.ui-core';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { envStorage, unauthorizedKick } from 'Utilities/util';
import config from '../../config/config';

export interface AppHttpError {
	code: string;
	msg: string;
}

const browserStorageUtil = new BrowserStorageUtil(envStorage as any);

const tokenInjectMiddleware = (req: AxiosRequestConfig) => {
	const authInfo = browserStorageUtil.deserializedGet('authInfo');
	if (!authInfo) {
		return unauthorizedKick();
	}

	const {
		accessToken,
		//refreshToken
	} = authInfo;

	const scheme = 'Bearer';
	req.headers = {
		Authorization: `${scheme} ${accessToken}`,
		'Accept': 'application/json',
	};
	return req;
};

const checkUnAuthorizedMiddleware = (err: AxiosError) => {
	if (err.response?.status === 401) {
		return unauthorizedKick();
	}

	return err;
};

const httpClient = (url = config.baseUrl) => {
	const http = axios.create({
		baseURL: url,
		headers: {
			'Content-Type': 'application/json',
		},
	});

	if(url !== config.grapUrl) {
		http.interceptors.request.use(
			function (req: AxiosRequestConfig) {
				return tokenInjectMiddleware(req);
			},
			function (error) {
				return Promise.reject(error);
			},
		);
	}

	http.interceptors.response.use(
		(res) => {
			return res;
		},
		(error: AxiosError) => {
			const err = checkUnAuthorizedMiddleware(error);
			return Promise.reject(err);
		},
	);
	return http;
};

export default httpClient;
