import React from 'react';
import { Input } from 'antd';
import { ReactNode } from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

interface Props
	extends Omit<
		React.InputHTMLAttributes<HTMLInputElement>,
		'size' | 'type' | 'value'
		> {
	value?: any;
	name?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	size?: 'lg' | 'md' | 'sm' | 'xs';
	className?: string;
	icon?: ReactNode;
	error?: boolean;
	helperText?: string;
	errorMsg?: string;
	onClear?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onClick?: () => void;
	onIconClick?: () => void;
	disabled?: boolean;
	label?: string;
	placeholder?: string;
	readonly?: boolean;
	addonBefore?: string | ReactNode;
	addonAfter?: string | ReactNode;
	status?: 'error' | 'warning' | '';
	iconRender?: any
}
const InputPassword = (props: Props) => {
	const {
		value,
		onChange,
		name,
		className,
		icon,
		error,
		helperText,
		onClear,
		onFocus,
		onBlur,
		disabled = false,
		errorMsg,
		label,
		placeholder,
		size = 'md',
		readonly,
		onIconClick,
		onClick,
		addonBefore,
		addonAfter,
		status,
		iconRender,
		...rest
	} = props;

	const containerClasses = classnames(
		{
			[`${classPrefix}-input-password-container`]: true,
			[`${classPrefix}-input-password-container--error`]: error,
			[`${classPrefix}-input-password-container--disabled`]: disabled,
			[`${classPrefix}-input-password-container--${size}`]: true,
			[`${classPrefix}-input-password-container--with-label`]: label,
		},
		className,
	);

	return (
		<>
			<div className={`${containerClasses}`}>
				<Input.Password
					placeholder={placeholder || label}
					addonBefore={addonBefore}
					addonAfter={addonAfter}
					value={value}
					onChange={onChange}
					name={name}
					onBlur={onBlur}
					onFocus={onFocus}
					onClick={onClick}
					status={status}
					disabled={disabled}
					iconRender={iconRender}
					{...rest}
				/>
			</div>
		</>
	);
};

export default InputPassword;
