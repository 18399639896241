import { Radio } from '@swift-247/s247.library.ui-core';
import { Collapse } from 'antd';
import images from 'Assets/images';
import MessageAlert from 'Components/MessageAlert/messageAlert';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DTOPaymentCardGroup } from 'Services/v1/payment/dto';
import {
  c2cOrderPaymentSelectors,
  C2cPaymentConfig,
  selectPaymentMethodAction
} from 'Stores/c2c-order-payment';
import { updatePaymentMethod } from 'Stores/order-bill';
import { TypeCard, TypePayment } from 'Utilities/constants';
import SelectPaymentVN from '../SelectPaymentVN';
import './index.scss';

interface PaymentKoreaItem {}

const SelectPaymentKorea = (props: PaymentKoreaItem) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Panel } = Collapse;
  const { paymentConfig } = useSelector(c2cOrderPaymentSelectors.get);
  const { c2cValidateFields, chosenPaymentMethod, toggleOrder } = useSelector(
    c2cOrderPaymentSelectors.get
  );

  useEffect(() => {
    dispatch(updatePaymentMethod());
  }, []);

  const getPaymentMethodData = (item: C2cPaymentConfig) => {
    const paymentMethodData: DTOPaymentCardGroup[] = [];
    (item.methods || []).forEach(method => {
      let objPaymentMethod: DTOPaymentCardGroup & {
        codeParent?: string;
      } = {};

      if (method.banks && method.banks.length > 0) {
        objPaymentMethod.isGroup = true;
        objPaymentMethod.child = method.banks?.map(x => ({
          code: x.code,
          nameVn: x.name,
          nameEng: x.name,
          urlImage: x.urlImage,
          codeParent: item.codeParent || item.code
        }));
      }

      objPaymentMethod = {
        ...objPaymentMethod,
        code: method.code,
        nameVn: method.name,
        nameEng: method.name,
        urlImage: method.icon,
        codeParent: item.codeParent || item.code
      };
      paymentMethodData.push(objPaymentMethod);
    });
    return paymentMethodData;
  };

  const renderPaymentKorea = (item: C2cPaymentConfig, index: number) => {
    switch (item.code) {
      case TypePayment.CASH:
        return item.isShow && renderItem(item, `${index}_${TypePayment.CASH}`);
      case TypePayment.KCP:
        return (
          !item.isShow &&
          (item.methods || []).map((method, indexKcp) =>
            renderItem(method, `${indexKcp}_${TypePayment.KCP}`)
          )
        );
      default:
        break;
    }
  };

  const renderItem = (method: C2cPaymentConfig, index: string) => {
    return (
      <div
        key={index}
        onClick={() => {
          dispatch(
            selectPaymentMethodAction({
              code: method.code,
              name: method.name,
              codeParent: method.codeParent || method.code
            })
          );
          dispatch(updatePaymentMethod());
        }}
        className={`select-payment-kr ${
          method.code === chosenPaymentMethod.code ? 'active' : ''
        }`}
      >
        <div className="select-payment-kr-item">
          <Radio
            className="mr-2"
            checked={method.code === chosenPaymentMethod.code}
          />
          {method.code !== TypeCard.CARD ? (
            <>
              <img
                className={
                  method.code === TypeCard.PAYCO ? 'payco' : 'img-default'
                }
                src={method.icon || method?.auth?.icon}
              />
              <span>
                {t(`payment_method_${method.code?.toLocaleLowerCase()}`)}
              </span>
            </>
          ) : (
            <span>{t('korea_local_cards')}</span>
          )}
        </div>
        {method.code === TypeCard.CARD && buildListBankIcon(method?.code)}
      </div>
    );
  };

  const buildListBankIcon = (bankCode: string | undefined) => {
    let bankSourceList: string[] = [];
    switch (bankCode) {
      case TypeCard.SAMSUNG:
        bankSourceList = [images.bankHana, images.bankDaegu, images.bankIBK];
        break;
      case TypeCard.CARD:
        bankSourceList = [
          images.bankCitiBank,
          images.bankSuHyUp,
          images.bankShinHan
        ];
        break;
      case TypeCard.PHONE:
        bankSourceList = [images.bankSKT, images.bankKT, images.bankTU];
        break;
      case TypeCard.PAYCO:
        bankSourceList = [
          images.bankVISA,
          images.bankMasterCard,
          images.bankJCB
        ];
        break;
      case TypeCard.VBANK:
        bankSourceList = [images.bankShinHan, images.bankHana, images.bankBNK];
        break;
      default:
        bankSourceList = [];
        break;
    }
    return (
      <div className="select-payment-kr-bank-icon">
        {bankSourceList.map((bankIcon: string, index: number) => {
          return <img key={index} src={bankIcon} />;
        })}
      </div>
    );
  };

  const renderPaymentVNPay = (item: C2cPaymentConfig, index: number) => {
    return (
      <div
        className="collapse-payment-vn"
        key={`${index}_${TypePayment.VNPAY}`}
      >
        <Collapse defaultActiveKey={['1']} expandIconPosition={'end'} ghost>
          <Panel
            className="collapse-panel"
            header={t('order_payment_vn')}
            key="1"
          >
            <div className="ml-4">
              <SelectPaymentVN
                paymentMethodDataDiff={getPaymentMethodData(item)}
                isIgnoreShowRequired={true}
              />
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  };
  return (
    <div className="select-payment-kr-container">
      {(paymentConfig || []).map((item: C2cPaymentConfig, index: number) => {
        if (item.code !== TypePayment.VNPAY.toLowerCase()) {
          return renderPaymentKorea(item, index);
        }

        return item.isShow && renderPaymentVNPay(item, index);
      })}
      {!!c2cValidateFields?.paymentMethod?.hasError && (
        <MessageAlert
          {...c2cValidateFields?.paymentMethod}
          toggle={toggleOrder}
          className="message-alert-payment-method"
        />
      )}
    </div>
  );
};

export default SelectPaymentKorea;
