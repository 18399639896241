import { useForm } from 'react-hook-form';
import { Button } from '@swift-247/s247.library.ui-core';
import { useSelector } from 'react-redux';
import { orderBillSelectors } from 'Stores/order-bill';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { FormHookItem } from 'Components/ReactFormHook';
import { E_INVOICE_FORM_CONSTANT } from 'Pages/C2cOrderPayment/constants';
import { DTOOrderVATInfo } from 'Services/v1/cargo-direct/dto';
import { usePopup } from 'Hooks';

const EInvoiceFormPopup = () => {
	const { t } = useTranslation();
	const { onSubmit } = usePopup();

	const vatInfo = useSelector(orderBillSelectors.get).vatInfo;
	const { control, handleSubmit, formState, getFieldState } = useForm({
		defaultValues: vatInfo.data,
	});
	const { isSubmitted } = formState; // do not remove, this is subscribing to form state

	const renderFieldForm = () => {
		return E_INVOICE_FORM_CONSTANT.map((fieldForm) => {
			return (
				<FormHookItem<DTOOrderVATInfo>
					key={fieldForm?.field}
					{...fieldForm}
					control={control}
					isShowMessageError={
						(isSubmitted ||
							getFieldState(fieldForm.field).isTouched) &&
						!!getFieldState(fieldForm?.field).error
					}
					messageError={
						getFieldState(fieldForm?.field)?.error?.message
					}
				></FormHookItem>
			);
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{renderFieldForm()}
			<Button className="w-100" type="submit" size="md">
				{t('global_confirm')}
			</Button>
		</form>
	);
};

export default EInvoiceFormPopup;
