import { DTOServiceCustomResponse } from 'Services/interfaces';
import { TypePayment } from 'Utilities/constants';
import { getConfirmationUrl, isWhitelistedHost, toUpperLetter } from 'Utilities/util';
import config from '../../../../config/config';
import { C2cOrderPayment, IPaymentKorea, PaymentCustom, PaymentKoreaParam } from '../models';
// const navigate = useNavigate();

declare const window: Window &
	typeof globalThis & {
	IMP: any;
};

export const c2cPaymentHandler = (
	res: DTOServiceCustomResponse<C2cOrderPayment, PaymentCustom>,
	params: PaymentKoreaParam,
  callback: (isGlobal: boolean, data?: IPaymentKorea) => void
) => {
	const { data, meta } = res;

	if (!data) return;
	const paymentKorea = () => {

		if (window?.IMP) {
			const { IMP } = window;
			IMP.init(meta?.custom?.paymentData?.merchantId);
			IMP.request_pay(params, (data: IPaymentKorea) => {
        if(callback) callback(true, data);
			});
		}
	};


  const paymentMethod = toUpperLetter(data.paymentMethod);
	if (
		paymentMethod === toUpperLetter(TypePayment.VNBANK) ||
		paymentMethod === toUpperLetter(TypePayment.VNPAY) ||
		paymentMethod === toUpperLetter(TypePayment.INTCARD)
	) {
		if(meta?.custom?.paymentUrl &&
			isWhitelistedHost(
				[config.vnpayRedirectDomain],
				meta?.custom?.paymentUrl || '',
			)) {
        if(callback) callback(false);
			(window as Window).location = meta?.custom?.paymentUrl;
		}
	} else if (
		paymentMethod === toUpperLetter(TypePayment.KCP) ||
		paymentMethod === toUpperLetter(TypePayment.EXIM_BAY)
	) {
		paymentKorea();
	} else if (paymentMethod === toUpperLetter(TypePayment.CASH) || paymentMethod === toUpperLetter(TypePayment.TOPUP)) {
    if(callback) callback(false);
		(window as Window).location = getConfirmationUrl(
			paymentMethod as TypePayment,
			true,
		);
	}
};

export default c2cPaymentHandler;
