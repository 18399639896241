import search from 'Assets/icon/search.svg';
import InputText from 'Components/InputText';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DTOPaymentCard } from 'Services/v1/payment/dto';
import { C2cPaymentConfig } from 'Stores/c2c-order-payment';
import { compareOnUISearchTexts } from 'Utilities/util';
import './index.scss';

interface BankItemProps {
	name: string;
	iconUrl: string;
	width?: number;
	height?: number;
	onClick?: (val: any) => void;
}

interface SelectBankProps {
	isOpen: boolean;
	onClose: React.MouseEventHandler<HTMLElement>;
	data: DTOPaymentCard[];
	onSelect?: (val: DTOPaymentCard | C2cPaymentConfig) => void;
}

const BankItem = (props: BankItemProps) => {
	const { name, iconUrl, width, height, onClick } = props;
	return (
		<div onClick={onClick} className={'select-banks-item'}>
			<img src={iconUrl} alt={''} width={width} height={height} />
			<div>{name}</div>
		</div>
	);
};

const SelectBanks = (props: SelectBankProps) => {
	const { isOpen, onClose, data, onSelect } = props;
	const [filteredData, setFilteredData] = useState<DTOPaymentCard[]>([]);
	const [searchValue, setSearchValue] = useState<string>('');
	const { t } = useTranslation();
	useEffect(() => {
		if (!data) return;
		if (!searchValue) return setFilteredData(data);
		if (searchValue) {
			setFilteredData(
				data.filter((paymentMethod) =>
					compareOnUISearchTexts(
						searchValue,
						paymentMethod.code || '',
					),
				),
			);
		}
	}, [data, searchValue]);

	// return <CustomPopup isOpen={isOpen} onClose={onClose} title={t('title_select_bank')}>

	// </CustomPopup>
	return (
		<>
			<InputText
				className="mt-4 mb-6"
				value={searchValue}
				name="search-box"
				placeholder={t('global_search')}
				onChange={(val) => setSearchValue(val.target.value)}
				addonBefore={<img alt={''} src={search} width="18" />}
			/>
			<div className={'fs-p-lg lh-p-lg fw-600 mb-5'}>
				{t('payment_method_supported_banks_title')}
			</div>
			<div className={'select-banks-list d-flex flex-wrap gap-2'}>
				{filteredData.map((item) => {
					return (
						<BankItem
							onClick={() => onSelect && onSelect(item)}
							key={item.code}
							name={item.code || ''}
							iconUrl={item.urlImage || ''}
							width={item.width}
							height={item.height}
						/>
					);
				})}
			</div>
		</>
	);
};

export default SelectBanks;
