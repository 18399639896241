
import OrderProductItems from 'Pages/C2cOrderPayment/components/c2cPickedUpProduct/OrderProductItems';
import { C2cOrderPaymentPathEnum } from '../enums';

export const C2cOrderPaymentRoutes: Array<any> = [
	// {
	// 	name: 'Order Product Item',
	// 	path: C2cOrderPaymentPathEnum.OrderProductItem,
	// 	exact: true,
	// 	component: <OrderProductItems />,
	// 	sideBar: false,
	// },
];
