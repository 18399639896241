import { useTranslation } from 'react-i18next';
import { DeliveryMethodEnum, PromoVoucherType, VoucherDiscountType } from '../../enums';
import threeDotIcon from 'Assets/icon/three-dot-icon.svg';
import './styles.scss';
import { C2cVoucherModel } from 'Stores/c2c-order-payment';
import moment from 'moment';
import { formatToCurrency } from 'Utilities/util';

interface PropsModel {
	className?: string;
	voucherType?: PromoVoucherType | string;
	voucherName?: string;
	discountType?: VoucherDiscountType | string;
	discountAmount?: number;
	endAt?: string;
	usageLimit?: number;
	onClick?: (item: C2cVoucherModel) => void;
}

const PromoVoucher = (props: PropsModel) => {
	const {
		className = '',
		voucherName,
		endAt,
		usageLimit,
		voucherType = PromoVoucherType.Voucher,
		discountAmount,
		discountType
	} = props;

	const classNameAttach = `${className} ${
		voucherType === PromoVoucherType.Voucher
			? 'primary-voucher'
			: 'yellow-voucher'
	}`;

	const { t } = useTranslation();

	return (
		<div className={`${classNameAttach} promo-voucher`}>
			<div className="promo-voucher-name">
				<div>{voucherType}</div>
				<div>x{usageLimit}</div>
			</div>
			<div className="promo-voucher-content">
				<div className="promo-voucher-info">
					<div className="promo-voucher-info-title">
						{discountType === VoucherDiscountType.Percent ? `${discountAmount}%` : `${formatToCurrency(discountAmount)}đ`}
					</div>
					<div className="text-gray-primary">
						{t(DeliveryMethodEnum.PromoVoucher_ToFromDate)}:
						<span className="title-medium-15">
							{' '}
							{moment(endAt).format('DD/MM/YYYY')}
						</span>
					</div>
					<div>
						<span className="promo-voucher-code">
							{voucherName}
						</span>
					</div>
				</div>
				<div className="promo-voucher-right">
					<img
						className="promo-voucher-right-menu"
						src={threeDotIcon}
						alt="three dot"
					></img>
					<div className="promo-voucher-right-seal">
						<span> {t('promo_voucher_right_seal')}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PromoVoucher;
