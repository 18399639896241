import { DTOHttpError } from '../interfaces';

class RootServiceV1 {
	protected _errorParser(err: any, defaultMessage = 'InternalError'): DTOHttpError {
		const {
			code,
			message,
      data
		} = err;
		return {
			code: 'InternalError',
			msg: message || defaultMessage,
			statusCode: code || 500,
      data: data || {}
		}
	}
}

export default RootServiceV1;
