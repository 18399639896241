import { Col, Modal, Row } from 'antd';
import ButtonClick from 'Components/ButtonClick';
import { ReactNode } from 'react';
import classNames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

interface Props {
	visible: boolean;
	title?: string|ReactNode;
	onHandleOk?: () => void;
	onHandleCancel?: () => void;
	cancelText?: string;
	okText?: string;
	children?: React.ReactNode;
	className?: string;
	hideFooter?: boolean;
	destroyOnClose?: boolean;
}

const ModalDialog = (props: Props) => {
	const {
		visible,
		title,
		onHandleOk,
		onHandleCancel,
		cancelText = 'Cancel',
		okText = 'OK',
		children,
		className,
		hideFooter,
		destroyOnClose,
	} = props;

	const containerClasses = classNames(
		`${classPrefix}-modal-dialog-container`,
		className,
	);

	return (
		<Modal
			className={`${containerClasses}`}
			visible={visible}
			title={title}
			onOk={onHandleOk}
			onCancel={onHandleCancel}
			cancelText={cancelText}
			okText={okText}
			destroyOnClose={destroyOnClose}
			footer={[hideFooter ? null : <div className="d-flex justify-content-end">
			<ButtonClick
				className="w-100"
				variant="ghost"
				onClick={onHandleCancel}
			>
				{cancelText}
			</ButtonClick>
			<ButtonClick
				className="w-100"
				variant="primary"
				onClick={onHandleOk}
			>
				{okText}
			</ButtonClick>
		</div>]}
		>
			{children}
		</Modal>
	);
};

export default ModalDialog;
