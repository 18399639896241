import editIcon from 'Assets/icon/edit-icon.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  c2cOrderPaymentSelectors,
  setNoteAction
} from 'Stores/c2c-order-payment';
import { TypeOrder } from 'Stores/c2c-order-payment/enums';
import { APRICOT_PEACH_BLOSSOM } from 'Utilities/constants';
import { CommentPopup } from '..';
import { DeliveryMethodEnum } from '../../enums';
import './styles.scss';

interface PropsModel {
  className?: string;
}

const TransactionDetail = (props: PropsModel) => {
  const [isOpenNoteOrder, setIsOpenNoteOrder] = useState(false);
  const { className = '' } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { typeOrder } = useSelector(c2cOrderPaymentSelectors.get);

  const isPremium = typeOrder === TypeOrder.PREMIUM;
  const isCargo = typeOrder === TypeOrder.CARGO;

  const { productSelected, weightAndSize } = useSelector(
    c2cOrderPaymentSelectors.get
  );

  const onSubmitNote = (note: string) => {
    setIsOpenNoteOrder(false);
    dispatch(setNoteAction(note));
  };

  return (
    <div className={`${className} transaction-detail`}>
      <div className="title-medium">
        {t(DeliveryMethodEnum.TransactionDetail_Title)}
      </div>
      {productSelected && Object.keys(productSelected).length > 0 && (
        <div className="delivery-method-perfume-wrap">
          <span>{productSelected.product}</span>
        </div>
      )}

      <div className="transaction-detail-info">
        <div
          className="transaction-detail-info-icon-wrap"
          onClick={() => setIsOpenNoteOrder(true)}
        >
          <img
            className="delivery-method-edit-icon"
            src={editIcon}
            alt="edit icon"
          ></img>
          <span>{t(DeliveryMethodEnum.TransactionDetail_NoteBtn)}</span>
        </div>

        {productSelected.code !== APRICOT_PEACH_BLOSSOM.code && (
          <div>
            <div className="title-medium-15">
              {t(DeliveryMethodEnum.TransactionDetail_Weight)}
              <span className="text-gray-primary">
                : {weightAndSize.weight} kg
              </span>
            </div>
            <div className="title-medium-15">
              {t(DeliveryMethodEnum.TransactionDetail_Size)}
              <span className="text-gray-primary">
                :{' '}
                {`${weightAndSize.longs || 0}x${weightAndSize.width || 0}x${
                  weightAndSize.height || 0
                } cm`}
              </span>
            </div>
          </div>
        )}
      </div>
      <CommentPopup
        isOpen={isOpenNoteOrder}
        onClose={() => setIsOpenNoteOrder(false)}
        maxLength={200}
        onSubmitNote={onSubmitNote}
      ></CommentPopup>
    </div>
  );
};

export default TransactionDetail;
