import { Tabs } from 'antd';
import React, { ReactNode } from 'react';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';

const { TabPane } = Tabs;

import './index.scss';

export interface ITableTabData {
	key: string,
	title: string
}

export interface ITableTabsProps {
	data: ITableTabData[],
	className?: string,
	activeTabKey?: string,
	onTabChange?: ((activeKey: string) => void)
}

const TableTabs = (props: ITableTabsProps) => {
	const {
		data = [],
		className,
		activeTabKey,
		onTabChange
	} = props;

	const containerClasses = classnames(`${classPrefix}-table-tabs`, className)

	return (
		<div className={containerClasses}>
			<Tabs animated={false} activeKey={activeTabKey} onChange={onTabChange} type="card">
				{data.map((tab) => {
					return <TabPane key={tab.key} tab={tab.title}/>
				})}
			</Tabs>
		</div>
	)
}

export default TableTabs;