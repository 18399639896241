import iconATMUrl from 'Assets/icon/atm-payment-method-icon.svg';
import { AxiosResponse } from 'axios';
import { CamelSnakeMapper } from 'Services/data-mappers';
import httpClient from 'Services/http-client';
import { DTOServiceResponse } from 'Services/interfaces';
import {
  DTOGetSupportedPaymentCardResponseRaw,
  DTOPaymentCard,
  DTOPaymentCardGroup,
  DTOPaymentCardRaw
} from 'Services/v1/payment/dto';
import RootServiceV1 from 'Services/v1/root';
import { TypePayment } from 'Utilities/constants';

class PaymentServiceV1 extends RootServiceV1 {
  constructor() {
    super();
  }

  private _cardInfoDataMapping(
    card: DTOPaymentCardRaw,
    child?: DTOPaymentCardRaw[]
  ): DTOPaymentCardGroup {
    const newChild = child?.map(
      CamelSnakeMapper.toCamelCase<DTOPaymentCardRaw, DTOPaymentCard>
    );

    const newCard = CamelSnakeMapper.toCamelCase<
      DTOPaymentCardRaw,
      DTOPaymentCardGroup
    >(card);

    if (newChild) {
      newCard.isGroup = true;
      newCard.child = newChild;
    }

    return newCard;
  }

  public async getSupportedPaymentCards(): Promise<
    DTOServiceResponse<DTOPaymentCardGroup[]>
  > {
    try {
      const response = (await httpClient().get(
        '/v1/paymentcards'
      )) as AxiosResponse<DTOGetSupportedPaymentCardResponseRaw>;

      const data = response.data.data;

      const internationalCards = data?.INTCARD || [];
      const atmCards = data?.VNBANK || [];
      const topupCards = data?.TOPUP || [];

      const atmCardInfo: DTOPaymentCard = {
        code: 'ATM',
        nameEng: 'ATM',
        nameVn: 'global_payment_method_name_atm',
        description: 'global_payment_method_description_atm',
        urlImage: iconATMUrl,
        bankType: TypePayment.VNBANK
      };

      const intCardData = internationalCards.map(card =>
        this._cardInfoDataMapping(card)
      );

      const topupData = topupCards.map(card => this._cardInfoDataMapping(card));

      intCardData.unshift(this._cardInfoDataMapping(atmCardInfo, atmCards));

      return {
        statusCode: 200,
        data: [...topupData, ...intCardData]
      };
    } catch (err) {
      const parsedError = this._errorParser(err);
      return {
        statusCode: parsedError.statusCode,
        error: parsedError
      };
    }
  }
}

export default new PaymentServiceV1();
