import Paginate from 'Components/Paginate';
import RangeDatePicker from 'Components/RangeDatePicker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTopupTransaction,
  resetTopupTransition,
  topupSelectors
} from 'Stores/topup';
import { DATE_FORMATS, PAGESIZE } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { formatDateTimeParamUrl } from 'Utilities/util';
import './components.scss';
import TransactionItem from './TransactionItem';

const Transaction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useSelector(topupSelectors.get)?.topupTransaction;
  const formDateDefault = moment()
    .startOf('month')
    .format(DATE_FORMATS.DDMMYYYYHHmm);
  const toDateDefault = moment().format(DATE_FORMATS.DDMMYYYYHHmm);
  const [rangeDate, setRangeDate] = useState([formDateDefault, toDateDefault]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!rangeDate || rangeDate.length === 0) return;
    dispatch(
      getTopupTransaction({
        fromDate: formatDateTimeParamUrl(rangeDate[0]),
        toDate: formatDateTimeParamUrl(rangeDate[1]),
        page
      })
    );
  }, [rangeDate, page]);

  useEffect(() => {
    return () => {
      dispatch(resetTopupTransition());
    };
  }, []);

  const compClass = `${classPrefix}-transaction col-md-12 col-12 d-flex justify-content-between mb-4 py-0 px-0`;

  return (
    <div className="row">
      <div className={compClass}>
        <div className="d-flex flex-column mb-2">
          <span className="fw-500 fs-6">{t('topup_recent_transactions')}</span>
        </div>
        <RangeDatePicker
          isBackground={false}
          rangeDate={[rangeDate[0], rangeDate[1]]}
          showTime
          rangeDayAllow={90}
          onChangeDate={(fromDate, toDate) => setRangeDate([fromDate, toDate])}
        />
      </div>
      <div className="transaction-item px-0 py-0">
        {data?.transactions?.length ? (
          data.transactions?.map(transaction => (
            <TransactionItem transaction={transaction} />
          ))
        ) : (
          <div className="d-flex justify-content-center pt-5">
            {t('topup_you_have_no_recent_transactions')}
          </div>
        )}
        {!!data?.transactions && !!data.total && (
          <Paginate
            total={data.total}
            size={PAGESIZE}
            current={page}
            onPageChange={page => setPage(page)}
          />
        )}
      </div>
    </div>
  );
};

export default Transaction;
