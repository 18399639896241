import { Pagination, PaginationProps } from 'antd';
import classNames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss'

interface PaginateProps {
  onPageChange?: PaginationProps['onChange'];
  total?: number;
  size?: number;
  current?: number;
  showSizeChanger?: boolean;
  className?: string;
}

const Paginate = (props: PaginateProps) => {
  const {
    onPageChange,
    total = 1,
    size = 1,
    current = 0,
    showSizeChanger = false,
    className,
  } = props;

  const containerClasses = classNames(
    `${classPrefix}-pagination-container`,
    className,
  );

  return (
    <div className="text-center mt-5">
      <Pagination
        className={containerClasses}
        pageSize={size}
        total={total}
        current={current}
        onChange={onPageChange}
        showSizeChanger={showSizeChanger}
      />
    </div>
  );
};
export default Paginate;
