import BGTopUp from 'Assets/icon/bg-top-up.svg';
import BonusPoint from 'Assets/icon/bonus-point.svg';
import TopUpDecrease from 'Assets/icon/top-up-decrease.svg';
import TopUpIncrease from 'Assets/icon/top-up-increase.svg';
import { classPrefix } from 'Utilities/global';

import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTopupInfo, topupSelectors } from 'Stores/topup';
import { numberWithCommas, toUpperLetter } from 'Utilities/util';
import './components.scss';

const TopUpBonus = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { topupInfo } = useSelector(topupSelectors.get);
  useEffect(() => {
    dispatch(getTopupInfo());
  }, []);

  const compClass = `${classPrefix}-top-up-bonus row p-4 mb-6`;
  return (
    <div className={compClass} style={{ backgroundImage: `url(${BGTopUp})` }}>
      <div className="top-up-top-up col-md-6 col-sm-12 col-12">
        <span className="me-2 topup-point">
          <span className="topup">Topup</span>
          <span className="point">{toUpperLetter(t('topup_value_point'))}</span>
        </span>
        <div className="d-flex flex-column">
          <div className="text-label">Topup</div>
          <div className="fw-500 fs-5">
            {numberWithCommas(
              topupInfo?.agentPoints ? topupInfo.agentPoints[0]?.amount : 0
            )}{' '}
            {t('topup_value_point')}
          </div>
          <div className="top-up-total-arises d-flex mt-3">
            <div className="me-3 increase">
              <span className="mr-2">{t('topup_total_increase')}: </span>
              <span className="d-flex align-items-center">
                <span className="text-plus">
                  {numberWithCommas(topupInfo?.plusTopup || 0)}
                </span>
                <img src={TopUpIncrease} width="14" />
              </span>
            </div>
            <div className="decrease">
              <span className="mr-2">{t('topup_total_decrease')}: </span>
              <span className="d-flex align-items-center">
                <span className="text-deduct">
                  {numberWithCommas(topupInfo?.deductTopup || 0)}
                </span>
                <img src={TopUpDecrease} width="14" />
              </span>
            </div>
          </div>
        </div>
      </div>
      {isMobile && <div className="pt-2 pb-3 px-0"><hr/></div>}
      <div className="top-up-bonus-point col-md-6 col-sm-12 col-12">
        <img src={BonusPoint} width="25" height="25" className="me-2" />
        <div className="d-flex flex-column">
          <div className="text-label">{t('topup_bonus')}</div>
          <div className="fw-500 fs-5">0 {t('topup_value_point')}</div>
        </div>
      </div>
    </div>
  );
};

export default TopUpBonus;
