import React, { ReactNode } from 'react';
import { PopupContainer } from '@swift-247/s247.library.ui-core';
import { classPrefix } from 'Utilities/global';
import { CloseOutlined } from '@ant-design/icons';
import './index.scss';
import { PopupContainerProps } from '@swift-247/s247.library.ui-core/components/popup';
export interface CustomPopupProps extends PopupContainerProps{
	title: string;
	icon?: ReactNode;
}

const CustomPopup = (props: CustomPopupProps) => {
	const {
		title,
		icon,
		children,
		isOpen,
		className='',
		width,
		onClose
	} = props;

	return <PopupContainer isOpen={isOpen} onClose={onClose} width={width} className={`${classPrefix}-custom-popup ${className}`}>
		<div onClick={(e) => e.stopPropagation()}>
			<div className={`${classPrefix}-custom-popup-header`}>
				<div className={`${classPrefix}-custom-popup-header-info`}>
					{icon}
					<div className={`${classPrefix}-custom-popup-header-info-title`}>{title}</div>
				</div>
				<CloseOutlined className={'cursor-pointer'} onClick={onClose}/>
			</div>
			<div className={`${classPrefix}-custom-popup-body`}>
				{children}
			</div>
		</div>

	</PopupContainer>
}

export default CustomPopup