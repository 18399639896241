import iconATMURL from 'Assets/icon/atm-payment-method-icon.svg';
import iconJCBdURL from 'Assets/icon/jcb-payment-method-icon.svg';
import iconMasterCardURL from 'Assets/icon/master-card-payment-method-icon.svg';
import iconUnionPayURL from 'Assets/icon/union-pay-payment-method-icon.svg';
import iconVisaURL from 'Assets/icon/visa-payment-method-icon.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DTOPaymentCard, DTOPaymentCardGroup } from 'Services/v1/payment/dto';
import { c2cOrderPaymentSelectors, selectPaymentMethodAction } from 'Stores/c2c-order-payment';
import { orderBillSelectors, updatePaymentMethod } from 'Stores/order-bill';
import SelectPaymentMethodCard from '../SelectPaymentMethodCard';

const PaymentMethodItem = (props: {
	method: DTOPaymentCardGroup;
	handleSetParentSetter: (
		val: DTOPaymentCard[],
		isOpenBanksList: boolean,
	) => void;
}) => {
	const { method, handleSetParentSetter } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
  	const { isGlobal } = useSelector(
		c2cOrderPaymentSelectors.get,
	);
	const selectedPaymentMethod = useSelector(
		orderBillSelectors.get,
	).paymentMethod;

	const imgMapping = {
		VISA: iconVisaURL,
		MASTERCARD: iconMasterCardURL,
		UPI: iconUnionPayURL,
		JCB: iconJCBdURL,
		ATM: iconATMURL,
	};

	const isChildMethodSelectedOnThisGroup =
		(method.isGroup || false) &&
		!!method.child?.find((m) => m.code === selectedPaymentMethod?.code);

	const renderedMethod = isChildMethodSelectedOnThisGroup
		? selectedPaymentMethod
		: method;

	return (
		<SelectPaymentMethodCard
			key={renderedMethod?.code}
			isGroup={method.isGroup}
			disabled={method.disabled}
			onGroupAction={
				method.isGroup
					? () => handleSetParentSetter(method.child || [], true)
					: undefined
			}
			className={'mb-3'}
			iconUrl={
				imgMapping[
					(renderedMethod?.code ||
						'') as unknown as keyof typeof imgMapping
				] ||
				renderedMethod?.urlImage ||
				''
			}
			name={
				!isChildMethodSelectedOnThisGroup
					? t(
							`payment_method_${
								renderedMethod?.code?.toLocaleLowerCase() || ''
							}`,
					  )
					: renderedMethod?.nameEng || ''
			}
      customDescription={method?.customDescription}
			checked={
				method.isGroup
					? isChildMethodSelectedOnThisGroup
					: method.code === selectedPaymentMethod?.code
			}
			value={method}
			iconWidth={isChildMethodSelectedOnThisGroup ? 75 : method.width}
			iconHeight={isChildMethodSelectedOnThisGroup ? 15 : method.height}
			onChange={(val) => {
				if (method.isGroup || method.disabled) return;
        if (isGlobal) {
          dispatch(selectPaymentMethodAction(val));
        }
				dispatch(updatePaymentMethod(val));
			}}
		/>
	);
};

export default PaymentMethodItem;
