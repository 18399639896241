import ButtonClick from 'Components/ButtonClick';
import CustomPopup from 'Components/CustomPopup';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryMethodEnum } from '../../enums';
import './styles.scss';

interface PropsModel {
  maxLength?: number;
  isOpen: boolean;
  className?: string;
  onClose: () => void;
  onSubmitNote: (note: string) => void;
}

const CommentPopup = (props: PropsModel) => {
  const {
    maxLength = 200,
    className = '',
    isOpen,
    onClose,
    onSubmitNote,
  } = props;

  const [length, setLength] = useState(0);
  const [note, setNote] = useState('');

  const { t } = useTranslation();

  const handelOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (length >= maxLength) {
      setNote(e.target.value.slice(0, maxLength));
      return;
    }
    setNote(e.target.value);
    setLength(note.length);
  };

  return (
    <CustomPopup
      isOpen={isOpen}
      onClose={onClose}
      title={t(DeliveryMethodEnum.CommentPopup_Title)}
      className={`${className} comment-popup`}
    >
      <div className="comment-popup-content">
        <textarea rows={4} value={note} onChange={handelOnChange} />
        <span className="text-gray-primary">
          {length}/{maxLength}
        </span>
        <ButtonClick
          containerClassName="comment-popup-btn"
          onClick={() => onSubmitNote(note)}
        >
          {t(DeliveryMethodEnum.CommentPopup_ButtonSubmit)}
        </ButtonClick>
      </div>
    </CustomPopup>
  );
};

export default CommentPopup;
