import React from 'react';
import Chip from 'Components/Chip';
import classNames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './index.scss';

export interface ChipGroupProps {
	data: Array<{
		label: string
		value: string
	}>
	onChange: any
	currentValue?: string
	className?: string
}

const ChipGroup = (props: ChipGroupProps) => {
	const {data, currentValue, onChange, className} = props;
	const containerClasses = classNames(
		`${classPrefix}-chip-group`,
		className,
	);
	return <div className={containerClasses}>{data.map(chip => {
		return <div className={'cursor-pointer'} key={chip.value} onClick={() => onChange(chip.value)}>
			<Chip label={chip.label} />
		</div>
	})}</div>
}

export default ChipGroup
