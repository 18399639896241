import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import { getSettingGrapAction } from 'Stores/grap/grap-reducer';
import localization from './localization';
import Layout from './pages/common/Layout';
import { MainPageEnum, RouteModel, RoutersMain } from './routes';
import {
  AddressRoutes,
  C2cOrderPaymentRoutes,
  OrderBillRoutes
} from './routes/constants';
import { profileSliceSelectors } from 'Stores/profile';
import { PROFILE_TYPES } from 'Utilities/constants';

const App = () => {
	localization.init();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSettingGrapAction());
	}, [])
	const profile = useSelector(profileSliceSelectors.get).data;
	let routes = [
		...RoutersMain,
		...AddressRoutes,
		...C2cOrderPaymentRoutes,
		...OrderBillRoutes,
	] as Array<RouteModel>;

	const routesWithSidebar = routes.filter((route) => route.sideBar);
	const routesWithoutSidebar = routes.filter((route) => !route.sideBar);

	const renderListRoutes = (routes: Array<RouteModel>) => {
		let selectedRoutes = routes;
		if(profile.type === PROFILE_TYPES.personal) {
			selectedRoutes = routes.filter((route) => route.path !== MainPageEnum.OrderBill)
		}
		return selectedRoutes.map((route, index) => (
			<Route key={index} path={route.path} element={route.component}>
				{route?.children ? renderListRoutes(route.children) : <></>}
			</Route>
		));
	};

	return <Router>
		<Routes>
			<Route element={<Layout isShowSidebar={true} />}>
				{renderListRoutes(routesWithSidebar)}
			</Route>
			<Route element={<Layout isShowSidebar={false} />}>
				{renderListRoutes(routesWithoutSidebar)}
			</Route>
			<Route
				path="*"
				element={<Navigate to="/c2c-order-payment" replace />}
			/>
		</Routes>
	</Router>;
};

export default App;
