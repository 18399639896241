import PageTitle from 'Pages/common/PageTitle';
import OrderBillSelectAddress from 'Pages/OrderBill/SelectAddress';
import SelectDeliveryMethod from 'Pages/OrderBill/SelectDeliveryMethod';
import SelectFlight from 'Pages/OrderBill/SelectFlight';
import SelectFlightFilter from 'Pages/OrderBill/SelectFlightFilter';
import SelectPaymentMethod from 'Pages/OrderBill/SelectPaymentMethod';
import TaxAndFee from 'Pages/OrderBill/TaxAndFee';
import { createContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import OrderBillContainerLeftStyle from './styles';

const OrderBill = () => {
	const [isTop, setIsTop] = useState(false);
	const [isSticker, setIsSticker] = useState(false);
	const { t } = useTranslation();
	const containerRef = useRef<HTMLDivElement>(null);
	const refElementRight = useRef<HTMLDivElement>(null);
	const refElementLeft = useRef<HTMLDivElement>(null);
  const ThemeContext = createContext('light');
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'auto',
		});
	}, []);

	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll);
	// 	return () => window.removeEventListener('scroll', handleScroll);
	// }, []);

	// useEffect(() => {
	// 	const observer = new IntersectionObserver(handleScroll, options);
	// 	if(containerRef?.current) {
	// 		observer.observe(containerRef.current);
	// 	}
	// 	return ()=>{
	// 		if(containerRef.current) {
	// 			observer.unobserve(containerRef.current)
	// 		}
	// 	}
	// }, [options, containerRef]);

	// const handleScroll = () => {
	// 	if (window.pageYOffset - heightScroll.current > 50) {
	// 		if (window.pageYOffset > 200) {
	// 			heightScroll.current = window.pageYOffset;
	// 			setIsTop(false);
	// 		}
	// 	}
	//
	// 	if (window.pageYOffset - heightScroll.current < -50) {
	// 		if (window.pageYOffset > 500) {
	// 			heightScroll.current = window.pageYOffset;
	// 			setIsTop(true);
	// 		}
	// 	}
	//
	// 	if (window.pageYOffset < 500) {
	// 		heightScroll.current = 500;
	// 		setIsTop(false);
	// 	}
	//
	// 	if (
	// 		refElementLeft.current &&
	// 		refElementRight?.current &&
	// 		refElementLeft.current.offsetHeight <=
	// 			refElementRight?.current?.offsetHeight
	// 	) {
	// 		return;
	// 	}
	// 	if (
	// 		refElementLeft.current &&
	// 		window?.pageYOffset >= refElementLeft.current.offsetTop
	// 	) {
	// 		setIsSticker(true);
	// 	} else {
	// 		setIsSticker(false);
	// 	}
	// };

	return (
		<>
			<PageTitle title={t('title_page_order')} />
			<div className={`row mb-4`} ref={containerRef}>
				<div
					className={'col-xl-8 col-lg-8 col-lg-12 pe-xl-0'}
					ref={refElementLeft}
				>
					<div className={'mb-4'}>
						<OrderBillSelectAddress />
					</div>
					<div className={'mb-4'}>
						<SelectFlightFilter />
					</div>
					<div className={'mb-4'}>
						<SelectFlight/>
					</div>
					<div>
						<SelectDeliveryMethod />
					</div>
				</div>
				<OrderBillContainerLeftStyle
					className="col-xl-4 col-lg-4 col-lg-12 row h-100 justify-content-end p-0 m-0"
					ref={refElementRight}
				>
					<div
						className={
							isSticker
								? `order-bill-payment-tax-swap pm-stick ${
										isTop
											? 'pm-stick-top'
											: 'pm-tick-bottom'
								  }`
								: `order-bill-payment-tax-swap`
						}
					>
						<div className="order-bill-payment-method mb-4">
							<SelectPaymentMethod />
						</div>
						<div className="order-bill-tax-fee mb-4">
							<TaxAndFee />
						</div>
					</div>
				</OrderBillContainerLeftStyle>
			</div>
		</>
	);
};

export default OrderBill;
