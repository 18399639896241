import { UnitType } from 'Components/Enums';
import FieldUnit from 'Components/FieldUnit/fieldUnit';
import MessageAlert from 'Components/MessageAlert/messageAlert';
import { FieldUnitModel } from 'Components/Models';
import { MESSAGE_ALERT_ERROR } from 'Pages/C2cOrderPayment/constants';
import {
	C2cOnChangeTypeAction,
	C2cOverPaymentTextEnum
} from 'Pages/C2cOrderPayment/enums';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	c2cOrderPaymentSelectors, C2cOverPaymentState, getSuggestedDimensionList,
	onChangeHeightAction,
	onChangeLongAction,
	onChangeWidthAction, setSize,
} from 'Stores/c2c-order-payment';
import { toUpperLetter } from 'Utilities/util';
import './styles.scss';
import ChipGroup from 'Components/ChipGroup';
import { useEffect, useMemo, useState } from 'react';

interface SelectSizeProps {
	className?: string;
	onAfterChange?: () => void;
}

const SelectSize = (props: SelectSizeProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const {
		suggestedDimensionList
	} = useSelector(c2cOrderPaymentSelectors.get) as C2cOverPaymentState;

	useEffect(() => {
		dispatch(getSuggestedDimensionList())
	}, [])

	const [suggestedSize, setSuggestedSize] = useState<string | undefined>();
	const { className } = props;
	const { weightAndSize, c2cValidateFields, toggleOrder, checkDimWeight } =
		useSelector(c2cOrderPaymentSelectors.get);

	const fieldUnit: FieldUnitModel = {
		type: UnitType.Input,
		unit: 'cm'
	};

	const parsedSuggestedDimensionList = useMemo(() => {
		if(!suggestedDimensionList) return [];
		return suggestedDimensionList?.map(({long, width, height}) => ({
			value: `${long}x${width}x${height}`,
			label: `${long}x${width}x${height} cm`
		}))
	}, [suggestedDimensionList])

	useEffect(() => {
		if(!suggestedSize) return;
		const [long, width, height] = suggestedSize.split('x');
 		dispatch(setSize({
			long: parseInt(long),
			width: parseInt(width),
			height: parseInt(height)
		}))
	}, [suggestedSize])


	const suggestedSizeList = [{
		value: '10x10x20',
		label: '10x10x20 cm'
	}, {
		value: '20x20x30',
		label: '20x20x30 cm'
	}, {
		value: '5x5x5',
		label: '5x5x5 cm'
	}, {
		value: '2x2x2',
		label: '2x2x2 cm'
	}, {
		value: '8x8x8',
		label: '8x8x8 cm'
	}]

	const handleOnChange = (value: number, action: C2cOnChangeTypeAction) => {
		switch (action) {
		case C2cOnChangeTypeAction.Longs:
			dispatch(onChangeLongAction(value ? value : undefined));
			break;

		case C2cOnChangeTypeAction.Width:
			dispatch(onChangeWidthAction(value ? value : undefined));
			break;

		case C2cOnChangeTypeAction.Height:
			dispatch(onChangeHeightAction(value ? value : undefined));
			break;

		default:
			break;
		}
	};

	return (
		<div className={`${className} select-size`}>
			<div style={{ visibility: 'visible' }}></div>
			<div className="select-size-wrap">
				<div className="select-size-item">
					<div className="select-size-item-title">
						{t(C2cOverPaymentTextEnum.PSAW_SelectSize_Longs)}
					</div>
					<FieldUnit
						className="select-weight-unit"
						item={fieldUnit}
						value={weightAndSize?.longs}
						min={0}
						onBlurChange={value =>
							handleOnChange(value, C2cOnChangeTypeAction.Longs)
						}
					></FieldUnit>
				</div>
				<div className="select-size-item">
					<div className="select-size-item-title">
						{t(C2cOverPaymentTextEnum.PSAW_SelectSize_Width)}
					</div>
					<FieldUnit
						item={fieldUnit}
						className="select-weight-unit"
						value={weightAndSize?.width}
						min={0}
						onBlurChange={value =>
							handleOnChange(Number(value), C2cOnChangeTypeAction.Width)
						}
					></FieldUnit>
				</div>
				<div className="select-size-item">
					<div className="select-size-item-title">
						{t(C2cOverPaymentTextEnum.PSAW_SelectSize_Height)}
					</div>
					<FieldUnit
						item={fieldUnit}
						className="select-weight-unit"
						value={weightAndSize?.height}
						min={0}
						onBlurChange={value =>
							handleOnChange(Number(value), C2cOnChangeTypeAction.Height)
						}
					></FieldUnit>
				</div>
			</div>
			{!!c2cValidateFields?.size?.hasError && (
				<MessageAlert
					{...c2cValidateFields?.size}
					toggle={toggleOrder}
					className="message-alert-size"
				/>
			)}

			{!!(
				!c2cValidateFields?.size?.hasError &&
				checkDimWeight &&
				checkDimWeight.isCheckDimWeight
			) && (
				<MessageAlert
					{...MESSAGE_ALERT_ERROR}
					message={toUpperLetter(checkDimWeight.errMsg, false)}
					toggle={toggleOrder}
					className="message-alert-size"
				/>
			)}
			<ChipGroup className={'mt-4'} data={parsedSuggestedDimensionList} onChange={(size: string) => setSuggestedSize(size)} currentValue={suggestedSize}/>
		</div>
	);
};

export default SelectSize;
