import GMaps from 'Components/GMaps';
import AddressBookServiceV1 from 'Services/v1/address-book';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InputText from 'Components/InputText';
import { AutoComplete, Row, Col, Tooltip } from 'antd';
import search from 'Assets/icon/search.svg';
import images from 'Assets/images'
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { DTOSearchGoogleAddress } from 'Services/v1/address-book/dto';

import './index.scss';
import Spinner from 'Components/Spinner';


type LatLngLiteral = google.maps.LatLngLiteral;

type AutocompleteSessionToken = google.maps.places.AutocompleteSessionToken;

export interface GoogleAddressSelectOption {
	label: string
	value: string
	id: string
}

interface SearchAddressProps {
	onChange: (data: DTOSearchGoogleAddress) => void
	selectedAddressDetail: DTOSearchGoogleAddress | undefined | null
	center?: LatLngLiteral
	draggable?: boolean
}

const SearchAddress = (props: SearchAddressProps) => {
	const {onChange, selectedAddressDetail, center, draggable} = props;
	const ref = useRef<HTMLDivElement>(null);

	const [keyword, setKeyword] = useState('');
	const [addressOptions, setAddressOptions] = useState<any[]>([]);
	const [selectedAddressOption, setSelectedAddressOption] = useState<string>();
	const [mapCenter, setMapCenter] = useState<LatLngLiteral | undefined>(center);
	const [markerLocation, setMarkerLocation] = useState<LatLngLiteral | null>();
	const [isFetchingMyLocation, setIsFetchingMyLocation] = useState<boolean>(false);

	useEffect(() => {
		setMapCenter(center)
	}, [center])

	useEffect(() => {
		if(!selectedAddressDetail) return;
		setKeyword(selectedAddressDetail.formattedAddress)
		setMarkerLocation({
			lat: selectedAddressDetail.geometry.location.lat(),
			lng: selectedAddressDetail.geometry.location.lng()
		})

	}, [selectedAddressDetail])

	const {t} = useTranslation();

	const sessionToken = useMemo(() => {
		if(!google) return;
		return new google.maps.places.AutocompleteSessionToken();
	}, [selectedAddressOption, google])

	const fetchGoogleAddresses = useCallback(debounce((keyword: string, sessionToken: AutocompleteSessionToken) => {
		return AddressBookServiceV1.getAutoCompleteGoogleAddress({
			query: keyword,
			sessionToken
		}).then(resp => {
			const {data} = resp;
			if(!data) return setAddressOptions([]);
			const optionData = data.map(item => ({
				label: item.description,
				value: item.description,
				id: item.placeId
			}))
			setAddressOptions(optionData);
		})
	}, 300), [])

	const onSearchHandler = (value: string) => {
		setKeyword(value);
		fetchGoogleAddresses(value, sessionToken);
	};

	const onSelectHandler = async (selectedValue: string, option: GoogleAddressSelectOption) => {
		const newSelectedAddressOption = option.id;

		setSelectedAddressOption(newSelectedAddressOption);
		const googleAddressDetail = await AddressBookServiceV1.getGoogleAddressDetail({
			placeId: newSelectedAddressOption,
			attrContainer: ref.current
		})

		if(!googleAddressDetail.data) return;

		const selectedLocation = {
			lat: googleAddressDetail?.data?.geometry.location.lat(),
			lng: googleAddressDetail?.data?.geometry.location.lng()
		}

		setMapCenter(selectedLocation)

		setMarkerLocation(selectedLocation)

		onChange(googleAddressDetail.data)
	}

	const onDragendChangeHandler = async (lat: number, lng: number) => {
		const googleAddressDetail = await AddressBookServiceV1.getGoogleReverseGeocoding({
			location: {lat, lng}
		})
		if(!googleAddressDetail?.data) return;
		setMarkerLocation({
			lat,
			lng
		})

		onChange({
			...googleAddressDetail.data,
			geometry: {
				location: {
					lat: () => lat,
					lng: () => lng,
				}
			}
		})
	}

	const onUseMyLocationHandler = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if ('geolocation' in navigator) {
			setIsFetchingMyLocation(true);
			navigator.geolocation.getCurrentPosition(async (position) => {
				const lat = position.coords.latitude;
				const lng = position.coords.longitude;
				const googleAddressDetail = await AddressBookServiceV1.getGoogleReverseGeocoding({
					location: {lat, lng}
				})
				if(!googleAddressDetail?.data) return;
				setMarkerLocation({
					lat,
					lng
				})

				setMapCenter({
					lat,
					lng
				})

				onChange(googleAddressDetail.data)
				setIsFetchingMyLocation(false)
			}, (err) => {
				console.error(err);
			});
		} else {
			console.error('Location service not supported')
		}
	}


	return <div style={{ height: '100%' }}>
		<div className={'search-address-autocomplete-gmap'} style={{ height: '100%' }}>
			<GMaps
				isShowMarker={true}
				markerLocation={markerLocation}
				center={mapCenter || { lat: 10.8121385, lng: 106.6778963 }}
				dragendChange={onDragendChangeHandler}
				height={'100%'}
				draggable={draggable}
			/>

			<div className={'search-address-autocomplete-input-wrapper'}>
				<div className={'search-address-autocomplete'}>
					<AutoComplete
						defaultActiveFirstOption={true}
						style={{ width: 500 }}
						options={addressOptions}
						onSearch={onSearchHandler}
						onSelect={onSelectHandler}

						value={keyword}
					>
						<InputText
							value={keyword}
							name="search-box"
							className={'search-address-autocomplete-input'}
							onClick={() => {}}
							placeholder={t('placeholder_search_address_autocomplete')}
							addonBefore={<img src={search} width="18" />}
							addonAfter={!isFetchingMyLocation ? <Tooltip title={t('label_use_my_location')}><img onClick={onUseMyLocationHandler} src={images.icUseMyLocation} width={18}/></Tooltip> : <Spinner/>}
						/>
					</AutoComplete>
				</div>
			</div>
		</div>

		<div ref={ref}/>
	</div>
}

export default SearchAddress;
