import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GrapSettingModel } from '.';
import { RootState } from '..';

export interface GrapState {
	settings: Array<GrapSettingModel>;
	blogs: Array<any>;
	images: Array<any>;
	staticPages: Array<any>;
	packageTypes: Array<any>;
}

const initialState: GrapState = {
	settings: [],
	blogs: [],
	images: [],
	staticPages: [],
	packageTypes: [],
};

export const grapSlice = createSlice({
	name: 'grap',
	initialState,
	reducers: {
		getSettingGrapAction: (state) => {},
		getSettingGrapSuccess: (state, action: PayloadAction<GrapState>) => {
			state.settings = action?.payload?.settings;
			state.blogs = action?.payload?.blogs;
			state.images = action?.payload?.images;
			state.packageTypes = action?.payload?.packageTypes;
			state.staticPages = action?.payload?.staticPages;
		},
	},
});

export const { getSettingGrapAction, getSettingGrapSuccess } =
	grapSlice.actions;

export const grapSelectors = {
	get: (state: RootState) => state.grap,
};
const GrapReducer = grapSlice.reducer;

export default GrapReducer;
