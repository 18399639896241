import Bell from 'Assets/icon/noti-bell.svg'
import moment from 'moment';
import './index.scss'

const EstimatedDelivery = ({datetime }:{datetime: string|undefined}) => {
	return <>
		{datetime && (<div className="estimated-delivery-time mb-4">
			<img src={ Bell } width="30" />
			<span className=' ms-2'>Shipper sẽ lấy hàng vào </span>
			<span className='fw-500'>{moment(datetime).format('DD/MM/YYYY HH:mm')}</span>
		</div>)}
	</>;
};

export default EstimatedDelivery;