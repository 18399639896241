import { DTOOrder } from 'Services/v1/cargo-direct/dto';

import iconSwiftUrl from 'Assets/icon/swift-icon.svg';
import { QRCodeSVG } from 'qrcode.react';
import { forwardRef, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { profileSliceSelectors } from 'Stores/profile';
import { PROFILE_TYPES, SWIFT247_HOTLINE } from 'Utilities/constants';
import './index.scss';

const PrintWaybill = forwardRef(
  (props: { orderDetail: DTOOrder }, ref: Ref<HTMLDivElement>) => {
    const { orderDetail } = props;
    const profile = useSelector(profileSliceSelectors.get);
    const { t } = useTranslation();

    return (
      <div ref={ref} className="waybill">
        <div className="img-logo">
          <img width={120} height={50} src={iconSwiftUrl} alt="swift247" />
        </div>
        <div className="content">
          <div className="left">
            <div>
              <QRCodeSVG size={100} value={orderDetail.orderId} />
            </div>
            <div className="order-number">
              <span>{orderDetail.orderId}</span>
            </div>
          </div>
          <div className="right">
            <div className="row">
              <div className="col-md-3">
                <span>{t('waybill_acc_name')}</span>
              </div>
              <div className="col-md-9 right-fw">
                <span>
                  {profile?.data.type === PROFILE_TYPES.personal
                    ? profile?.data.name
                    : profile?.data.companyName}
                </span>
              </div>
              <div className="col-md-3 mt-1">
                <span>{t('waybill_receiver')}</span>
              </div>

              <div className="col-md-9 mt-1 right-fw">
                <span>{orderDetail.toName}</span>
              </div>
              <div className="col-md-3 mt-1">
                <span>{t('waybill_phone_number')}</span>
              </div>

              <div className="col-md-9 mt-1 right-fw">
                <span>{orderDetail.toPhone}</span>
              </div>
              <div className="col-md-3 mt-1">
                <span>{t('waybill_address')}</span>
              </div>

              <div className="col-md-9 mt-1 right-fw">
                <span>{orderDetail.receiverAddress}</span>
              </div>
              <div className="col-md-3 mt-1">
                <span>{t('waybill_delivery_type')}</span>
              </div>

              <div className="col-md-9 mt-1 right-fw">
                <span>{orderDetail.deliveryType}</span>
              </div>

              <div className="col-md-3 mt-1">
                <span>{t('waybill_note')}</span>
              </div>
              {orderDetail.orderDetails && (
                <div className="col-md-9 mt-1 right-fw">
                  <span>
                    {t(
                      `${orderDetail.orderDetails[0]?.categories?.name || ''} ${
                        orderDetail.orderDetails[0]?.name || ''
                      }`
                    )}
                  </span>
                </div>
              )}

              <div className="col-md-3 mt-1">
                <span>{t('waybill_hotline')}</span>
              </div>
              <div className="col-md-9 mt-1 right-fw">
                <span>{SWIFT247_HOTLINE}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PrintWaybill;
