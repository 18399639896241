import { Select } from 'antd';
import { classPrefix } from 'Utilities/global'
import React from 'react';
import { SUPPORTED_LANGUAGE } from 'Utilities/constants';
import { useTranslation } from 'react-i18next';
const { Option } = Select;
import './index.scss'
import { changeLanguage } from '../../localization';
import { useDispatch } from 'react-redux';
import { updateCurrentLanguage } from 'Stores/layout-utilities';


const LanguagePicker = () => {
	const {i18n} = useTranslation();
	const dispatch = useDispatch();
	const onSelect = (data: string) => {
		changeLanguage(data);
		dispatch(updateCurrentLanguage(i18n.language))
	}
	return (
		<>
			<Select showArrow={false} value={i18n.language} onSelect={onSelect} className={`${classPrefix}-language-picker`}>
				{SUPPORTED_LANGUAGE.map(lang => (
					<Option key={lang.key} value={lang.key}>
						<div className={'d-flex align-items-center'}>
							<img className={'mr-1'} width={30} height={15} src={lang.icon} alt={''} />
							<span>{lang.name}</span>
						</div>
					</Option>
				))}
			</Select>
		</>
	);
};

export default LanguagePicker;
