import ButtonClick from 'Components/ButtonClick';
import { CheckBoxItem } from 'Components/CheckBox';
import React, { useEffect } from 'react';
import { VIETNAM_COUNTRY_CODE } from 'Utilities/constants';
import FieldLabel from 'Components/FieldLabel';
import InputText from 'Components/InputText';
import { useLoadingBar, useToast } from 'Components/LayoutUtilities';
import RadioButton from 'Components/RadioButton';
import SelectBox from 'Components/SelectBox';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import UserServiceV1 from 'Services/v1/user';
import { DTOUserProfile } from 'Services/v1/user/dto';
import {
  getLocations,
  getSelectedLocation,
  locationSelectors
} from 'Stores/locations';
import './index.scss';

interface BusinessProfileProps {
  data: DTOUserProfile;
}

const BusinessProfile = (props: BusinessProfileProps) => {
  const { t } = useTranslation();

  const { startLoadingBar, stopLoadingBar, loadingBarState } = useLoadingBar();
  const toast = useToast();
  const profile = props.data;

  const {
    control,
    watch,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    resetField,
    handleSubmit
  } = useForm();

  const dispatch = useDispatch();

  const watchCountry = watch('country');
  const watchProvince = watch('province');
  const watchDistrict = watch('district');
  const watchVillage = watch('village');
  const watchAddress = watch('address');

  const cityData = useSelector(locationSelectors.get).city.data;
  const districtData = useSelector(locationSelectors.get).district.data;
  const wardData = useSelector(locationSelectors.get).ward.data;
  // const wardListData = useSelector(locationSelectors.get).ward.list;

  const titleOptions: CheckBoxItem[] = [
    { label: t('global_title_male'), value: '1' },
    { label: t('global_title_female'), value: '0' }
  ];

  useEffect(() => {
    if (!profile) return;
    setValue('customerCode', profile.customerCode);
    setValue('companyName', profile.companyName);
    setValue('taxNumber', profile.taxNumber);
    setValue('companyPhone', profile.companyPhone);
    setValue('gender', profile.gender);
    setValue('companyRepresentative', profile.companyRepresentative);
    setValue('email', profile.email);

    setValue('country', profile.country);
    setValue('province', profile.province);
    setValue('district', profile.district);
    setValue('village', profile.village);
    setValue('address', profile.address);
  }, [profile]);

  useEffect(() => {
		dispatch(getLocations({ level: 'city', id: VIETNAM_COUNTRY_CODE }));
  }, [watchCountry]);

  useEffect(() => {
    if (watchProvince) {
      dispatch(getLocations({ level: 'district', id: watchProvince }));
    }
  }, [watchProvince]);

  useEffect(() => {
    if (watchDistrict) {
      dispatch(getLocations({ level: 'ward', id: watchDistrict }));
    }
  }, [watchDistrict]);

  useEffect(() => {
    if (watchVillage) {
      dispatch(getSelectedLocation({ level: 'ward', id: watchVillage }));
    }
  }, [watchVillage]);

  useEffect(() => {
    dispatch(getLocations({ level: 'country', id: '' }));
    // mapDataToForm();
  }, []);

  const onSubmit = async (values: any) => {
    const payload = { ...values };

    startLoadingBar();
    const resp = await UserServiceV1.updateProfile(payload);
    const {error} = resp;
    if(!error) {
      toast('success', t('message_update_profile_successfully'));
    } else {
      toast('error', t('message_update_profile_failed'));
    }
    stopLoadingBar();
  };

  return (
    <form className={'business-profile-form'} onSubmit={handleSubmit(onSubmit)}>
      <div className="row gap-5">
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_customer_code')} />
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <InputText
                  {...field}
                  disabled={true}
                  placeholder={t('label_customer_code')}
                />
              );
            }}
            name={'customerCode'}
          />
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_company_name')} />
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <InputText {...field} placeholder={t('label_company_name')} />
              );
            }}
            name={'companyName'}
          />
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_email')} />
          <Controller
            control={control}
            render={({ field }) => {
              return <InputText {...field} placeholder={t('label_email')} />;
            }}
            name={'email'}
          />
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_tax_number')} />
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <InputText {...field} placeholder={t('label_tax_number')} />
              );
            }}
            name={'taxNumber'}
          />
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_company_phone')} />
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <InputText {...field} placeholder={t('label_company_phone')} />
              );
            }}
            name={'companyPhone'}
          />
        </div>
        <div className="col-md-12">
          <FieldLabel
            className={'mb-2'}
            value={t('label_company_representative')}
          />
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <InputText
                  {...field}
                  placeholder={t('label_company_representative')}
                />
              );
            }}
            name={'companyRepresentative'}
          />
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_title')} />
          <Controller
            control={control}
            name={'gender'}
            render={({ field }) => {
              return (
                <RadioButton
                  {...field}
                  options={titleOptions}
                  radioGroupClassName="w-100 d-flex"
                />
              );
            }}
          />
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_province')} />
          <Controller
            render={({ field: { value, ref, ...rest } }) => (
              <SelectBox
                {...rest}
                options={cityData || []}
                selectedValue={value}
                placeholder={t('label_province')}
              />
            )}
            control={control}
            name="province"
          />
          {/*<RequiredErrorInput fieldName={t('label_province')} isShow={true}/>*/}
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_district')} />
          <Controller
            render={({ field: { value, ...rest } }) => (
              <SelectBox
                {...rest}
                options={districtData || []}
                selectedValue={value}
                placeholder={t('label_district')}
              />
            )}
            control={control}
            name="district"
          />
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_ward')} />
          <Controller
            render={({ field: { value, name, ...rest } }) => (
              <SelectBox
                {...rest}
                options={wardData || []}
                selectedValue={value}
                placeholder={t('label_ward')}
              />
            )}
            control={control}
            name="village"
          />
        </div>
        <div className="col-md-12">
          <FieldLabel className={'mb-2'} value={t('label_address')} />
          <Controller
            render={({ field }) => (
              <InputText {...field} placeholder={t('label_address')} />
            )}
            control={control}
            name="address"
          />
        </div>
        <ButtonClick
          variant="primary"
          htmlType="submit"
          containerClassName={'text-right'}
          disabled={loadingBarState === 'loading'}
          className={'business-profile-form-save-button'}
        >
          {t('global_save')}
        </ButtonClick>
      </div>
    </form>
  );
};

export default BusinessProfile;
