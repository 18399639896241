import { call, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import LocationsServiceV1 from 'Services/v1/locations';
import AddressServiceV1 from 'Services/v1/address-book'

import { PayloadAction } from '@reduxjs/toolkit';
import {
  getLocations,
  getLocationsByAddress,
  getLocationsByAddressSuccess,
  getLocationsFailed,
  getLocationsSuccess
} from './index';
import { GetLocationActionPayload } from './models';

export function* handleGetLocations(
	action: PayloadAction<GetLocationActionPayload>,
): any {
	const { level, id } = action.payload;
	const { data, error } = yield call(
		LocationsServiceV1.getLocations.bind(LocationsServiceV1),
		id,
	);
	if (error) {
		yield put(
			getLocationsFailed({
				level,
				data: {
					errCode: error.code,
					errMsg: error.msg,
				},
			}),
		);
		return;
	}

	yield put(
		getLocationsSuccess({
			level,
			data,
		}),
	);
}

export function* handleGetLocationsByAddress(
	action: PayloadAction<string>,
): any {
	const { data, error }: any = yield call(
		LocationsServiceV1.getLocationsByAddress.bind(LocationsServiceV1),
		action.payload,
	);
	if (data && data.results && data.results.length) {
		yield put(
			getLocationsByAddressSuccess({
				formattedAddress: data.results[0]?.formatted_address,
				lat: data.results[0].geometry.location.lat,
				lng: data.results[0].geometry.location.lng,
				placeId: data.results[0].place_id
			}),
		);
	}
}

export default function* locationsSaga() {
	yield takeEvery(getLocations.toString(), handleGetLocations);
	yield takeLatest(
		getLocationsByAddress.toString(),
		handleGetLocationsByAddress,
	);
}
