import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DTOPaymentCardGroup } from 'Services/v1/payment/dto';
import { RootState } from 'Stores/index';
import { GetFailedActionPayload, ListItemsState } from 'Stores/models';

const initialState: ListItemsState<DTOPaymentCardGroup> = {
  fetchingStatus: 'init',
  list: []
};

export const paymentMethodSlice = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getPayment: state => {
      state.fetchingStatus = 'pending';
    },
    getPaymentSuccess: (
      state,
      action: PayloadAction<DTOPaymentCardGroup[]>
    ) => {
      state.fetchingStatus = 'success';
      state.list = action.payload;
    },
    getPaymentFailed: (
      state,
      action: PayloadAction<GetFailedActionPayload>
    ) => {
      const { errMsg, errCode } = action.payload;
      state.fetchingStatus = 'error';
      state.errMsg = errMsg;
      state.errCode = errCode;
    }
  }
});

export const { getPayment, getPaymentSuccess, getPaymentFailed } =
  paymentMethodSlice.actions;

export const paymentMethodSelector = {
  get: (rootState: RootState) => rootState.paymentMethod
};

export default paymentMethodSlice.reducer;
