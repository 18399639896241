import React from 'react';
import { Spin, SpinProps } from 'antd';
import './index.scss'
import { classPrefix } from 'Utilities/global';

const Spinner = (props: SpinProps) => {
	return <div className={`${classPrefix}-spinner`}>
		<Spin {...props}>
			{props.children}
		</Spin>
	</div>
}

export default Spinner;