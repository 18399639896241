import React from 'react';
import { Col, Form, Row } from 'antd';
import { UploadFile } from 'antd/es';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import ButtonClick from 'Components/ButtonClick';
import { CheckBox } from 'Components/CheckBox';
import InputText from 'Components/InputText';
import Section from 'Components/Section';
import SelectBox from 'Components/SelectBox';
import TextAreaField from 'Components/TextAreaField';
import UploadForm from 'Components/UploadForm';
import { cloneDeep } from 'lodash';
import { C2cCategoryPackageTypeEnum } from 'Pages/C2cOrderPayment/enums';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MediaAssetsServiceV1 from 'Services/v1/media-assets';
import {
	c2cOrderPaymentSelectors,
	C2cProductModel,
	getC2cProductTypesAction,
	upsertProductDeclaration,
} from 'Stores/c2c-order-payment';
import { RootState } from 'Stores/index';
import { layoutUtilitiesSliceSelectors } from 'Stores/layout-utilities';
import { Location } from 'Utilities/constants';
import { classPrefix } from 'Utilities/global';
import { randomBase64String } from 'Utilities/util';
import './index.scss';
import { MainPageEnum } from '../../../../routes';
import { usePopup } from 'Hooks';
import { orderBillSelectors } from 'Stores/order-bill';

const GoodsInformation = (props: { idGoods?: string }) => {
	const compClass = `${classPrefix}-goods-information`;
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	// const navigate = useNavigate();
	const params = useParams();
	const { onClose } = usePopup();
	// const selectedId = params.id;
	const { idGoods } = props;

	const { productTypes, packageTypeSelected, isGlobal } =
		useSelector(c2cOrderPaymentSelectors.get);
	const orderBillState = useSelector(orderBillSelectors.get);
	const { senderAddress, receiverAddress } = orderBillState;

	const [productNameCharacterCount, setProductNameCharacterCount] =
		useState(0);
	const { currentLanguage } = useSelector(layoutUtilitiesSliceSelectors.get);
	const validateMessages = {
		required: t('validation_required', { fieldName: '${label}' }),
	};
	const [productTypeData, setProductTypeData] = useState<any[]>([]);

	const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

	const selectedProduct = useSelector((state: RootState) =>
		c2cOrderPaymentSelectors.getOneProductDeclaration(state, idGoods || ''),
	);

	useEffect(() => {
		if (!idGoods) {
			form.resetFields();
		}
	}, [idGoods]);

	useEffect(() => {
		if (!selectedProduct) {
			return;
		}
		const _data: any = cloneDeep(selectedProduct);
		// _data.categories = JSON.stringify(selectedProduct.categories);
		form.setFieldsValue({
			..._data,
			categories: _data.categories?.code,
		});
		if (selectedProduct.receipts && selectedProduct.receipts.length > 0) {
			setFileList(
				selectedProduct.receipts.map((url: string) => ({
					status: 'done',
					url,
					name: url,
					uid: randomBase64String(),
				})),
			);
		}
	}, [selectedProduct]);

	// TODO: Calls duplicated here need to be refactored
	useEffect(() => {
		if(!senderAddress?.country || !receiverAddress?.country) return;

		dispatch(
			getC2cProductTypesAction({
				lang: currentLanguage || 'en',
				location: isGlobal ? Location.KR : Location.VI,
				locationFrom: senderAddress?.country,
				locationTo: receiverAddress?.country
			}),
		);
	}, [currentLanguage, senderAddress?.country, receiverAddress?.country]);

	useEffect(() => {
		if (!productTypes) return;
		const productTypesList = (productTypes.parcel || [])
			// TODO: isKorean is country specific
			.filter((x) => isGlobal && x.isKorean)
			.map((item) => ({
				label: item?.product || '',
				value: item?.code,
			}))
			?.sort((a, b) =>
				a.label?.toLowerCase()?.localeCompare(b.label?.toLowerCase()),
			);
		setProductTypeData(productTypesList);
	}, [productTypes]);

	const onSubmit = () => {
		const formData = form.getFieldsValue();
		const formValue = cloneDeep(formData);
		let productTypesFilter: C2cProductModel[] = [];
		if (packageTypeSelected === C2cCategoryPackageTypeEnum.Parcel) {
			productTypesFilter = productTypes.parcel?.find(
				(x) => x.code === formValue.categories,
			) as C2cProductModel[];
		} else {
			productTypesFilter = productTypes.document?.find(
				(x) => x.code === formValue.categories,
			) as C2cProductModel[];
		}

		if (productTypesFilter) {
			formValue.categories = productTypesFilter;
		}

		if (!selectedProduct?.id)
			formValue.id = randomBase64String(); // edit mode check
		else formValue.id = selectedProduct.id;

		formValue.receipts = fileList
			.filter((item) => item.url)
			.map((item) => item.url);

		dispatch(upsertProductDeclaration(formValue));

		// navigate('/c2c-order-payment');
		onClose();
		form.resetFields();
	};

	const onHandleFormChange = () => {
		const formValue = form.getFieldsValue();
		form.setFieldValue(
			'totalPrice',
			(formValue.quantity || 0) * (formValue.price || 0),
		);
		formValue.productName &&
			setProductNameCharacterCount(formValue.productName.length);
	};

	const onUploadImage = async (dataFile: UploadRequestOption) => {
		const formData = new FormData();
		const file = dataFile.file;
		formData.append('file', file);

		dataFile.onProgress &&
			dataFile.onProgress({
				percent: 30,
			});
		const uploadResponse = await MediaAssetsServiceV1.uploadGoodsImage(
			formData,
		);
		dataFile.onProgress &&
			dataFile.onProgress({
				percent: 100,
			});
		if (dataFile.onSuccess) {
			dataFile.onSuccess({
				url: uploadResponse.data?.resUrl,
			});
		}
	};

	const onImageChange = async (data: UploadChangeParam) => {
		if (data.file.status === 'done') {
			data.file.url = data.file.response.url;
		}
		setFileList(data.fileList);
	};

	const beforeUpload = (file: RcFile) => {
		const validExtension = ['image/png', 'image/jpeg', 'image/jpg'];
		const isValid = validExtension.includes(file.type);
		if (!isValid) {
			toast.error(t('file_upload_is_not_support'), {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
		const isLt3M = file.size < 3000000;
		if (!isLt3M) {
			toast.error(t('do_not_upload_file_more_than_3mb'), {
				position: toast.POSITION.TOP_RIGHT,
			});
		}
		return isValid && isLt3M;
	};

	return (
		<>
			{/* <PageTitle
				title={t(C2cOverPaymentTextEnum.C2CGoodsInformationTitle)}
			/> */}
			<Section className={`${compClass}`} title={undefined}>
				<div className={`${compClass}-form`}>
					<Form
						form={form}
						layout="vertical"
						onFinish={onSubmit}
						onValuesChange={() => onHandleFormChange()}
						validateMessages={validateMessages}
					>
						<Row gutter={24}>
							<Col span={24} lg={{ span: 12 }}>
								<Form.Item
									name="categories"
									rules={[{ required: true }]}
									label={t('goods_info_category')}
								>
									<SelectBox
										selectedValue={form.getFieldValue(
											'categories',
										)}
										options={productTypeData}
										placeholder={t(
											'goods_info_category_placeholder',
										)}
									/>
								</Form.Item>
							</Col>

							<Col span={24} lg={{ span: 12 }}>
								<Form.Item
									name="quantity"
									rules={[
										{
											required: true,
										},
										({ getFieldValue }) => ({
											validator(rule, value) {
												if (
													value !== '' &&
													Number(value) <= 0
												) {
													return Promise.reject(
														t(
															'please_enter_a_quantity_greater_than_0',
														),
													);
												} else {
													return Promise.resolve();
												}
											},
										}),
									]}
									label={t('goods_info_quantity')}
								>
									<InputText
										type="number"
										step="1"
										className="hide-arrow"
										placeholder={t('goods_info_quantity')}
										onKeyDown={(e) => {
											if (e.key === '.') {
												e.preventDefault();
											}
										}}
									/>
								</Form.Item>
							</Col>

							<Col span={24} lg={{ span: 12 }}>
								<Form.Item
									name="productName"
									rules={[{ required: true }]}
									label={t('goods_info_product_name')}
								>
									<InputText
										maxLength={200}
										placeholder={t(
											'goods_info_product_name',
										)}
									/>
								</Form.Item>
								<div className="custom-form-item-help-alert text-right text-gray-primary fs-l-sm">
									<div
										role="alert"
										className="ant-form-item-explain-validating"
									>{`${productNameCharacterCount}/200`}</div>
								</div>
							</Col>

							<Col span={24} lg={{ span: 12 }}>
								<Form.Item
									name="price"
									rules={[
										{ required: true },
										({ getFieldValue }) => ({
											validator(rule, value) {
												if (
													value !== '' &&
													Number(value) <= 0
												) {
													return Promise.reject(
														t(
															'please_enter_a_price_greater_than_0',
														),
													);
												} else {
													return Promise.resolve();
												}
											},
										}),
									]}
									label={t('goods_info_price')}
								>
									<InputText
										type="number"
										step="0.01"
										placeholder={t('goods_info_price')}
									/>
								</Form.Item>
							</Col>
						</Row>

						<Row gutter={24}>
							<Col span={24} lg={{ span: 12 }}>
								<Form.Item
									name="material"
									rules={[{ required: true }]}
									label={t('goods_info_material')}
								>
									<TextAreaField
										rows={8}
										placeholder={t(
											'goods_info_material_placeholder',
										)}
									/>
								</Form.Item>
							</Col>

							<Col span={24} lg={{ span: 12 }}>
								<Row gutter={24}>
									<Col span={24}>
										<Form.Item
											name="totalPrice"
											label={t('goods_info_total_price')}
										>
											<InputText
												disabled
												placeholder={t(
													'goods_info_total_price',
												)}
											/>
										</Form.Item>
									</Col>

									<Col span={24}>
										<Form.Item
											name="receipt"
											label={t('goods_info_receipt')}
										>
											<>
												<UploadForm
													onChange={(data) =>
														onImageChange(data)
													}
													beforeUpload={beforeUpload}
													uploadText={t(
														'upload_photo',
													)}
													customRequest={
														onUploadImage
													}
													fileList={fileList}
													maxCount={3}
												/>
											</>
										</Form.Item>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Form.Item
									name="goodsUrl"
									label={t('create_product_goods_url')}
								>
									<InputText
										placeholder={t(
											'create_product_goods_url_if_it_online_purchase',
										)}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<div className={'d-flex align-items-center'}>
									<Form.Item
										rules={[
											{
												validator: (_, value) => {
													return value
														? Promise.resolve()
														: Promise.reject(
																new Error(
																	t(
																		'validation_term_and_service_korea_product_declare',
																	),
																),
														  );
												},
											},
										]}
										name="term"
										valuePropName="checked"
									>
										<CheckBox
											label={t(
												'korea_term_and_service_message_product_declare',
											)}
										/>
									</Form.Item>
								</div>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12} offset={12}>
								<ButtonClick
									variant="primary"
									htmlType="submit"
									containerClassName="text-right"
								>
									{t('buttons_complete')}
								</ButtonClick>
							</Col>
						</Row>
					</Form>
				</div>
			</Section>
		</>
	);
};
export default GoodsInformation;
