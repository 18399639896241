import { PopupContainer } from '@swift-247/s247.library.ui-core';
import CommonPopupLayout from 'Components/CommonPopupLayout/commonPopupLayout';
import { usePopup } from 'Hooks';
import { useSelector } from 'react-redux';
import { layoutUtilitiesSliceSelectors } from 'Stores/layout-utilities';
import classnames from 'Utilities/classnames';
import { classPrefix } from 'Utilities/global';
import './styles.scss';

interface PropsModel {
  className?: string;
}

const CommonPopup = (props: PropsModel) => {
  const popup = usePopup();
  const { popupCommon } = useSelector(layoutUtilitiesSliceSelectors.get);
  const { className } = props;
  const containerClasses = classnames(`${classPrefix}-common-popup`, className);

  return (
    <PopupContainer
      isOpen={!!popup?.isOpen}
      onClose={popup?.onClose}
      className={containerClasses}
    >
      {' '}
      {!!popup?.isOpen && (
        <CommonPopupLayout
          popupConfig={popupCommon.popupConfig}
          className={className}
        >
          {popup?.component}
        </CommonPopupLayout>
      )}
    </PopupContainer>
  );
};

export default CommonPopup;
