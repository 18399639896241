export const GRAP_QUERY_SETTING_PAGE = () => {
    return `{
      blogs {
        title
        titleEn
        titleKo
        location
        description
        descriptionVi
        descriptionEn
        descriptionKo
        stage
        categoryUrl
        id
        slug
        createdAt
        publishedAt
        thumbnail {
          url
        }
        banner {
          url
        }
        pageHtml
      }
      staticPages {
        id
        pageId
        pageUrl
        pageHtml
        pageTitle
        pageDescription
      }
      settings {
        value
        valueEn
        valueKr
        settingKey
        pageHtml
      }
      images {
        slug
        imageKey
        title
        location
        description
        pageUrl
        stage
        image {
          url
        }
      }
      packageTypes {
        slug
        name
        stage
        messagesApp
        inactiveIcon {
          url
        }
        inactiveColor {
          hex
        }
        activeIcon {
          url
        }
        activeColor {
          hex
        }
        default
        orderSort
        multipleSelect
        danger
        packageKey
        packageSizeNameFixed
        fromVersion
        toVersion
        packageCategory {
          name
          key
          hiden
          orderSort
          packageCategory {
            name
            key
            stage
          }
        }
      }
    }`;
  };