import { Tooltip } from 'antd';
import images from 'Assets/images';
import Section from 'Components/Section';
import { format, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DTOOrder } from 'Services/v1/cargo-direct/dto';
import { DATE_FORMATS, PACKAGE_OVERALL_STATUS } from 'Utilities/constants';
import {
  copyToClipboard,
  displayCurrency,
  newDateWithFormat
} from 'Utilities/util';
import './component.scss';

interface OrderItemProps {
  data: DTOOrder;
}

interface RenderedInfoMappingItem {
  iconUrl: string;
  status: string;
  deliveryStatus: (
    time: string,
    name: string | undefined,
    address: string
  ) => string;
}

interface RenderedInfoMapping {
  [key: string]: RenderedInfoMappingItem;
}

export const OrderItem = (props: OrderItemProps) => {
  const { data } = props;

  const { t } = useTranslation();

  const renderedInfoMapping: RenderedInfoMapping = {
    [PACKAGE_OVERALL_STATUS.SENDING]: {
      iconUrl: images.deliveringPackageIconUrl,
      status: t('order_detail_status_sending'),
      deliveryStatus: (time: string, name = '', address: string) =>
        `${time} - ${t(
          'order_detail_status_sending_description'
        )} ${name} - ${address}`
    },
    [PACKAGE_OVERALL_STATUS.RECEIVING]: {
      iconUrl: images.loadingPackageIconUrl,
      status: t('order_detail_status_receiving'),
      deliveryStatus: (time: string, name = '', address: string) =>
        `${time} - ${t(
          'order_detail_status_receiving_description'
        )}  ${name} - ${address}`
    },
    [PACKAGE_OVERALL_STATUS.CANCELLED]: {
      iconUrl: images.cancelledPackageIconUrl,
      status: t('order_detail_status_cancelled'),
      deliveryStatus: (time: string, name = '', address: string) =>
        `${time} - ${t(
          'order_detail_status_cancelled_description'
        )} ${name} - ${address}`
    },
    [PACKAGE_OVERALL_STATUS.FINISHED]: {
      iconUrl: images.deliveredPackageIconUrl,
      status: t('order_detail_status_completed'),
      deliveryStatus: (time: string, name = '', address: string) =>
        `${time} - ${t(
          'order_detail_status_completed_description'
        )} ${name} - ${address}`
    }
  };

  const timeFormat = 'dd/MM HH:mm';
  const dateInstance = newDateWithFormat(
    data.createdAt,
    DATE_FORMATS.ISO_CUSTOM
  );

  const renderedTime = isValid(dateInstance)
    ? format(dateInstance, timeFormat)
    : '';

  const renderedInfo = renderedInfoMapping[data.statusName || ''];

  const orderCategory = Array.isArray(data.orderDetails)
    ? data.orderDetails[0]
    : data.orderDetails;
  const navigate = useNavigate();
  const goToDetail = (uuid: string, statusName: string) => {
    navigate(`/my-orders/${uuid}?type=${statusName}`);
  };

  return (
    <Section className={'my-orders-item'}>
      <div className={'d-flex flex-column gap-4'}>
        <div
          className={
            'my-orders-item-header d-flex align-items-center justify-content-between'
          }
        >
          <div className="my-orders-item-title">
            <span className={'mr-2'}>
              <span className="text-uppercase">{t('global_order_code')} </span>
              <span className={'fw-bold'}>#{data.orderId}</span>
            </span>
            <Tooltip
              title={t('global_copy')}
              overlayInnerStyle={{ borderRadius: '5px' }}
            >
              <img
                className="cursor-pointer"
                onClick={() => copyToClipboard(data.orderId)}
                src={images.icCopy}
                width={20}
              />
            </Tooltip>
          </div>
          <div className={'my-orders-item-status text-warning'}>
            {renderedInfo?.status}
          </div>
        </div>
        <div
          className={
            'my-orders-item-last-status d-flex align-items-center cursor-pointer'
          }
          onClick={() => goToDetail(data.uuid || '', data?.statusName || '')}
        >
          <img className={'mr-5'} alt={''} src={renderedInfo?.iconUrl} />
          <span>
            {renderedInfo?.deliveryStatus(
              renderedTime,
              data?.toName,
              data.receiverAddress
            )}
          </span>
        </div>
        <div
          className={
            'my-orders-item-summary d-flex align-items-center justify-content-between'
          }
        >
          {orderCategory && (
            <div>
              <img className={'mr-2'} src={images.packageIconUrl} alt={''} />
              <span>
                {t(
                  `commodity_${
                    orderCategory.productionCode || orderCategory.key
                  }`,
                  orderCategory.name || orderCategory.productName
                )}
              </span>
            </div>
          )}
          <div>
            {t('global_delivery_fee')}:{' '}
            <span className={'fw-bold'}>
              {displayCurrency(
                data.toPrice ? data.toPrice : data.amount,
                data.toCurrency ? data.toCurrency : data.currency
              )}
            </span>
          </div>
        </div>
      </div>
    </Section>
  );
};
