import {
	CamelSnakeMapper,
	DataMapperDictionary,
	DictionaryMapper,
} from 'Services/data-mappers';
import {
	ExtraServiceResponse,
} from 'Stores/c2c-order-payment/models';
import httpClient from '../../http-client';
import { DTOServiceResponse } from '../../interfaces';
import RootServiceV1 from '../root';
import { GRAP_QUERY_SETTING_PAGE } from './constants';
import config from '../../../../config/config';

class GrapServices extends RootServiceV1 {
	constructor() {
		super();
	}

	async getSettingGrapData(): Promise<
		DTOServiceResponse<ExtraServiceResponse>
	> {
		try {
			const response = await httpClient(config.grapUrl).post(
				`/v2/cklg86q81hg3e01z6dch83qys/master`,
				 JSON.stringify({
					query: GRAP_QUERY_SETTING_PAGE(),
				  }),
			);
			const data = response?.data?.data;
			return {
				statusCode: 200,
				data,
			};
		} catch (err) {
			const parsedError = this._errorParser(err);
			return {
				statusCode: parsedError.statusCode,
				error: parsedError,
			};
		}
	}
}

export default new GrapServices() as GrapServices;
