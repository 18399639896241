import { PopupConfigModel } from 'Hooks';

export const CONFIRM_SELECT_PRODUCT_POPUP: PopupConfigModel = {
  title: 'global_product',
  okBtnLabel: 'global_accept_action',
  cancelBtnLabel: 'global_confirm_product_cancel',
  width: '100%',
  btnFullWidth: true,
  btnReverse: true
};
