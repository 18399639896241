import { call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import PaymentServiceV1 from 'Services/v1/payment';
import {
  getPayment,
  getPaymentFailed,
  getPaymentSuccess
} from 'Stores/payment-method/index';
import { BOOKING_TYPE } from 'Utilities/constants';

export function* handleGetPaymentMethod(
  action: PayloadAction<BOOKING_TYPE>
): any {
  const { data, error } = yield call(
    PaymentServiceV1.getSupportedPaymentCards.bind(PaymentServiceV1)
  );

  if (error) {
    return yield put(
      getPaymentFailed({
        errCode: error.code,
        errMsg: error.msg
      })
    );
  }

  yield put(getPaymentSuccess(data));
}

export default function* paymentMethodSaga() {
  yield takeLatest(getPayment.toString(), handleGetPaymentMethod);
}
