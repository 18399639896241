import React from 'react';
import { classPrefix } from 'Utilities/global';
import classnames from 'Utilities/classnames';
import './index.scss';
interface FieldLabelProps {
	value: string,
	isRequired?: boolean,
	className?: string
}

const FieldLabel = (props: FieldLabelProps) => {
	const {
		value,
		isRequired,
		className
	} = props;

	const containerClassName = classnames(className, `${classPrefix}-field-label fs-p-md lh-p-md fw-600`)

	return <div className={containerClassName}>{value}{isRequired && <sup className={'text-error'}>*</sup>}</div>
}

export default FieldLabel;