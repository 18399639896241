import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import addressBookReducer from './address-book';
import c2cOrderPaymentReducer from './c2c-order-payment';
import flightsState from './flights';
import GrapReducer from './grap';
import layoutUtilitiesReducer from './layout-utilities';
import locationsReducer from './locations';
import myOrdersReducer from './my-orders';
import orderBillReducer from './order-bill';
import paymentMethodReducer from './payment-method';
import profileReducer from './profile';
import rootSaga from './rootSaga';
import TopupReducer from './topup';

// @ts-ignore
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/es/storage/session';

// WHITELIST
const persistConfig = {
	key: 'root',
	storage: storageSession,
	whitelist: [],
};

const orderBillPersistConfig = {
	key: 'orderBill',
	storage: storageSession,
	whitelist: ['vatInfo', 'paymentMethod', 'senderAddress', 'receiverAddress'],
};

const addressBookPersistConfig = {
	key: 'addressBook',
	storage: storageSession,
	whitelist: ['selectedValue'],
};

const c2cOrderPaymentPersistConfig = {
	key: 'c2cOrderPayment',
	storage: storageSession,
	blackList:[]
};

const rootReducer = combineReducers({
	orderBill: orderBillReducer,
	addressBook: addressBookReducer,
	c2cOrderPayment:  c2cOrderPaymentReducer,
	locations: locationsReducer,
	paymentMethod: paymentMethodReducer,
	myOrders: myOrdersReducer,
	flights: flightsState.reducer,
	profile: profileReducer,
	layoutUtilities: layoutUtilitiesReducer,
	grap: GrapReducer,
  topup: TopupReducer
})


const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: persistedReducer,
	// middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({  serializableCheck: false }).concat(sagaMiddleware),
})

export type RootState = ReturnType<typeof store.getState>

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;
