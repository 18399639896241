import { PromoVoucher, PromoVoucherType } from 'Components/DeliveryMethod';
import ModalDialog from 'Components/ModalDialog';
import { C2cOverPaymentTextEnum } from 'Pages/C2cOrderPayment/enums';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	c2cOrderPaymentSelectors,
	getC2cVouchersAction,
	C2cVoucherModel,
	chooseVoucherAction,
	updateVoucherValidation,
} from 'Stores/c2c-order-payment';
import { orderBillSelectors } from 'Stores/order-bill';
import voucherPrimary from 'Assets/icon/voucher-primary.svg';
import InputText from 'Components/InputText';
import ButtonClick from 'Components/ButtonClick';
import { CloseOutlined } from '@ant-design/icons';
import { classPrefix } from 'Utilities/global';
import './style.scss';
import InputMessage from 'Components/InputMessage';
import { VOUCHER_STATUS } from 'Utilities/constants';
import C2cOverPaymentServiceV1 from 'Services/v1/c2c-order-payment';

interface PromotionModalProps {
	visible: boolean;
	onClose: () => void;
}

const PromotionModal = (props: PromotionModalProps) => {
	const { visible, onClose } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [selectedVoucher, setSelectedVoucher] = useState<C2cVoucherModel>();
	const [validateStatus, setValidateStatus] = useState({
		status: '',
		message: '',
	});
	const receiverAddress = useSelector(orderBillSelectors.get).receiverAddress;
	const senderAddress = useSelector(orderBillSelectors.get).senderAddress;
	const { vouchers } = useSelector(c2cOrderPaymentSelectors.get);
	const voucherValidationResult = useSelector(
		c2cOrderPaymentSelectors.get,
	).voucherValidation;

	const voucherSelected = useSelector(
		c2cOrderPaymentSelectors.get,
	).voucherSelected;

	useEffect(() => {
		if (visible) {
			voucherSelected?.code && setSelectedVoucher(voucherSelected);
		} else {
			setValidateStatus({
				status: '',
				message: '',
			});
			setSelectedVoucher({ code: '' });
		}
	}, [visible]);

	useEffect(() => {
		voucherSelected?.code && setSelectedVoucher(voucherSelected);
	}, [voucherSelected]);

	useEffect(() => {
		if (voucherValidationResult && voucherValidationResult.status) {
			setValidateStatus({
				status: voucherValidationResult.status,
				message: voucherValidationResult.message || '',
			});

			if (voucherValidationResult.status === 'success') {
				onClose && onClose();
			}
		}
	}, [voucherValidationResult]);

	useEffect(() => {
		if (senderAddress && receiverAddress) {
			const originId = [
				Number(senderAddress?.country),
				Number(senderAddress?.province),
				Number(senderAddress?.district),
				Number(senderAddress?.village),
			];
			const destinationId = [
				Number(receiverAddress.country),
				Number(receiverAddress.province),
				Number(receiverAddress.district),
				Number(receiverAddress.village),
			];
			if (originId.length && destinationId.length) {
				dispatch(
					getC2cVouchersAction({
						productId: 0,
						originId,
						destinationId,
					}),
				);
			}
		}
	}, [receiverAddress, senderAddress]);

	const chooseVoucher = (voucher: C2cVoucherModel) => {
		if (voucher.code) {
			setSelectedVoucher(voucher);
		}
	};

	const onClearValue = () => {
		setSelectedVoucher(undefined);
		setValidateStatus({
			status: '',
			message: '',
		});
	};

	const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
		setSelectedVoucher({ code: e.target.value });
		if (!e.target.value) {
			setValidateStatus({
				status: '',
				message: '',
			});
		}
	};

	const applyVoucher = async () => {
		if (!selectedVoucher?.code) return;

		setValidateStatus({
			status: 'checking',
			message: 'voucher_availability_validating',
		});

		const checkVoucherAvailabilityRes =
			await C2cOverPaymentServiceV1.checkVoucherAvailability({
				voucher: selectedVoucher?.code,
			});

		if (checkVoucherAvailabilityRes.error) {
			setValidateStatus({
				status: 'error',
				message: 'global_error_general',
			});
			return;
		}

		const voucherStatus = checkVoucherAvailabilityRes.data;
		if (voucherStatus !== VOUCHER_STATUS.VALID) {
			setValidateStatus({
				status: 'error',
				message: voucherStatus || '',
			});
			return;
		}

		setValidateStatus({
			status: 'success',
			message: 'voucher_message_apply_success',
		});
		return (
			dispatch(
				updateVoucherValidation({
					status: 'success',
					message: 'voucher_message_apply_success',
				}),
			) && dispatch(chooseVoucherAction(selectedVoucher))
		);
	};

	const renderVouchers = () => {
		if (!vouchers?.length) {
			return <></>;
		}
		return vouchers?.map((voucher, index) => {
			return (
				<div
					className="cursor-pointer mt-4"
					key={index}
					onClick={() => chooseVoucher(voucher)}
				>
					<PromoVoucher
						key={voucher?.id}
						voucherName={voucher?.tittle}
						discountType={voucher?.discountType}
						discountAmount={voucher?.discountAmount}
						voucherType={PromoVoucherType.Voucher}
						endAt={voucher?.endAt}
						usageLimit={voucher?.usageLimit}
					></PromoVoucher>
				</div>
			);
		});
	};

	const compClass = `${classPrefix}-promotion-page`;

	return (
		<ModalDialog
			visible={visible}
			title={
				<>
					<img src={voucherPrimary} alt={''} />
					<span className="ps-2">{t('menu_item_your_deals')}</span>
				</>
			}
			hideFooter={true}
			onHandleCancel={onClose}
			destroyOnClose={true}
		>
			<div className={compClass}>
				<div className="d-flex">
					<InputText
						className="flex-fill w-100"
						placeholder={t(
							C2cOverPaymentTextEnum.C2cPromo_PlaceHolderInput,
						)}
						value={selectedVoucher?.code}
						onChange={onChangeValue}
						suffix={
							<CloseOutlined
								className={`${
									selectedVoucher?.code ? '' : 'd-none'
								} cursor-point`}
								onClick={onClearValue}
							/>
						}
					/>
					<ButtonClick
						onClick={applyVoucher}
						disabled={!selectedVoucher?.code}
						className="ms-2"
						containerClassName={'flex-basis-0'}
					>
						{t(C2cOverPaymentTextEnum.C2cPromoCode_BtnApply)}
					</ButtonClick>
				</div>
				<div className={'mt-2'}>
					{(validateStatus.status === 'error' ||
						validateStatus.status === 'checking') && (
						<InputMessage
							message={t(validateStatus.message || '')}
							type={
								validateStatus.status === 'checking'
									? 'info'
									: validateStatus.status
							}
						/>
					)}
				</div>
				{renderVouchers()}
			</div>
		</ModalDialog>
	);
};

export default PromotionModal;
