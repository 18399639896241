import { C2cValidationFieldModel } from '../models';

export const InitC2cValidationValue: C2cValidationFieldModel = {
	productCategories: {
		message: 'c2c_notification_select_product',
		hasError: false,
	},
	size: {
		message: 'c2c_notification_select_size',
		hasError: false,
	},
	weight: {
		message: 'c2c_notification_select_weight',
		hasError: false,
	},
	paymentMethod: {
		message: 'c2c_notification_select_payment_method',
		hasError: false,
	},
	termAndConditionAgreed: {
		message: 'c2c_notification_agree_service',
		hasError: false
	}
};


